/* eslint-disable import/prefer-default-export */
import React, { ReactNode } from "react";
import { AppModeContext, AppMode } from "@emisgroup/clint-templates-common";
import { addToLocalStorage } from "../utils/localStorageUtils";
import { TEMPLATE_BUILDER_APP_MODE_KEY } from "../constants";

const defaultAppMode = AppMode.EDIT;

type AppModeProviderProps = {
    children: ReactNode;
    initialMode?: AppMode;
};

export const AppModeProvider = ({ children, initialMode = defaultAppMode }: AppModeProviderProps) => {
    const allowedModes = [AppMode.READ, AppMode.RUN, AppMode.EDIT];

    const [mode, setMode] = React.useState<AppMode>(allowedModes.includes(initialMode) ? initialMode : AppMode.READ);
    const [allowedAppModes, setAllowedAppModes] = React.useState<AppMode[]>(allowedModes);

    const updateMode = inputMode => {
        addToLocalStorage(TEMPLATE_BUILDER_APP_MODE_KEY, inputMode);
        setMode(inputMode);
    };
    const setReadOnly = () => {
        if (mode === AppMode.EDIT) {
            setMode(AppMode.READ);
        }

        const updatedAppModes = new Set([...allowedAppModes.filter(appMode => appMode !== AppMode.EDIT), AppMode.READ]);
        setAllowedAppModes([...updatedAppModes]);
    };

    return (
        <AppModeContext.Provider value={{ mode, updateMode, setReadOnly, allowedModes: allowedAppModes }}>
            {children}
        </AppModeContext.Provider>
    );
};
