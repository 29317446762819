/* eslint-disable react/style-prop-object */
import { Button, Checkbox } from "@emisgroup/ui-kit-react";
import * as React from "react";
import { useTranslation } from "@emisgroup/application-intl";
import { v4 as uuid } from "uuid";
import { CanvasItem, findCanvasItem, PickingListOption, TemplateContext } from "@emisgroup/clint-templates-common";
import { ConnectDragSource, ConnectDropTarget } from "react-dnd";
import { LIST_ITEM_DRAG_TYPE } from "../constants";
import addDragDropSort from "./addDragDropSort";
import ListBuilderItem from "./listBuilderItem";
import "./listBuilder.css";
import PropertyError from "./propertyError";
import { getCanvasItemIdsActedOnBy } from "../utils/ruleUtils";

export type ItemWithKey = {
    value: PickingListOption;
    key: string;
};

const SortableListBuilderItem = addDragDropSort(ListBuilderItem, LIST_ITEM_DRAG_TYPE);

const swapItems = (items: ItemWithKey[], fromIndex: number, toIndex: number) => {
    const updatedItems = items.slice();
    const to = updatedItems[toIndex];

    updatedItems[toIndex] = updatedItems[fromIndex];
    updatedItems[fromIndex] = to;

    return updatedItems;
};

type ListConfigProps = {
    hasValues: boolean;
    hasFilingData: boolean;
    onAdd: () => void;
    onHasValuesToggle: (value: boolean) => void;
    onHasFilingDataToggle: (value: boolean) => void;
};
const ListConfig = ({ hasValues, hasFilingData, onAdd, onHasValuesToggle, onHasFilingDataToggle }: ListConfigProps) => {
    const { t } = useTranslation();

    return (
        <div style={{ display: "flex", columnGap: "0.5rem", alignItems: "center" }}>
            <Button
                iconName="add-secondary"
                iconPosition="left"
                data-testid="list-item-add-button"
                onClick={onAdd}
                variant="primary"
            >
                {t("listEntry.addOption")}
            </Button>
            <Checkbox
                labelText="Value"
                data-testid="values-toggle"
                checked={hasValues}
                onChange={e => onHasValuesToggle(e.currentTarget.checked)}
            />
            <Checkbox
                labelText="Data"
                data-testid="filing-data-toggle"
                checked={hasFilingData}
                onChange={e => onHasFilingDataToggle(e.currentTarget.checked)}
            />
        </div>
    );
};

type ListBuilderProps = {
    componentId: string;
    items: ItemWithKey[];
    isReadOnly: boolean;
    hasValues: boolean;
    hasFilingData: boolean;
    validity: { isValid: boolean; message?: string };
    updateItems: (items: ItemWithKey[], updateProperty: boolean) => void;
    onHasValuesToggle: (value: boolean) => void;
    onHasFilingDataToggle: (value: boolean) => void;
};

export const ListBuilder = ({
    componentId,
    items,
    isReadOnly,
    hasValues,
    hasFilingData,
    validity,
    updateItems,
    onHasValuesToggle,
    onHasFilingDataToggle,
}: ListBuilderProps) => {
    const { templateDefinition } = React.useContext(TemplateContext);
    const [focusedItemIndex, setFocusedItemIndex] = React.useState<number | undefined>();

    const handleAdd = () => {
        setFocusedItemIndex(items.length);
        const id = uuid();
        const updatedItems = [...items, { value: { id, text: "" }, key: id }];
        updateItems(updatedItems, false);
    };

    const handleRemove = (indexInItemList: number) => () => {
        setFocusedItemIndex(undefined);
        const updatedItems = [...items];
        updatedItems.splice(indexInItemList, 1);
        updateItems(updatedItems, true);
    };

    const handleUpdate = (index: number) => (newValue: PickingListOption) => {
        setFocusedItemIndex(undefined);
        const updatedItems = items.slice();
        updatedItems[index] = { ...updatedItems[index], value: newValue };
        updateItems(updatedItems, false);
    };

    const handleBlur = (e: React.FocusEvent) => {
        if (e.currentTarget.contains(e.relatedTarget as Node)) {
            e.stopPropagation();
        } else {
            updateItems(items, true);
        }
    };

    const handleSwapItems = (fromIndex: number, toIndex: number) => {
        if (typeof fromIndex === "undefined" || typeof toIndex === "undefined") {
            return;
        }

        updateItems(swapItems(items, fromIndex, toIndex), true);
    };

    const valueValidityClass = validity.isValid ? "" : "invalid-property-value";

    const componentsActedOn = React.useMemo<CanvasItem[]>(
        () =>
            templateDefinition.members
                ? (getCanvasItemIdsActedOnBy(componentId, templateDefinition)
                      .map(id => findCanvasItem(id, templateDefinition.members))
                      .filter(Boolean) as CanvasItem[])
                : [],
        [componentId],
    );

    return (
        <>
            <div data-testid="list-items" className={`list-builder-items ${valueValidityClass}`} onBlur={handleBlur}>
                {items.map((item, index) => {
                    const props = {
                        componentId,
                        value: item.value,
                        index,
                        key: item.key,
                        isReadOnly,
                        hasValues,
                        hasFilingData,
                        componentsActedOn,
                        setFocus: index === focusedItemIndex,
                        onRemove: handleRemove(index),
                        onUpdate: handleUpdate(index),
                        swapItems: handleSwapItems,
                    };

                    return !isReadOnly ? (
                        <SortableListBuilderItem {...props} />
                    ) : (
                        <ListBuilderItem
                            {...props}
                            id={componentId}
                            isDragging={false}
                            connectDragSource={{} as ConnectDragSource}
                            connectDropTarget={{} as ConnectDropTarget}
                        />
                    );
                })}

                {!isReadOnly && (
                    <ListConfig
                        hasValues={hasValues}
                        hasFilingData={hasFilingData}
                        onAdd={handleAdd}
                        onHasValuesToggle={onHasValuesToggle}
                        onHasFilingDataToggle={onHasFilingDataToggle}
                    />
                )}
                <PropertyError validity={validity} />
            </div>
        </>
    );
};
