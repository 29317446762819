import { ComponentType, HistoryRequest } from "@emisgroup/clint-templates-common";
import Logger from "../logging/Logger";

const MAX_MILLISECONDS_TO_WAIT = 10000;
export type QueryResults = {
    [name: string]: any;
};

const getPatientUuid = (patientId: string): string => {
    return patientId.replace("ern:emis:subject:subject:", "");
};

const queryHeader = (patientId: string) => ({
    "clint-version": "0.1",
    description: "template query",
    parameters: [{ name: "person", value: getPatientUuid(patientId) }],
});

const createResourceQueryYaml = async (resourceIds: string[], patientId: string) => {
    const jsYaml = await import("js-yaml");
    const queryObj = {
        ...queryHeader(patientId),
        stored: resourceIds,
    };

    return jsYaml.safeDump(queryObj);
};

const fetchWithTimeout = async (resource, options) => {
    const timeout = MAX_MILLISECONDS_TO_WAIT;

    const controller = new AbortController();
    const id = setTimeout(() => {
        controller.abort();
    }, timeout);

    const response = await fetch(resource, {
        ...options,
        signal: controller.signal,
    });
    clearTimeout(id);
    return response;
};

const executeClintApiCall = async (path: string, bearerToken: string, body: string) => {
    const response = await fetchWithTimeout(path, {
        body,
        method: "POST",
        headers: new Headers({ "Content-Type": "text/plain", Authorization: `Bearer ${bearerToken}` }),
    });

    return response.json();
};

const executeQuery = async (queryApiUrl: string, bearerToken: string, queryYaml: string) =>
    executeClintApiCall(`${queryApiUrl}/query/run`, bearerToken, queryYaml);

export const executeQueryResources = async (
    queryApiUrl: string,
    bearerToken: string,
    resourceIds: string[],
    patientId: string,
): Promise<QueryResults> => {
    if (!resourceIds.length) return {};

    const distinctResourceIds = Array.from(new Set(resourceIds));
    const results = await executeQuery(
        queryApiUrl,
        bearerToken,
        await createResourceQueryYaml(distinctResourceIds, patientId),
    );
    if (!results.QueryResults) {
        return results;
    }

    return {
        ...results,
        QueryResults: results.QueryResults.map((result, index) => ({
            ...result,
            resourceId: distinctResourceIds[index],
        })),
    };
};

const areConfidentialHistoryItemsRemoved = (queryMetaData: any, patientId: string): boolean => {
    if (!queryMetaData) {
        return false;
    }

    const patientUuid = getPatientUuid(patientId);
    const patientMetaDataProperty = Object.keys(queryMetaData).find(key => key.endsWith(patientUuid));
    const patientMetaData = patientMetaDataProperty ? queryMetaData[patientMetaDataProperty] : {};

    return Boolean(
        patientMetaData.AreConfidentialActiveDiaryEntriesRemoved ||
            patientMetaData.AreConfidentialObservationsRemoved ||
            patientMetaData.AreConfidentialProblemsRemoved,
    );
};

export const executeHistoryQueries = async (
    queryApiUrl: string,
    bearerToken: string,
    requests: HistoryRequest[],
    patientId: string,
) => {
    if (!requests.length) {
        return { history: [] };
    }
    const distinctRequests: HistoryRequest[] = [];
    const map = new Map();
    requests.forEach(item => {
        if (!map.has(item.key)) {
            map.set(item.key, true);
            distinctRequests.push(item);
        }
    });
    try {
        const request = {
            PersonIdentifier: getPatientUuid(patientId),
            Components: distinctRequests.map(hr => ({
                Id: hr.key,
                Codes: hr.emisCodeIds?.map(codeId => `${codeId}`),
                HistorySource: hr.componentType === ComponentType.DIARY_ENTRY ? "diary" : "clinical",
            })),
        };
        const results = await executeClintApiCall(`${queryApiUrl}/query/history`, bearerToken, JSON.stringify(request));

        if (!results.QueryResults) {
            return {
                errors: distinctRequests,
                areConfidentialItemsRemoved: areConfidentialHistoryItemsRemoved(results.MetaData, patientId),
            };
        }

        return {
            history: results.QueryResults.map((result, index) => ({
                key: distinctRequests[index].key,
                ...result,
            })),
            areConfidentialItemsRemoved: areConfidentialHistoryItemsRemoved(results.MetaData, patientId),
        };
    } catch (ex) {
        Logger.error(ex);
        return { errors: distinctRequests };
    }
};
