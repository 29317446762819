import { AlertSize, AlertType, ButtonProps } from "../types";
import GenericDialog from "../uiComponents/genericDialog";
import AlertDialog from "../uiComponents/alertDialog";

export const getGenericDialog = (
    dialogTitle: string,
    dialogButtons: ButtonProps[],
    hideDialog: () => void,
    dialogContent,
    disableDismiss?: boolean,
) => (
    <GenericDialog title={dialogTitle} buttons={dialogButtons} onCancel={hideDialog} disableDismiss={disableDismiss}>
        {dialogContent}
    </GenericDialog>
);

export const getAlertDialog = (
    title: string,
    content: any,
    buttons: ButtonProps[],
    onClose: () => void,
    type?: AlertType,
    size?: AlertSize,
) => {
    return (
        <AlertDialog type={type} size={size} title={title} buttons={buttons} onClose={onClose}>
            {content}
        </AlertDialog>
    );
};
