import { useContext } from "react";
import {
    Calculator,
    ClinicalContent,
    Coded,
    CodedPickingList,
    DiaryEntry,
    FreeText,
    NumericValue,
    PanelComponent,
    UncodedPickingList,
    Uncoded,
    CodedComponent,
    ComponentType,
    DataFromTemplateRun,
    CanvasItem,
    Panel,
    Tab,
    ClinicalContentComponent,
    NumericValueComponent,
    PickingListComponent,
    CodedPickingListComponent,
    DiaryEntryComponent,
    FreeTextComponent,
    UncodedComponent,
    CalculatorComponent,
    ActionButton,
    BrandingComponent,
    Branding,
} from "@emisgroup/clint-templates-common";
import { ErrorHandling, ErrorState } from "@emisgroup/clinical-code-entry/lib/types";
import TabContainer from "./templateComponents/tabContainer"; // eslint-disable-line import/no-cycle
import CanvasColumns from "./canvasColumns"; // eslint-disable-line import/no-cycle
import { validateComponent } from "./utils/validationUtils";
import { ActionsContext } from "./context/actions";

type CanvasComponentProps = {
    component: CanvasItem;
    onTemplateDataChange: (data: DataFromTemplateRun) => void;
    onHandleError?: (errorState: ErrorState) => ErrorHandling;
};

function noop() {
    // This is intentional
}

const CanvasComponent = ({ component, onTemplateDataChange, onHandleError }: CanvasComponentProps) => {
    const runtimeValidator = data => validateComponent(component, data);
    const { onRaiseAction } = useContext(ActionsContext);
    const handleRaiseAction = (actionButton: ActionButton) => {
        if (onRaiseAction) {
            onRaiseAction(actionButton.action, actionButton.validate, component);
        }
    };

    const componentProps = {
        isSelected: false,
        arePropertiesValid: true,
        onSelect: noop,
        onPropertyUpdate: noop,
        onChange: onTemplateDataChange,
    };
    switch (component.type) {
        case ComponentType.BRANDING:
            return <Branding component={component as BrandingComponent} />;
        case ComponentType.CODED:
            return <Coded {...componentProps} {...(component as CodedComponent)} onHandleError={onHandleError} />;

        case ComponentType.UNCODED:
            return (
                <Uncoded {...componentProps} {...(component as UncodedComponent)} runtimeValidator={runtimeValidator} />
            );

        case ComponentType.PANEL:
            return (
                <PanelComponent component={component as Panel} onSelect={noop} onRaiseAction={handleRaiseAction}>
                    <CanvasColumns component={component as Panel} />
                </PanelComponent>
            );

        case ComponentType.TAB_CONTAINER:
            return <TabContainer tabContainer={component as Tab} />;

        case ComponentType.CLINICAL_CONTENT:
            return (
                <ClinicalContent {...componentProps} clinicalContentComponent={component as ClinicalContentComponent} />
            );

        case ComponentType.NUMERIC_VALUE:
            return (
                <NumericValue
                    {...componentProps}
                    {...(component as NumericValueComponent)}
                    runtimeValidator={runtimeValidator}
                />
            );

        case ComponentType.PICKING_LIST:
            return (
                <UncodedPickingList
                    {...componentProps}
                    {...(component as PickingListComponent)}
                    runtimeValidator={runtimeValidator}
                />
            );

        case ComponentType.FREE_TEXT:
            return (
                <FreeText
                    {...componentProps}
                    {...(component as FreeTextComponent)}
                    runtimeValidator={runtimeValidator}
                />
            );

        case ComponentType.CODED_PICKING_LIST:
            return (
                <CodedPickingList
                    {...componentProps}
                    {...(component as CodedPickingListComponent)}
                    onHandleError={onHandleError}
                    runtimeValidator={runtimeValidator}
                />
            );

        case ComponentType.DIARY_ENTRY:
            return <DiaryEntry {...componentProps} {...(component as DiaryEntryComponent)} />;

        case ComponentType.CALCULATOR:
            return <Calculator {...componentProps} {...(component as CalculatorComponent)} />;

        default:
            return null;
    }
};

export default CanvasComponent;
