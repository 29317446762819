import * as React from "react";
import {
    findCanvasItem,
    TemplateContext,
    ComponentIcon,
    ComponentType,
    PickingListComponent,
    ComponentContainer,
    CanvasItem,
    ComponentValueCondition,
} from "@emisgroup/clint-templates-common";
import { useTranslation } from "@emisgroup/application-intl";
import { createVisibilityRulesComponentTree } from "../utils/componentSelectorUtils";
import { getCanvasItemIdsActedOnBy } from "../utils/ruleUtils";
import TreeView from "./treeView/treeView";

import "./visibilityRuleViewer.css";

type VisibilityRuleViewerProps = { componentId: string; optionValue?: string };
type ComponentHeaderProps = VisibilityRuleViewerProps & { template: ComponentContainer };
function ComponentHeader({ componentId, optionValue, template }: ComponentHeaderProps) {
    const component = React.useMemo(() => findCanvasItem(componentId, template.members), [componentId]);
    const option = React.useMemo(
        () =>
            !optionValue || component?.type !== ComponentType.PICKING_LIST
                ? null
                : (component as PickingListComponent).options.find(({ id }) => id === optionValue) || null,
        [component],
    );

    const optionDisplay = !option ? "" : ` - ${option.text}`;

    return (
        <div className="component-header">
            <ComponentIcon type={component?.type as ComponentType} /> {`${component?.label} ${optionDisplay}`}
        </div>
    );
}

type AffectedListProps = VisibilityRuleViewerProps & {
    template: ComponentContainer;
    selectedId: string;
    onSelect: (id: string) => void;
};
function AffectedList({ componentId, optionValue, template, selectedId, onSelect }: AffectedListProps) {
    const affected = React.useMemo(() => {
        const components = getCanvasItemIdsActedOnBy(componentId, template)
            .map(id => findCanvasItem(id, template.members))
            .filter(Boolean) as CanvasItem[];

        return !optionValue
            ? components
            : components.filter(
                  ({ rule }) =>
                      rule?.conditionMembers &&
                      rule.conditionMembers.find((member: ComponentValueCondition) => member.value === optionValue),
              );
    }, [componentId, optionValue, template]);

    return (
        <ul className="affected-list" data-testid="affected-list">
            {affected.map(item => (
                <li key={item.id}>
                    <button
                        type="button"
                        onClick={() => onSelect(item.id)}
                        className={selectedId === item.id ? "selected-item" : ""}
                    >
                        <ComponentIcon type={item.type as ComponentType} />
                        {item.label}
                    </button>
                </li>
            ))}
        </ul>
    );
}

type ComponentTreeProps = { template: ComponentContainer; selectedId: string };
function ComponentTree({ template, selectedId }: ComponentTreeProps) {
    const { t } = useTranslation();
    const componentsTreeRootNode = createVisibilityRulesComponentTree(t, template, selectedId);

    return (
        <div className="tree-container" data-testid="template-tree">
            <TreeView showRootNode={false} rootNode={componentsTreeRootNode} showNodeId={selectedId} />
        </div>
    );
}

type VisibilityGridProps = VisibilityRuleViewerProps & { template: ComponentContainer };
function VisibilityGrid(props: VisibilityGridProps) {
    const { t } = useTranslation();
    const [selectedId, setSelectedId] = React.useState("");

    return (
        <div className="visibility-grid">
            <div className="grid-header">{t("visibilityRuleViewer.affectsVisibilityOf")}</div>
            <div className="grid-header">{t("visibilityRuleViewer.location")}</div>
            <AffectedList {...props} selectedId={selectedId} onSelect={setSelectedId} />
            <ComponentTree template={props.template} selectedId={selectedId} />
        </div>
    );
}

export default function VisibilityRuleViewer({ componentId, optionValue }: VisibilityRuleViewerProps) {
    const { templateDefinition } = React.useContext(TemplateContext);

    return (
        <div className="visibility-rule-viewer">
            <ComponentHeader componentId={componentId} optionValue={optionValue} template={templateDefinition} />
            <VisibilityGrid componentId={componentId} optionValue={optionValue} template={templateDefinition} />
        </div>
    );
}
