/* eslint-disable react/style-prop-object */
import * as React from "react";
import { Button } from "@emisgroup/ui-kit-react";
import { AuthContext, Code, ComponentType } from "@emisgroup/clint-templates-common";
import { useTranslation } from "@emisgroup/application-intl";
import CodePickerDialog from "../uiComponents/codePickerDialog";
import { PropertyValueProps } from "./types";
import { CodeDisplay, RemoveCodeButton } from "../uiComponents/inlineCodeItem";

type CodePropertyEntryProps = PropertyValueProps;

const CodePropertyEntry = ({ isReadOnly, component, name, onPropertyUpdate }: CodePropertyEntryProps) => {
    const { t } = useTranslation();
    const [codePickerOpen, setCodePickerOpen] = React.useState<boolean>(false);

    const { getBearerToken } = React.useContext(AuthContext);
    const bearerTokenRef = React.useRef("");
    React.useEffect(() => {
        const updateBearerToken = async () => {
            bearerTokenRef.current = await getBearerToken();
        };
        updateBearerToken();
    }, [getBearerToken]);

    const openCodePicker = evt => {
        setCodePickerOpen(true);
        evt.stopPropagation();
    };

    const closeCodePicker = () => {
        setCodePickerOpen(false);
    };

    const onCodeSelect = (code: Code) => {
        onPropertyUpdate({ item: component, propertyName: name, propertyValue: code });
        closeCodePicker();
    };

    const handleRemoveCode = () => onPropertyUpdate({ item: component, propertyName: name, propertyValue: undefined });

    const hasDefinedValue = typeof component[name] !== "undefined";

    const valueToDisplay = () => (
        <span className="value-text">{hasDefinedValue ? component[name].term : t("codeEntry.noCodeSelected")}</span>
    );

    const suppressPreferredTermAlert = component.type === ComponentType.DIARY_ENTRY;
    const clickToSelectCode = t("codeEntry.clickToSelectCode");
    return (
        <div>
            {isReadOnly && valueToDisplay()}
            {!isReadOnly && !component[name] && (
                <Button onClick={openCodePicker} ariaLabel={clickToSelectCode} iconName="picker" variant="primary">
                    {t("codeEntry.selectCode")}
                </Button>
            )}
            {!isReadOnly && component[name] && (
                <div className="code-property-selector">
                    <div className="code-property-value">
                        <CodeDisplay code={component[name]} includeDetails={true} />
                    </div>
                    <div className="code-property-buttons">
                        <Button
                            iconName="picker"
                            variant="primary"
                            iconOnly
                            onClick={openCodePicker}
                            ariaLabel={clickToSelectCode}
                        />
                        <RemoveCodeButton onRemove={handleRemoveCode} />
                    </div>
                </div>
            )}
            {codePickerOpen && (
                <CodePickerDialog
                    onClose={closeCodePicker}
                    onCodeSelect={onCodeSelect}
                    bearerToken={bearerTokenRef.current}
                    suppressPreferredTermAlert={suppressPreferredTermAlert}
                />
            )}
        </div>
    );
};

export default CodePropertyEntry;
