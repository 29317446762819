import { apiWithRetry, Api, ApiError } from "@emisgroup/content-library-sdk-ts";
import { ContentLibraryActivity } from "../types";

const FETCH_RETRY_TIMEOUT_MS = 1000;

export const getContentLibraryApi = async (contentLibraryUrl: string, bearerToken: string): Promise<Api> => {
    return apiWithRetry(contentLibraryUrl, fetch, bearerToken, 3, FETCH_RETRY_TIMEOUT_MS);
};

export const NAME_IN_USE_ERROR_CODE = "ern:emis:x:error:resource-name-in-use";
export const ATTRIBUTE_TOO_LONG_ERROR_CODE = "ern:emis:x:error:attribute-limit-exceeded";
export const ATTRIBUTE_NOT_TRIMMED_ERROR_CODE = "ern:emis:x:error:attribute-not-trimmed";
export const PATH_PARAMETER_INVALID_ERROR_CODE = "ern:emis:x:error:path-parameter-invalid";
export const PATH_PARAMETER_MISSING_ERROR_CODE = "ern:emis:x:error:path-parameter-missing";
export const RESOURCE_NOT_FOUND_ERROR_CODE = "ern:emis:x:error:resource-not-found";
export const PERMISSION_DENIED_ERROR_CODE = "ern:emis:x:error:permission-denied";
export const VERSION_OUT_OF_DATE_ERROR_CODE = "ern:emis:x:error:version-out-of-date";

export const NAME_ATTRIBUTE = "name";
export const RESOURCE_ERN_PATH_PARAMETER = "resourceErn";

export const NAME_IN_USE_ERROR_MESSAGE = "save.errorNameInUse";
export const NAME_TOO_LONG_ERROR_MESSAGE = "save.errorNameTooLong";
export const NAME_NOT_TRIMMED_ERROR_MESSAGE = "save.errorNameNotTrimmed";
export const INVALID_ERN_ERROR_MESSAGE = "save.errorInvalidErn";
export const RESOURCE_NOT_FOUND_ERROR_MESSAGE = "save.errorNoSuchResource";
export const DRAFT_DELETED_ERROR_MESSAGE = "save.errorDraftDeleted";
export const PERMISSION_DENIED_ERROR_MESSAGE = "save.errorPermissionDenied";
export const VERSION_OUT_OF_DATE_ERROR_MESSAGE = "save.errorDraftConflict";

export const TEMPLATE_RESOURCE_TYPE = "emis:resource:type:template";

export const getStatusCode = (apiErrors: ApiError[]): string => {
    return apiErrors[0].status;
};

const errorReferencesAttribute = (apiError: ApiError, attribute: string): boolean => {
    return typeof apiError.detail !== "undefined" && apiError.detail.includes(attribute);
};

const ensureMessage = (apiError: ApiError): string => {
    return apiError.detail || apiError.title || apiError.status;
};

export const getReadableFailMessage = (apiErrors: ApiError[], errorActivity: ContentLibraryActivity) => {
    const apiError = apiErrors[0];

    switch (apiError.code) {
        case NAME_IN_USE_ERROR_CODE:
            return { message: NAME_IN_USE_ERROR_MESSAGE, propertyName: "name" };
        case ATTRIBUTE_TOO_LONG_ERROR_CODE:
            return errorReferencesAttribute(apiError, NAME_ATTRIBUTE)
                ? { message: NAME_TOO_LONG_ERROR_MESSAGE, propertyName: "name" }
                : { message: ensureMessage(apiError) };
        case ATTRIBUTE_NOT_TRIMMED_ERROR_CODE:
            return errorReferencesAttribute(apiError, NAME_ATTRIBUTE)
                ? { message: NAME_NOT_TRIMMED_ERROR_MESSAGE, propertyName: "name" }
                : { message: ensureMessage(apiError) };
        case PATH_PARAMETER_INVALID_ERROR_CODE:
        case PATH_PARAMETER_MISSING_ERROR_CODE:
            return errorReferencesAttribute(apiError, RESOURCE_ERN_PATH_PARAMETER)
                ? { message: INVALID_ERN_ERROR_MESSAGE }
                : { message: ensureMessage(apiError) };
        case RESOURCE_NOT_FOUND_ERROR_CODE:
            return {
                message:
                    errorActivity === ContentLibraryActivity.LOAD
                        ? RESOURCE_NOT_FOUND_ERROR_MESSAGE
                        : DRAFT_DELETED_ERROR_MESSAGE,
            };
        case PERMISSION_DENIED_ERROR_CODE:
            return { message: PERMISSION_DENIED_ERROR_MESSAGE };
        case VERSION_OUT_OF_DATE_ERROR_CODE:
            return { message: VERSION_OUT_OF_DATE_ERROR_MESSAGE };
        default:
            return { message: ensureMessage(apiError) };
    }
};

export const definitionAsObject = (definition: any) => {
    return typeof definition === "string" ? JSON.parse(definition) : definition;
};
