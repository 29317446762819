import { MouseEvent } from "react";
import { TabItemType, TabItem } from "@emisgroup/clint-templates-common";

export type TabPageSelectedProps = { evt: MouseEvent; selectedTabIndex: number };

type DynamicTabPageProps = {
    tabItem: TabItemType;
    tabPageIndex: number;
    onTabSelect: (tabPageSelectedProps: TabPageSelectedProps) => void;
    isTabPageSelected: boolean;
    dynamicTabId: string;
};

const DynamicTabPage = ({
    tabItem,
    tabPageIndex,
    onTabSelect,
    isTabPageSelected,
    dynamicTabId,
}: DynamicTabPageProps) => {
    return (
        <>
            <div key={tabPageIndex} data-testid={`drag-container-${dynamicTabId}-${tabPageIndex}`}>
                <TabItem
                    {...tabItem}
                    tabPageIndex={tabPageIndex}
                    isTabPageSelected={isTabPageSelected}
                    onTabSelect={onTabSelect}
                />
            </div>

            <div className="tab-spacer" />
        </>
    );
};

export default DynamicTabPage;
