import {
    ButtonProps,
    getGenericDialog,
    getAlertDialog,
    AlertType,
    AlertSize,
    ComponentType,
    COMPONENT_TYPE_LABELS,
} from "@emisgroup/clint-templates-common";
import React from "react";
import { v4 as uuid } from "uuid";
import { ConflictType } from "../types";

export type Conflicts = { description: string; conflictType: ConflictType }[];

const dialogContentForDeletion = (t, itemType: string, additionalInformation: string) => (
    <>
        <p />
        <p>
            {t("confirmDeletionOfSelectedItem", { item: itemType })}
            {additionalInformation}
        </p>
    </>
);

const DeletionConflicts = ({ itemType, conflicts, message }) =>
    conflicts.length > 0 ? (
        <>
            <p />
            <p data-testid={`cannot-delete-${itemType}-message`}>{message}</p>
            <p />
            {conflicts.map(warning => (
                <p key={uuid()}>{warning}</p>
            ))}
        </>
    ) : null;

const dialogContentForBlockedDeletion = (t, itemType: string, conflicts: Conflicts) => (
    <>
        <DeletionConflicts
            itemType={itemType}
            message={t("templates.cannotDeleteItemDueToVisibilityRules", { item: itemType })}
            conflicts={conflicts
                .filter(({ conflictType }) => conflictType === ConflictType.VisibilityRule)
                .map(({ description }) => description)}
        />
        <DeletionConflicts
            itemType={itemType}
            message={t("templates.cannotDeleteItemDueToCalculationParam", { item: itemType })}
            conflicts={conflicts
                .filter(({ conflictType }) => conflictType === ConflictType.CalculationParameter)
                .map(({ description }) => description)}
        />
    </>
);

const dialogContentForGroupingBlocked = (t, visibilityRuleConflicts: string[]) => (
    <>
        <p />
        <p data-testid="cannot-group-message">{t("templates.cannotGroupComponentsDueToVisibilityRules")}:</p>
        <p />
        {visibilityRuleConflicts.map(warning => (
            <p key={uuid()}>{warning}</p>
        ))}
    </>
);

const getDialogContentForUngrouping = t => <p>{t("templates.confirmPanelDeletion")}</p>;

type SaveConflictDialogProps = {
    t: any;
    conflicts: Conflicts;
    onClose: () => void;
};
type VisibilityConflictDialogProps = {
    t: any;
    visibilityConflicts: string[];
    onClose: () => void;
};

const dialogContentForItemMove = (t, visibilityConflicts: string[]) => (
    <>
        <p />
        <p>{t("templates.cannotMoveDueToVisibilityRules", { action: t("move"), item: t("component") })}</p>
        <ul>
            {visibilityConflicts.map(conflict => (
                <li key={uuid()}>{conflict}</li>
            ))}
        </ul>
    </>
);

const SaveSelectionConflicts = ({ message, conflicts }) =>
    conflicts.length ? (
        <>
            <p />
            <p>{message}</p>
            <ul>
                {conflicts.map(conflict => (
                    <li key={uuid()}>{conflict}</li>
                ))}
            </ul>
        </>
    ) : null;
const dialogContentForSaveSelection = (t, conflicts: Conflicts) => (
    <>
        <SaveSelectionConflicts
            message={t("templates.cannotSaveSelectionDueToVisibilityRules")}
            conflicts={conflicts
                .filter(({ conflictType }) => conflictType === ConflictType.VisibilityRule)
                .map(({ description }) => description)}
        />
        <SaveSelectionConflicts
            message={t("templates.cannotSaveSelectionDueToCalculators")}
            conflicts={conflicts
                .filter(({ conflictType }) => conflictType === ConflictType.CalculationParameter)
                .map(({ description }) => description)}
        />
    </>
);

const getCloseButton = (t, close: () => void, ariaLabel: string): ButtonProps[] => [
    {
        label: t("close"),
        ariaLabel: t(ariaLabel),
        onClick: close,
        isPrimary: true,
    },
];

const getDialogButtonsForBlockedAction = (t, closeDialog: () => void) =>
    getCloseButton(t, closeDialog, "doNotContinue");

const getDialogButtonsForDeletion = (
    t: any,
    conflicts: any[],
    canvasItemType: string,
    deleteItem: () => void,
    closeDialog: () => void,
): ButtonProps[] => {
    let buttons;
    if (conflicts.length) {
        buttons = getDialogButtonsForBlockedAction(t, closeDialog);
    } else {
        buttons = [
            {
                label: t("delete"),
                ariaLabel: t("clickToDeleteSelectedItem", { item: canvasItemType }),
                iconClasses: "primary-danger",
                onClick: () => {
                    deleteItem();
                    closeDialog();
                },
            },
            {
                label: t("cancel"),
                ariaLabel: t("doNotContinue"),
                onClick: closeDialog,
                isPrimary: false,
            },
        ];
    }
    return buttons;
};

const getDialogButtonsForUngrouping = (ungroup: () => void, closeDialog: () => void): ButtonProps[] => [
    {
        label: "Ungroup",
        isPrimary: true,
        ariaLabel: "Click to ungroup the selected panel",
        onClick: () => {
            ungroup();
            closeDialog();
        },
    },
    {
        label: "Cancel",
        ariaLabel: "Do not continue",
        onClick: closeDialog,
        isPrimary: false,
    },
];

const getItemTypeForDialog = (t, itemType: string) => {
    let itemTypeForDialog;
    if (itemType === ComponentType.TAB_CONTAINER) itemTypeForDialog = t("components.tabBar.tabbedContainer");
    else itemTypeForDialog = t(COMPONENT_TYPE_LABELS[itemType]);

    return itemTypeForDialog.toLowerCase();
};

const getDialogTitleForDeletion = (t, itemType: string) => {
    let itemTypeForTitle;
    switch (itemType) {
        case ComponentType.TAB_CONTAINER:
            itemTypeForTitle = t("components.tabBar.tabbedContainer");
            break;
        case ComponentType.TAB_PAGE:
        case ComponentType.PANEL:
            itemTypeForTitle = t(COMPONENT_TYPE_LABELS[itemType]);
            break;
        default:
            itemTypeForTitle = t("components.component");
            break;
    }

    return t("deleteItem", { item: itemTypeForTitle.toLowerCase() });
};

export const getDialogForItemMove = ({ t, visibilityConflicts, onClose }: VisibilityConflictDialogProps) =>
    getGenericDialog(
        t("moveComponent"),
        getCloseButton(t, onClose, "doNotMoveComponent"),
        onClose,
        dialogContentForItemMove(t, visibilityConflicts),
        true,
    );

export const getDialogForSaveSelection = ({ t, conflicts, onClose }: SaveConflictDialogProps) =>
    getGenericDialog(
        t("save.selection"),
        getCloseButton(t, onClose, "save.doNotSaveSelection"),
        onClose,
        dialogContentForSaveSelection(t, conflicts),
        true,
    );

export const getDialogForItemDeletion = ({
    t,
    membersExist,
    canvasItemType,
    conflicts,
    onDelete,
    hideDialog,
}: {
    t: any;
    membersExist: boolean;
    canvasItemType: string;
    conflicts: Conflicts;
    onDelete: () => void;
    hideDialog: () => void;
}) => {
    const itemType = getItemTypeForDialog(t, canvasItemType);
    const additionalInformation = membersExist ? ` ${t("andAllOfItsComponents", { item: itemType })}` : "";
    const dialogTitle = getDialogTitleForDeletion(t, canvasItemType);
    const dialogButtons = getDialogButtonsForDeletion(t, conflicts, itemType, onDelete, hideDialog);
    const dialogContent =
        conflicts.length > 0
            ? dialogContentForBlockedDeletion(t, itemType, conflicts)
            : dialogContentForDeletion(t, itemType, additionalInformation);
    return getGenericDialog(dialogTitle, dialogButtons, hideDialog, dialogContent, true);
};

export const getDialogForGroupingItems = ({
    t,
    visibilityRuleConflicts,
    closeDialog,
}: {
    t: any;
    visibilityRuleConflicts: string[];
    closeDialog: () => void;
}) => {
    const dialogTitle = t("templates.groupComponents");
    const dialogButtons = getDialogButtonsForBlockedAction(t, closeDialog);
    const dialogContent = dialogContentForGroupingBlocked(t, visibilityRuleConflicts);
    return getGenericDialog(dialogTitle, dialogButtons, closeDialog, dialogContent, true);
};

export const getDialogForUngroupingPanel = ({
    t,
    ungroupPanel,
    hideDialog,
}: {
    t: any;
    ungroupPanel: () => void;
    hideDialog: () => void;
}) => {
    const dialogTitle = t("templates.ungroupPanel");
    const dialogButtons = getDialogButtonsForUngrouping(ungroupPanel, hideDialog);
    const dialogContent = getDialogContentForUngrouping(t);
    return getGenericDialog(dialogTitle, dialogButtons, hideDialog, dialogContent, true);
};

export const getAlertDialogForUnsavedChanges = ({
    t,
    templateName,
    saveAndContinueOperation,
    continueOperation,
    closeDialog,
}: {
    t: any;
    templateName: string;
    saveAndContinueOperation: () => void;
    continueOperation: () => void;
    closeDialog: () => void;
}) => {
    const dialogButtons = [
        {
            onClick: saveAndContinueOperation,
            label: t("save.draft"),
            isPrimary: true,
            ariaLabel: t("save.changesAndContinue"),
            disabled: false,
        },
        {
            onClick: continueOperation,
            label: t("save.discard"),
            isPrimary: false,
            isDanger: true,
            ariaLabel: t("save.discardAndContinue"),
        },
        {
            onClick: closeDialog,
            label: t("cancel"),
            isSecondary: true,
            ariaLabel: t("clickToCancel"),
        },
    ];

    const content = (
        <>
            <p>{t("save.wouldYouLikeToSave")}</p>
            <h4 style={{ marginBottom: 0 }}>{t("templates.name")}:</h4>
            <p>{templateName}</p>
        </>
    );

    return getAlertDialog(t("save.changes"), content, dialogButtons, closeDialog, AlertType.WARNING, AlertSize.LARGE);
};

export const getBlockingErrorAlertDialog = ({
    t,
    title,
    errorMessage,
    onClose,
}: {
    t: any;
    title: string;
    errorMessage: string;
    onClose: () => void;
}) => {
    return getAlertDialog(
        t(title),
        <p>{t(errorMessage)}</p>,
        getCloseButton(t, onClose, "doNotContinue"),
        onClose,
        AlertType.ERROR,
    );
};

export const getBlockingErrorForOutdatedAlertDialog = ({
    t,
    title,
    errorMessage,
    onDiscard,
    onCancel,
}: {
    t: any;
    title: string;
    errorMessage: string;
    onDiscard: () => void;
    onCancel: () => void;
}) => {
    const dialogButtons = [
        {
            onClick: onDiscard,
            label: t("save.discardAndClose"),
            isPrimary: false,
            isDanger: true,
            ariaLabel: t("save.discardAndClose"),
        },
        {
            onClick: onCancel,
            label: t("cancel"),
            isSecondary: true,
            ariaLabel: t("cancel"),
        },
    ];

    return getAlertDialog(t(title), <p>{t(errorMessage)}</p>, dialogButtons, onCancel, AlertType.ERROR);
};
