import { useTranslation } from "@emisgroup/application-intl";
import { getAlertDialog, AlertType } from "../../clint-templates-common/lib";

type VisibilityRulesErrorAlertProps = { retry: () => void; cancel: () => void };
const VisibilityRulesErrorAlert = ({ retry, cancel }: VisibilityRulesErrorAlertProps) => {
    const { t } = useTranslation();
    return (
        <div data-testid="visibility-rules-error-alert">
            {getAlertDialog(
                t("canvas.visibilityRulesError.title"),
                <p>{t("canvas.visibilityRulesError.detail")}</p>,
                [
                    {
                        onClick: retry,
                        label: t("retry"),
                        isPrimary: true,
                        ariaLabel: t("retry"),
                        disabled: false,
                    },
                    {
                        onClick: cancel,
                        label: t("cancel"),
                        isPrimary: false,
                        ariaLabel: t("cancel"),
                    },
                ],
                cancel,
                AlertType.ERROR,
            )}
        </div>
    );
};

export default VisibilityRulesErrorAlert;
