import * as React from "react";

const MAX_CHARS = 15;
const ALLOWED_KEYS_REGEX =
    /(-|[0-9.]|Backspace|Tab|Enter|Delete|Insert|ArrowLeft|ArrowRight|ArrowUp|ArrowDown|Shift|Home|End|PageUp|PageDown)/;
const NUMERIC_KEYS_REGEX = /[0-9.]/;

const isAllowedCriteria = (key: string, existingValue: string): boolean => {
    return ALLOWED_KEYS_REGEX.test(key) && (key !== "-" || existingValue === "");
};

type NumericEntryProps = {
    id?: string;
    className?: string;
    value?: number;
    defaultValue?: number;
    min?: number;
    max?: number;
    readOnly?: boolean;
    disabled?: boolean;
    "aria-label"?: string;
    "data-testid"?: string;
    onChange: (value: number) => void;
    onBlur: (value: number, e: React.FocusEvent<HTMLInputElement>) => void;
};

const NumericEntry = ({
    id,
    className,
    value,
    defaultValue,
    min,
    max,
    readOnly,
    disabled,
    "aria-label": ariaLabel,
    "data-testid": dataTestId,
    onChange,
    onBlur,
}: NumericEntryProps) => {
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.ctrlKey || e.altKey || e.metaKey) {
            return;
        }

        if (!isAllowedCriteria(e.key, e.currentTarget.value)) {
            e.preventDefault();
            return;
        }

        if (e.currentTarget.value.toString().length >= MAX_CHARS && NUMERIC_KEYS_REGEX.test(e.key)) {
            e.preventDefault();
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.currentTarget.value === "" ? Number.NaN : Number(e.currentTarget.value));
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        onBlur(e.currentTarget.value === "" ? Number.NaN : Number(e.currentTarget.value), e);
    };

    const handlePaste = (e: React.ClipboardEvent) => {
        const pasting = e.clipboardData.getData("text");
        if (Number.isNaN(Number(pasting))) {
            e.preventDefault();
        }
    };

    return (
        <input
            type="number"
            id={id}
            className={className}
            value={value}
            defaultValue={defaultValue}
            min={min}
            max={max}
            step="any"
            aria-label={ariaLabel}
            data-testid={dataTestId}
            readOnly={readOnly}
            disabled={disabled}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            onBlur={handleBlur}
            onPaste={handlePaste}
        />
    );
};

export default NumericEntry;
