import React from "react";
import { Tag } from "@emisgroup/ui-kit-react";
import {
    CanvasItem,
    ComponentContainer,
    ComponentType,
    ComponentIcon,
    itemsInColumnOrder,
    isDescendantOf,
    isEmbeddedItem,
    isDescendantOfEmbeddedItem,
    COMPONENT_TYPE_LABELS,
} from "@emisgroup/clint-templates-common";
import { TreeNodeData } from "../uiComponents/treeView/types";
import { getCanvasItemIdsActedOnBy } from "./ruleUtils";

const containerTypes = [
    String(ComponentType.PANEL),
    String(ComponentType.TEMPLATE),
    String(ComponentType.TAB_CONTAINER),
    String(ComponentType.TAB_PAGE),
];

const isContainerType = (type: string): boolean => containerTypes.some(containerType => containerType === type);

const isOrIsDescendantOfNewPanelMembers = (
    canvasItemId: string,
    templateItems: CanvasItem[],
    newPanelMemberIds?: string[],
): boolean => {
    return newPanelMemberIds
        ? newPanelMemberIds.includes(canvasItemId) || isDescendantOf(canvasItemId, newPanelMemberIds, templateItems)
        : false;
};

const createTags = (canvasItem: CanvasItem, isLeaf: boolean, nodeBuilder: NodeBuilder): (JSX.Element | null)[] => {
    const isNewPanelMember = isOrIsDescendantOfNewPanelMembers(
        canvasItem.id,
        nodeBuilder.templateItems,
        nodeBuilder.newPanelMemberIds,
    );
    const newPanelMemberTag = isNewPanelMember ? (
        <Tag className="component-tag" color="color-3" text={nodeBuilder.t("componentSelection.newPanelMember")} />
    ) : null;

    const isMasterEmbeddedComponent =
        !isLeaf && isEmbeddedItem(canvasItem) && !isDescendantOfEmbeddedItem(canvasItem, nodeBuilder.templateItems);
    const masterEmbeddedTag = isMasterEmbeddedComponent ? (
        <Tag
            className="component-tag"
            color="color-2"
            text={nodeBuilder.t("componentSelection.contentLibraryResource")}
        />
    ) : null;

    return [newPanelMemberTag, masterEmbeddedTag];
};

const createTreeNode = (canvasItem: CanvasItem, isLeaf: boolean, nodeBuilder: NodeBuilder): TreeNodeData => {
    const selectionError = nodeBuilder.getSelectionError(canvasItem);
    const type = canvasItem.type as ComponentType;
    const label = canvasItem.label || nodeBuilder.t(COMPONENT_TYPE_LABELS[type]);
    const tags = createTags(canvasItem, isLeaf, nodeBuilder);
    const disabled = Boolean(selectionError);

    return {
        content: (
            <>
                <ComponentIcon type={type} disabled={disabled} />
                {label}
                {tags}
            </>
        ),
        isLeaf,
        nodeId: canvasItem.id,
        disabled,
        className: nodeBuilder.getClassName(canvasItem.id),
        metadata: selectionError ? { selectionError } : undefined,
    };
};

export const toTreeNode = (canvasItem: CanvasItem, nodeBuilder: NodeBuilder): TreeNodeData => {
    if (isContainerType(canvasItem.type)) {
        const container = canvasItem as ComponentContainer;
        const node = createTreeNode(container, false, nodeBuilder);
        return {
            ...node,
            children: itemsInColumnOrder(container.members).map(member => toTreeNode(member, nodeBuilder)),
        };
    }

    return createTreeNode(canvasItem, true, nodeBuilder);
};

type NodeBuilder = {
    getClassName: (canvasItemId: string) => string | undefined;
    getSelectionError: (canvasItem: CanvasItem) => string | undefined;
    t: (key: string) => string;
    templateItems: CanvasItem[];
    newPanelMemberIds?: string[];
};

export const getClassName = (canvasItemId: string, selectedCanvasItemId: string): string | undefined => {
    return canvasItemId === selectedCanvasItemId ? "highlighted-component" : undefined;
};

const getSelectionError = (
    canvasItem: CanvasItem,
    templateItems: CanvasItem[],
    selectedCanvasItemId: string,
    componentIdsActedOnBySelectedItem: string[],
    newPanelMemberIds?: string[],
): string | undefined => {
    if (canvasItem.id === selectedCanvasItemId) {
        return "componentSelection.errors.noSelectTargetComponent";
    }
    if (isDescendantOf(canvasItem.id, [selectedCanvasItemId], templateItems)) {
        return "componentSelection.errors.noSelectMemberOfTarget";
    }
    if (isOrIsDescendantOfNewPanelMembers(canvasItem.id, templateItems, newPanelMemberIds)) {
        return "componentSelection.errors.noSelectNewPanelMember";
    }
    if (canvasItem.type === ComponentType.CLINICAL_CONTENT) {
        return "componentSelection.errors.noSelectTextEditor";
    }
    if (componentIdsActedOnBySelectedItem.includes(canvasItem.id)) {
        return "componentSelection.errors.ruleExistsBetweenTheseComponents";
    }
    if (isEmbeddedItem(canvasItem) || isDescendantOfEmbeddedItem(canvasItem, templateItems)) {
        return "componentSelection.errors.noSelectSavedContent";
    }
    if (isContainerType(canvasItem.type)) {
        return "componentSelection.errors.noSelectLayoutComponent";
    }
    return undefined;
};

export const createVisibilityRulesComponentTree = (
    t: (key: string) => string,
    templateDefinition: ComponentContainer,
    selectedCanvasItemId: string,
    newPanelMemberIds?: string[],
): TreeNodeData => {
    const componentIdsActedOnBySelectedItem = getCanvasItemIdsActedOnBy(selectedCanvasItemId, templateDefinition);
    const nodeBuilder: NodeBuilder = {
        getClassName: (canvasItemId: string) => getClassName(canvasItemId, selectedCanvasItemId),
        getSelectionError: (canvasItem: CanvasItem) =>
            getSelectionError(
                canvasItem,
                templateDefinition.members,
                selectedCanvasItemId,
                componentIdsActedOnBySelectedItem,
                newPanelMemberIds,
            ),
        t,
        templateItems: templateDefinition.members,
        newPanelMemberIds,
    };
    return toTreeNode(templateDefinition, nodeBuilder);
};

const findInChildren = (nodeId: string, childNodes: TreeNodeData[]): TreeNodeData | undefined => {
    for (let i = 0, len = childNodes.length; i < len; i += 1) {
        if (nodeId === childNodes[i].nodeId) return childNodes[i];

        if (childNodes[i].children) {
            const result = findInChildren(nodeId, childNodes[i].children as TreeNodeData[]);
            if (result) return result;
        }
    }

    return undefined;
};

export const findTreeNode = (
    nodeId: string,
    treeRootNode: TreeNodeData,
    includeRoot: boolean,
): TreeNodeData | undefined => {
    if (includeRoot && nodeId === treeRootNode.nodeId) {
        return treeRootNode;
    }

    return treeRootNode.children ? findInChildren(nodeId, treeRootNode.children) : undefined;
};

export const getTreeNodeSelectionError = (node?: TreeNodeData): string | undefined => {
    return node?.metadata?.selectionError;
};
