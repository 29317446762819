import {
    DropTargetMonitor,
    DragSourceMonitor,
    DragSource,
    DropTarget,
    DropTargetConnector,
    DragSourceConnector,
    XYCoord,
} from "react-dnd";

export type DragDropSortInstance = { getNode: () => HTMLDivElement | null };
export type DragDropSortProps = {
    index: number;
    id: string;
    swapItems: (fromIndex: number, toIndex: number) => void;
};

function addDragDropSort<T extends DragDropSortProps>(sortableComponent: React.FC<any>, itemType: string) {
    return DropTarget(
        itemType,
        {
            hover(props: T, monitor: DropTargetMonitor, component: DragDropSortInstance) {
                if (!component) {
                    return;
                }

                const node = component.getNode();
                if (!node) {
                    return;
                }

                const dragIndex = monitor.getItem().index;
                const hoverIndex = props.index;

                if (dragIndex === hoverIndex) {
                    return;
                }

                // Determine rectangle on screen
                const hoverBoundingRect = node.getBoundingClientRect();

                // Get vertical middle
                const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

                // Determine mouse position
                const clientOffset = monitor.getClientOffset();

                // Get pixels to the top
                const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

                // Only perform the move when the mouse has crossed half of the items height
                // When dragging downwards, only move when the cursor is below 50%
                // When dragging upwards, only move when the cursor is above 50%

                // Dragging downwards
                if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                    return;
                }

                // Dragging upwards
                if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                    return;
                }

                // Time to actually perform the action
                props.swapItems(dragIndex, hoverIndex);

                // eslint-disable-next-line no-param-reassign
                monitor.getItem().index = hoverIndex;
            },
        },
        (connect: DropTargetConnector) => ({
            connectDropTarget: connect.dropTarget(),
        }),
    )(
        DragSource(
            itemType,
            {
                beginDrag: (props: T) => ({
                    id: props.id,
                    index: props.index,
                }),
            },
            (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
                connectDragSource: connect.dragSource(),
                isDragging: monitor.isDragging(),
            }),
        )(sortableComponent),
    );
}

export default addDragDropSort;
