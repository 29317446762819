import * as React from "react";
import { Clear } from "@emisgroup/icons-react";
import { useTranslation } from "@emisgroup/application-intl";
import {
    ComponentIcon,
    ComponentParameter,
    ComponentType,
    findCanvasItem,
    TemplateContext,
} from "@emisgroup/clint-templates-common";

type ParameterSourceProps = { componentId: string };
const ParameterSource = ({ componentId }: ParameterSourceProps) => {
    const {
        templateDefinition: { members },
    } = React.useContext(TemplateContext);
    const component = findCanvasItem(componentId, members);
    return (
        <div className="calculation-parameter-source">
            <ComponentIcon type={component?.type as ComponentType} />
            <span>{component?.label}</span>
        </div>
    );
};

const ALLOWED_CHARS = /[a-zA-Z0-9_]/;
const STARTING_CHAR = /[a-zA-Z]/;
const ALLOWED_LABEL_REGEX = /^[a-zA-Z][a-zA-Z0-9_]*$/;

type LabelParameterProps = { label: string; isReadOnly: boolean; onLabelChange: (label: string) => void };
const LabelParameter = ({ label, isReadOnly, onLabelChange }: LabelParameterProps) => {
    const handleLabelChange = (e: React.ChangeEvent<HTMLInputElement>) => onLabelChange(e.currentTarget.value);

    const labelRef = React.useRef<HTMLInputElement>(null);
    React.useEffect(() => {
        labelRef.current?.focus();
    }, []);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.ctrlKey || e.altKey || e.metaKey) {
            return;
        }

        const regex = e.currentTarget.selectionStart === 0 ? STARTING_CHAR : ALLOWED_CHARS;
        if (!regex.test(e.key)) {
            e.preventDefault();
        }
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        const afterPaste = `${e.currentTarget.value.substring(
            0,
            e.currentTarget.selectionStart || 0,
        )}${e.clipboardData.getData("text")}${e.currentTarget.value.substring(
            e.currentTarget.selectionEnd || e.currentTarget.selectionStart || 0,
        )}`;

        if (!ALLOWED_LABEL_REGEX.test(afterPaste)) {
            e.preventDefault();
        }
    };

    return (
        <input
            ref={labelRef}
            className="eui-text-input calculation-parameter-label"
            aria-label="Enter parameter name"
            onChange={handleLabelChange}
            onKeyDown={handleKeyDown}
            onPaste={handlePaste}
            value={label}
            readOnly={isReadOnly}
        />
    );
};

type ParameterProps = {
    parameter: ComponentParameter;
    isReadOnly: boolean;
    onLabelChange: (label: string) => void;
    onRemove: () => void;
};
const Parameter = ({ parameter, isReadOnly, onLabelChange, onRemove }: ParameterProps) => {
    const { t } = useTranslation();
    return (
        <>
            <ParameterSource componentId={parameter.componentId} />
            <LabelParameter label={parameter.label} isReadOnly={isReadOnly} onLabelChange={onLabelChange} />
            {!isReadOnly && (
                <button
                    type="button"
                    className="calculation-parameter-remove-button"
                    data-testid="remove-parameter"
                    onClick={onRemove}
                >
                    <Clear title={t("parameterEntry.removeParameter")} />
                </button>
            )}
        </>
    );
};

type ParametersProps = {
    isReadOnly: boolean;
    parameters: ComponentParameter[];
    onUpdate: (parameters: ComponentParameter[]) => void;
    onUpdateAndCommit: (parameters: ComponentParameter[]) => void;
};
const Parameters = ({ isReadOnly, parameters, onUpdate, onUpdateAndCommit }: ParametersProps) => {
    const handleLabelChange = (index: number) => (label: string) =>
        onUpdate(parameters.map((param, existingIndex) => (existingIndex === index ? { ...param, label } : param)));

    const handleRemove = (index: number) => () =>
        onUpdateAndCommit(parameters.filter((_, parameterIndex) => parameterIndex !== index));

    return (
        <div className="calculation-parameter-list">
            {parameters.length > 0 && (
                <>
                    <span className="parameter-header source-header">Source</span>
                    <span className="parameter-header label-header">Parameter</span>
                </>
            )}
            {React.Children.toArray(
                parameters.map((parameter, index) => (
                    <Parameter
                        parameter={parameter}
                        isReadOnly={isReadOnly}
                        onLabelChange={handleLabelChange(index)}
                        onRemove={handleRemove(index)}
                    />
                )),
            )}
        </div>
    );
};

export default Parameters;
