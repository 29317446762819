import * as React from "react";
import { replaceParameters } from "../utils";

import "./calculatorExpression.css";

const getCurrentSelection = (element: HTMLDivElement): { start: number; end: number } | null => {
    if (!window.getSelection) {
        return null;
    }

    const selection = window.getSelection();
    if (!selection || !element) {
        return null;
    }

    const range = selection.getRangeAt(0);
    const clonedRange = range.cloneRange();
    clonedRange.selectNodeContents(element);
    clonedRange.setEnd(range.endContainer, range.endOffset);
    const end = clonedRange.toString().length;

    clonedRange.setStart(range.startContainer, range.startOffset);
    const start = end - clonedRange.toString().length;

    return {
        start,
        end,
    };
};

const getTextNodesIn = (node: Node) => {
    const textNodes: any[] = [];
    if (node.nodeType === Node.TEXT_NODE) {
        textNodes.push(node);
    } else {
        const children = node.childNodes;
        for (let i = 0, len = children.length; i < len; i += 1) {
            textNodes.push(...getTextNodesIn(children[i]));
        }
    }
    return textNodes;
};

const setSelectionRange = (el: HTMLDivElement, start: number, end: number) => {
    if (!document.createRange || !window.getSelection) {
        return;
    }
    const range = document.createRange();
    range.selectNodeContents(el);
    const textNodes = getTextNodesIn(el);
    let foundStart = false;
    let charCount = 0;
    let endCharCount;

    for (let i = 0; i < textNodes.length; i += 1) {
        const textNode = textNodes[i];
        endCharCount = charCount + textNode.length;
        if (
            !foundStart &&
            start >= charCount &&
            (start < endCharCount || (start === endCharCount && i <= textNodes.length))
        ) {
            range.setStart(textNode, start - charCount);
            foundStart = true;
        }
        if (foundStart && end <= endCharCount) {
            range.setEnd(textNode, end - charCount);
            break;
        }
        charCount = endCharCount;
    }

    const sel = window.getSelection();
    if (sel) {
        sel.removeAllRanges();
        sel.addRange(range);
    }
};

type CalculatorExpressionProps = {
    id?: string;
    expression?: string;
    readOnly: boolean;
    disabled?: boolean;
    onChange?: (expression: string) => void;
    onBlur?: () => void;
};
const CalculatorExpression = ({
    id,
    expression = "",
    readOnly,
    disabled,
    onChange,
    onBlur,
}: CalculatorExpressionProps) => {
    const expressionRef = React.useRef<HTMLDivElement>(null);
    const lastSelection = React.useRef<{ start: number; end: number } | null>(null);
    const handleInput = (e: React.FormEvent<HTMLDivElement>) => {
        if (onChange) {
            onChange(e.currentTarget.textContent || "");
        }

        lastSelection.current = getCurrentSelection(expressionRef.current as HTMLDivElement);
    };

    React.useEffect(() => {
        let withFormatting = replaceParameters(
            expression,
            param => `<span class="expression-parameter">${param}</span>`,
        );
        if (withFormatting[withFormatting.length - 1] === " ") {
            withFormatting = `${withFormatting.slice(0, withFormatting.length - 1)}&nbsp;`;
        }
        const element = expressionRef.current as HTMLDivElement;
        if (withFormatting === element.innerHTML) {
            return;
        }
        element.innerHTML = withFormatting;
        if (lastSelection.current) {
            setSelectionRange(element, lastSelection.current.start, lastSelection.current.end);
            lastSelection.current = null;
        }
    }, [expression]);

    React.useEffect(() => {
        if (!readOnly) {
            return;
        }

        const element = expressionRef.current as HTMLDivElement;
        element.title = element.offsetWidth < element.scrollWidth ? expression : "";
    }, [expression]);

    return (
        <div
            id={id}
            data-testid={id}
            ref={expressionRef}
            className={`calculator-expression${disabled ? " disabled" : ""} eui-text-input`}
            contentEditable={!readOnly}
            onInput={handleInput}
            onBlur={onBlur}
            suppressContentEditableWarning
            tabIndex={disabled ? undefined : 0}
            role="textbox"
            aria-label="calculator expression"
        />
    );
};

export default CalculatorExpression;
