import * as React from "react";
import { Clear } from "@emisgroup/icons-react";
import { ConnectDropTarget, ConnectDragSource } from "react-dnd";
import { Code, ConfigContext, CODE_EXTRA_INFORMATION_PROPERTIES } from "@emisgroup/clint-templates-common";
import { useTranslation } from "@emisgroup/application-intl";
import { DragDropSortInstance, DragDropSortProps } from "./addDragDropSort";

export const CodeDisplay = ({ code, includeDetails }: { code: Code; includeDetails: boolean }) => {
    const { features } = React.useContext(ConfigContext);
    return (
        <div className="inline-code-item-body">
            <div className="inline-code-item-term">{code.term}</div>
            {includeDetails && (
                <div className="inline-code-item-snomed">
                    {features[CODE_EXTRA_INFORMATION_PROPERTIES].map(({ propertyName, header }, index) => (
                        <React.Fragment key={`${propertyName}-header`}>
                            <div className="snomed-header" style={{ gridRow: index + 1 }}>
                                {header}:
                            </div>
                            <div className="snomed-detail" style={{ gridRow: index + 1 }}>
                                {code[propertyName]}
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            )}
        </div>
    );
};

export const RemoveCodeButton = ({ onRemove, codeId }: { onRemove: () => void; codeId?: number }) => {
    const { t } = useTranslation();
    return (
        <button
            data-testid={`remove-code-${codeId}`}
            type="button"
            style={{ backgroundColor: "transparent", padding: 0, fontSize: "inherit" }}
            className="btn btn-default"
            onClick={onRemove}
        >
            <Clear title={t("codeEntry.removeCode")} />
        </button>
    );
};

type InlineCodeItemProps = DragDropSortProps & {
    code: Code;
    isReadOnly?: boolean;
    includeDetails: boolean;
    onRemove: () => void;
    isDragging: boolean;
    connectDragSource: ConnectDragSource;
    connectDropTarget: ConnectDropTarget;
};

const InlineCodeItem = React.forwardRef<HTMLDivElement, InlineCodeItemProps>(
    (
        {
            code,
            onRemove,
            isDragging,
            connectDragSource,
            connectDropTarget,
            isReadOnly,
            includeDetails,
        }: InlineCodeItemProps,
        ref,
    ) => {
        const elementRef = React.useRef(null);

        connectDragSource(elementRef);
        connectDropTarget(elementRef);
        const opacity = isDragging ? 0 : 1;

        React.useImperativeHandle<any, DragDropSortInstance>(ref, () => ({
            getNode: () => elementRef.current,
        }));
        return (
            <div
                ref={isReadOnly ? null : elementRef}
                className={`inline-code-item${isReadOnly ? " read-only-item" : ""}`}
                style={{ opacity }}
            >
                <CodeDisplay code={code} includeDetails={includeDetails} />
                {!isReadOnly && <RemoveCodeButton onRemove={onRemove} codeId={code.emisCodeId} />}
            </div>
        );
    },
);

export default InlineCodeItem;
