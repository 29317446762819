import * as React from "react";
import {
    AppModeContext,
    TemplateContext,
    addRemoveUpdateItemInContainer,
    AppMode,
    CanvasItem,
    Rule,
    ComponentType,
    Panel,
    ActionButton,
} from "@emisgroup/clint-templates-common";
import { IPerspective } from "@emisgroup/clint-templates-perspectives/lib";
import PropertyOverrideInspector from "./propertyOverrideInspector";
import VisibilityRules from "./visibilityRules";
import "./sidebar.css";
import useProperties from "./useProperties";
import { canvasItemCanHaveVisibilityRules } from "../utils/ruleUtils";
import { DesignLayoutContext } from "../context/designLayoutContext";
import useVisibilityRules from "./useVisibilityRules";
import PanelActions from "./panelActions";
import usePanelActions from "./usePanelActions";
import Perspectives from "./perspectives";

type InspectorsProps = {
    isPropertyInspectorEditEnabled: boolean;
    isRuleEditorEditEnabled: boolean;
    onItemsUpdate: (items: CanvasItem[]) => void;
};

const Inspectors = ({ isPropertyInspectorEditEnabled, isRuleEditorEditEnabled, onItemsUpdate }: InspectorsProps) => {
    const {
        selectedItem,
        setTemplateDefinition,
        setSelectedItem,
        templateDefinition,
        invalidComponentDefinitionIds,
        setInvalidComponentDefinitionIds,
    } = React.useContext(TemplateContext);
    const { mode } = React.useContext(AppModeContext);
    const { invalidProperties, updateItemProperty, areAllPropertiesValid } = useProperties(
        selectedItem,
        onItemsUpdate,
        true,
    );
    const { invalidRuleConditions, updateRuleConditionValidity, areAllRuleConditionsValid } =
        useVisibilityRules(selectedItem);
    const { invalidPanelActions, onPanelActionInteracted, areAllPanelActionsValid } = usePanelActions(selectedItem);

    const { isPropInspectorExpanded } = React.useContext(DesignLayoutContext);

    const isComponentValid = areAllPropertiesValid() && areAllRuleConditionsValid() && areAllPanelActionsValid();

    React.useEffect(() => {
        const componentPreviouslyValid = !invalidComponentDefinitionIds.some(id => id === selectedItem.id);

        if (isComponentValid === componentPreviouslyValid) {
            return;
        }

        const updateComponentsValidity = isComponentValid
            ? invalidComponentDefinitionIds.filter(id => id !== selectedItem.id)
            : [...invalidComponentDefinitionIds, selectedItem.id];
        setInvalidComponentDefinitionIds(updateComponentsValidity);
    }, [selectedItem.id, isComponentValid]);

    const updateCanvasItem = (canvasItem: CanvasItem) => {
        const updatedDefinition = addRemoveUpdateItemInContainer(templateDefinition, canvasItem, "update");
        setTemplateDefinition(updatedDefinition);
        setSelectedItem(canvasItem);
    };
    const addOrUpdateRule = (rule: Rule) => {
        const updatedItem = {
            ...selectedItem,
            rule,
        } as CanvasItem;

        updateCanvasItem(updatedItem);
    };

    const handlePanelActionsUpdate = (actionButtons: ActionButton[] | null) => {
        const updatedItem = {
            ...selectedItem,
            actionButtons,
        } as Panel;

        updateCanvasItem(updatedItem);
    };

    const handlePerspectivesUpdate = (perspectives?: IPerspective[]) => {
        updateCanvasItem({ ...selectedItem, perspectives } as CanvasItem);
    };

    if (mode === AppMode.RUN) {
        return null;
    }

    const colClasses = isPropInspectorExpanded ? "col-12 col-md-12 col-lg-10" : "col-6 col-md-5 col-xl-4";
    return (
        <div className={`inspectors-container ${colClasses}`}>
            <div className="inspectors">
                <PropertyOverrideInspector
                    component={selectedItem}
                    editEnabled={isPropertyInspectorEditEnabled}
                    onPropertyUpdate={updateItemProperty}
                    invalidProperties={invalidProperties}
                />
                <VisibilityRules
                    show={canvasItemCanHaveVisibilityRules(selectedItem) ?? false}
                    editEnabled={isRuleEditorEditEnabled}
                    selectedCanvasItem={selectedItem}
                    invalidRuleConditions={invalidRuleConditions}
                    onRuleAddOrUpdate={addOrUpdateRule}
                    onUpdateRuleConditionValidity={updateRuleConditionValidity}
                />

                {selectedItem.type === ComponentType.PANEL && (
                    <PanelActions
                        panel={selectedItem as Panel}
                        editEnabled={isRuleEditorEditEnabled}
                        invalidPanelActions={invalidPanelActions}
                        onPanelActionsUpdate={handlePanelActionsUpdate}
                        onPanelActionInteracted={onPanelActionInteracted}
                    />
                )}
            </div>
            <div className="inspectors-footer">
                <Perspectives
                    editEnabled={isPropertyInspectorEditEnabled}
                    selectedCanvasItem={selectedItem}
                    onUpdate={handlePerspectivesUpdate}
                />
            </div>
        </div>
    );
};

export default Inspectors;
