import {
    CalculatorComponent,
    CanvasItem,
    CanvasItemWithContainer,
    ComponentContainer,
    ComponentType,
    findCanvasItem,
    getAllNestedCanvasItems,
    isCanvasItemAContainer,
    isContainerType,
} from "@emisgroup/clint-templates-common";
import { DeletionInformation, ConflictType } from "../types";
import { getVisibilityConflictsForDelete, getVisibilityConflictsForSaveContainer } from "./ruleUtils";

export const getCalculationParameterConflictsForDelete = (t, canvasItem: CanvasItem, container: ComponentContainer) => {
    const allItems: CanvasItemWithContainer[] = isContainerType(canvasItem.type)
        ? getAllNestedCanvasItems(canvasItem as ComponentContainer)
        : [{ canvasItem, container: {} as ComponentContainer }];
    const getComponentsUsingItemAsParameter = (items: CanvasItem[]) => {
        const conflicts: { calculator: CanvasItem; parameter: CanvasItemWithContainer }[] = [];
        // eslint-disable-next-line no-restricted-syntax
        for (const item of items) {
            if (isContainerType(item.type)) {
                conflicts.push(...getComponentsUsingItemAsParameter((item as ComponentContainer).members));
            }

            if (item.type === ComponentType.CALCULATOR && !allItems.some(i => i.canvasItem.id === item.id)) {
                const parameters = allItems.filter(i =>
                    (item as CalculatorComponent).parameters.find(({ componentId }) => componentId === i.canvasItem.id),
                );
                conflicts.push(...parameters.map(parameter => ({ calculator: item, parameter })));
            }
        }

        return conflicts;
    };

    const calculationsUsingItemAsParameter = getComponentsUsingItemAsParameter(container.members);
    return calculationsUsingItemAsParameter.map(conflict => {
        const tObj = { paramName: conflict.parameter.canvasItem.label, calculatorName: conflict.calculator.label };
        return conflict.parameter.container.label
            ? t("templates.conflicts.calculationParameterConflictParamInContainer", {
                  ...tObj,
                  containerName: conflict.parameter.container.label,
              })
            : t("templates.conflicts.calculationParameterConflict", tObj);
    });
};

export const getDeletionInformation = (
    t,
    selectedItem: CanvasItem,
    rootContainer: ComponentContainer,
): DeletionInformation => {
    let membersExist = false;

    if (isCanvasItemAContainer(selectedItem) && selectedItem.type !== ComponentType.CLINICAL_CONTENT) {
        membersExist = (selectedItem as ComponentContainer).members.length > 0;
    }

    const visibilityRuleConflicts = getVisibilityConflictsForDelete(t, selectedItem, rootContainer);
    const calculationParameterConflicts = getCalculationParameterConflictsForDelete(t, selectedItem, rootContainer);
    return {
        canvasItemType: selectedItem.type,
        membersExist,
        conflicts: visibilityRuleConflicts
            .map(description => ({ description, conflictType: ConflictType.VisibilityRule }))
            .concat(
                calculationParameterConflicts.map(description => ({
                    description,
                    conflictType: ConflictType.CalculationParameter,
                })),
            ),
    };
};

const getCalculators = (canvasItems: CanvasItem[]): CalculatorComponent[] => {
    const nestedCalculators = canvasItems
        .filter(({ type }) => isContainerType(type))
        .flatMap(item => getCalculators((item as ComponentContainer).members));
    return canvasItems
        .filter(({ type }) => type === ComponentType.CALCULATOR)
        .concat(nestedCalculators) as CalculatorComponent[];
};

export const getCalculationParameterConflictsForSaveContainer = (
    t,
    container: ComponentContainer,
    templateContainer: ComponentContainer,
) => {
    const conflicts = [] as string[];
    const calculatorsInContainer = getCalculators(container.members);
    const parametersNotInContainer = calculatorsInContainer
        .map(calculator => ({
            calculator,
            missingParams: calculator.parameters
                .filter(({ componentId }) => !findCanvasItem(componentId, container.members))
                .map(({ componentId }) => findCanvasItem(componentId, templateContainer.members)),
        }))
        .flatMap(({ calculator, missingParams }) =>
            missingParams.map(param =>
                t("templates.conflicts.affectsCalculationCalculatorInContainer", {
                    paramName: param?.label,
                    containerName: container.label,
                    calculatorName: calculator.label,
                }),
            ),
        );
    const calculatorsNotInContainer = getCalculators(templateContainer.members).filter(
        calculator => !calculatorsInContainer.includes(calculator),
    );
    const parametersInContainer = calculatorsNotInContainer
        .map(calculator => ({
            calculator,
            paramsInContainer: calculator.parameters
                .map(({ componentId }) => findCanvasItem(componentId, container.members))
                .filter(Boolean),
        }))
        .flatMap(({ calculator, paramsInContainer }) =>
            paramsInContainer.map(param =>
                t("templates.conflicts.affectsCalculationParamInContainer", {
                    paramName: param?.label,
                    containerName: container.label,
                    calculatorName: calculator.label,
                }),
            ),
        );

    conflicts.push(...parametersNotInContainer.concat(parametersInContainer));
    return conflicts;
};

export const getConflictsForSaveContainer = (
    t,
    container: ComponentContainer,
    templateContainer: ComponentContainer,
) => {
    const visibilityConflicts = getVisibilityConflictsForSaveContainer(t, container, templateContainer);
    const parameterConflicts = getCalculationParameterConflictsForSaveContainer(t, container, templateContainer);
    return visibilityConflicts
        .map(description => ({ description, conflictType: ConflictType.VisibilityRule }))
        .concat(
            parameterConflicts.map(description => ({
                description,
                conflictType: ConflictType.CalculationParameter,
            })),
        );
};
