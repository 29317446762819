// Fixes problems caused by packages that include old versions of css for packages we use
export function moveLinksBelowStyles() {
    const allLinks = [...document.head.getElementsByTagName("link")];
    // eslint-disable-next-line no-restricted-syntax
    for (const link of allLinks) {
        document.head.appendChild(link);
    }
}

export function navigateTo(url: string | undefined) {
    if (url) window.location.href = url;
}
