import * as React from "react";
import { useDragLayer } from "react-dnd";
import { CanvasItem, ComponentType, TabItem } from "@emisgroup/clint-templates-common";
import { LibraryComponent } from "./uiComponents/templateComponent";
import CanvasComponent from "./uiComponents/canvasComponent";
import { LIST_ITEM_DRAG_TYPE } from "./constants";

const DragLayer = () => {
    const { item, itemType, isDragging, currentOffset } = useDragLayer(monitor => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging(),
    }));

    if (!isDragging || !currentOffset) {
        return null;
    }

    const noOp = () => {};
    const renderPreview = () => {
        if (itemType === LIST_ITEM_DRAG_TYPE) {
            return null;
        }

        if (itemType === ComponentType.TAB_PAGE) {
            return (
                <TabItem
                    tabPageIndex={item.tabPageIndex}
                    isTabPageSelected={true}
                    text={item.text}
                    isEmbedded={false}
                    onTabSelect={noOp}
                />
            );
        }

        if (!item.id) {
            return <LibraryComponent label={item.label} />;
        }

        return (
            <div className="drag-preview-component">
                <CanvasComponent
                    component={item}
                    selectedItem={{} as CanvasItem}
                    isSelected={false}
                    arePropertiesValid={true}
                    dragDropEnabled={false}
                    onSelect={noOp}
                    onPropertyUpdate={noOp}
                />
            </div>
        );
    };
    const transform = `translate(${currentOffset.x}px, ${currentOffset.y}px)`;
    return (
        <div
            className="drag-preview"
            style={{
                transform,
                WebkitTransform: transform,
            }}
        >
            <div className="drag-preview-item">{renderPreview()}</div>
        </div>
    );
};

export default DragLayer;
