import React, { Component } from "react";

export default class extends Component {
    state = {
        error: null as string | null,
        errorInfo: null as any | null,
    };

    componentDidCatch(error, errorInfo) {
        this.setState({
            error,
            errorInfo,
        });
    }

    render() {
        const { error, errorInfo } = this.state;
        if (errorInfo) {
            return (
                <div style={{ padding: "5px" }}>
                    <div className="alert alert-danger" role="alert">
                        <h2>Something went wrong.</h2>
                        <details style={{ whiteSpace: "pre-wrap" }}>
                            {error && error.toString()}
                            <br />
                            {errorInfo.componentStack}
                        </details>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}
