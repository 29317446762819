import React from "react";
import { ButtonProps, ComponentContainer, GenericDialog } from "@emisgroup/clint-templates-common";
import { useTranslation } from "@emisgroup/application-intl";
import PropertyInspector from "./propertyInspector";
import { getMandatoryProperties } from "../utils/componentUtils";
import { NEW_TEMPLATE_DEFINITION } from "../constants";
import useProperties from "./useProperties";
import { PropertyInspectorProvider } from "./propertyInspectorContext";

type NewTemplateDialogProps = {
    createNewTemplate: (templateDefinition: ComponentContainer) => void;
    cancel: () => void;
};

const NewTemplateDialog = ({ createNewTemplate, cancel }: NewTemplateDialogProps) => {
    const { t } = useTranslation();
    const [templateDefinition, setTemplateDefinition] = React.useState<ComponentContainer>({
        ...NEW_TEMPLATE_DEFINITION,
        label: "",
    });
    const { areAllPropertiesValid, updateItemProperty, invalidProperties, showAllValidation } = useProperties(
        templateDefinition,
        (items: ComponentContainer[]) => setTemplateDefinition(items[0]),
    );

    const formElement = React.useRef<HTMLFormElement>(null);

    const handleSubmit = ev => {
        if (areAllPropertiesValid()) {
            createNewTemplate(templateDefinition);
        } else {
            showAllValidation();
        }
        ev.preventDefault();
    };

    const dialogButtons = React.useMemo(
        () => [
            {
                onClick: evt => {
                    formElement.current?.requestSubmit();
                    evt.stopPropagation();
                },
                label: t("create"),
                isPrimary: true,
                ariaLabel: t("templates.clickToCreateNewTemplate"),
            } as ButtonProps,
            {
                onClick: evt => {
                    cancel();
                    evt.stopPropagation();
                },
                label: t("cancel"),
                isPrimary: false,
                ariaLabel: "templates.clickToCancelNewTemplate",
            } as ButtonProps,
        ],
        [],
    );

    return (
        <GenericDialog
            key="new-template-dialog"
            buttons={dialogButtons}
            onCancel={() => cancel()}
            title={t("templates.createNewTemplate")}
            leftAlignButtons
            disableDismiss
        >
            <form ref={formElement} onSubmit={handleSubmit} data-testid="new-template-form">
                <PropertyInspectorProvider isNewItem>
                    <PropertyInspector
                        component={templateDefinition}
                        editEnabled={true}
                        onPropertyUpdate={updateItemProperty}
                        focusOn={getMandatoryProperties(templateDefinition)[0]?.name}
                        invalidProperties={invalidProperties}
                    />
                </PropertyInspectorProvider>
            </form>
        </GenericDialog>
    );
};

export default NewTemplateDialog;
