import React from "react";
import { AppMode } from "../types";

const defaultAppMode = AppMode.EDIT;

type AppModeContextType = {
    mode: AppMode;
    allowedModes: AppMode[];
    updateMode: (mode: AppMode) => void;
    setReadOnly: () => void;
};

const AppModeContext = React.createContext<AppModeContextType>({
    mode: defaultAppMode,
    allowedModes: [],
    updateMode: () => {},
    setReadOnly: () => {},
});

export default AppModeContext;
