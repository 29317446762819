import * as React from "react";
import { DataEntryComponentProps, DataItem, DataPropertyValidity, TemplateData } from "../types";
import MultiSelectSelection from "./multiSelectSelection";
import AdditionalDataEntryComponent from "./additionalDataEntryComponent";

type PickingListDataItemProps = DataEntryComponentProps & {
    children?: React.ReactNode;
    isMultiSelect?: boolean;
    header: string;
    dataItem: DataItem;
    getHasValue?: (templateData: TemplateData) => boolean;
    onRemove: () => void;
    applyPropertyUpdate: (obj) => any;
    onValidityChanged: (isDataItemValid: boolean) => void;
};

const PickingListDataItem = (props: PickingListDataItemProps) => {
    const { id, children, isMultiSelect, header, dataItem, getHasValue, onRemove, onValidityChanged } = props;

    const [valuePropertiesValidity, setValuePropertValidity] = React.useState<DataPropertyValidity[]>([]);

    const updateAdditionalPropertyValidity = (propertyName: string, isValid: boolean) => {
        const updatedValuePropertiesValidities = valuePropertiesValidity
            .filter(v => v.propertyName !== propertyName)
            .concat({ propertyName, isValid });

        setValuePropertValidity(updatedValuePropertiesValidities);
        const areAllPropertiesValid = !updatedValuePropertiesValidities.some(
            propertyValidity => !propertyValidity.isValid,
        );
        onValidityChanged(areAllPropertiesValid);
    };

    const childrenWithAdditionalEntry = (
        <>
            {children}
            <AdditionalDataEntryComponent
                {...props}
                dataItem={dataItem}
                getComponentHasValue={getHasValue}
                updatePropertyValidity={updateAdditionalPropertyValidity}
            />
        </>
    );
    if (!isMultiSelect) {
        return <div className="single-select-data-entry-container">{childrenWithAdditionalEntry}</div>;
    }

    return (
        <MultiSelectSelection id={id} itemName={header} onRemove={onRemove}>
            <div className="multi-select-data-entry-container">{childrenWithAdditionalEntry}</div>
        </MultiSelectSelection>
    );
};

export default PickingListDataItem;
