import { v4 as uuid } from "uuid";
import { deleteEntity, getContentIds } from "@emisgroup/clint-content";

import {
    isContainerType,
    isCanvasItemAContainer,
    addRemoveUpdateItemInContainer,
    getUpdatedContainer,
    CanvasItem,
    CanvasItemClickHandler,
    ClinicalContentComponent,
    ClinicalContentEntity,
    CodedPickingListComponent,
    ComponentType,
    ConditionSource,
    ComponentContainer,
    DataItem,
    Panel,
    PickingListComponent,
    Tab,
    TabPage,
    TemplateData,
    CanvasItemWithContainer,
    PropertyAttributes,
    ItemTypePropertiesMap,
    COMPONENT_TYPE_LABELS,
    getAllNestedCanvasItems,
    ComponentValueConditionOperator,
    ConditionState,
    CodedComponent,
    FeaturesConfig,
} from "@emisgroup/clint-templates-common";

import { getDefaultPanelActionButtons } from "./panelActionUtils";

export const arePropertiesShownAtCreate = (componentType: string): boolean =>
    componentType !== ComponentType.CLINICAL_CONTENT &&
    componentType !== ComponentType.CLINICAL_CONTENT_ENTITY &&
    componentType !== ComponentType.SAVED_CONTENT;

export const itemFinder = (item: CanvasItem) => (currentItem: CanvasItem) => currentItem.id === item.id;

export const withSelectHandler = (selectHandler: CanvasItemClickHandler) => (item: CanvasItem) => (evt: MouseEvent) => {
    selectHandler({ evt, item });
    evt.stopPropagation();
};

export const getMandatoryProperties = (component: CanvasItem): PropertyAttributes[] => {
    if (component && component.type) {
        const properties: PropertyAttributes[] = ItemTypePropertiesMap[component.type] || [];
        return properties.filter(property => property.required).filter(({ name }) => !["id", "type"].includes(name));
    }
    return [] as PropertyAttributes[];
};

export const getStringPropertyDisplayValue = (t, propertyName: string, propertyValue: string) => {
    return propertyName === "type" ? t(COMPONENT_TYPE_LABELS[propertyValue]) ?? propertyValue : propertyValue;
};

const setValidationMessageForProperty = (t, component: CanvasItem, all: string, property: PropertyAttributes) => {
    let message;
    switch (component.type) {
        case ComponentType.PICKING_LIST:
            message = t("components.pickingList.mandatoryText", {
                componentType: t(COMPONENT_TYPE_LABELS[component.type]),
            });
            break;
        case ComponentType.CODED:
        case ComponentType.DIARY_ENTRY:
            message = t("components.coded.mandatoryText");
            break;
        case ComponentType.CALCULATOR:
            message = t("components.calculator.mandatoryText");
            break;
        case ComponentType.UNCODED:
            message = t("components.uncoded.mandatoryText");
            break;
        default:
            message = t("components.property.valueRequired", { propertyName: `${all}${property.name}` });
    }
    return message;
};

const setValidationMessageForLabel = (t, component: CanvasItem) => {
    let message;
    switch (component.type) {
        case ComponentType.PANEL:
        case ComponentType.TAB_PAGE:
            message = t("components.panel.mandatoryText", { componentType: t(COMPONENT_TYPE_LABELS[component.type]) });
            break;
        case ComponentType.TAB_CONTAINER:
            message = t("components.tabBar.mandatoryTextContainer", {
                componentType: t(COMPONENT_TYPE_LABELS[component.type]),
            });
            break;
        default:
            message = t("components.property.mandatoryText", {
                componentType: t(COMPONENT_TYPE_LABELS[component.type]),
            });
    }
    return message;
};

const propertyHasValue = (propertyName: string, propertyValue: any, component: CanvasItem) => {
    if (propertyValue && propertyName === "options" && component.type === ComponentType.PICKING_LIST) {
        return propertyValue.every(option => option.text !== "");
    }

    const isArray = propertyValue && Array.isArray(propertyValue);
    return isArray ? propertyValue.every(Boolean) : Boolean(propertyValue);
};

const getPropertyValidity = (t, property: PropertyAttributes, propertyValue: any, component: CanvasItem) => {
    if (property.required) {
        if (!propertyHasValue(property.name, propertyValue, component)) {
            const isArray = propertyValue && Array.isArray(propertyValue);
            const all = isArray ? " all " : "";
            let message = setValidationMessageForProperty(t, component, all, property);
            if (property.name === "label") {
                message = setValidationMessageForLabel(t, component);
            }
            return {
                isValid: false,
                message,
            };
        }
    }

    if (property.dataAttributes?.validate) {
        return property.dataAttributes.validate(t, propertyValue, component);
    }

    return { isValid: true };
};

const getPerspectiveComponentPropertyValidity = (perspective: any, t, component: CanvasItem | null) => {
    const perspectiveComponent: CanvasItem = { ...component, ...perspective, perspectives: undefined };
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return { key: perspective.key, ...getComponentPropertyValidity(t, perspectiveComponent) };
};

const allPropertiesValid = (propertyValidity: any) =>
    Object.values(propertyValidity).every((value: any) => value.isValid);

const allPerspectivePropertiesValid = (perspectiveValidity: any) => {
    const { key, ...perspectivesValidityWithoutKey } = perspectiveValidity;
    return allPropertiesValid(perspectivesValidityWithoutKey);
};

export const getComponentPropertyValidity = (t, component: CanvasItem | null) => {
    if (!component) {
        return [];
    }
    const perspectiveValidities =
        component?.perspectives?.map(p => getPerspectiveComponentPropertyValidity(p, t, component)) ?? [];
    const perspectivesValid = perspectiveValidities.every(allPerspectivePropertiesValid);
    const baseValidities = perspectivesValid ? {} : { perspectiveValidities };

    const properties: PropertyAttributes[] = ItemTypePropertiesMap[component.type] || [];
    return properties.reduce(
        (validity, property) => ({
            ...validity,
            [property.name]: getPropertyValidity(t, property, component[property.name], component),
        }),
        baseValidities,
    );
};

export const areAllComponentPropertiesValid = (t, component: CanvasItem) =>
    allPropertiesValid(getComponentPropertyValidity(t, component));

export const cancelAddition = (setNewItem, setNewItemDialogShown, setItemAddition) => {
    setNewItem(null);
    setNewItemDialogShown(false);
    setItemAddition(null);
};

export const getItemId = ({ id }: CanvasItem): string => id;

export function findIndicesOfItems(itemsToLookFor: CanvasItem[], sourceItems: CanvasItem[]) {
    const itemIds = itemsToLookFor.map(getItemId);
    return sourceItems
        .map((item, index) => {
            if (itemIds.includes(item.id)) {
                return index;
            }
            return -1;
        })
        .filter(index => index !== -1);
}

export const getCanvasItemParentContainers = (
    canvasItemId: string,
    container: ComponentContainer,
): ComponentContainer[] => {
    if (!container.members || !container.members.length) {
        return [];
    }

    for (let i = 0; i < container.members.length; i += 1) {
        const member = container.members[i];
        if (member.id === canvasItemId) {
            return [container];
        }

        if (isContainerType(member.type)) {
            const containers = getCanvasItemParentContainers(canvasItemId, member as ComponentContainer);
            if (containers.length > 0) {
                return [...containers, container];
            }
        }
    }

    return [];
};

export const isItemAncestorOf = (itemBeingDropped: CanvasItem, itemBeingDroppedInto: CanvasItem) => {
    if (!isContainerType(itemBeingDropped.type)) {
        return false;
    }

    const itemAsContainer: ComponentContainer = itemBeingDropped as ComponentContainer;
    return getCanvasItemParentContainers(itemBeingDroppedInto.id, itemAsContainer).includes(itemAsContainer);
};

export const isClinicalContentComponent = (item: CanvasItem) => item.type === ComponentType.CLINICAL_CONTENT;

export const getNestedContainerIds = (container: ComponentContainer): string[] =>
    container.members
        .filter(canvasItem => canvasItem.type === ComponentType.PANEL)
        .reduce((result, item) => {
            return [...result, item.id, ...getNestedContainerIds(item as ComponentContainer)];
        }, []);

const findCanvasItemWithContainerInNestedLevel = (
    id: string,
    container: ComponentContainer,
): CanvasItemWithContainer | undefined => {
    for (let i = 0, len = container.members.length; i < len; i += 1) {
        const item = container.members[i];
        if (item.id === id) return { canvasItem: item, container };

        if (isCanvasItemAContainer(item)) {
            const result = findCanvasItemWithContainerInNestedLevel(id, item as ComponentContainer);
            if (result) return result;
        }
    }
    return undefined;
};

export const getCanvasItemWithContainer = (
    canvasItemId: string,
    templateContainer: ComponentContainer,
): CanvasItemWithContainer => {
    return findCanvasItemWithContainerInNestedLevel(canvasItemId, templateContainer) as CanvasItemWithContainer;
};

export const getUpdatedContainerMembers = (
    rootContainer: ComponentContainer,
    containerIdToUpdate: string,
    newMembers: CanvasItem[],
): CanvasItem[] => {
    if (containerIdToUpdate === rootContainer.id) {
        return newMembers;
    }
    return rootContainer.members.map(item => {
        if (!isContainerType(item.type)) {
            return item;
        }

        return item.id === containerIdToUpdate
            ? { ...item, members: newMembers }
            : {
                  ...item,
                  members: getUpdatedContainerMembers(item as ComponentContainer, containerIdToUpdate, newMembers),
              };
    });
};

export const moveFromSourceToTarget = (
    itemToBeMoved: CanvasItem,
    source: ComponentContainer,
    target: ComponentContainer,
    targetPosition: number,
    rootContainer: ComponentContainer,
): ComponentContainer => {
    let adjustedPosition = targetPosition;
    if (source.id === target.id) {
        const currentPosition = source.members.findIndex(item => item.id === itemToBeMoved.id);
        adjustedPosition = targetPosition > currentPosition ? targetPosition - 1 : targetPosition;
    }
    let updatedRoot = addRemoveUpdateItemInContainer(rootContainer, itemToBeMoved, "remove");
    const sourceMembersWithoutItem = source.members.filter(item => item.id !== itemToBeMoved.id);
    const updatedTarget = getUpdatedContainer(target, [[source.id, sourceMembersWithoutItem]]);
    updatedRoot = addRemoveUpdateItemInContainer(updatedRoot, itemToBeMoved, "add", {
        position: adjustedPosition,
        containerToAddTo: updatedTarget,
    });
    return updatedRoot;
};

export function replaceItem(updatedItem: CanvasItem) {
    const replaceItemWithUpdatedItem = (item: CanvasItem) => {
        if (item.id === updatedItem.id) {
            return updatedItem;
        }

        if ((item as ComponentContainer).members) {
            return { ...item, members: (item as ComponentContainer).members.map(replaceItemWithUpdatedItem) };
        }

        return item;
    };

    return replaceItemWithUpdatedItem;
}

export const createContainerFromSavedContent = (
    type: string,
    columnIndex: number,
    definition: ComponentContainer,
    ern: string,
): ComponentContainer => ({
    ...definition,
    id: uuid(),
    type,
    columnIndex,
    rule: null,
    ern,
});

const augmentDefaultProperties = (canvasItem: CanvasItem, features: FeaturesConfig): CanvasItem => {
    const componentProperties = ItemTypePropertiesMap[canvasItem.type].filter(
        property => (property.defaultValue || property.getDefaultValue) && property.name !== "members",
    );

    const canvasItemWithProperties = { ...canvasItem };
    componentProperties.forEach(property => {
        if (property.getDefaultValue) {
            const value = property.getDefaultValue(features);
            if (typeof value !== "undefined") {
                canvasItemWithProperties[property.name] = value;
            }
        } else {
            canvasItemWithProperties[property.name] = property.defaultValue;
        }
    });
    return canvasItemWithProperties;
};

export function createNewCanvasItemFromDroppedItem(
    droppedItem: CanvasItem,
    columnIndex: number,
    features: FeaturesConfig,
    t,
): CanvasItem {
    const newCanvasItem: CanvasItem = {
        ...droppedItem,
        id: uuid(),
        columnIndex,
        label: "",
        rule: null,
    };
    switch (droppedItem.type) {
        case ComponentType.CLINICAL_CONTENT:
            {
                const newClinicalContentComponent: ClinicalContentComponent = newCanvasItem as ClinicalContentComponent;
                newClinicalContentComponent.members = getContentIds(
                    (droppedItem as ClinicalContentComponent).content,
                ).map(id => ({
                    id,
                    type: ComponentType.CLINICAL_CONTENT_ENTITY,
                    componentId: newClinicalContentComponent.id,
                    columnIndex: 0,
                    label: "entity",
                    rule: null,
                }));
            }
            break;
        case ComponentType.TAB_CONTAINER:
            {
                const newTabContainer: Tab = newCanvasItem as Tab;

                const initialTabPage: TabPage = {
                    type: ComponentType.TAB_PAGE,
                    id: uuid(),
                    label: "Tab Page 1",
                    members: [],
                    columnCount: 1,
                    columnIndex: 0,
                    rule: null,
                };

                newTabContainer.members = [initialTabPage] as TabPage[];
            }
            break;
        case ComponentType.NUMERIC_VALUE:
        case ComponentType.FREE_TEXT:
        case ComponentType.BRANDING:
            newCanvasItem.type = droppedItem.type;
            break;
        case ComponentType.PANEL:
            {
                const newPanel: Panel = newCanvasItem as Panel;
                newPanel.columnCount = 1;
                newPanel.members = [];
                newPanel.actionButtons = getDefaultPanelActionButtons(t);
            }
            break;
        case ComponentType.PICKING_LIST:
            {
                const newPickingList: PickingListComponent = newCanvasItem as PickingListComponent;
                newPickingList.options = [];
            }
            break;
        case ComponentType.CODED_PICKING_LIST: {
            const newPickingList: CodedPickingListComponent = newCanvasItem as CodedPickingListComponent;
            newPickingList.codes = [];
            break;
        }
        default:
            break;
    }

    return augmentDefaultProperties(newCanvasItem, features);
}

export const insertItem = (
    item: CanvasItem,
    destination: ComponentContainer,
    template: ComponentContainer,
    position: number,
    offset = 0,
): ComponentContainer => {
    const insertIndex = position + offset;
    return addRemoveUpdateItemInContainer(template, item, "add", {
        position: insertIndex,
        containerToAddTo: destination,
    });
};

export const getCanvasItemIdsWithNested = (item: CanvasItem): string[] =>
    isCanvasItemAContainer(item)
        ? [item.id, ...getAllNestedCanvasItems(item as ComponentContainer).map(c => c.canvasItem.id)]
        : [item.id];

const defaultDelete = (itemToDelete: CanvasItem, items: CanvasItem[]) =>
    items.filter(item => item.id !== itemToDelete.id);

const deleteClinicalComponentEntity = (itemToDelete: CanvasItem, items: CanvasItem[]) => {
    const contentComponentId = (itemToDelete as ClinicalContentEntity).componentId;
    const contentComponent = items.find(({ id }) => id === contentComponentId) as any;
    if (!contentComponent) {
        return items;
    }

    return items.map(item =>
        item.id !== contentComponentId
            ? item
            : { ...item, content: deleteEntity(itemToDelete.id, contentComponent.content) },
    );
};

export function deleteCanvasItem(itemToDelete: CanvasItem, items: CanvasItem[]) {
    const doDelete =
        itemToDelete.type === ComponentType.CLINICAL_CONTENT_ENTITY ? deleteClinicalComponentEntity : defaultDelete;
    return doDelete(itemToDelete, items);
}

export const canDeleteCanvasItemFromContainer = (container: ComponentContainer): boolean =>
    container.type !== ComponentType.TAB_CONTAINER || container.members.length > 1;
export const capitalise = (str: string) => `${str[0].toUpperCase()}${str.substr(1)}`;

export const getDataItemsForMultiSelectListComponent = (templateData: TemplateData, componentId: string): DataItem[] =>
    templateData[componentId]?.items || [];

export function getItemsForNewPanel(
    newItem: ComponentContainer,
    groupedItems: CanvasItem[],
    containerMembers: CanvasItem[],
) {
    return containerMembers.reduce(
        (acc, member) => {
            const itemIsGrouped = groupedItems.includes(member);
            if (!itemIsGrouped) {
                return {
                    ...acc,
                    newMembers: [...acc.newMembers, member],
                };
            }

            if (itemIsGrouped && acc.panelHasBeenAdded) {
                return acc;
            }

            return { ...acc, panelHasBeenAdded: true, newMembers: [...acc.newMembers, newItem] };
        },
        {
            newMembers: [],
            panelHasBeenAdded: false,
        },
    ).newMembers;
}

export function getRuleConditionSourcesForItem(canvasItem?: CanvasItem) {
    if (!canvasItem) {
        return [ConditionSource.COMPONENT_SELECTED];
    }

    switch (canvasItem.type) {
        case ComponentType.PICKING_LIST:
        case ComponentType.CODED_PICKING_LIST: {
            return [ConditionSource.COMPONENT_VALUE_SELECTED];
        }

        case ComponentType.NUMERIC_VALUE:
        case ComponentType.CALCULATOR: {
            return [ConditionSource.COMPONENT_HAS_VALUE, ConditionSource.COMPONENT_VALUE_COMPARISON];
        }

        case ComponentType.CODED: {
            const codedComponent = canvasItem as CodedComponent;

            return codedComponent.code.isNumeric
                ? [ConditionSource.COMPONENT_SELECTED, ConditionSource.COMPONENT_VALUE_COMPARISON]
                : [ConditionSource.COMPONENT_SELECTED];
        }

        case ComponentType.FREE_TEXT: {
            return [ConditionSource.COMPONENT_HAS_VALUE];
        }

        default:
            return [ConditionSource.COMPONENT_SELECTED];
    }
}

const hasValueConditionState = t => [
    {
        text: t(`templates.rules.hasValue`),
        value: "hasValue",
        conditionSource: ConditionSource.COMPONENT_HAS_VALUE,
    },
    {
        text: t("templates.rules.hasNoValue"),
        value: "hasNoValue",
        conditionSource: ConditionSource.COMPONENT_HAS_VALUE,
    },
];

export function getAvailableRuleComponentStates(t, canvasItem?: CanvasItem): ConditionState[] {
    if (canvasItem && canvasItem.type === ComponentType.PICKING_LIST) {
        const pickingList = canvasItem as PickingListComponent;
        return pickingList.options.map(({ text, id }) => ({
            text,
            value: id,
            conditionSource: ConditionSource.COMPONENT_VALUE_SELECTED,
        }));
    }

    if (canvasItem && canvasItem.type === ComponentType.CODED_PICKING_LIST) {
        const pickingList = canvasItem as CodedPickingListComponent;
        return pickingList.codes.map(code => ({
            text: code.term,
            value: code.emisCodeId.toString(),
            conditionSource: ConditionSource.COMPONENT_VALUE_SELECTED,
        }));
    }

    const conditionSources = getRuleConditionSourcesForItem(canvasItem);

    let componentStates: ConditionState[] = [];

    if (conditionSources.includes(ConditionSource.COMPONENT_HAS_VALUE)) {
        componentStates = hasValueConditionState(t);
    }

    if (conditionSources.includes(ConditionSource.COMPONENT_SELECTED)) {
        componentStates.push(
            {
                text: t("selected"),
                value: "selected",
                conditionSource: ConditionSource.COMPONENT_SELECTED,
            },
            {
                text: t("notSelected"),
                value: "unselected",
                conditionSource: ConditionSource.COMPONENT_SELECTED,
            },
        );
    }

    if (conditionSources.includes(ConditionSource.COMPONENT_VALUE_COMPARISON)) {
        const operators: ConditionState[] = Object.values(ComponentValueConditionOperator).map(operator => ({
            text: t(`templates.rules.operators.${operator}`),
            value: operator,
            conditionSource: ConditionSource.COMPONENT_VALUE_COMPARISON,
        }));

        componentStates.push(...operators);
    }

    return componentStates;
}
