import * as React from "react";
import { DisplayType } from "@emisgroup/clint-content/lib/types";
import { PerspectivesDefinition } from "@emisgroup/clint-templates-perspectives";

export const CLINICAL_CONTENT_QUERY_DISPLAY_TYPES = "CLINICAL_CONTENT_QUERY_DISPLAY_TYPES";
export const SAVED_CONTENT_ENABLED = "SAVE_SELECTION_AS_ENABLED";
export const VISIBILITY_RULES_SELECT_QUERY_ENABLED = "VISIBILITY_RULES_SELECT_QUERY_ENABLED";
export const CODED_COMPONENTS_ENABLED = "CODED_COMPONENTS_ENABLED";
export const NUMERIC_VALUE_COMPONENT_ENABLED = "NUMERIC_VALUE_COMPONENT_ENABLED";
export const CODE_EXTRA_INFORMATION_PROPERTIES = "CODE_EXTRA_INFORMATION_PROPERTIES";
export const SUPPORT_MULTI_COLUMN = "SUPPORT_MULTI_COLUMN";
export const ASSOCIATED_TEXT_IS_CLINICAL_CONTENT = "ASSOCIATED_TEXT_IS_CLINICAL_CONTENT";
export const USE_TEMPLATE_PICKER = "USE_TEMPLATE_PICKER";
export const PERSPECTIVES = "PERSPECTIVES";

export type FeaturesConfig = {
    [CLINICAL_CONTENT_QUERY_DISPLAY_TYPES]: DisplayType[];
    [SAVED_CONTENT_ENABLED]: boolean;
    [VISIBILITY_RULES_SELECT_QUERY_ENABLED]: boolean;
    [CODED_COMPONENTS_ENABLED]: boolean;
    [NUMERIC_VALUE_COMPONENT_ENABLED]: boolean;
    [CODE_EXTRA_INFORMATION_PROPERTIES]: any[];
    [SUPPORT_MULTI_COLUMN]: boolean;
    [ASSOCIATED_TEXT_IS_CLINICAL_CONTENT]: boolean;
    [USE_TEMPLATE_PICKER]: boolean;
    [PERSPECTIVES]: PerspectivesDefinition[];
};

type ConfigContextType = {
    features: FeaturesConfig;
    contentLibraryUrl: string;
    platformUrl: string;
    freeTextMaxChars: number;
};

const ConfigContext = React.createContext<ConfigContextType>({
    features: {
        [CLINICAL_CONTENT_QUERY_DISPLAY_TYPES]: [],
        [SAVED_CONTENT_ENABLED]: true,
        [VISIBILITY_RULES_SELECT_QUERY_ENABLED]: true,
        [CODED_COMPONENTS_ENABLED]: true,
        [NUMERIC_VALUE_COMPONENT_ENABLED]: true,
        [CODE_EXTRA_INFORMATION_PROPERTIES]: [],
        [SUPPORT_MULTI_COLUMN]: true,
        [ASSOCIATED_TEXT_IS_CLINICAL_CONTENT]: true,
        [USE_TEMPLATE_PICKER]: true,
        [PERSPECTIVES]: [],
    },
    contentLibraryUrl: "",
    platformUrl: "",
    freeTextMaxChars: 500,
});

export default ConfigContext;
