import { useTranslation } from "@emisgroup/application-intl";
import { CanvasItem, ItemTypePropertiesMap, PropertyAttributes } from "@emisgroup/clint-templates-common";
import { IPerspective } from "@emisgroup/clint-templates-perspectives/lib";
import { ITabItem, Tabs } from "@emisgroup/ui-kit-react";
import React from "react";
import { InvalidPerspective, InvalidProperties, ParametersForPropertyUpdate } from "../types";
import { getPerspectiveName, NamedPerspective } from "../utils/perspectiveUtils";
import PropertyInspector, { InspectorProps } from "./propertyInspector";

type PropertyOverrideInspectorProps = {
    component: CanvasItem;
    editEnabled: boolean;
    onPropertyUpdate: any;
    invalidProperties: InvalidProperties;
};

type PropertyOverrideInspectorState = { tabIndex: number; componentId: string };

// Create tabs for a component's default properties and each of its perspectives
const getPerspectiveTabs = (t, perspectives: NamedPerspective[]): ITabItem[] => {
    const defaultTab = {
        text: t("perspectives.default"),
        ariaControls: "default",
    };
    return [defaultTab].concat(
        perspectives.map(p => ({
            text: getPerspectiveName(t, p),
            ariaControls: p.key,
        })),
    );
};

// Create props for inspecting a component's default properties or from one of its perspectives
const getInspectorProperties = (
    tabIndex: number,
    inspectorProps: PropertyOverrideInspectorProps,
    perspectives: IPerspective[],
    invalidPerspectives: InvalidPerspective[],
    setState: (state: PropertyOverrideInspectorState) => void,
): InspectorProps => {
    if (tabIndex === 0 || tabIndex > perspectives.length) return inspectorProps;

    const { component, onPropertyUpdate } = inspectorProps;
    const perspective = perspectives[tabIndex - 1];
    const invalidPerspective = invalidPerspectives.find(v => v.key === perspective.key) ?? {};
    const overrideProperty = (params: ParametersForPropertyUpdate, properties: PropertyAttributes[]) => {
        if (typeof component[params.propertyName] !== "undefined" || params.propertyValue !== "") {
            onPropertyUpdate({ ...params, item: component }, properties);
        }
    };
    const revertOverride = (propertyName: string, properties: PropertyAttributes[]) => {
        const revertParams = {
            item: component,
            propertyName,
            propertyValue: component[propertyName],
            perspectiveKey: perspective.key,
        };
        overrideProperty(revertParams, properties);
        setState({ tabIndex, componentId: component.id });
    };

    return {
        ...inspectorProps,
        invalidProperties: invalidPerspective,
        component: { ...component, ...perspective },
        onPropertyUpdate: overrideProperty,
        overriddenProperties: Object.keys(perspective),
        perspectiveKey: perspective.key,
        revertOverride,
    };
};

const PropertyOverrideInspector = (props: PropertyOverrideInspectorProps) => {
    const { t } = useTranslation();
    const { component, invalidProperties } = props;
    const perspectives = component?.perspectives ?? [];
    const invalidPerspectives = (invalidProperties?.perspectiveValidities ?? []) as InvalidPerspective[];
    const createState = (tabIndex): PropertyOverrideInspectorState => ({ tabIndex, componentId: component.id });
    const [state, setState] = React.useState<PropertyOverrideInspectorState>(createState(0));

    React.useEffect(() => {
        if (component.id !== state.componentId) setState(createState(0));
    }, [component]);

    const componentProperties = ItemTypePropertiesMap[component.type];
    const showPerspectives = perspectives.length !== 0 && componentProperties.some(p => p.allowsOverrideEdit);
    const perspectivesLabel = t("perspectives.perspectives");
    return (
        <>
            {showPerspectives && <div className="sidebar-row-header">{perspectivesLabel}</div>}
            <div className={showPerspectives ? "property-tab" : ""}>
                {showPerspectives && (
                    <Tabs
                        ariaLabel={perspectivesLabel}
                        activeTab={state.tabIndex}
                        tabsList={getPerspectiveTabs(t, perspectives)}
                        onTabSelect={tabIndex => setState(createState(tabIndex))}
                    />
                )}
                <PropertyInspector
                    {...getInspectorProperties(state.tabIndex, props, perspectives, invalidPerspectives, setState)}
                />
            </div>
        </>
    );
};

export default PropertyOverrideInspector;
