import * as React from "react";
import { Dropdown } from "@emisgroup/ui-kit-react";
import { CanvasItem } from "@emisgroup/clint-templates-common";
import { useTranslation } from "@emisgroup/application-intl";
import { ParametersForPropertyUpdate } from "../types";

type DropdownPropertyEntryProps = {
    component: CanvasItem;
    name: string;
    options: string[];
    isReadOnly: boolean;
    onPropertyUpdate: (params: ParametersForPropertyUpdate) => void;
};

const DropdownPropertyEntry = ({
    component,
    name,
    options,
    isReadOnly,
    onPropertyUpdate,
}: DropdownPropertyEntryProps) => {
    const { t } = useTranslation();
    const handleDropdownChange = (selectedCategory: string) => {
        onPropertyUpdate({ item: component, propertyName: name, propertyValue: selectedCategory });
    };
    const value = component[name] || options[0];
    const dataSource = (value ? [] : [{ text: "", value: "" }]).concat(
        options.map(option => ({ text: t(option), value: option })),
    );

    return !isReadOnly ? (
        <Dropdown
            data-testid={`select-${name}`}
            dataSource={dataSource}
            value={value}
            onChange={handleDropdownChange}
        />
    ) : (
        <span>{t(value)}</span>
    );
};

export default DropdownPropertyEntry;
