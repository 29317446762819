import React from "react";

export interface ISharedCodePickerProps {
    /**
     * The Coding Region (eg england)
     */
    region: string;
    /**
     * The Coding System (eg snomedct)
     */
    codingSystem: string;
    /**
     * The API URL the package needs to communicate with
     */
    ckbURL: string;
    /**
     * Data test-id
     */
    "data-testid"?: string;
    /**
     * The patient information to display in a patient banner, if undefined no banner will be displayed
     */
    patientContext?: any;
    /**
     * Config setting that will mark EMIS authored codes in the search results
     */
    highlightInternallyAuthored: boolean;
    /**
     * Callback function to pass API errors back to consuming application to deal with appropriately
     */
    onHandleError: (error: Error) => void;
    /**
     * Callback function that exposes the code when it is selected
     */
    onSuccess: (code: any) => void;
    /**
     * By default an alert is displayed if the a non-preferred term is selected.
     * This provides the ability to suppress that alert.
     */
    suppressPreferredTermAlert?: boolean;
}

export interface ICodePickerProps extends ISharedCodePickerProps {
    /**
     * Data loading for the whole dialog?
     */
    isLoading?: boolean;
    /**
     * Sets whether the picker is visible or not
     */
    displayPicker: boolean;
    /**
     * Config setting that will let the user to pick multiple codes
     */
    multiSelection?: boolean;
    /**
     * Callback function that exposes the codes when they are selected
     */
    onMultiSuccess?: (codes: any[]) => void;
    /**
     * Callback when the form is closed, or canceled
     */
    onCancel: () => void;
    /**
     * Should inactive codes be included?
     * Defaults to false if not set
     */
    includeInactive?: boolean;
    /**
     * Should non-selectable codes be included?
     * Defaults to false if not set
     */
    includeNonSelectable?: boolean;
    /**
     * Show warning when trying to select an un-selectable code
     * Defaults to true if not set
     */
    suppressWarningAlertOnNonSelectable?: boolean;

    EMISAppId?: string;
    APIToken: string;
}

const ASTHMA_CODE = {
    id: "41990019",
    attributes: {
        term: "Asthma",
        terminology: { "snomed-ct": { concept: { id: "195967001" }, description: { id: "195967002" } } },
    },
};

const HEIGHT_CODE = {
    id: "253669010",
    attributes: {
        term: "Height",
        qualifiers: { numeric: {} },

        terminology: { "snomed-ct": { concept: { id: "248333004" }, description: { id: "248333005" } } },
    },
};

const ClinicalCodePicker = (props: ICodePickerProps) => (
    <>
        <button type="button" data-testid="asthma" aria-label="asthma" onClick={() => props.onSuccess(ASTHMA_CODE)}>
            asthma
        </button>
        <button type="button" data-testid="height" aria-label="height" onClick={() => props.onSuccess(HEIGHT_CODE)}>
            height
        </button>
    </>
);

export default ClinicalCodePicker;
