import * as React from "react";

const CANVAS_SIZE_CLASSES = [
    { isMatch: width => width <= 630, className: "width-under-630" },
    { isMatch: width => width <= 800, className: "width-630-800" },
    { isMatch: width => width <= 1280, className: "width-800-1280" },
    { isMatch: width => width > 1280, className: "width-over-1280" },
];

const getCanvasSizeClass = (canvasDiv: HTMLDivElement) => {
    const { width } = canvasDiv.getBoundingClientRect();
    const canvasSizeClass = CANVAS_SIZE_CLASSES.find(({ isMatch }) => isMatch(width));
    return canvasSizeClass ? canvasSizeClass.className : "";
};
const useCanvasSizeClass = (getCanvas: () => HTMLDivElement) => {
    const [canvasSizeClass, setCanvasSizeClass] = React.useState("");

    const updateCanvasSizeClass = (canvas: HTMLDivElement) => {
        setCanvasSizeClass(getCanvasSizeClass(canvas));
    };

    React.useEffect(() => {
        const canvasDiv = getCanvas();
        const handleResize = () => {
            updateCanvasSizeClass(canvasDiv);
        };

        let resizeObserver: ResizeObserver | null = null;
        if (canvasDiv) {
            resizeObserver = new ResizeObserver(handleResize);
            resizeObserver.observe(canvasDiv);
        }

        return () => {
            if (resizeObserver) {
                resizeObserver.unobserve(canvasDiv);
            }
        };
    }, []);
    return canvasSizeClass;
};

export default useCanvasSizeClass;
