import * as React from "react";
import {
    PickingListComponent,
    PickingListOption,
    RunningTemplateContext,
    TemplateDataItem,
} from "@emisgroup/clint-templates-common";

type DropdownPickingListProps = {
    pickingList: PickingListComponent;
    dataItem: TemplateDataItem;
};

export default function DropdownPickingList({ pickingList, dataItem }: DropdownPickingListProps) {
    const { templateData, updateTemplateData } = React.useContext(RunningTemplateContext);

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedDataItem = dataItem && dataItem.items ? dataItem.items[0] : {};
        const selectedOption = pickingList.options.find(({ id: optionId }) => optionId === e.currentTarget.value);
        updateTemplateData({
            ...templateData,
            [pickingList.id]: {
                ...dataItem,
                items: selectedOption ? [{ ...selectedDataItem, value: selectedOption, selected: true }] : [],
                interacted: true,
            },
        });
    };

    const handleBlur = () => {
        updateTemplateData({
            ...templateData,
            [pickingList.id]: { ...dataItem, interacted: true },
        });
    };
    const id = `dropdown-${pickingList.id}`;
    const [selectedItem] = dataItem?.items || [];
    return (
        <>
            <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--s">
                <h1 className="nhsuk-fieldset__heading">{pickingList.label}</h1>
            </legend>

            <select
                className="nhsuk-select"
                id={id}
                onChange={handleChange}
                onBlur={handleBlur}
                value={(selectedItem?.value as PickingListOption)?.id}
            >
                {[{ id: "", text: "" }, ...pickingList.options].map(option => (
                    <option key={option.text || "__blank__"} value={option.id}>
                        {option.text}
                    </option>
                ))}
            </select>
        </>
    );
}
