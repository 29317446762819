import { Validity } from "@emisgroup/clinical-code-entry/lib/types";
import {
    CanvasItem,
    CodedDataItem,
    CodeValueType,
    ComponentContainer,
    ComponentType,
    DataItem,
    Tab,
    TabPage,
    TemplateData,
    TemplateDataItem,
} from "../types";

const containerTypes = [
    String(ComponentType.CONTAINER),
    String(ComponentType.PANEL),
    String(ComponentType.CLINICAL_CONTENT),
    String(ComponentType.TAB_CONTAINER),
    String(ComponentType.TAB_PAGE),
];

export const isContainerType = (type: string): boolean => containerTypes.some(containerType => containerType === type);
export const isCanvasItemAContainer = (canvasItem: CanvasItem) => isContainerType(canvasItem.type);

export const findCanvasItem = (id: string, items: CanvasItem[]): CanvasItem | undefined => {
    for (let i = 0, len = items.length; i < len; i += 1) {
        const item = items[i];
        if (item.id === id) return item;

        if (isCanvasItemAContainer(item)) {
            const result = findCanvasItem(id, (item as ComponentContainer).members);
            if (result) return result;
        }
    }

    return undefined;
};

const findCanvasItemParentInNestedLevel = (
    id: string,
    items: CanvasItem[],
    currentContainer: ComponentContainer | undefined,
): ComponentContainer | undefined => {
    for (let i = 0, len = items.length; i < len; i += 1) {
        const item = items[i];
        if (item.id === id) {
            return currentContainer;
        }

        if (isContainerType(item.type)) {
            const container = item as ComponentContainer;
            const result = findCanvasItemParentInNestedLevel(id, container.members, container);
            if (result) {
                return result;
            }
        }
    }
    return undefined;
};

export const findCanvasItemParent = (id: string, templateItems: CanvasItem[]): ComponentContainer | undefined => {
    if (typeof id === "undefined") {
        return undefined;
    }

    return findCanvasItemParentInNestedLevel(id, templateItems, undefined);
};

export const findRootLevelAncestor = (id: string, templateItems: CanvasItem[]): ComponentContainer | undefined => {
    let rootLevelAncestor = findCanvasItemParent(id, templateItems);
    while (rootLevelAncestor) {
        const nextAncestor = findCanvasItemParent(rootLevelAncestor.id, templateItems);
        if (!nextAncestor) break;
        rootLevelAncestor = nextAncestor;
    }
    return rootLevelAncestor;
};

export const findTabPageContainingCanvasItem = (id: string, tabContainer: Tab): TabPage | undefined =>
    tabContainer.members.find(tabPage => tabPage.id === id || findCanvasItem(id, tabPage.members));

export const getUpdatedContainer = (
    rootContainer: ComponentContainer,
    containersWithUpdatedMembers: Array<[string, CanvasItem[]]>,
): ComponentContainer => {
    let updatedRootMembers = rootContainer.members;
    containersWithUpdatedMembers.forEach(([containerId, updatedItems]) => {
        if (containerId === rootContainer.id) {
            updatedRootMembers = updatedItems;
        } else {
            updatedRootMembers = updatedRootMembers.map(rootMember => {
                if (rootMember.id === containerId) {
                    return { ...rootMember, members: updatedItems };
                }
                if (isContainerType(rootMember.type)) {
                    return getUpdatedContainer(rootMember as ComponentContainer, containersWithUpdatedMembers);
                }
                return rootMember;
            });
        }
    });
    return {
        ...rootContainer,
        members: updatedRootMembers,
    };
};

type ActionPayload = { position?: number; containerToAddTo?: ComponentContainer };
export function addRemoveUpdateItemInContainer(
    rootContainer: ComponentContainer,
    whichItem: CanvasItem,
    action: string,
    { position, containerToAddTo }: ActionPayload = {},
): ComponentContainer {
    let containerOfItem = findCanvasItemParent(whichItem.id, rootContainer.members);
    if (action === "add" && containerToAddTo) {
        containerOfItem = containerToAddTo;
    } else if (!containerOfItem) {
        containerOfItem = rootContainer;
    }
    const itemsInContainer = containerOfItem.members;
    let updatedItemsInContainer;
    if (action === "add") {
        if (typeof position !== "undefined" && position < itemsInContainer.length) {
            updatedItemsInContainer = [
                ...itemsInContainer.slice(0, position),
                whichItem,
                ...itemsInContainer.slice(position),
            ];
        } else {
            updatedItemsInContainer = [...itemsInContainer, whichItem];
        }
    } else if (action === "remove") {
        updatedItemsInContainer = itemsInContainer.filter(item => item.id !== whichItem.id);
    } else if (action === "update") {
        updatedItemsInContainer = itemsInContainer.map(item => (item.id === whichItem.id ? whichItem : item));
    }
    return getUpdatedContainer(rootContainer, [[containerOfItem.id, updatedItemsInContainer]]);
}

export const createDefaultDataItem = (associatedText?: string): DataItem => {
    return (associatedText ? { selected: false, associatedText } : { selected: false }) as DataItem;
};

export const getDataItemsForMultiSelectListComponent = (templateData: TemplateData, componentId: string): DataItem[] =>
    templateData[componentId]?.items || [];

export const getDataItemForSingleSelectListComponent = (
    templateData: TemplateData,
    componentId: string,
    associatedText?: string,
): DataItem => {
    const dataItems = getDataItemsForMultiSelectListComponent(templateData, componentId);
    return dataItems.length === 0 ? createDefaultDataItem(associatedText) : dataItems[0];
};

const codedComponentHasValue = ({ code, selected, value }: CodedDataItem) => (code.isNumeric ? value : selected);

export function componentHasValue(templateData: TemplateData, componentId: string, componentType: string) {
    const componentDataInTemplate = templateData[componentId];
    if (!componentDataInTemplate) {
        return false;
    }
    const { items } = componentDataInTemplate;
    switch (componentType) {
        case ComponentType.PICKING_LIST:
            return items && items.length !== 0 && Boolean(items[0].value);
        case ComponentType.UNCODED:
        case ComponentType.DIARY_ENTRY:
            return Boolean(componentDataInTemplate.selected);
        case ComponentType.NUMERIC_VALUE:
            return typeof componentDataInTemplate.value === "number";
        case ComponentType.FREE_TEXT:
            return Boolean(componentDataInTemplate.value);
        case ComponentType.CODED: {
            const componentValue = componentDataInTemplate.value;
            return (
                (typeof componentValue === "object" &&
                    (componentValue as any).values.some(v => typeof v?.value !== "undefined" && v.value !== null)) ||
                componentDataInTemplate.selected === true
            );
        }
        case ComponentType.CODED_PICKING_LIST: {
            return items && items.length === 1 && codedComponentHasValue(items[0] as CodedDataItem);
        }
        default:
            return false;
    }
}

export const isEmbeddedContainer = (container: ComponentContainer) => Boolean(container.ern);

export type CanvasItemWithContainer = { canvasItem: CanvasItem; container: ComponentContainer };

const nestedCanvasItemsReducer = (
    result: CanvasItemWithContainer[],
    canvasItemWithContainer: CanvasItemWithContainer,
): CanvasItemWithContainer[] => {
    if (isCanvasItemAContainer(canvasItemWithContainer.canvasItem)) {
        const container = canvasItemWithContainer.canvasItem as ComponentContainer;
        return result.concat(
            container.members
                .map(canvasItem => ({ canvasItem, container } as CanvasItemWithContainer))
                .reduce(nestedCanvasItemsReducer, [canvasItemWithContainer]),
        );
    }

    return result.concat(canvasItemWithContainer);
};

export const getAllNestedCanvasItems = (container: ComponentContainer): CanvasItemWithContainer[] => {
    return container.members
        .map(canvasItem => ({ canvasItem, container } as CanvasItemWithContainer))
        .reduce(nestedCanvasItemsReducer, []);
};

export const getCanvasItemsWithNestedMembers = (
    canvasItems: CanvasItem[],
    container: ComponentContainer,
): CanvasItem[] => {
    return canvasItems
        .map(canvasItem => ({ canvasItem, container } as CanvasItemWithContainer))
        .reduce(nestedCanvasItemsReducer, [])
        .map(canvasItemWithContainer => canvasItemWithContainer.canvasItem);
};

export const applyAdditionalDataPropertyUpdate = item => obj => ({
    ...item,
    ...obj,
});

export const getListPropertyValidity = (t, propertyValue: any[], keyPropertyName?: string) => {
    if (propertyValue && propertyValue.length < 2) {
        return { isValid: false, message: t("components.pickingList.atLeastTwoItems") };
    }

    const deduplicatedArraySize = new Set(
        keyPropertyName ? propertyValue.map(val => val[keyPropertyName]) : propertyValue,
    ).size;

    if (deduplicatedArraySize !== propertyValue.length) {
        return { isValid: false, message: t("components.pickingList.allItemsUnique") };
    }

    return { isValid: true };
};

export const getMaxLengthValidity = (t, propertyValue: string, maxLength: number): Validity => {
    return propertyValue?.length > maxLength
        ? { isValid: false, message: t("components.maximumCharactersWarning", { maxLength }) }
        : { isValid: true };
};

export const getComponentTypeClass = (componentType: string) => {
    return `${componentType.replace(/ /g, "-")}-component`;
};

export const itemsInColumnOrder = (canvasItems: CanvasItem[]): CanvasItem[] => {
    return [...canvasItems].sort((a, b) => (a.columnIndex || 0) - (b.columnIndex || 0));
};

export const isDescendantOf = (
    itemId: string,
    candidateAncestorIds: string[],
    templateItems: CanvasItem[],
): boolean => {
    let parent = findCanvasItemParent(itemId, templateItems);

    while (parent) {
        if (candidateAncestorIds.includes(parent.id)) {
            return true;
        }
        parent = findCanvasItemParent(parent.id, templateItems);
    }

    return false;
};

export const extractCodedValue = (data: TemplateDataItem): number => {
    if (!data.value) {
        return Number.NaN;
    }

    const { values } = data.value as CodeValueType;
    const nonNullValues = values.filter(({ value }) => value !== null);
    return nonNullValues.length ? nonNullValues.reduce((total, { value }) => total + value, 0) : Number.NaN;
};
