import * as React from "react";
import { QueryResultData } from "@emisgroup/clint-content/lib/types";
import { ErrorHandling, ErrorState } from "@emisgroup/clinical-code-entry/lib/types";
import { ActionType, TemplateData } from "../types";

type RunningTemplateContextType = {
    templateData: TemplateData;
    updateTemplateData: (newData: TemplateData) => void;
    initialiseTemplateData: (queryResults: QueryResultData) => void;
    onHandleError?: (errorState: ErrorState) => ErrorHandling;
    runDate: Date;
    showDefaultActionButtons?: boolean;
    onRaiseAction?: (actionType: ActionType, validData: boolean | null) => void;
    onHandleAction?: (actionType: ActionType, validData: boolean | null) => void;
};

export const RunningTemplateContext = React.createContext<RunningTemplateContextType>({
    templateData: {},
    updateTemplateData: () => {},
    initialiseTemplateData: () => {},
    runDate: new Date(),
    showDefaultActionButtons: false,
    onRaiseAction: () => {},
    onHandleAction: () => {},
});
