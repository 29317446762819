import { ActionButton, ActionType, ActionButtonStyle } from "@emisgroup/clint-templates-common";
import { ActionButtonErrors, ActionButtonProperty, ActionButtonPropertyValidity } from "../types";

export const getActionButtonPropertyValidity = (
    actionButtonProperty: ActionButtonProperty,
    invalidActionButtonProperties?: any,
): ActionButtonPropertyValidity =>
    typeof invalidActionButtonProperties !== "undefined" &&
    typeof invalidActionButtonProperties[actionButtonProperty] !== "undefined"
        ? { isValid: false, message: invalidActionButtonProperties[actionButtonProperty] }
        : { isValid: true };

export const anyInvalidActionButtonProperties = (invalidActionButtonProperties?: any) =>
    typeof invalidActionButtonProperties !== "undefined" && Object.keys(invalidActionButtonProperties).length > 0;

const validateActionButton = (t, actionButton: ActionButton): ActionButtonErrors | null => {
    const actionButtonErrors: ActionButtonErrors = {};

    if (actionButton.label.trim().length === 0) {
        actionButtonErrors[ActionButtonProperty.LABEL] = t("templates.panelActionButtons.enterLabel");
    }

    return Object.keys(actionButtonErrors).length ? actionButtonErrors : null;
};

export const validateActionButtons = (t, actionButtons: ActionButton[]) => {
    const result = {};

    actionButtons.forEach((actionButton, index) => {
        const actionButtonErrors = validateActionButton(t, actionButton);
        if (actionButtonErrors !== null) {
            result[index] = actionButtonErrors;
        }
    });

    return result;
};

export const areAllActionButtonsValid = (t, actionButtons: ActionButton[]) =>
    Object.keys(validateActionButtons(t, actionButtons)).length === 0;

export const getDefaultPanelActionButtons = t => [
    {
        action: ActionType.NEXTSTEP,
        label: t("actions.continue"),
        style: ActionButtonStyle.PRIMARY,
        validate: true,
    },
];
