import * as React from "react";
import { ProgressSpinnerLarge } from "@emisgroup/ui-kit-react";
import { AuthContext, ComponentContainer, ConfigContext } from "@emisgroup/clint-templates-common";
import { loadEmbeddedItems } from "../utils/embeddingUtils";
import TimedNotification from "./timedNotification";
import { NOTIFICATION_TIMEOUT_MS } from "../constants";

const useFullTemplateDefinition = () => {
    const { getBearerToken } = React.useContext(AuthContext);
    const { contentLibraryUrl } = React.useContext(ConfigContext);

    const [loadError, setLoadError] = React.useState("");
    const [loadingEmbeddedContent, setLoadingEmbeddedContent] = React.useState(false);

    const getFullTemplateDefinition = async (definition: any): Promise<any> => {
        try {
            const { members } = definition as ComponentContainer;
            if (!members) {
                return definition;
            }

            setLoadingEmbeddedContent(true);
            const loadResult = await loadEmbeddedItems(members, contentLibraryUrl, await getBearerToken());

            if (loadResult.errors) {
                setLoadError(loadResult.errors.join());
                return undefined;
            }

            return {
                ...definition,
                members: loadResult.loadedItems,
            };
        } finally {
            setLoadingEmbeddedContent(false);
        }
    };

    const view = (
        <>
            {loadingEmbeddedContent && <ProgressSpinnerLarge text="Loading dependencies" />}
            {loadError && (
                <TimedNotification
                    type="info"
                    text={loadError}
                    milliseconds={NOTIFICATION_TIMEOUT_MS}
                    onTimeout={() => setLoadError("")}
                />
            )}
        </>
    );

    return { getFullTemplateDefinition, view };
};

export default useFullTemplateDefinition;
