import * as React from "react";
import { IListItem } from "@emisgroup/ui-kit-react";
import { useTranslation } from "@emisgroup/application-intl";
import MultiSelectOptions from "./multiSelectOptions";
import "./multiSelectDropdown.css";
import { KEYS } from "../../constants";

type MultiSelectDropdownProps = {
    id?: string;
    placeholder?: string;
    dataSource: IListItem[];
    values?: any[];
    disabled?: boolean;
    onChange?: (selectedValue: any[]) => void;
    "data-testid"?: string;
    isValid?: boolean;
    selectedItemsText?: string;
};

const MultiSelectDropdown = (props: MultiSelectDropdownProps) => {
    const { t } = useTranslation();
    const {
        id,
        placeholder = "",
        dataSource,
        values,
        disabled,
        onChange,
        "data-testid": dataTestId,
        isValid,
        selectedItemsText,
    } = props;
    const multiSelectDropdownRef = React.useRef<HTMLDivElement>(null);
    const [selectedValues, setSelectedValues] = React.useState<any[]>([]);
    const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

    const valuesToDisplay = values || selectedValues;
    const selectionMessage =
        valuesToDisplay.length === 0
            ? placeholder
            : selectedItemsText ??
              t("components.pickingList.numberSelected", { numberSelected: valuesToDisplay.length });

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const updateSelectedValues = updatedValues => {
        if (!values) {
            setSelectedValues(updatedValues);
        } else if (onChange) {
            onChange(updatedValues);
        }
    };
    const onSelectionChange = (option: IListItem, selected: boolean) => {
        const newSelectedValues = dataSource
            .map(item => item.value)
            .reduce((valueList, value) => {
                if (value === option.value) {
                    if (selected) {
                        valueList.push(value);
                    }
                    return valueList;
                }
                if (valuesToDisplay.includes(value)) {
                    valueList.push(value);
                }
                return valueList;
            }, [] as any[]);

        updateSelectedValues(newSelectedValues);
    };

    const optionsRef = React.useRef<any>(null);

    const handleBlur = (e: React.FocusEvent) => {
        if (!multiSelectDropdownRef.current?.contains(e.relatedTarget as any)) {
            updateSelectedValues(valuesToDisplay.length ? valuesToDisplay : []);
        }
    };

    const handleButtonBlur = (e: React.FocusEvent) => {
        if (isDropdownOpen) {
            e.stopPropagation();
            setTimeout(() => optionsRef.current?.focusFirstElement(document.activeElement), 0);
        }
    };

    const handleButtonKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === KEYS.ESC && isDropdownOpen) {
            toggleDropdown();
        }
    };

    const buttonId = `${id}-button`;
    const dropdownId = `${id}-dropDown`;

    const dataTestIdPrefix = dataTestId ? `${dataTestId}-` : "";

    return (
        <div
            ref={multiSelectDropdownRef}
            id={id}
            data-testid={dataTestId}
            className="multi-select"
            role="combobox"
            aria-controls={buttonId}
            aria-expanded={isDropdownOpen}
            aria-haspopup={isDropdownOpen}
            aria-owns={isDropdownOpen ? dropdownId : undefined}
            onBlur={handleBlur}
        >
            <button
                id={buttonId}
                className={`eui-dropdown multi-select-dropdown-button${
                    isValid ? "" : " eui-dropdown__select--invalid"
                } ${disabled ? "disabled" : ""}`}
                type="button"
                onClick={toggleDropdown}
                onBlur={handleButtonBlur}
                onKeyDown={handleButtonKeyDown}
                data-testid={`${dataTestIdPrefix}dropdown-button`}
                disabled={disabled}
            >
                <span className="multi-select-placeholder" data-testid={`${dataTestIdPrefix}placeholder`}>
                    {selectionMessage}
                </span>
            </button>
            <MultiSelectOptions
                ref={optionsRef}
                id={dropdownId}
                dataTestId={dataTestId}
                isOpen={isDropdownOpen}
                dataSource={dataSource}
                valuesToDisplay={valuesToDisplay}
                multiSelectDropdownElement={multiSelectDropdownRef.current}
                onSelectionChange={onSelectionChange}
                closeDropdown={() => setIsDropdownOpen(false)}
            />
        </div>
    );
};

export default MultiSelectDropdown;
