/* eslint-disable import/prefer-default-export */
import React from "react";
import {
    ButtonProps,
    ComponentType,
    GenericDialog,
    Panel,
    Rule,
    ActionButton,
} from "@emisgroup/clint-templates-common";
import { useTranslation } from "@emisgroup/application-intl";
import { IPerspective } from "@emisgroup/clint-templates-perspectives/lib";
import PropertyInspector from "./propertyInspector";
import VisibilityRules from "./visibilityRules";
import { capitalise, getMandatoryProperties } from "../utils/componentUtils";
import useProperties from "./useProperties";
import useVisibilityRules from "./useVisibilityRules";
import PanelActions from "./panelActions";
import usePanelActions from "./usePanelActions";
import { PropertyInspectorProvider } from "./propertyInspectorContext";
import Perspectives from "./perspectives";

export const NewItemDialog = ({
    item,
    container,
    setItems,
    addItem,
    cancelAddition,
    visibilityRulesDisallowedItems,
}) => {
    const { t } = useTranslation();
    const { areAllPropertiesValid, invalidProperties, updateItemProperty, showAllValidation } = useProperties(
        item,
        setItems,
    );

    const { areAllRuleConditionsValid, invalidRuleConditions, updateRuleConditionValidity } = useVisibilityRules(item);
    const { areAllPanelActionsValid, invalidPanelActions, onPanelActionInteracted } = usePanelActions(item);

    const formElement = React.useRef<HTMLFormElement>(null);

    const handleSubmit = ev => {
        if (areAllPropertiesValid() && areAllRuleConditionsValid() && areAllPanelActionsValid()) {
            addItem();
        } else {
            showAllValidation();
        }

        ev.preventDefault();
    };

    const dialogButtons = React.useMemo(
        () => [
            {
                onClick: evt => {
                    formElement.current?.requestSubmit();
                    evt.stopPropagation();
                },
                label: capitalise(t("add")),
                isPrimary: true,
                ariaLabel: t("templates.addComponent"),
            } as ButtonProps,
            {
                onClick: evt => {
                    cancelAddition();
                    evt.stopPropagation();
                },
                label: t("cancel"),
                isPrimary: false,
                ariaLabel: t("templates.cancelAddComponent"),
            } as ButtonProps,
        ],
        [],
    );

    const onRuleUpdate = (rule: Rule) => {
        setItems([{ ...item, rule }]);
    };

    const onPerspectivesUpdate = (perspectives?: IPerspective[]) => {
        setItems([{ ...item, perspectives }]);
    };

    const handlePanelActionsUpdate = (actionButtons: ActionButton[] | null) => {
        const updatedItem = {
            ...item,
            actionButtons,
        } as Panel;

        setItems([updatedItem]);
    };

    return (
        item && (
            <GenericDialog
                key="new-item-dialog"
                buttons={dialogButtons}
                onCancel={cancelAddition}
                title={t("templates.componentProperties")}
                ariaLabel={`Properties for new component in ${container.label}`}
                fillAvailableSpace
                leftAlignButtons
                disableDismiss
            >
                <form ref={formElement} onSubmit={handleSubmit} data-testid="new-item-form">
                    <PropertyInspectorProvider isNewItem>
                        <PropertyInspector
                            component={item}
                            editEnabled={true}
                            onPropertyUpdate={updateItemProperty}
                            focusOn={getMandatoryProperties(item)[0]?.name}
                            invalidProperties={invalidProperties}
                        />
                    </PropertyInspectorProvider>
                    <VisibilityRules
                        show={true}
                        editEnabled={true}
                        selectedCanvasItem={item}
                        onRuleAddOrUpdate={onRuleUpdate}
                        disallowedItems={visibilityRulesDisallowedItems}
                        invalidRuleConditions={invalidRuleConditions}
                        onUpdateRuleConditionValidity={updateRuleConditionValidity}
                    />
                    {item.type === ComponentType.PANEL && (
                        <PanelActions
                            panel={item as Panel}
                            editEnabled={true}
                            invalidPanelActions={invalidPanelActions}
                            onPanelActionsUpdate={handlePanelActionsUpdate}
                            onPanelActionInteracted={onPanelActionInteracted}
                        />
                    )}
                    <Perspectives editEnabled={true} selectedCanvasItem={item} onUpdate={onPerspectivesUpdate} />
                </form>
            </GenericDialog>
        )
    );
};
