import * as React from "react";
import { CanvasItem, MultiSelectDropdown, MultiSelectPropertyOption } from "@emisgroup/clint-templates-common";
import { useTranslation } from "@emisgroup/application-intl";
import { ParametersForPropertyUpdate } from "../types";
import { getSelectionsAsText } from "../utils/multiSelectPropertyUtils";

type MultiSelectPropertyEntryProps = {
    component: CanvasItem;
    name: string;
    placeHolder: string;
    options: MultiSelectPropertyOption[];
    isReadOnly: boolean;
    onPropertyUpdate: (params: ParametersForPropertyUpdate) => void;
};

const MultiSelectPropertyEntry = ({
    component,
    name,
    placeHolder,
    options,
    isReadOnly,
    onPropertyUpdate,
}: MultiSelectPropertyEntryProps) => {
    const { t } = useTranslation();
    const handleChange = (selectedValues: string[]) => {
        onPropertyUpdate({
            item: component,
            propertyName: name,
            propertyValue: selectedValues,
        });
    };

    const values = component[name] != null && typeof component[name] !== "undefined" ? component[name] : [];

    const selectedItemsText = getSelectionsAsText(t, values, options);

    return !isReadOnly ? (
        <MultiSelectDropdown
            placeholder={t(placeHolder)}
            dataSource={options}
            onChange={handleChange}
            values={values}
            selectedItemsText={selectedItemsText}
            isValid={true}
            id={`${component.id}-${name}`}
        />
    ) : (
        <span data-testid="multi-select-read-only">{selectedItemsText ?? placeHolder}</span>
    );
};

export default MultiSelectPropertyEntry;
