import * as React from "react";
import { AssociatedTextEntry } from "@emisgroup/clinical-code-entry/";
import { useTranslation } from "@emisgroup/application-intl";
import { AppMode, DataEntryComponentProps, TemplateData } from "../types";
import { RunningTemplateContext } from "../context/runningTemplate";
import { componentHasValue } from "../utils/componentUtils";
import AppModeContext from "../context/appMode";
import { ComponentAssociatedTextEdit, ComponentAssociatedTextView } from "./componentAssociatedTextOptions";

type ComponentAssociatedTextProps = DataEntryComponentProps & {
    text?: string;
    getComponentHasValue?: (templateData: TemplateData) => boolean;
    onTextChange?: (text: string) => void;
};

const ComponentAssociatedText = (props: ComponentAssociatedTextProps) => {
    const { t } = useTranslation();
    const { templateData = {} } = React.useContext(RunningTemplateContext);
    const { mode } = React.useContext(AppModeContext);

    const {
        hasAssociatedText,
        associatedText,
        label,
        isSelected,
        onSelect,
        id,
        type,
        text,
        onTextChange,
        onPropertyUpdate,
        getComponentHasValue,
    } = props;
    const isInRunMode = mode === AppMode.RUN;
    const isRunModeAssociatedTextAvailable = () =>
        hasAssociatedText &&
        (typeof getComponentHasValue !== "undefined"
            ? getComponentHasValue(templateData)
            : componentHasValue(templateData, id, type));

    const isAvailable = isInRunMode ? isRunModeAssociatedTextAvailable() : hasAssociatedText;
    if (!isAvailable) {
        return null;
    }

    const handleBlurRunMode = (updatedText: string) => {
        if (onTextChange) onTextChange(updatedText);
    };

    const content = () => {
        switch (mode) {
            case AppMode.RUN:
                return (
                    <div className="indented-data-entry">
                        <AssociatedTextEntry
                            text={text || ""}
                            ariaLabel={t("components.associatedText.forLabel", { label })}
                            onChange={handleBlurRunMode}
                            label={t("components.associatedText.name")}
                        />
                    </div>
                );
            case AppMode.EDIT:
                return (
                    <ComponentAssociatedTextEdit
                        associatedText={associatedText}
                        isSelected={isSelected}
                        onSelect={onSelect}
                        onPropertyUpdate={onPropertyUpdate}
                    />
                );
            case AppMode.READ:
                return <ComponentAssociatedTextView associatedText={associatedText} />;
            default:
                return null;
        }
    };

    return <div className="canvas-item-entry">{content()}</div>;
};

export default ComponentAssociatedText;
