import { useTranslation } from "@emisgroup/application-intl";
import { CanvasItem } from "@emisgroup/clint-templates-common";
import * as React from "react";
import { validateVisibilityRule } from "../utils/ruleUtils";

const getInvalidInteractedRuleConditions = (ruleErrors: any, interactedConditions: number[]) =>
    Object.entries(ruleErrors)
        .filter(([conditionIndex]) => interactedConditions.includes(Number(conditionIndex)))
        .reduce((result, [conditionIndex, conditionErrors]) => ({ ...result, [conditionIndex]: conditionErrors }), {});

function useVisibilityRules(canvasItem: CanvasItem) {
    const { t } = useTranslation();

    const interactedRuleConditionIndexes = React.useRef<number[]>([]);

    React.useEffect(() => {
        interactedRuleConditionIndexes.current = Object.keys(validateVisibilityRule(t, canvasItem?.rule)).map(
            conditionIndexKey => Number(conditionIndexKey),
        );
    }, [canvasItem.id]);

    const invalidRuleConditions = React.useMemo(
        () =>
            getInvalidInteractedRuleConditions(
                validateVisibilityRule(t, canvasItem.rule),
                interactedRuleConditionIndexes.current,
            ),

        [canvasItem, interactedRuleConditionIndexes.current],
    );

    const updateRuleConditionValidity = (conditionIndex: number) => {
        if (!interactedRuleConditionIndexes.current.includes(conditionIndex)) {
            interactedRuleConditionIndexes.current = [...interactedRuleConditionIndexes.current, conditionIndex];
        }
    };

    const areAllRuleConditionsValid = () => Object.entries(invalidRuleConditions).length === 0;

    return { areAllRuleConditionsValid, invalidRuleConditions, updateRuleConditionValidity };
}

export default useVisibilityRules;
