import AppModeContext from "./appMode";
import AuthContext from "./auth";
import { ComponentConfigDataContext, ComponentConfigData } from "./componentConfig";
import ConfigContext, {
    CLINICAL_CONTENT_QUERY_DISPLAY_TYPES,
    SAVED_CONTENT_ENABLED,
    VISIBILITY_RULES_SELECT_QUERY_ENABLED,
    CODED_COMPONENTS_ENABLED,
    NUMERIC_VALUE_COMPONENT_ENABLED,
    CODE_EXTRA_INFORMATION_PROPERTIES,
    SUPPORT_MULTI_COLUMN,
    ASSOCIATED_TEXT_IS_CLINICAL_CONTENT,
    USE_TEMPLATE_PICKER,
    PERSPECTIVES,
    FeaturesConfig,
} from "./config";
import PatientDataContext from "./patientData";
import { TemplateContext } from "./template";
import { TemplateHistoryContext, HistoryItem, HistoryItems, HistoryRequest } from "./templateHistory";
import { RunningTemplateContext } from "./runningTemplate";

export {
    AppModeContext,
    AuthContext,
    ComponentConfigDataContext,
    ConfigContext,
    PatientDataContext,
    TemplateContext,
    TemplateHistoryContext,
    RunningTemplateContext,
    CLINICAL_CONTENT_QUERY_DISPLAY_TYPES,
    SAVED_CONTENT_ENABLED,
    VISIBILITY_RULES_SELECT_QUERY_ENABLED,
    CODED_COMPONENTS_ENABLED,
    NUMERIC_VALUE_COMPONENT_ENABLED,
    CODE_EXTRA_INFORMATION_PROPERTIES,
    SUPPORT_MULTI_COLUMN,
    ASSOCIATED_TEXT_IS_CLINICAL_CONTENT,
    USE_TEMPLATE_PICKER,
    PERSPECTIVES,
};
export type { ComponentConfigData, FeaturesConfig, HistoryItem, HistoryItems, HistoryRequest };
