import {
    findCanvasItemParent,
    isContainerType,
    isDescendantOfEmbeddedItem,
    CanvasItem,
    ComponentType,
    ComponentContainer,
    isEmbeddedItem,
} from "@emisgroup/clint-templates-common";
import { getUpdatedContainerMembers } from "./componentUtils";
import { loadDraftDefinition, LoadDefinitionResultType } from "../contentLibrary/loadDefinition";
import { definitionAsObject } from "../contentLibrary/utils";

const isEmbeddableType = (type: string): boolean => {
    return type === ComponentType.PANEL || type === ComponentType.TAB_PAGE;
};

type LoadResult = {
    loadedItems: CanvasItem[];
    errors?: string[];
};

/* eslint-disable @typescript-eslint/no-use-before-define */
const loadEmbeddedContainerItem = async (
    item: CanvasItem,
    children: CanvasItem[],
    contentLibraryUrl: string,
    bearerToken: string,
): Promise<LoadResult> => {
    const loadResult = await loadEmbeddedItems(children, contentLibraryUrl, bearerToken);

    if (loadResult.errors) {
        return { loadedItems: [item], errors: loadResult.errors };
    }

    return {
        loadedItems: [
            {
                ...item,
                members: loadResult.loadedItems,
            },
        ],
    };
};

const loadEmbeddedItem = async (
    item: CanvasItem,
    contentLibraryUrl: string,
    bearerToken: string,
): Promise<LoadResult> => {
    const { ern } = item as ComponentContainer;

    if (ern) {
        try {
            const loadDefinitionResult = await loadDraftDefinition({
                contentLibraryUrl,
                bearerToken,
                ern,
            });

            if (loadDefinitionResult.type === LoadDefinitionResultType.Fail) {
                return { loadedItems: [item], errors: [loadDefinitionResult.error] };
            }

            const templateDefinition = definitionAsObject(loadDefinitionResult.definition);

            return await loadEmbeddedContainerItem(
                {
                    ...item,
                    libraryName: templateDefinition.label,
                } as CanvasItem,
                (templateDefinition as ComponentContainer).members,
                contentLibraryUrl,
                bearerToken,
            );
        } catch {
            return { loadedItems: [item], errors: ["An error occurred loading the template"] };
        }
    }

    if (isContainerType(item.type)) {
        return loadEmbeddedContainerItem(item, (item as ComponentContainer).members, contentLibraryUrl, bearerToken);
    }

    return { loadedItems: [item] };
};

export const loadEmbeddedItems = async (
    items: CanvasItem[],
    contentLibraryUrl: string,
    bearerToken: string,
): Promise<LoadResult> => {
    const loadResults = await Promise.all(items.map(item => loadEmbeddedItem(item, contentLibraryUrl, bearerToken)));

    const loadErrors = loadResults.reduce((errors, loadResult) => {
        if (loadResult.errors) {
            errors.push(...loadResult.errors);
        }
        return errors;
    }, [] as string[]);

    return loadErrors.length === 0
        ? { loadedItems: loadResults.map(loadResult => loadResult.loadedItems[0]) }
        : { loadedItems: items, errors: loadErrors };
};
/* eslint-enable @typescript-eslint/no-use-before-define */

export const removeEmbeddedContent = (items: CanvasItem[]): CanvasItem[] => {
    return items.map(item => {
        if (isEmbeddedItem(item)) {
            return {
                ...item,
                members: [],
            };
        }

        if (isContainerType(item.type)) {
            return {
                ...item,
                members: removeEmbeddedContent((item as ComponentContainer).members),
            };
        }

        return item;
    });
};

export const isItemEmbedded = (item: CanvasItem, templateItems: CanvasItem[]): boolean => {
    return isEmbeddedItem(item) || isDescendantOfEmbeddedItem(item, templateItems);
};

export const canEmbedItem = (item: CanvasItem, templateItems: CanvasItem[]): boolean => {
    return isEmbeddableType(item.type) && !isItemEmbedded(item, templateItems);
};

export const getTemplateDefinitionWithEmbeddedContainer = (
    templateDefinition: ComponentContainer,
    containerIdToEmbed: string,
    ern: string,
    libraryName?: string,
): ComponentContainer => {
    const parentOfContainer = findCanvasItemParent(containerIdToEmbed, templateDefinition.members);
    const parentMembers = (parentOfContainer || templateDefinition).members;
    const containerIndex = parentMembers.findIndex(member => member.id === containerIdToEmbed);

    const embeddedContainer = { ...parentMembers[containerIndex], ern, libraryName };

    const newParentMembers = [
        ...parentMembers.slice(0, containerIndex),
        embeddedContainer,
        ...parentMembers.slice(containerIndex + 1),
    ];

    const parentContainerId = parentOfContainer ? parentOfContainer.id : templateDefinition.id;

    return {
        ...templateDefinition,
        members: getUpdatedContainerMembers(templateDefinition, parentContainerId, newParentMembers),
    };
};
