import * as React from "react";
import { AppMode, AppModeContext } from "@emisgroup/clint-templates-common";

type AppModeProviderProps = { children: React.ReactNode | React.ReactNodeArray };
const AppModeProvider = ({ children }: AppModeProviderProps) => (
    <AppModeContext.Provider
        value={{ mode: AppMode.RUN, allowedModes: [AppMode.RUN], updateMode: () => {}, setReadOnly: () => {} }}
    >
        {children}
    </AppModeContext.Provider>
);

export default AppModeProvider;
