import { Button } from "@emisgroup/ui-kit-react";
import { ButtonProps } from "../types";

export function DialogButton({ button }: { button: ButtonProps }) {
    const { ariaLabel, label, disabled, onClick, isPrimary, isSecondary, isDanger, iconClasses } = button;
    let buttonStyle;
    if (isPrimary) {
        buttonStyle = "primary";
    } else if (isDanger) {
        buttonStyle = "primary-danger";
    } else if (isSecondary) {
        buttonStyle = "secondary";
    }

    return (
        <Button
            key={label}
            type="button"
            variant={buttonStyle}
            className={`eui-button eui-button--${iconClasses}`}
            onClick={onClick}
            ariaLabel={ariaLabel}
            disabled={disabled ?? false}
        >
            {label}
        </Button>
    );
}
