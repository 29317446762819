/* eslint-disable react/style-prop-object */
import { AppModeContext, AuthContext, ConfigContext } from "@emisgroup/clint-templates-common";
import * as React from "react";
import { Button, ProgressSpinnerLarge } from "@emisgroup/ui-kit-react";
import { useTranslation } from "@emisgroup/application-intl";
import {
    loadTemplateFromContentLibrary,
    LoadDefinitionResultType,
    GENERAL_STATUS,
    BETA_STATUS,
    getCurrentStatus,
} from "./contentLibrary/loadDefinition";
import { ContentLibraryContext } from "./context/contentLibrary";
import AppContainer from "./appContainer";
import useChangesWarning from "./uiComponents/useChangesWarning";
import TitleBar from "./titleBar";

import "./initialTemplateLoad.css";

const EDITOR_STATUS_VIEW = "view";
const EDITOR_STATUS_EDIT = "edit";

// ern:emis:contlib:resource:<guid>
const CONTENT_ERN_LENGTH = 5;
const CONTENT_ERN_WITH_VERSION_LENGTH = 6;

const getContentLibraryTemplate = (templateErn?: string | null): ContentLibraryTemplate | string | null => {
    if (!templateErn) {
        return null;
    }

    const templateParamParts = templateErn.split(":");
    if (
        templateParamParts.length !== CONTENT_ERN_LENGTH &&
        templateParamParts.length !== CONTENT_ERN_WITH_VERSION_LENGTH
    ) {
        return "templates.errorInvalidErnFormat";
    }

    return templateParamParts.length === CONTENT_ERN_WITH_VERSION_LENGTH
        ? {
              ern: templateParamParts.slice(0, templateParamParts.length - 1).join(":"),
              version: templateParamParts[templateParamParts.length - 1],
          }
        : { ern: templateErn };
};

type ContentLibraryTemplate = {
    ern: string;
    version?: string;
};

type InitialTemplateLoadProps = {
    children?: React.ReactNode | React.ReactNodeArray;
    contentLibraryTemplateErn?: string | null;
    editorStatus?: string | null;
    redirectUri?: string;
};

const withAppContainer = component => <AppContainer>{component}</AppContainer>;

const InitialTemplateLoad = ({
    children,
    contentLibraryTemplateErn,
    redirectUri,
    editorStatus,
}: InitialTemplateLoadProps) => {
    const { t } = useTranslation();
    const { getBearerToken } = React.useContext(AuthContext);
    const { contentLibraryUrl } = React.useContext(ConfigContext);
    const { loadFromContentLibrary } = React.useContext(ContentLibraryContext);
    const { setReadOnly } = React.useContext(AppModeContext);
    const { withChangesWarning, dialog } = useChangesWarning({
        onDialogCancel: () => {
            if (redirectUri) {
                window.location.href = redirectUri;
            }
        },
    });

    const [loadingFromContentLibrary, setLoadingFromContentLibrary] = React.useState(
        Boolean(contentLibraryTemplateErn),
    );
    const [loadingFromContentLibraryError, setLoadingFromContentLibraryError] = React.useState<{
        message: string;
        canRetry: boolean;
    } | null>(null);

    const loadTemplate = React.useCallback(async () => {
        const updateContentLibrary = (loadedContentLibraryTemplate: any, viewingHistoricVersion: boolean) => {
            loadFromContentLibrary(loadedContentLibraryTemplate);
            const { statusFlags } = loadedContentLibraryTemplate;
            const currentStatus = getCurrentStatus(statusFlags);
            const defaultToReadOnly =
                viewingHistoricVersion || currentStatus === GENERAL_STATUS || currentStatus === BETA_STATUS;
            if (editorStatus === EDITOR_STATUS_VIEW || (editorStatus !== EDITOR_STATUS_EDIT && defaultToReadOnly)) {
                setReadOnly();
            }
        };

        const contentLibraryTemplateResult = getContentLibraryTemplate(contentLibraryTemplateErn);
        const contentLibraryTemplate = contentLibraryTemplateResult as ContentLibraryTemplate;
        if (!contentLibraryTemplate.ern) {
            setLoadingFromContentLibraryError({ message: contentLibraryTemplateResult as string, canRetry: false });
            return;
        }

        setLoadingFromContentLibrary(true);
        setLoadingFromContentLibraryError(null);
        const bearerToken = await getBearerToken();
        const loadContentLibraryResult = await loadTemplateFromContentLibrary({
            ...contentLibraryTemplate,
            contentLibraryUrl,
            bearerToken,
        });

        setLoadingFromContentLibrary(false);
        if (loadContentLibraryResult.type === LoadDefinitionResultType.Success) {
            updateContentLibrary(
                loadContentLibraryResult.contentLibraryTemplate,
                Boolean(contentLibraryTemplate.version),
            );
        } else {
            setLoadingFromContentLibraryError({ message: "templates.errorLoadingTemplate", canRetry: true });
        }
    }, [contentLibraryTemplateErn]);

    React.useEffect(() => {
        if (!contentLibraryTemplateErn) {
            return;
        }
        withChangesWarning(loadTemplate)();
    }, [loadTemplate, contentLibraryTemplateErn]);

    if (dialog) {
        return withAppContainer(
            <>
                <TitleBar />
                {dialog}
            </>,
        );
    }

    if (loadingFromContentLibraryError) {
        return withAppContainer(
            <div className="load-error">
                <h3>{t(loadingFromContentLibraryError.message)}</h3>
                <div className="options">
                    {redirectUri && (
                        <a
                            data-testid="return-link"
                            href={redirectUri}
                            className="eui-button eui-button--primary-danger"
                        >
                            {t("templates.returnToContentLibrary")}
                        </a>
                    )}
                    {!redirectUri && loadingFromContentLibraryError.canRetry && (
                        <Button
                            data-testid="retry-button"
                            ariaLabel={t("retry")}
                            variant="primary-danger"
                            onClick={loadTemplate}
                        >
                            {t("retry")}
                        </Button>
                    )}
                </div>
            </div>,
        );
    }

    if (loadingFromContentLibrary) {
        return withAppContainer(<ProgressSpinnerLarge text={t("templates.loadingFromContentLibrary")} />);
    }

    return <>{children}</>;
};

export default InitialTemplateLoad;
