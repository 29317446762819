import * as React from "react";
import { CanvasColumn as RunnerCanvasColumn, CanvasItem } from "@emisgroup/clint-templates-common";
// eslint-disable-next-line import/no-cycle
import ComponentRenderer from "./componentRenderer";
import useAutoScroll from "./useAutoScroll";
import DefaultActionButtons from "./defaultActionButtons";
import useGoToComponent from "./useGoToComponent";

type CanvasColumnProps = {
    columnIndex: number;
    items: CanvasItem[];
    isRootTemplate?: boolean;
    hasCustomActionButtons?: boolean;
};

const CanvasColumn = ({
    columnIndex,
    items,
    isRootTemplate = false,
    hasCustomActionButtons = false,
}: CanvasColumnProps) => {
    const columnRef = React.useRef<HTMLDivElement>(null);
    useAutoScroll(columnRef);
    useGoToComponent(columnRef);

    const renderComponent = (item: CanvasItem, index: number) => {
        const nextComponent = items[index + 1];
        const nextComponentTypeName = nextComponent ? nextComponent.type.replace(/ /g, "-") : "";
        const nextComponentTypeClass = nextComponentTypeName ? `next-component-${nextComponentTypeName}` : "";
        return (
            <React.Fragment key={item.id}>
                <ComponentRenderer key={item.id} component={item} className={nextComponentTypeClass} />
            </React.Fragment>
        );
    };

    return (
        <RunnerCanvasColumn ref={columnRef} columnIndex={columnIndex}>
            {items.map(renderComponent)}
            {isRootTemplate && columnIndex === 0 && !hasCustomActionButtons && <DefaultActionButtons />}
        </RunnerCanvasColumn>
    );
};

export default CanvasColumn;
