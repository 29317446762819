import * as React from "react";
import { Checkbox } from "@emisgroup/ui-kit-react";
import { PropertyEntryProps } from "./types";

type BooleanPropertyEntryProps = PropertyEntryProps;

const BooleanPropertyEntry = ({ id, component, name, isReadOnly, onPropertyUpdate }: BooleanPropertyEntryProps) => {
    const handleCheckboxChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        onPropertyUpdate({ item: component, propertyName: name, propertyValue: evt.currentTarget.checked });
    };

    const hasDefinedValue = typeof component[name] !== "undefined" && Boolean(String(component[name]).trim());
    const isChecked = hasDefinedValue ? Boolean(component[name]) : false;

    return (
        <Checkbox
            id={id}
            data-testid={id}
            labelText=""
            checked={isChecked}
            onChange={handleCheckboxChange}
            disabled={isReadOnly}
        />
    );
};

export default BooleanPropertyEntry;
