import { ResponseType, Api } from "@emisgroup/content-library-sdk-ts";
import { ComponentContainer } from "@emisgroup/clint-templates-common";
import { getContentLibraryApi, getReadableFailMessage, TEMPLATE_RESOURCE_TYPE } from "./utils";
import { ContentLibraryActivity } from "../types";

type SaveTemplateOptions = {
    contentLibraryUrl: string;
    bearerToken: string;
    name: string;
    description: string;
    tags: string[];
    template: ComponentContainer;
};

export enum SaveTemplateResultType {
    Success,
    Fail,
}

type SaveTemplateSuccess = { type: SaveTemplateResultType.Success; templateErn: string; ref: string };
type SaveTemplateError = { type: SaveTemplateResultType.Fail; error: { message: string; propertyName?: string } };
export type SaveTemplateResult = SaveTemplateSuccess | SaveTemplateError;

const createFailResult = ({ errors }): SaveTemplateError => {
    return {
        type: SaveTemplateResultType.Fail,
        error: getReadableFailMessage(errors, ContentLibraryActivity.SAVE),
    };
};

const uploadTemplate = async (
    contentLibraryApi: Api,
    template: ComponentContainer,
    ern: string,
    refToUpload = "",
): Promise<SaveTemplateResult> => {
    const createDraftResult = await contentLibraryApi.createDraft(ern, { ref: refToUpload });

    if (createDraftResult.type === ResponseType.Error) {
        return createFailResult(createDraftResult);
    }

    const { uploadUrl, headers } = createDraftResult.payload;
    const uploadResult = await contentLibraryApi.uploadContent(uploadUrl, JSON.stringify(template), {
        ...headers,
        "Content-Type": "application/json",
    });

    if (uploadResult.type === ResponseType.Error) {
        return createFailResult(uploadResult);
    }

    const { ref } = uploadResult.payload;
    return { type: SaveTemplateResultType.Success, templateErn: ern, ref };
};

export const saveNewTemplate = async ({
    contentLibraryUrl,
    bearerToken,
    name,
    description,
    tags,
    template,
}: SaveTemplateOptions): Promise<SaveTemplateResult> => {
    const contentLibraryApi = await getContentLibraryApi(contentLibraryUrl, bearerToken);

    const createResourceResult = await contentLibraryApi.createResource({
        name,
        description,
        tags,
        resourceType: TEMPLATE_RESOURCE_TYPE,
    });

    if (createResourceResult.type === ResponseType.Error) {
        return createFailResult(createResourceResult);
    }

    const { ern } = createResourceResult.payload;
    return uploadTemplate(contentLibraryApi, template, ern);
};

type UpdateTemplateOptions = {
    contentLibraryUrl: string;
    bearerToken: string;
    ern: string;
    ref: string;
    template: ComponentContainer;
};

export const updateTemplate = async ({ contentLibraryUrl, bearerToken, ern, ref, template }: UpdateTemplateOptions) => {
    const contentLibraryApi = await getContentLibraryApi(contentLibraryUrl, bearerToken);
    return uploadTemplate(contentLibraryApi, template, ern, ref);
};
