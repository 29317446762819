import * as React from "react";
import { useTranslation } from "@emisgroup/application-intl";
import { IPerspective } from "@emisgroup/clint-templates-perspectives";
import AppModeContext from "../context/appMode";
import { AppMode, Code, ComponentType, Rule, ActionButton } from "../types";
import { COMPONENT_TYPE_LABELS } from "../constants";
import { ComponentIcon } from "../utils/componentIcons";
import "./componentInfo.css";
import { isContainerType } from "../utils";

export type InfoProps = {
    id: string;
    type: ComponentType;
    label?: string;
    hasDatePrompt?: boolean;
    isMandatory?: boolean;
    hasAssociatedText?: boolean;
    tooltip?: string;
    category?: string;
    data?: string;
    code?: Code;
    selectedByDefault?: boolean;
    showAllQualifiers?: boolean;
    rule?: Rule | null;
    panelActions?: ActionButton[] | null;
    perspectives?: IPerspective[];
};

const ComponentInfo = ({
    id,
    type,
    label,
    hasDatePrompt,
    isMandatory,
    hasAssociatedText,
    tooltip,
    code,
    selectedByDefault,
    showAllQualifiers,
    category,
    data,
    rule,
    panelActions,
    perspectives,
}: InfoProps) => {
    const { t } = useTranslation();
    const { mode } = React.useContext(AppModeContext);
    const isInRunMode = mode === AppMode.RUN;

    if (isInRunMode) {
        return null;
    }

    const addDiv = (content, subType, title) => (
        <div className={`component-info ${subType}`} title={title}>
            {content}
        </div>
    );

    const addItem = (content, hoverText?: string, defaultCssClass = "standard") =>
        addDiv(content, hoverText ? "hover" : defaultCssClass, hoverText ?? "");

    const addItemSeparated = (itemAvailable, name, hoverText?: any) =>
        itemAvailable && (
            <>
                {addDiv("|", "separator", "")}
                {addItem(name, hoverText)}
            </>
        );

    return (
        <div id={`${id}-component-info`} className="component-info layout">
            <div className={`component-info properties${isContainerType(type) ? " container" : ""}`}>
                <ComponentIcon type={type} />
                {addItem(t(COMPONENT_TYPE_LABELS[type]), code?.term, "hover")}
                {data && data !== label && addItemSeparated(data, t("components.uncoded.shortDataDescription"), data)}
                {addItemSeparated(hasDatePrompt, t("components.date"))}
                {addItemSeparated(isMandatory, t("components.property.mandatory"))}
                {addItemSeparated(hasAssociatedText, t("components.associatedText.name"))}
                {addItemSeparated(tooltip, t("components.property.tooltip"), tooltip)}
                {addItemSeparated(selectedByDefault, t("components.property.selectedByDefault"))}
                {addItemSeparated(showAllQualifiers, t("components.coded.showAllQualifiersShort"))}
                {addItemSeparated(rule, t("components.property.visibilityRules"))}
                {addItemSeparated(panelActions, t("components.property.panelActionButtons"))}
                {addItemSeparated(perspectives, t("perspectives.customPerspective"))}
            </div>
            {category && (
                <div className="component-info section">
                    {t("components.categories.summary", { category: t(category) })}
                </div>
            )}
        </div>
    );
};

export default ComponentInfo;
