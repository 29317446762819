import * as React from "react";
import { useTranslation } from "@emisgroup/application-intl";
import { InfoBanner } from "@emisgroup/ui-kit-react";
import AppModeContext from "../context/appMode";
import { AppMode, Code, ComponentParameter, DataEntryComponentProps, DataFromTemplateRun } from "../types";
import DataEntryComponent from "./dataEntryComponent";
import CalculatorExpression from "./calculatorExpression";
import { ComponentConfigDataContext, RunningTemplateContext } from "../context";
import { calculateValue, CalculationStatus } from "../utils";
import { CalculatorIcon } from "../utils/componentIcons";

import "./calculator.css";

const iconColour = (status: string): string => {
    switch (status) {
        case CalculationStatus.Success:
            return "#2E5685";
        case CalculationStatus.Failed:
            return "#DC3545";
        default:
            return "#767676";
    }
};

const formatValue = (value: number, unitOfMeasure: string) =>
    [`${parseFloat(value.toFixed(3))}`, unitOfMeasure].filter(Boolean).join(" ");

type RuntimeCalculatorProps = {
    id: string;
    expression: string;
    parameters: ComponentParameter[];
    code?: Code;
    onChange: (data: DataFromTemplateRun) => void;
};
const RuntimeCalculator = ({ id, expression, parameters, code, onChange }: RuntimeCalculatorProps) => {
    const { t } = useTranslation();
    const { templateData } = React.useContext(RunningTemplateContext);
    const { componentsConfig } = React.useContext(ComponentConfigDataContext);
    const { status = CalculationStatus.Inconclusive, value = Number.NaN, message = "" } = templateData[id];

    const unitOfMeasure = React.useMemo(() => {
        const componentConfigData =
            code && componentsConfig && componentsConfig.length
                ? componentsConfig.filter(dataForComponent => dataForComponent.id === `${code.emisCodeId}`)[0]
                : null;
        return componentConfigData?.attributes?.qualifiers?.numeric?.unitOfMeasure || "";
    }, [componentsConfig]);

    const parameterDeps = parameters.map(({ componentId }) => templateData[componentId]);

    React.useEffect(() => {
        const calculationResult = calculateValue(t, parameters, expression, templateData);
        onChange({
            [id]: {
                status: calculationResult.status,
                value: calculationResult.value,
                message: calculationResult.message,
            },
        });
    }, parameterDeps);

    return (
        <>
            <div className="calculator-result-line">
                <div className={`calculator-result-value ${status}`} data-testid="calculator-result-value">
                    {status === CalculationStatus.Success ? formatValue(value as number, unitOfMeasure) : ""}
                </div>
                <div className={`calculator-result-icon ${status}`} title={t("components.calculator.calculatedValue")}>
                    <CalculatorIcon colour={iconColour(status)} />
                </div>
            </div>
            {message && (
                <InfoBanner
                    className="calculator-result-message"
                    data-testid="calculator-result-message"
                    iconName="info-notification"
                    iconTitle="info-notification"
                >
                    {message}
                </InfoBanner>
            )}
        </>
    );
};

type CalculatorProps = DataEntryComponentProps & { expression: string; parameters: ComponentParameter[] };
const Calculator = (props: CalculatorProps) => {
    const { mode } = React.useContext(AppModeContext);
    const isInRunMode = mode === AppMode.RUN;
    const { expression } = props;

    const renderInEditMode = () => {
        return <CalculatorExpression expression={expression} readOnly disabled />;
    };

    return (
        <DataEntryComponent {...props}>
            <div className="canvas-item-selection">
                {isInRunMode ? <RuntimeCalculator {...props} /> : renderInEditMode()}
            </div>
        </DataEntryComponent>
    );
};

export default Calculator;
