import * as React from "react";
import { TemplateHistoryContext } from "../context";
import { Code, ComponentType } from "../types";
import HistoryHeadline from "./historyHeadline";

type ComponentProps = {
    id: string;
    type?: ComponentType;
    label?: string;
    isMandatory?: boolean;
    areValuesValid?: boolean;
    code?: Code;
    codes?: Code[];
    className?: string;
    excludeHistory?: boolean;
    toggleShowingHistoryList?: () => void;
    children: React.ReactNode | React.ReactNodeArray;
};

const Component = ({
    id,
    type,
    label,
    isMandatory,
    areValuesValid,
    code,
    codes,
    className,
    excludeHistory,
    toggleShowingHistoryList,
    children,
}: ComponentProps) => {
    const labelMandatoryClass = `eui-form-element__label${isMandatory ? " eui-form-element__label--mandatory" : ""}`;
    const labelValueInvalidClass = `${areValuesValid ?? true ? "" : " eui-form-element__label--invalid"}`;
    const handleToggleHistoryList = toggleShowingHistoryList || (() => ({}));
    const { getHistoryKey } = React.useContext(TemplateHistoryContext);

    return (
        <div className={`component-layout ${className || ""}`}>
            {label && (
                <label
                    htmlFor={`component-${id}`}
                    className={`canvas-item-label ${labelMandatoryClass} ${labelValueInvalidClass}`}
                >
                    {label}
                </label>
            )}
            {children}
            {!excludeHistory && (
                <HistoryHeadline
                    code={code}
                    historyKey={getHistoryKey(
                        id,
                        type ?? "",
                        code?.emisCodeId || codes?.map(({ emisCodeId }) => emisCodeId),
                    )}
                    toggleHistoryList={handleToggleHistoryList}
                />
            )}
        </div>
    );
};

export default Component;
