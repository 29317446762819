import React from "react";
import {
    DynamicTabContainer,
    PatientDataContext,
    TabPageContent,
    Tab,
    TabPage,
    TabItemType,
    isEmbeddedItem,
} from "@emisgroup/clint-templates-common";
import DynamicTabPage, { TabPageSelectedProps } from "./dynamicTabPage";
// eslint-disable-next-line import/no-cycle
import CanvasColumns from "../canvasColumns";

import "@emisgroup/clint-templates-common/lib/tabContainer.css";

type SelectedTabPageContentProps = {
    tabPage: TabPage;
};
const SelectedTabPageContent = ({ tabPage }: SelectedTabPageContentProps) => {
    return (
        <TabPageContent tabPage={tabPage} onSelect={() => {}}>
            <CanvasColumns component={tabPage} />
        </TabPageContent>
    );
};

type TabContainerProps = {
    tabContainer: Tab;
};

const TabContainer = (props: TabContainerProps) => {
    const { tabContainer } = props;
    const { patientId } = React.useContext(PatientDataContext);

    const [selectedTabPageIndex, setSelectedTabPageIndex] = React.useState(0);

    const onTabSelected = (index: number) =>
        window.dispatchEvent(
            new CustomEvent("tab-container-page-selected", { detail: { id: tabContainer.members[index].id } }),
        );

    React.useEffect(() => {
        if (patientId && tabContainer.members.length > 0) {
            setSelectedTabPageIndex(0);
            onTabSelected(0);
        }
    }, [patientId]);

    const tabPageSelected = ({ evt, selectedTabIndex }: TabPageSelectedProps) => {
        evt.stopPropagation();
        setSelectedTabPageIndex(selectedTabIndex);
        onTabSelected(selectedTabIndex);
    };

    const tabsList: TabItemType[] = tabContainer.members.map(member => ({
        text: member.label,
        isEmbedded: isEmbeddedItem(member),
    }));

    const renderTabPage = (tabItem: TabItemType, tabPageIndex: number) => (
        <DynamicTabPage
            key={tabPageIndex}
            tabItem={tabItem}
            tabPageIndex={tabPageIndex}
            isTabPageSelected={selectedTabPageIndex !== null && selectedTabPageIndex === tabPageIndex}
            onTabSelect={tabPageSelected}
            dynamicTabId={tabContainer.id}
        />
    );

    if (!tabsList.length) {
        return null;
    }
    return (
        <DynamicTabContainer
            id={tabContainer.id}
            content={
                <SelectedTabPageContent
                    {...props}
                    tabPage={tabContainer.members[Math.min(selectedTabPageIndex, tabContainer.members.length - 1)]}
                />
            }
        >
            {tabsList.map((tabItem, tabPageIndex) => renderTabPage(tabItem, tabPageIndex))}
        </DynamicTabContainer>
    );
};

export default TabContainer;
