import React from "react";
import { Tag } from "@emisgroup/ui-kit-react";
import { useTranslation } from "@emisgroup/application-intl";
import AppModeContext from "../context/appMode";
import { TemplateContext } from "../context/template";
import { Container } from "./container";
import { isEmbeddedContainer } from "../utils/componentUtils";
import { isDescendantOfEmbeddedItem } from "../utils/embeddingUtils";
import { AppMode, CanvasItemClickHandler, ComponentType, Panel, ActionButton } from "../types";
import "./panelComponent.css";
import InvalidPropertyIndicator from "./invalidPropertyIndicator";
import ComponentInfo from "./componentInfo";
import ActionButtons from "./actionButtons";

type PanelProps = {
    children: React.ReactNode | React.ReactNodeArray;
    component: Panel;
    arePropertiesValid?: boolean;
    onSelect: CanvasItemClickHandler;
    onRaiseAction?: (actionButton: ActionButton) => void;
};

function PanelComponent({ children, component, arePropertiesValid = true, onSelect, onRaiseAction }: PanelProps) {
    const { t } = useTranslation();
    const { mode } = React.useContext(AppModeContext);
    const { templateDefinition: rootContainer, selectedItem } = React.useContext(TemplateContext);
    const { id, type, label } = component;
    const isInRunMode = mode === AppMode.RUN;
    const isEmbeddedComponent = isEmbeddedContainer(component);
    const isMasterEmbeddedComponent =
        isEmbeddedComponent && !isDescendantOfEmbeddedItem(component, rootContainer.members);
    const isSelected = !isInRunMode && selectedItem.id === id;
    const invalidClass = arePropertiesValid ? "" : " invalid";
    return (
        <Container
            label={label}
            dataTestId={`${type}-${id}`}
            className={`canvas-item panel ${invalidClass}`}
            onItemSelect={isInRunMode ? undefined : onSelect}
            component={component}
            isSelected={isSelected}
        >
            {!arePropertiesValid && <InvalidPropertyIndicator />}
            <span className="panel-label">
                {component.label}
                {isMasterEmbeddedComponent && mode === AppMode.EDIT && (
                    <Tag
                        className="content-library-indicator"
                        color="color-2"
                        text={t("components.panel.contentLibraryResource")}
                    />
                )}
            </span>
            {children}
            {component.actionButtons && component.actionButtons.length > 0 && (
                <div className="panel-action-buttons">
                    <ActionButtons
                        actionButtons={component.actionButtons}
                        disabled={mode === AppMode.EDIT}
                        onRaiseAction={onRaiseAction}
                    />
                </div>
            )}
            <ComponentInfo
                id={id}
                type={ComponentType.PANEL}
                rule={component.rule}
                panelActions={component.actionButtons}
                perspectives={component.perspectives}
            />
        </Container>
    );
}

export default PanelComponent;
