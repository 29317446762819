import * as React from "react";
import { useTranslation } from "@emisgroup/application-intl";

type PropertyErrorProps = { validity?: { isValid: boolean; message?: string } };
const PropertyError = ({ validity = { isValid: true } }: PropertyErrorProps) => {
    const { t } = useTranslation();
    if (validity.isValid) {
        return null;
    }

    return (
        <div className="sidebar-row">
            <div className="eui-form-element__error property-error-message">{t(validity.message ?? "")}</div>
        </div>
    );
};

export default PropertyError;
