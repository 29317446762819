/* eslint-disable react/style-prop-object */
import React from "react";
import { ActionButtons, ActionButton, ActionButtonStyle, ActionType } from "@emisgroup/clint-templates-common";
import { useTranslation } from "@emisgroup/application-intl";
import { ActionsContext } from "./context/actions";

const DefaultActionButtons = () => {
    const { onRaiseAction } = React.useContext(ActionsContext);

    const handleRaiseAction = (actionButton: ActionButton) => {
        if (onRaiseAction) {
            onRaiseAction(actionButton.action, actionButton.validate);
        }
    };

    const { showDefaultActionButtons } = React.useContext(ActionsContext);
    const { t } = useTranslation();

    const defaultButtons: ActionButton[] = [
        {
            action: ActionType.CREATE,
            label: t("actionButtons.create"),
            style: ActionButtonStyle.PRIMARY,
            validate: true,
        },
        {
            action: ActionType.DISCARD,
            label: t("actionButtons.discard"),
            style: ActionButtonStyle.PRIMARY,
            validate: false,
        },
    ];

    return (
        <>
            {showDefaultActionButtons && (
                <div data-testid="default-action-buttons">
                    <ActionButtons actionButtons={defaultButtons} onRaiseAction={handleRaiseAction} />
                </div>
            )}
        </>
    );
};

export default DefaultActionButtons;
