import React from "react";
import AppModeContext from "../context/appMode";
import { TemplateContext } from "../context/template";
import { Container } from "./container";
import { AppMode, TabPage, CanvasItemClickHandler } from "../types";
import "./panelComponent.css";

type TabPageProps = {
    tabPage: TabPage;
    children: React.ReactNode | React.ReactNodeArray;
    onSelect: CanvasItemClickHandler;
};

function TabPageContent({ tabPage, children, onSelect }: TabPageProps) {
    const { mode } = React.useContext(AppModeContext);
    const { selectedItem } = React.useContext(TemplateContext);

    const isSelected = mode === AppMode.EDIT && selectedItem.id === tabPage.id;
    return (
        <Container component={tabPage} isSelected={isSelected} className="canvas-container" onItemSelect={onSelect}>
            {children}
        </Container>
    );
}

export default TabPageContent;
