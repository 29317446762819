import * as React from "react";
import { useTranslation } from "@emisgroup/application-intl";
import { DataFromTemplateRun, DataEntryComponentProps, DataItem, PickingListOption } from "../types";
import { RunningTemplateContext } from "../context";
import {
    createDefaultDataItem,
    getDataItemsForMultiSelectListComponent,
    getDataItemForSingleSelectListComponent,
} from "../utils";
import PickingList from "./pickingList";
import PickingListDataItem from "./pickingListDataItem";

type UncodedPickingListComponentProps = DataEntryComponentProps & {
    options: PickingListOption[];
    isMultiSelect: boolean;
    displayStyle?: string;
};

const UncodedPickingList = (props: UncodedPickingListComponentProps) => {
    const { t } = useTranslation();
    const { isMultiSelect, id, options, onChange } = props;
    const { templateData = {} } = React.useContext(RunningTemplateContext);
    const defaultAssociatedText = templateData[id]?.associatedText;

    const componentDataItems = isMultiSelect
        ? getDataItemsForMultiSelectListComponent(templateData, id)
        : [getDataItemForSingleSelectListComponent(templateData, id, defaultAssociatedText)];
    const selectedDataItems = componentDataItems.filter(({ selected }) => selected);
    const [invalidSelectedItems, setInvalidSelectedItems] = React.useState<string[]>([]);

    const dataSource = (isMultiSelect ? [] : [{ value: "", text: t("components.pickingList.selectFromList") }]).concat(
        options.map(({ id: optionId, text }) => ({ value: optionId, text })),
    );

    const getDataItemsForMultiSelectList = (selectedValues: string[]): DataItem[] => {
        return options.reduce((newDataItems, option) => {
            const existingDataItem = componentDataItems.find(
                dataItem => (dataItem.value as PickingListOption).id === option.id,
            );
            if (selectedValues.includes(option.id)) {
                if (existingDataItem) {
                    newDataItems.push({ ...existingDataItem, selected: true });
                } else {
                    newDataItems.push({
                        ...createDefaultDataItem(defaultAssociatedText),
                        selected: true,
                        value: option,
                    });
                }
            } else if (existingDataItem) {
                newDataItems.push({ ...existingDataItem, selected: false });
            }
            return newDataItems;
        }, [] as DataItem[]);
    };

    const handleMultiSelect = (selectedValues: string[]) => {
        onChange({
            [id]: {
                items: getDataItemsForMultiSelectList(selectedValues),
                interacted: true,
            },
        } as DataFromTemplateRun);
    };

    const handleSingleSelect = (selectedValues: string[]) => {
        const [selectedValue] = selectedValues;
        const [selectedDataItem] = selectedDataItems;
        const selectedOption = options.find(({ id: optionId }) => optionId === selectedValue);
        onChange({
            [id]: {
                items: [
                    {
                        ...selectedDataItem,
                        selected: Boolean(selectedValue),
                        value: selectedOption,
                        associatedText: selectedDataItem ? selectedDataItem.associatedText : defaultAssociatedText,
                    },
                ],
                interacted: true,
            },
        } as DataFromTemplateRun);

        setInvalidSelectedItems([]);
    };

    const applyAdditionalDataPropertyUpdate = (item: DataItem) => obj => ({
        items: componentDataItems.map(dataItem => (dataItem === item ? { ...dataItem, ...obj } : dataItem)),
    });

    const handleRemoveFromMultiSelect = (itemToRemove: DataItem) => () => {
        onChange({
            [id]: {
                items: componentDataItems.map(item => (item === itemToRemove ? { ...item, selected: false } : item)),
                interacted: true,
            },
        } as DataFromTemplateRun);
        const itemKey = (itemToRemove.value as PickingListOption).id;
        if (invalidSelectedItems.includes(itemKey)) {
            setInvalidSelectedItems(invalidSelectedItems.filter(invalidKey => invalidKey !== itemKey));
        }
    };

    const selectedItemsValidityChanged = (selectedItemKey: string, isValid: boolean) => {
        const itemAlreadyInvalid = invalidSelectedItems.includes(selectedItemKey);
        if (!isValid && !itemAlreadyInvalid) {
            setInvalidSelectedItems(invalidSelectedItems.concat(selectedItemKey));
        } else if (isValid && itemAlreadyInvalid) {
            setInvalidSelectedItems(invalidSelectedItems.filter(invalidKey => invalidKey !== selectedItemKey));
        }
    };

    return (
        <PickingList
            {...props}
            dataSource={dataSource}
            selectedValues={selectedDataItems.map(({ value }) => (value as PickingListOption).id)}
            onPickingListSelect={isMultiSelect ? handleMultiSelect : handleSingleSelect}
            selectedValuesValid={invalidSelectedItems.length === 0}
            templateData={templateData[id]}
        >
            {selectedDataItems.map(item => (
                <PickingListDataItem
                    key={(item.value as PickingListOption).id}
                    {...props}
                    dataItem={item}
                    applyPropertyUpdate={applyAdditionalDataPropertyUpdate(item)}
                    header={(item.value as PickingListOption).text}
                    onRemove={handleRemoveFromMultiSelect(item)}
                    onValidityChanged={isValid =>
                        selectedItemsValidityChanged((item.value as PickingListOption).id, isValid)
                    }
                />
            ))}
        </PickingList>
    );
};

export default UncodedPickingList;
