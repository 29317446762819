import * as React from "react";
import { CalculatorExpression } from "@emisgroup/clint-templates-common";
import { PropertyValueProps } from "./types";

const CalculatorExpressionPropertyEntry = ({
    id,
    component,
    name,
    isReadOnly,
    onPropertyUpdate,
}: PropertyValueProps) => {
    const [expression, setExpression] = React.useState(component[name]);
    const handleBlur = () => onPropertyUpdate({ item: component, propertyName: name, propertyValue: expression });

    return (
        <CalculatorExpression
            id={id}
            expression={expression}
            readOnly={isReadOnly}
            disabled={isReadOnly}
            onChange={setExpression}
            onBlur={handleBlur}
        />
    );
};

export default CalculatorExpressionPropertyEntry;
