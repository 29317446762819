import React from "react";
import { ComponentConfigDataContext, ComponentConfigData } from "@emisgroup/clint-templates-common";

type ComponentConfigDataProviderProps = {
    children: React.ReactNode;
    initialComponentConfig: Array<ComponentConfigData>;
};

const ComponentConfigDataProvider = ({ children, initialComponentConfig = [] }: ComponentConfigDataProviderProps) => {
    const [configData, setConfigData] = React.useState<Array<ComponentConfigData>>(initialComponentConfig);
    const [error, setError] = React.useState<string>();
    const setComponentsConfig = (data: Array<ComponentConfigData>, err?: string) => {
        setConfigData(data);
        setError(err);
    };
    return (
        <ComponentConfigDataContext.Provider value={{ componentsConfig: configData, error, setComponentsConfig }}>
            {children}
        </ComponentConfigDataContext.Provider>
    );
};

export default ComponentConfigDataProvider;
