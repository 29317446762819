import * as React from "react";
import {
    findCanvasItem,
    CanvasItem,
    ComponentType,
    ComponentContainer,
    getCanvasItemsWithNestedMembers,
    TemplateHistoryContext,
    HistoryRequest,
    HistoryItems,
    ComponentConfigDataContext,
} from "@emisgroup/clint-templates-common";
import { useTranslation } from "@emisgroup/application-intl";
import { executeHistoryQueries } from "../queries/executeQueries";
import { getHistoryRequests, getHistoryItems, getHistoryKey } from "../utils/historyUtils";

type TemplateHistoryProviderProps = {
    children: React.ReactNode;
    templateDefinition: ComponentContainer;
    queryApiUrl: string;
    getBearerToken: () => Promise<string>;
    patientId?: string;
    initialHistory?: HistoryItems;
    initialHistoryWithErrors?: HistoryRequest[];
    initialAreConfidentialItemsRemoved?: boolean;
};

const TemplateHistoryProvider = ({
    children,
    templateDefinition,
    queryApiUrl,
    getBearerToken,
    patientId = "",
    initialHistory = {},
    initialHistoryWithErrors = [],
    initialAreConfidentialItemsRemoved = false,
}: TemplateHistoryProviderProps) => {
    const { t } = useTranslation();
    const [history, setHistory] = React.useState(initialHistory);
    const [historyWithErrors, setHistoryWithErrors] = React.useState<HistoryRequest[]>(initialHistoryWithErrors);
    const [areConfidentialItemsRemoved, setAreConfidentialItemsRemoved] = React.useState(
        initialAreConfidentialItemsRemoved,
    );
    const [isHistoryRetry, setIsHistoryRetry] = React.useState<boolean>(false);

    const visibleContainer = React.useRef<ComponentContainer>(templateDefinition);
    const visibleItems = React.useRef<CanvasItem[]>([]);

    const { componentsConfig } = React.useContext(ComponentConfigDataContext);

    React.useEffect(() => {
        setHistory(initialHistory);
        setHistoryWithErrors(initialHistoryWithErrors);
    }, [patientId]);

    const retrieveHistory = async (historyRequests: HistoryRequest[]) => {
        const historyQueryResults = await executeHistoryQueries(
            queryApiUrl,
            await getBearerToken(),
            historyRequests,
            patientId,
        );

        if (historyQueryResults.history) {
            const retrievedHistoryItems = getHistoryItems(t, historyQueryResults.history);
            setHistory({ ...history, ...retrievedHistoryItems });
            const retrievedKeys = Object.keys(retrievedHistoryItems);
            const newRequests = historyRequests.filter(
                newRequest => !historyWithErrors.some(historyError => historyError.key === newRequest.key),
            );
            const outstandingErrors = [...historyWithErrors, ...newRequests].filter(
                h => !retrievedKeys.includes(h.key),
            );

            setHistoryWithErrors(outstandingErrors);
        } else if (historyQueryResults.errors) {
            setHistoryWithErrors([
                ...historyWithErrors.filter(h => !historyQueryResults.errors.find(e => e.key === h.key)),
                ...historyQueryResults.errors,
            ]);
        }

        setAreConfidentialItemsRemoved(Boolean(historyQueryResults.areConfidentialItemsRemoved));
        setIsHistoryRetry(false);
    };

    const currentlyLoadingHistory = React.useRef<boolean>(false);

    const updateHistoryFromVisibleItemsAndContainer = async () => {
        if (currentlyLoadingHistory.current) return;

        const historyKeys = Object.keys(history);
        const membersToRetrieve =
            visibleContainer.current.type === ComponentType.TEMPLATE
                ? visibleContainer.current.members
                : visibleContainer.current.members.concat(
                      templateDefinition.members.filter(({ type }) => type !== ComponentType.TAB_CONTAINER),
                  );
        const allItemIdsInVisibleContainer = getCanvasItemsWithNestedMembers(
            membersToRetrieve,
            visibleContainer.current,
        ).map(({ id }) => id);
        const historyRequests = ([] as HistoryRequest[])
            .concat(
                ...getCanvasItemsWithNestedMembers(visibleItems.current, visibleContainer.current)
                    .filter(({ id }) => allItemIdsInVisibleContainer.includes(id))
                    .map(r => getHistoryRequests(r, componentsConfig)),
            )
            .filter(request => !historyKeys.includes(request.key));

        if (historyRequests.length === 0) {
            return;
        }

        currentlyLoadingHistory.current = true;
        await retrieveHistory(historyRequests);
        currentlyLoadingHistory.current = false;
    };

    React.useEffect(() => {
        const handleTabPageSelected = ev => {
            const tabPage = findCanvasItem(ev.detail.id, templateDefinition.members);
            if (tabPage) {
                visibleContainer.current = tabPage as ComponentContainer;
                updateHistoryFromVisibleItemsAndContainer();
            }
        };
        window.addEventListener("tab-container-page-selected", handleTabPageSelected);

        return () => window.removeEventListener("tab-container-page-selected", handleTabPageSelected);
    }, [patientId, history]);

    React.useEffect(() => {
        if (visibleContainer.current.type === ComponentType.TEMPLATE) {
            visibleContainer.current = templateDefinition;
        }
    }, [templateDefinition]);

    const updateHistory = async (items: CanvasItem[]) => {
        visibleItems.current = items;
        updateHistoryFromVisibleItemsAndContainer();
    };

    const retryHistory = () => {
        if (historyWithErrors.length) {
            setIsHistoryRetry(true);
            retrieveHistory(historyWithErrors);
        }
    };

    return (
        <TemplateHistoryContext.Provider
            value={{
                history,
                historyWithErrors,
                areConfidentialItemsRemoved,
                updateHistory,
                retryHistory,
                isHistoryRetry,
                getHistoryKey,
            }}
        >
            {children}
        </TemplateHistoryContext.Provider>
    );
};

export default TemplateHistoryProvider;
