export function addToLocalStorage(key: string, item: any) {
    if (localStorage && typeof item !== "undefined") {
        localStorage.setItem(key, JSON.stringify(item));
    }
}

export function getFromLocalStorage(storageKey: string, fallbackValue: any) {
    if (!localStorage) {
        return fallbackValue;
    }
    const item = localStorage.getItem(storageKey);
    return item ? JSON.parse(item) : fallbackValue;
}

export function deleteFromLocalStorage(storageKey) {
    if (localStorage) {
        localStorage.removeItem(storageKey);
    }
}
