import * as React from "react";
import {
    DropdownPropertyForEdit,
    EditorPropertyForEdit,
    TextPropertyForEdit,
    TypeOfPropertyForEdit,
} from "@emisgroup/clint-content/lib/types";
import { useTranslation } from "@emisgroup/application-intl";
import { KEYCODES } from "../constants";

type SelectionOption = { text: string; key: any; value: any };
type ContentEntityPropertyProps = {
    editEnabled: boolean;
    propertyValue: any;
    propertyForEdit: DropdownPropertyForEdit | EditorPropertyForEdit | TextPropertyForEdit;
    onUpdate: (updatedValue: any) => void;
};

const NO_SELECTION_KEY = "__no_selection__";
const ContentEntityProperty = ({
    editEnabled,
    propertyForEdit,
    propertyValue,
    onUpdate,
}: ContentEntityPropertyProps) => {
    const { t } = useTranslation();
    const { name } = propertyForEdit;
    const handleDropdownChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
        const { options } = propertyForEdit as DropdownPropertyForEdit;
        const selectedOption = options.find(
            option => option.key.toString() === ev.currentTarget.value,
        ) as SelectionOption;
        onUpdate(selectedOption.value);
    };

    const allOptions = () => {
        const { options, noCurrentValueOption } = propertyForEdit as DropdownPropertyForEdit;
        return options.find(({ key }) => key === propertyValue)
            ? options
            : [{ text: noCurrentValueOption, key: NO_SELECTION_KEY, value: "" }].concat(options);
    };

    const inputRef = React.useRef<HTMLInputElement>(null);
    const handleTextUpdate = () => {
        if (inputRef.current) {
            onUpdate({ [propertyForEdit.key]: inputRef.current.value });
        }
    };

    const handleKeyDownEvent = ev => {
        if (ev.keyCode === KEYCODES.ESC && inputRef.current) {
            inputRef.current.value = (propertyForEdit as TextPropertyForEdit).value;
        }
    };

    return (
        <div className="sidebar-row">
            <div className="property-name" aria-label={name}>
                {propertyForEdit.name}
            </div>
            <div className="property-value">
                {propertyForEdit.type === TypeOfPropertyForEdit.Dropdown && (
                    <select
                        data-testid={`${name}-select`}
                        value={propertyValue || NO_SELECTION_KEY}
                        style={{ width: "100%" }}
                        onChange={handleDropdownChange}
                        disabled={!editEnabled}
                    >
                        {allOptions().map(option => (
                            <option key={option.key} value={option.key}>
                                {option.text}
                            </option>
                        ))}
                    </select>
                )}
                {propertyForEdit.type === TypeOfPropertyForEdit.Editor && editEnabled && (
                    <button
                        type="button"
                        onClick={propertyForEdit.edit}
                        data-testid={`click-to-edit-${name}`}
                        aria-label={t("stringEntry.clickToEditName", { name })}
                    >
                        {t("edit")}
                    </button>
                )}
                {propertyForEdit.type === TypeOfPropertyForEdit.Text && (
                    <input
                        data-testid={`${name}-input`}
                        ref={inputRef}
                        type="text"
                        onKeyDown={handleKeyDownEvent}
                        onBlur={handleTextUpdate}
                        aria-label={t("stringEntry.enterValueForName", { name })}
                        defaultValue={propertyForEdit.value}
                    />
                )}
            </div>
        </div>
    );
};

export default ContentEntityProperty;
