import { PropertyDataType } from "@emisgroup/clint-templates-common";
import * as React from "react";

const PROPERTY_CHANGE_EVENT = "property-change";
const usePropertyUpdates = (
    name: string,
    originalPropertyValue: string,
    inputRef: HTMLInputElement | null,
    dataAttributes: PropertyDataType,
) => {
    const previousValue = React.useRef(originalPropertyValue);
    const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        if (dataAttributes.sendChangesMessage) {
            window.dispatchEvent(
                new CustomEvent("property-change", {
                    detail: { propertyName: name, originalValue: previousValue.current, newValue: ev.target.value },
                }),
            );

            previousValue.current = ev.target.value;
        }
    };

    React.useEffect(() => {
        const handlePropertyChange = ev => {
            const { propertyName, originalValue, newValue } = ev.detail;
            if (inputRef && propertyName === dataAttributes.updateFromProperty && inputRef.value === originalValue) {
                // eslint-disable-next-line no-param-reassign
                inputRef.value = newValue;
            }
        };
        if (dataAttributes.updateFromProperty) {
            window.addEventListener(PROPERTY_CHANGE_EVENT, handlePropertyChange);
        }

        return () => {
            if (dataAttributes.updateFromProperty) {
                window.removeEventListener(PROPERTY_CHANGE_EVENT, handlePropertyChange);
            }
        };
    });

    return { handleChange: dataAttributes.sendChangesMessage ? handleChange : undefined };
};

export default usePropertyUpdates;
