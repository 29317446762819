import * as React from "react";
import {
    FreeTextComponent,
    RunningTemplateContext,
    TemplateDataItem,
    ComponentType,
} from "@emisgroup/clint-templates-common";
import { useTranslation } from "@emisgroup/application-intl";

type AssessmentFreeTextProps = {
    freeText: FreeTextComponent;
    runtimeValidator: (templateData: TemplateDataItem) => boolean;
};

export default function AssessmentFreeText({ freeText, runtimeValidator }: AssessmentFreeTextProps) {
    const { t } = useTranslation();
    const { templateData, updateTemplateData } = React.useContext(RunningTemplateContext);
    const data = templateData[freeText.id];
    const isValid = runtimeValidator(data);

    const value = data?.value as string;
    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        updateTemplateData({
            ...templateData,
            [freeText.id]: { type: ComponentType.FREE_TEXT, interacted: true, value: e.currentTarget.value },
        });
    };

    const handleBlur = () => {
        updateTemplateData({
            ...templateData,
            [freeText.id]: { ...data, interacted: true },
        });
    };

    return (
        <div data-testid={`freetext-${freeText.id}`}>
            <label className="nhsuk-label" htmlFor={`text-area-${freeText.id}`}>
                {freeText.label}
            </label>
            <textarea
                className="nhsuk-textarea"
                id={`text-area-${freeText.id}`}
                rows={5}
                value={value}
                onChange={handleChange}
                onBlur={handleBlur}
            />
            {!isValid && (
                <span className="nhsuk-error-message">
                    <span className="nhsuk-u-visually-hidden">Error:</span> {t("components.freeText.enterSomeText")}
                </span>
            )}
        </div>
    );
}
