import React from "react";
import { CanvasItem, ComponentContainer, ComponentContainerHistoryItem } from "../types";

type TemplateContextType = {
    templateDefinition: ComponentContainer;
    setTemplateDefinition: (
        container: ComponentContainer,
        isOriginalDefinition?: boolean,
        resetHistory?: boolean,
        addToHistory?: boolean,
    ) => void;
    clearTemplateDefinition: () => void;
    groupedItems: CanvasItem[];
    setGroupedItems: (items: CanvasItem[]) => void;
    selectedItem: CanvasItem;
    setSelectedItem: (item: CanvasItem) => void;
    containerOfSelectedItem: ComponentContainer | undefined;
    history: Array<ComponentContainerHistoryItem>;
    currentIndex: number;
    goBack: () => void;
    goForward: () => void;
    hasDefinitionChanged: boolean;
    invalidComponentDefinitionIds: string[];
    setInvalidComponentDefinitionIds: (invalidComponentDefinitionIds: string[]) => void;
};

export const TemplateContext = React.createContext<TemplateContextType>({
    templateDefinition: {} as ComponentContainer,
    setTemplateDefinition: () => {},
    clearTemplateDefinition: () => {},
    groupedItems: [],
    setGroupedItems: () => {},
    selectedItem: {} as CanvasItem,
    setSelectedItem: () => {},
    containerOfSelectedItem: {} as ComponentContainer,
    hasDefinitionChanged: false,
    history: [],
    currentIndex: 0,
    goBack: () => {},
    goForward: () => {},
    invalidComponentDefinitionIds: [] as string[],
    setInvalidComponentDefinitionIds: () => {
        //* Intentionally blank
    },
});
