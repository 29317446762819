import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import React from "react";
import { CanvasItem, ComponentIcon } from "@emisgroup/clint-templates-common";
import { useTranslation } from "@emisgroup/application-intl";
import { Drag } from "@emisgroup/icons-react";

type LibraryComponentProps = { label: string; icon?: JSX.Element };
export const LibraryComponent = ({ label, icon }: LibraryComponentProps) => {
    const labelText = useTranslation().t(label);
    return (
        <div aria-label={labelText} className="library-component">
            {icon}
            <div>{labelText}</div>
            <Drag className="drag-icon" title="Drag" size="small" color="primary" />
        </div>
    );
};

const TemplateComponent = ({ type, label }) => {
    const canvasItem: CanvasItem = { id: "", type, label, columnIndex: -1, rule: null };
    const [{ isDragging }, drag, preview] = useDrag({
        item: canvasItem,
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
        begin: () => canvasItem,
    });

    React.useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, []);

    return (
        <li
            ref={drag}
            data-testid={`available-comp-${type}`}
            style={{
                opacity: isDragging ? 0.5 : 1,
                cursor: "move",
            }}
        >
            <LibraryComponent label={label} icon={<ComponentIcon type={type} />} />
        </li>
    );
};

export default TemplateComponent;
