/* eslint-disable react/style-prop-object */
import * as React from "react";
import {
    Button,
    ButtonGroup,
    Dialog,
    DialogContent,
    DialogFooter,
    FormElement,
    TextInput,
} from "@emisgroup/ui-kit-react";
import { Tag } from "@emisgroup/content-picker";
import { useTranslation } from "@emisgroup/application-intl";

import "./saveDraftDialog.css";
import { SaveScope } from "../types";
import { capitalise } from "../utils/componentUtils";
import {
    NAME_FIELD_ID,
    DESCRIPTION_FIELD_ID,
    TAGS_FIELD_ID,
    getValidationErrors,
    isValid,
    PropertyErrors,
    ValidationErrors,
} from "./saveDraftDialogUtils";

type SaveDraftDialogProps = {
    saveScope: SaveScope;
    initialName: string;
    propertyErrors: PropertyErrors;
    onSave: (name: string, description: string, tags: string[]) => void;
    onCancel: () => void;
};

const SaveDraftDialog = ({ saveScope, initialName, propertyErrors, onSave, onCancel }: SaveDraftDialogProps) => {
    const { t } = useTranslation();
    const [name, setName] = React.useState(initialName);
    const [description, setDescription] = React.useState("");
    const [tags, setTags] = React.useState<string[]>([]);
    const [tag, setTag] = React.useState("");
    const [validationErrors, setValidationErrors] = React.useState<ValidationErrors>({
        [NAME_FIELD_ID]: propertyErrors.name,
        [DESCRIPTION_FIELD_ID]: propertyErrors.description,
    });

    const fieldsToValidate = React.useRef(new Set<string>());
    const fieldsIgnoringPropertyError = React.useRef(new Set<string>());

    const addFieldsToValidate = (fieldIds: string[]) => {
        fieldIds.forEach(field => fieldsToValidate.current.add(field));
    };

    const ignorePropertyError = (fieldId: string) => {
        fieldsIgnoringPropertyError.current.add(fieldId);
    };

    const updateValidationErrors = (newName: string, newDescription: string): ValidationErrors => {
        const newValidationErrors = getValidationErrors(
            t,
            fieldsToValidate.current,
            fieldsIgnoringPropertyError.current,
            newName,
            newDescription,
            propertyErrors,
        );

        setValidationErrors(newValidationErrors);
        return newValidationErrors;
    };

    const handleNameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const newName = ev.target.value.trimLeft();
        setName(newName);
        addFieldsToValidate([NAME_FIELD_ID]);
        updateValidationErrors(newName, description);
    };

    const handleDescriptionChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const newDescription = ev.target.value.trimLeft();
        setDescription(newDescription);
        addFieldsToValidate([DESCRIPTION_FIELD_ID]);
        updateValidationErrors(name, newDescription);
    };

    const handleTagChange = (ev: React.ChangeEvent<HTMLInputElement>) => setTag(ev.target.value.trimLeft());
    const handleAddTag = () => {
        setTags([...tags, tag]);
        setTag("");
    };

    const handleSave = () => {
        addFieldsToValidate([NAME_FIELD_ID, DESCRIPTION_FIELD_ID]);
        const newValidationErrors = updateValidationErrors(name, description);
        if (isValid(newValidationErrors)) onSave(name, description, tags);
    };

    React.useEffect(() => {
        fieldsIgnoringPropertyError.current.clear();
        updateValidationErrors(name, description);
    }, [propertyErrors]);

    const title = t(saveScope === SaveScope.Selection ? "save.selection" : "save.templateAs");
    const cancelLabel = t(saveScope === SaveScope.Selection ? "save.cancelSelection" : "save.cancelTemplate");
    const nameField = t("contentLibraryName");
    const descriptionField = t("description");
    const tagsField = t("tags");

    return (
        <Dialog open={true} onClose={onCancel} title={title} className="auto-sized-dialog" disableDismiss>
            <DialogContent>
                <hr />
                <FormElement
                    mandatory
                    labelText={capitalise(nameField)}
                    fieldId={NAME_FIELD_ID}
                    errorText={validationErrors[NAME_FIELD_ID]}
                >
                    <TextInput
                        id={NAME_FIELD_ID}
                        inputType="text"
                        ariaLabel={nameField}
                        value={name}
                        onBlur={handleNameChange}
                        onChange={() => ignorePropertyError(NAME_FIELD_ID)}
                        invalid={Boolean(validationErrors[NAME_FIELD_ID])}
                    />
                </FormElement>
                <FormElement
                    mandatory
                    labelText={capitalise(descriptionField)}
                    fieldId={DESCRIPTION_FIELD_ID}
                    errorText={validationErrors[DESCRIPTION_FIELD_ID]}
                >
                    <TextInput
                        id={DESCRIPTION_FIELD_ID}
                        inputType="text"
                        ariaLabel={descriptionField}
                        value={description}
                        onBlur={handleDescriptionChange}
                        onChange={() => ignorePropertyError(DESCRIPTION_FIELD_ID)}
                        invalid={Boolean(validationErrors[DESCRIPTION_FIELD_ID])}
                    />
                </FormElement>
                <FormElement labelText={capitalise(tagsField)} fieldId={TAGS_FIELD_ID}>
                    <div className="tag-entry">
                        <input
                            className="eui-text-input"
                            id={TAGS_FIELD_ID}
                            type="text"
                            aria-label={t("enterATag")}
                            value={tag}
                            onChange={handleTagChange}
                        />
                        <Button
                            type="button"
                            variant="primary"
                            disabled={tag === ""}
                            onClick={handleAddTag}
                            ariaLabel={t("addTag")}
                        >
                            {t("addTag")}
                        </Button>
                    </div>
                    <div className="tag-list">
                        {tags.map(tagText => (
                            <Tag key={tagText} text={tagText} />
                        ))}
                    </div>
                </FormElement>
            </DialogContent>
            <DialogFooter>
                <ButtonGroup className="left-aligned-dialog-button-group">
                    <Button
                        data-testid={`save ${saveScope}`}
                        type="button"
                        variant="primary"
                        onClick={handleSave}
                        ariaLabel={title}
                    >
                        {t("save.save")}
                    </Button>
                    <Button
                        data-testid={`cancel save ${saveScope}`}
                        type="button"
                        variant="secondary"
                        onClick={onCancel}
                        ariaLabel={cancelLabel}
                    >
                        {t("cancel")}
                    </Button>
                </ButtonGroup>
            </DialogFooter>
        </Dialog>
    );
};

export default SaveDraftDialog;
