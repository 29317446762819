import { useEffect } from "react";

type HeightSetter = (height: number | null, element: HTMLElement) => void;
const CONTAINER_CLASSES = [
    {
        clsName: "panel-component",
        heightSetter: (height: number | null, element: HTMLElement) => {
            // eslint-disable-next-line no-param-reassign
            (element.firstChild as HTMLElement).style.height = height === null ? "" : `${height}px`;
        },
    },
    {
        clsName: "tab-container-component",
        heightSetter: (height: number | null, element: HTMLElement) => {
            const contentElement = element.getElementsByClassName("tab-content")[0] as HTMLElement;
            contentElement.style.height = height === null ? "" : `${height}px`;
        },
    },
];

type ContainerWithSetter = {
    container: HTMLElement;
    heightSetter: HeightSetter;
};
function getContainerElement(dateEntryElement: HTMLElement): ContainerWithSetter | null {
    const container = CONTAINER_CLASSES.find(({ clsName }) =>
        dateEntryElement.parentElement?.classList.contains(clsName),
    );
    if (container) {
        return { container: dateEntryElement.parentElement as HTMLElement, heightSetter: container.heightSetter };
    }

    return dateEntryElement.parentElement === null ? null : getContainerElement(dateEntryElement.parentElement);
}

function ensureContainerHasSpace(calendar: HTMLElement, containerWithSetter: ContainerWithSetter | null) {
    if (!containerWithSetter) {
        return;
    }
    const { container, heightSetter } = containerWithSetter;
    const { bottom: calendarBottom } = calendar.getBoundingClientRect();
    const { bottom: containerBottom, height: containerHeight } = container.getBoundingClientRect();
    const extraHeightRequired = calendarBottom - containerBottom;
    if (extraHeightRequired > 0) {
        heightSetter(containerHeight + extraHeightRequired, container);
    }
}

function removeAddedSpace(containerWithSetter: ContainerWithSetter | null) {
    if (!containerWithSetter) {
        return;
    }

    containerWithSetter.heightSetter(null, containerWithSetter.container);
}

export default function useDateEntry(dateEntryElement: HTMLElement | null) {
    useEffect(() => {
        if (!dateEntryElement) {
            return () => {
                // nothing to do here
            };
        }

        const resizeHandler = e => {
            const [{ target: calendar }] = e;
            const isOpened = calendar.getBoundingClientRect().height > 0;
            const containerElement = getContainerElement(dateEntryElement);
            if (isOpened) {
                ensureContainerHasSpace(calendar, containerElement);
            } else {
                removeAddedSpace(containerElement);
            }
        };

        const calendarDiv = dateEntryElement?.getElementsByClassName(
            "eui-calendar eui-date-picker__calendar",
        )[0] as HTMLDivElement;
        const resizeObserver = new ResizeObserver(resizeHandler);
        resizeObserver.observe(calendarDiv);
        return () => resizeObserver.unobserve(calendarDiv);
    }, [dateEntryElement]);
}
