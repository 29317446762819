import React from "react";
import { ChevronLeft, ChevronRight } from "@emisgroup/icons-react";
import { useTranslation } from "@emisgroup/application-intl";
import "../dynamicTab.css";

type DynamicTabProps = {
    dynamicTabId: string;
    children?: React.ReactNode | React.ReactNodeArray;
};

const DynamicTabBar = ({ dynamicTabId, children }: DynamicTabProps) => {
    const { t } = useTranslation();
    const tabSection = React.useRef<HTMLDivElement>(null);

    const scrollLeft = evt => {
        const section = tabSection.current as HTMLDivElement;
        section.scrollLeft -= section.clientWidth;
        evt.stopPropagation();
    };

    const scrollRight = evt => {
        const section = tabSection.current as HTMLDivElement;
        section.scrollLeft += section.clientWidth;
        evt.stopPropagation();
    };

    const resizeHandler = () => {
        if ((tabSection.current?.scrollWidth || 0) > (tabSection.current?.clientWidth || 0)) {
            tabSection.current?.classList.add("eui-dynamic-tabs__section--overflows");
        } else {
            tabSection.current?.classList.remove("eui-dynamic-tabs__section--overflows");
        }
    };

    React.useEffect(() => {
        const section = tabSection.current as HTMLDivElement;

        const resizeObserver = new ResizeObserver(resizeHandler);
        resizeObserver.observe(section);
        return () => resizeObserver.unobserve(section);
    }, [tabSection]);

    React.useLayoutEffect(() => {
        resizeHandler();
    }, [children]);

    return (
        <>
            <div className="eui-dynamic-tabs">
                <div ref={tabSection} id={`tab_section_${dynamicTabId}`} className="eui-dynamic-tabs__section">
                    <div
                        ref={tabSection}
                        className="eui-dynamic-tabs__list"
                        role="tablist"
                        aria-label={t("components.tabBar.tabs")}
                        id={`tab_list_${dynamicTabId}`}
                    >
                        {children}
                    </div>
                </div>
                <div className="eui-dynamic-tabs__section eui-dynamic-tabs__section--nav">
                    <button
                        className="eui-dynamic-tabs__nav-button eui-dynamic-tabs__nav-button--left"
                        type="button"
                        onClick={scrollLeft}
                    >
                        <ChevronLeft title={t("components.tabBar.scrollLeft")} />
                    </button>
                    <button
                        className="eui-dynamic-tabs__nav-button eui-dynamic-tabs__nav-button--right"
                        type="button"
                        onClick={scrollRight}
                    >
                        <ChevronRight title={t("components.tabBar.scrollRight")} />
                    </button>
                </div>
            </div>
        </>
    );
};

export default DynamicTabBar;
