import React from "react";
import { useDrag, useDrop } from "react-dnd";
import { $enum } from "ts-enum-util";
import { getEmptyImage } from "react-dnd-html5-backend";
import {
    TemplateContext,
    CanvasItem,
    CanvasItemClickHandler,
    ComponentType,
    MouseEventHandler,
    getComponentTypeClass,
} from "@emisgroup/clint-templates-common";
import { ComponentDragDropLayout, ParametersForPropertyUpdate } from "../types";
import { withSelectHandler } from "../utils/componentUtils";
// eslint-disable-next-line import/no-cycle
import CanvasComponent from "./canvasComponent";
import {
    DROP_BEFORE_INDEX,
    COMPONENT_INDEX,
    DROP_AFTER_INDEX,
    HoverTarget,
    getHoverTarget,
    getDropIndicatorHeight,
    isDragWithinEditableContent,
} from "../utils/dragDropUtils";
import "../dragDrop.css";

type RendererProps = {
    isSelected: boolean;
    onSelect: CanvasItemClickHandler | MouseEventHandler;
    component: CanvasItem;
    index: number;
    dragDropLayout: ComponentDragDropLayout;
    arePropertiesValid: boolean;
    canDropItem: (item: any) => boolean;
    onItemDropped: (item: any, index: number) => void;
    onPropertyUpdate: (params: ParametersForPropertyUpdate) => void;
};

function ComponentRenderer({
    isSelected,
    onSelect,
    component,
    index,
    dragDropLayout,
    arePropertiesValid,
    canDropItem,
    onItemDropped,
    onPropertyUpdate,
}: RendererProps) {
    const componentRef = React.useRef<HTMLDivElement>(null);
    const [hoverTarget, setHoverTarget] = React.useState(HoverTarget.NONE);
    const { selectedItem } = React.useContext(TemplateContext);

    const getItemClickHandler = withSelectHandler(onSelect as CanvasItemClickHandler);

    const [{ canDrop }, drop] = useDrop({
        accept: $enum(ComponentType).getValues(),
        canDrop: (item, monitor) => {
            return monitor.isOver({ shallow: true }) && canDropItem(item);
        },
        hover: (_, monitor) => {
            setHoverTarget(
                getHoverTarget(componentRef.current?.children[COMPONENT_INDEX], monitor.getClientOffset(), true),
            );
        },
        collect: monitor => ({
            canDrop: monitor.canDrop(),
        }),
        drop: item => {
            onItemDropped(item, hoverTarget === HoverTarget.SECOND_HALF_COMPONENT ? index + 1 : index);
        },
    });

    const [{ isDragging }, drag, preview] = useDrag({
        item: component,
        canDrag: monitor => {
            return !isDragWithinEditableContent(monitor.getClientOffset());
        },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
        begin: () => component,
    });

    const setDropIndicatorHeight = (indicatorIndex: number, isHovering: boolean, isFirstOrLast: boolean) => {
        const indicator = componentRef.current?.children[indicatorIndex] as HTMLElement;
        indicator.style.height = getDropIndicatorHeight(canDrop && isHovering, isFirstOrLast);
    };

    React.useEffect(() => {
        if (dragDropLayout.dragDropEnabled && componentRef.current) {
            setDropIndicatorHeight(
                DROP_BEFORE_INDEX,
                hoverTarget === HoverTarget.FIRST_HALF_COMPONENT,
                dragDropLayout.isFirstChild,
            );
            setDropIndicatorHeight(
                DROP_AFTER_INDEX,
                hoverTarget === HoverTarget.SECOND_HALF_COMPONENT,
                dragDropLayout.isLastChild,
            );
        }
    }, [canDrop, hoverTarget]);

    React.useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, []);

    const renderComponent = () => {
        let handler;
        if (component.type === ComponentType.PANEL || component.type === ComponentType.TAB_CONTAINER) {
            handler = onSelect as CanvasItemClickHandler;
        } else {
            handler = getItemClickHandler(component);
        }

        return (
            <CanvasComponent
                component={component}
                selectedItem={selectedItem}
                isSelected={isSelected}
                dragDropEnabled={dragDropLayout.dragDropEnabled}
                arePropertiesValid={arePropertiesValid}
                onSelect={handler}
                onPropertyUpdate={onPropertyUpdate}
            />
        );
    };

    if (dragDropLayout.dragDropEnabled) {
        drag(drop(componentRef));
    }

    return (
        <div
            ref={componentRef}
            data-testid={`drag-container-${component.id}`}
            style={{ opacity: isDragging ? 0.5 : 1 }}
        >
            {dragDropLayout.dragDropEnabled && (
                <div className="drop-indicator" data-testid={`${component.id}-drop-before-indicator`} />
            )}
            <div className={`drag-container ${getComponentTypeClass(component.type)}`}>{renderComponent()}</div>
            {dragDropLayout.dragDropEnabled && (
                <div className="drop-indicator" data-testid={`${component.id}-drop-after-indicator`} />
            )}
        </div>
    );
}

export default ComponentRenderer;
