import * as React from "react";
import { v4 as uuid } from "uuid";
import {
    TemplateContext,
    findCanvasItemParent,
    CanvasItem,
    ComponentType,
    ComponentContainer,
    ConfigContext,
    Panel,
} from "@emisgroup/clint-templates-common";
import { useTranslation } from "@emisgroup/application-intl";
import { NewItemDialog } from "./newItemDialog";
import { TemplatePickerContext } from "../context/templatePicker";
import {
    arePropertiesShownAtCreate,
    cancelAddition,
    createContainerFromSavedContent,
    createNewCanvasItemFromDroppedItem,
    getItemsForNewPanel,
    getUpdatedContainerMembers,
    insertItem,
} from "../utils/componentUtils";
import { getDefaultPanelActionButtons } from "../utils/panelActionUtils";

enum AdditionType {
    Standard,
    FromGroupedItems,
}

type StandardAddition = { type: AdditionType.Standard; indexToBeAddedAt: number };
type GroupedItemsAddition = { type: AdditionType.FromGroupedItems };
type ItemAddition = StandardAddition | GroupedItemsAddition;

const useCreateItem = (containerToCreateItemIn?: ComponentContainer) => {
    const { t } = useTranslation();
    const { showTemplatePickerInDialog } = React.useContext(TemplatePickerContext);
    const { features } = React.useContext(ConfigContext);
    const [newItemDialogShown, setNewItemDialogShown] = React.useState<boolean>(false);
    const [newItem, setNewItem] = React.useState<CanvasItem | null>(null);
    const [itemAddition, setItemAddition] = React.useState<ItemAddition | null>(null);
    const [disallowGroupedItemsInVisibilityRules, setDisallowGroupedItemsInVisibilityRules] = React.useState(false);

    const { groupedItems, setGroupedItems, setTemplateDefinition, setSelectedItem, templateDefinition } =
        React.useContext(TemplateContext);
    const { members: templateItems } = templateDefinition;
    let container;
    if (containerToCreateItemIn) {
        container = containerToCreateItemIn;
    }

    const insertNewItem = (canvasItem: CanvasItem, position: number) => {
        const updatedTemplate = insertItem(canvasItem, container, templateDefinition, position);
        setTemplateDefinition(updatedTemplate);
    };

    const createPanelFromGroupedItems = () => {
        if (newItem) {
            const groupedItemsContainer = findCanvasItemParent(groupedItems[0].id, templateDefinition.members);
            const newItems = getItemsForNewPanel(
                newItem as ComponentContainer,
                groupedItems,
                groupedItemsContainer ? groupedItemsContainer.members : templateItems,
            );
            setTemplateDefinition({
                ...templateDefinition,
                members: groupedItemsContainer
                    ? getUpdatedContainerMembers(templateDefinition, groupedItemsContainer.id, newItems)
                    : newItems,
            });
            setGroupedItems([]);
            cancelAddition(setNewItem, setNewItemDialogShown, setItemAddition);
            setSelectedItem(newItem);
        }
    };

    const handlePanelCreationFromGroupedItems = () => {
        setNewItem({
            id: uuid(),
            type: ComponentType.PANEL,
            label: "",
            columnIndex: groupedItems[0].columnIndex,
            rule: null,
            members: groupedItems,
            columnCount: 1,
            actionButtons: getDefaultPanelActionButtons(t),
        } as Panel);
        setNewItemDialogShown(true);
        setItemAddition({ type: AdditionType.FromGroupedItems });
        setDisallowGroupedItemsInVisibilityRules(true);
    };

    const handleItemAddition = (canvasItem: CanvasItem, index: number, columnIndex: number) => {
        if (canvasItem.type === ComponentType.SAVED_CONTENT) {
            showTemplatePickerInDialog((contentLibraryStore, definition) => {
                const newSavedPanel = createContainerFromSavedContent(
                    ComponentType.PANEL,
                    columnIndex,
                    definition,
                    contentLibraryStore.ern,
                );
                insertNewItem(newSavedPanel, index);
                setSelectedItem(newSavedPanel);
            });

            return;
        }

        if (newItemDialogShown) {
            return;
        }
        const newCanvasItem: CanvasItem = createNewCanvasItemFromDroppedItem(canvasItem, columnIndex, features, t);

        if (arePropertiesShownAtCreate(canvasItem.type)) {
            setNewItem(newCanvasItem);
            setNewItemDialogShown(true);
            setItemAddition({ type: AdditionType.Standard, indexToBeAddedAt: index });
            setDisallowGroupedItemsInVisibilityRules(false);
        } else {
            insertNewItem(newCanvasItem, index);
            setSelectedItem(newCanvasItem);
            cancelAddition(setNewItem, setNewItemDialogShown, setItemAddition);
        }
    };

    const addItemAt = (index: number) => {
        if (newItem && index !== -1) {
            insertNewItem(newItem, index);
            setSelectedItem(newItem);

            cancelAddition(setNewItem, setNewItemDialogShown, setItemAddition);
        }
    };

    const addItem = () => {
        const addition = itemAddition as ItemAddition;
        if (addition.type === AdditionType.FromGroupedItems) {
            createPanelFromGroupedItems();
        } else if (addition.type === AdditionType.Standard) {
            addItemAt(addition.indexToBeAddedAt);
        }
    };

    const handleCancel = () => cancelAddition(setNewItem, setNewItemDialogShown, setItemAddition);

    if (!newItemDialogShown) {
        return { handleItemAddition, handlePanelCreationFromGroupedItems, createItemView: null };
    }

    return {
        setContainer: (item: ComponentContainer): void => {
            container = item;
        },
        handleItemAddition,
        handlePanelCreationFromGroupedItems,
        newItemProperties: (containerForDialog?: ComponentContainer): React.ReactNode => (
            <NewItemDialog
                item={newItem}
                setItems={items => setNewItem(items[0])}
                addItem={addItem}
                cancelAddition={handleCancel}
                container={containerForDialog || container}
                visibilityRulesDisallowedItems={disallowGroupedItemsInVisibilityRules ? groupedItems : []}
            />
        ),
    };
};

export default useCreateItem;
