import {
    CalculatorComponent,
    CanvasItem,
    Code,
    CodedComponent,
    ComponentParameter,
    ComponentType,
    PickingListComponent,
    PickingListOption,
    TemplateData,
    TemplateDataItem,
} from "../types";
import { extractCodedValue, findCanvasItem, isContainerType } from "./componentUtils";
import { isDescendantOfEmbeddedItem } from "./embeddingUtils";
import executeCalculation from "./executeCalculation";

const VALID_CALCULATION_COMPONENT_TYPES = [
    ComponentType.CALCULATOR,
    ComponentType.CODED,
    ComponentType.NUMERIC_VALUE,
    ComponentType.PICKING_LIST,
];

export enum InvalidParameterReason {
    TargetComponent,
    Container,
    NotValueType,
    PickingListWithNoValues,
    NonNumericCode,
    CircularReference,
    SavedContent,
}
export function isValidCalculationParameter(
    canvasItem: CanvasItem,
    calculator: CalculatorComponent,
    templateItems: CanvasItem[],
) {
    const isCircularReference = (calculatorToCheck: CalculatorComponent) => {
        const { parameters } = calculatorToCheck;
        if (!parameters) {
            return false;
        }

        if (parameters.find(({ componentId }) => componentId === calculator.id)) {
            return true;
        }

        return parameters.some(param => {
            const paramItem = findCanvasItem(param.componentId, templateItems);
            return (
                paramItem &&
                paramItem.type === ComponentType.CALCULATOR &&
                isCircularReference(paramItem as CalculatorComponent)
            );
        });
    };

    switch (true) {
        case canvasItem === calculator:
            return { valid: false, reason: InvalidParameterReason.TargetComponent };
        case isContainerType(canvasItem.type):
        case canvasItem.type === ComponentType.TEMPLATE:
            return { valid: false, reason: InvalidParameterReason.Container };
        case canvasItem.type === ComponentType.SAVED_CONTENT:
            return { valid: false, reason: InvalidParameterReason.SavedContent };
        case !VALID_CALCULATION_COMPONENT_TYPES.includes(canvasItem.type as ComponentType):
            return { valid: false, reason: InvalidParameterReason.NotValueType };
        case canvasItem.type === ComponentType.PICKING_LIST && !(canvasItem as PickingListComponent).hasValues:
            return { valid: false, reason: InvalidParameterReason.PickingListWithNoValues };
        case canvasItem.type === ComponentType.CODED && !(canvasItem as CodedComponent).code?.isNumeric:
            return { valid: false, reason: InvalidParameterReason.NonNumericCode };
        case canvasItem.type === ComponentType.CALCULATOR && isCircularReference(canvasItem as CalculatorComponent):
            return { valid: false, reason: InvalidParameterReason.CircularReference };
        case isDescendantOfEmbeddedItem(canvasItem, templateItems):
            return { valid: false, reason: InvalidParameterReason.SavedContent };
        default:
            return { valid: true };
    }
}

export function getCalculationParametersValidity(t, parameters: ComponentParameter[]) {
    switch (true) {
        case !parameters.length:
            return { isValid: false, message: t("components.calculator.atLeastOneParameter") };
        case !parameters.every(({ label }) => Boolean(label)):
            return { isValid: false, message: t("components.calculator.allHaveLabel") };
        case new Set(parameters.map(({ label }) => label.toLowerCase())).size !== parameters.length:
            return { isValid: false, message: t("components.calculator.noDuplicates") };
        default:
            return { isValid: true };
    }
}

const PARAMETERS_REGEX = /\b[A-Za-z][A-Za-z0-9_]*\b/gi;
function getAllParameterMatches(expression: string) {
    return expression.matchAll(PARAMETERS_REGEX);
}

export function replaceParameters(expression: string, replacement: (param: string) => string) {
    let result = "";
    let lastIndex = 0;
    // eslint-disable-next-line no-restricted-syntax
    for (const match of getAllParameterMatches(expression)) {
        if (lastIndex !== match.index) {
            result = `${result}${expression.substring(lastIndex, match.index)}`;
        }

        result = `${result}${replacement(match[0])}`;

        lastIndex = (match.index || 0) + match[0].length;
    }
    return `${result}${expression.substring(lastIndex)}`;
}

function expressionValidity(t, expression: string, parameters: ComponentParameter[]) {
    const availableParameters = parameters.map(({ label }) => label.toLowerCase());
    // eslint-disable-next-line no-restricted-syntax
    for (const match of getAllParameterMatches(expression)) {
        if (!availableParameters.includes(match[0].toLowerCase())) {
            return { isValid: false, message: t("components.calculator.parameterDoesNotExist") };
        }
    }

    const testCalculation = replaceParameters(expression, () => "1");
    try {
        executeCalculation(testCalculation);
    } catch (ex) {
        return { isValid: false, message: t("components.calculator.invalidCalculation") };
    }

    return { isValid: true };
}

export function getCalculationExpressionValidity(t, expression: string, calculator: CalculatorComponent) {
    return expressionValidity(t, expression, calculator.parameters);
}

export function getCalculationResultCodeValidity(t, code?: Code) {
    return code && !code.isNumeric
        ? { isValid: false, message: t("components.calculator.codeMustBeNumeric") }
        : { isValid: true };
}

function extractPickingListValue(data: TemplateDataItem) {
    const selectedItems = data.items?.filter(({ selected }) => selected);
    if (!selectedItems || !selectedItems.length) {
        return Number.NaN;
    }

    return selectedItems.reduce((total, { value }) => total + Number((value as PickingListOption).value), 0);
}

function extractComponentValue(data: TemplateDataItem) {
    switch (data.type) {
        case ComponentType.PICKING_LIST:
            return extractPickingListValue(data);
        case ComponentType.CODED:
            return extractCodedValue(data);
        default:
            return Number(data.value);
    }
}

export enum CalculationStatus {
    Success = "success",
    Inconclusive = "inconclusive",
    Failed = "failed",
}
export function calculateValue(t, parameters: ComponentParameter[], expression: string, templateData: TemplateData) {
    const validity = expressionValidity(t, expression, parameters);
    if (!validity.isValid) {
        return { status: CalculationStatus.Failed, message: validity.message };
    }

    const replacementErrors = [] as string[];
    const calculation = replaceParameters(expression, param => {
        const calculatorParameter = parameters.find(({ label }) => label.toLowerCase() === param.toLowerCase());
        const extractedValue = extractComponentValue(templateData[calculatorParameter?.componentId || ""]);
        if (Number.isNaN(extractedValue)) {
            replacementErrors.push(t("components.calculator.couldNotFindValue", { param }));
        }

        return String(extractedValue);
    });

    if (replacementErrors.length) {
        return { status: CalculationStatus.Inconclusive, message: replacementErrors[0] };
    }

    const result = executeCalculation(calculation);
    return result === Infinity
        ? { status: CalculationStatus.Inconclusive, message: t("components.calculator.divisionByZero") }
        : { status: CalculationStatus.Success, value: result };
}
