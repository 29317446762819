import {
    CanvasItem,
    CodedComponent,
    ComponentContainer,
    ComponentType,
    DiaryEntryComponent,
    FreeTextComponent,
    isContainerType,
    NumericValueComponent,
    PickingListComponent,
    TemplateData,
} from "@emisgroup/clint-templates-common";
import { TemplateDataItem, UncodedComponent } from "@emisgroup/clint-templates-common/src/types";

function validateUncoded(uncoded: UncodedComponent, templateData: TemplateDataItem) {
    const { isMandatory } = uncoded;
    const { selected, interacted } = templateData;
    return Boolean(!isMandatory || !interacted || selected);
}

function validatePickingList(pickingList: PickingListComponent, templateData: TemplateDataItem) {
    const { isMandatory } = pickingList;
    const { items, interacted } = templateData;
    return Boolean(!isMandatory || !interacted || (items?.length || 0) > 0);
}

function validateFreeText(freeText: FreeTextComponent, templateData: TemplateDataItem) {
    const { isMandatory } = freeText;
    const { value, interacted } = templateData;
    return Boolean(!isMandatory || !interacted || value);
}

function validateNumericValue(numericValue: NumericValueComponent, templateData: TemplateDataItem) {
    const { isMandatory } = numericValue;
    const { value, interacted } = templateData;

    return Boolean(!isMandatory || !interacted || (typeof value !== "undefined" && !Number.isNaN(value)));
}

function validateCoded(coded: CodedComponent, templateData: TemplateDataItem) {
    if (coded.code.isNumeric) {
        return validateNumericValue(coded as NumericValueComponent, templateData);
    }

    return validateUncoded(coded, templateData);
}

function validateDiaryEntry(diaryEntry: DiaryEntryComponent, templateData: TemplateDataItem) {
    const { isMandatory } = diaryEntry;
    const { selected, value, interacted } = templateData;

    switch (true) {
        case !interacted:
            return true;
        case isMandatory && !selected:
            return false;
        case selected && !value:
            return false;
        default:
            return Boolean(!isMandatory || (selected && value));
    }
}

export function validateComponent(component: CanvasItem, templateData: TemplateDataItem) {
    if (!templateData) {
        return true;
    }

    switch (component.type) {
        case ComponentType.UNCODED:
            return validateUncoded(component as UncodedComponent, templateData);
        case ComponentType.PICKING_LIST:
        case ComponentType.CODED_PICKING_LIST:
            return validatePickingList(component as PickingListComponent, templateData);
        case ComponentType.FREE_TEXT:
            return validateFreeText(component as FreeTextComponent, templateData);
        case ComponentType.NUMERIC_VALUE:
            return validateNumericValue(component as NumericValueComponent, templateData);
        case ComponentType.CODED:
            return validateCoded(component as CodedComponent, templateData);
        case ComponentType.DIARY_ENTRY:
            return validateDiaryEntry(component as DiaryEntryComponent, templateData);
        default:
            return true;
    }
}

function validateItem(canvasItem: CanvasItem, templateData: TemplateData) {
    if (isContainerType(canvasItem.type)) {
        return (canvasItem as ComponentContainer).members.every(item => validateItem(item, templateData));
    }

    return validateComponent(canvasItem, templateData[canvasItem.id]);
}
export function validateItems(canvasItems: CanvasItem[], templateData: TemplateData) {
    return canvasItems.every(item => validateItem(item, templateData));
}

function allItems(canvasItems: CanvasItem[]) {
    return canvasItems.flatMap(item =>
        isContainerType(item.type) ? [...allItems((item as ComponentContainer).members), item] : [item],
    );
}
export function setAllInteractedUpToItem(
    templateData: TemplateData,
    canvasItems: CanvasItem[],
    canvasItem: CanvasItem,
) {
    const updatedTemplateData = { ...templateData };
    const items = allItems(canvasItems);
    const canvasItemIndex = items.indexOf(canvasItem);

    for (let index = 0; index <= canvasItemIndex; index += 1) {
        if (!isContainerType(items[index].type)) {
            const itemId = items[index].id;
            updatedTemplateData[itemId] = { ...templateData[itemId], interacted: true };
        }
    }

    return updatedTemplateData;
}
