import * as React from "react";
import { AuthContext } from "@emisgroup/clint-templates-common";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../authconfig";

export const AuthProvider = ({ children }) => {
    const { instance } = useMsal();
    const useSession = process.env.APP_USE_LOCAL_AUTH !== "true";

    const checkAndSetActiveAccount = () => {
        const activeAccount = instance.getActiveAccount();
        if (!activeAccount && instance.getAllAccounts().length > 0) {
            const accounts = instance.getAllAccounts();
            instance.setActiveAccount(accounts[0]);
        }
    };

    const getBearerToken = async () => {
        if (useSession) {
            let token;

            checkAndSetActiveAccount();

            await instance
                .acquireTokenSilent(loginRequest)
                .then(response => {
                    token = response.accessToken;
                })
                .catch(() => {
                    instance.acquireTokenRedirect(loginRequest);
                });

            return token;
        }

        return process.env.APP_LOCAL_DEV_AUTH_TOKEN;
    };

    return <AuthContext.Provider value={{ getBearerToken }}>{children}</AuthContext.Provider>;
};
