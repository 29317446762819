import React from "react";
import { ButtonGroup, Dialog, DialogContent, DialogFooter } from "@emisgroup/ui-kit-react";
import { v4 as uuid } from "uuid";
import { DialogButton } from "./dialogButton";
import { ButtonProps } from "../types";

export const getButtonGroup = (buttons: ButtonProps[], leftAlignButtons = false) => (
    <ButtonGroup className={leftAlignButtons ? "left-aligned-dialog-button-group" : ""}>
        {buttons.map(button => (
            <DialogButton button={button} key={uuid()} />
        ))}
    </ButtonGroup>
);

type FooterProps = { buttons: ButtonProps[]; leftAlignButtons: boolean };
const Footer = React.memo(({ buttons, leftAlignButtons }: FooterProps) => (
    <DialogFooter>{getButtonGroup(buttons, leftAlignButtons)}</DialogFooter>
));

interface DialogProps {
    title?: string;
    buttons: ButtonProps[];
    onCancel: () => void;
    children: React.ReactNode;
    contentClassName?: string;
    fillAvailableSpace?: boolean;
    ariaLabel?: string;
    leftAlignButtons?: boolean;
    disableDismiss?: boolean;
}

const GenericDialog = ({
    children,
    buttons,
    onCancel,
    title = "",
    contentClassName = "",
    ariaLabel,
    fillAvailableSpace = false,
    leftAlignButtons = false,
    disableDismiss = false,
}: DialogProps) => {
    return (
        <div role="none" onClick={evt => evt.stopPropagation()} aria-label={ariaLabel}>
            <Dialog
                open={true}
                className={fillAvailableSpace ? "" : "auto-sized-dialog"}
                onClose={onCancel}
                title={title}
                disableDismiss={disableDismiss}
            >
                <DialogContent className={contentClassName}>
                    <>
                        <hr />
                        {children}
                    </>
                </DialogContent>
                <Footer buttons={buttons} leftAlignButtons={leftAlignButtons} />
            </Dialog>
        </div>
    );
};

export default GenericDialog;
