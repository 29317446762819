import * as React from "react";

type SelectedPatientContextType = {
    patientId: string;
    updatePatientId: (id: string) => void;
};

export const SelectedPatientContext = React.createContext<SelectedPatientContextType>({
    patientId: "",
    updatePatientId: () => {},
});

type SelectedPatientProviderProps = {
    children: React.ReactNode;
    initialPatientId?: string;
};

export const SelectedPatientProvider = ({ children, initialPatientId = "" }: SelectedPatientProviderProps) => {
    const [patientId, setPatientId] = React.useState(() => initialPatientId);

    const updatePatientId = (id: string) => {
        setPatientId(id);
    };

    return (
        <SelectedPatientContext.Provider
            value={{
                patientId,
                updatePatientId,
            }}
        >
            {children}
        </SelectedPatientContext.Provider>
    );
};
