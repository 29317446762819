import * as React from "react";
import {
    PickingListComponent,
    RADIO_BUTTONS,
    RunningTemplateContext,
    TemplateDataItem,
} from "@emisgroup/clint-templates-common";
import { useTranslation } from "@emisgroup/application-intl";
import DropdownPickingList from "./dropdownPickingList";
import RadioButtonPickingList from "./radioButtonPickingList";
import MultiSelectPickingList from "./multiSelectPickingList";

type AssessmentPickingListProps = {
    pickingList: PickingListComponent;
    runtimeValidator: (templateData: TemplateDataItem) => boolean;
};

export default function AssessmentPickingList({ pickingList, runtimeValidator }: AssessmentPickingListProps) {
    const { templateData } = React.useContext(RunningTemplateContext);
    const { t } = useTranslation();

    const data = templateData[pickingList.id];

    const getComponent = () => {
        switch (true) {
            case pickingList.isMultiSelect:
                return <MultiSelectPickingList pickingList={pickingList} dataItem={data} />;
            case pickingList.displayStyle === RADIO_BUTTONS:
                return <RadioButtonPickingList pickingList={pickingList} dataItem={data} />;
            default:
                return <DropdownPickingList pickingList={pickingList} dataItem={data} />;
        }
    };

    const isValid = runtimeValidator(data);
    return (
        <div data-testid={`picking-list-${pickingList.id}`}>
            {getComponent()}
            {!isValid && (
                <span className="nhsuk-error-message">
                    <span className="nhsuk-u-visually-hidden">Error:</span> {t("components.selectionMandatory")}
                </span>
            )}
        </div>
    );
}
