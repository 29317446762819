import * as React from "react";

type ListBuilderOptionGridProps = {
    hasValues: boolean;
    hasFilingData: boolean;
    children: React.ReactNode | React.ReactNodeArray;
};
const ListBuilderOptionGrid = ({ hasValues, hasFilingData, children }: ListBuilderOptionGridProps) => {
    const optionalColumn = hasValues && hasFilingData ? "1fr" : "2fr";
    const gridTemplateColumns = `2fr ${hasValues ? optionalColumn : "0"} ${hasFilingData ? optionalColumn : "0"} 5rem`;
    return (
        <div className="list-builder-option-grid" style={{ gridTemplateColumns }}>
            {children}
        </div>
    );
};

export default ListBuilderOptionGrid;
