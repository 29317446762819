import React, { ReactNodeArray } from "react";
import { InfoBanner, Button } from "@emisgroup/ui-kit-react";
import { UpdateableEditorEntity, EntityUpdateProperty } from "@emisgroup/clint-content/lib/types";
import {
    CanvasItem,
    ComponentContainer,
    ComponentDataType,
    isContainerType,
    isDescendantOfEmbeddedItem,
    isEmbeddedContainer,
    ItemTypePropertiesMap,
    PropertyAttributes,
    TemplateContext,
} from "@emisgroup/clint-templates-common";
import { useTranslation } from "@emisgroup/application-intl";
import { InvalidProperties, ParametersForPropertyUpdate, PropertyValidity, PropsForProperty } from "../types";
import Property from "./property";
import ContentEntityProperty from "./contentEntityProperty";
import { PropertyValueProps } from "../properties/types";
import { DesignLayoutContext } from "../context/designLayoutContext";
import PropertyInspectorIndicators from "./propertyInspectorIndicators";
import { PropertyInspectorContext } from "./propertyInspectorContext";
import "./propertyInspector.css";

export type InspectorProps = {
    component: CanvasItem;
    editEnabled: boolean;
    onPropertyUpdate: (params: ParametersForPropertyUpdate, properties: PropertyAttributes[]) => void;
    focusOn?: string;
    invalidProperties?: InvalidProperties;
    overriddenProperties?: string[];
    perspectiveKey?: string;
    revertOverride?: (propertyName: string, properties: PropertyAttributes[]) => void;
};

const PropertyInspector = ({
    component,
    editEnabled,
    onPropertyUpdate,
    perspectiveKey,
    focusOn = "",
    invalidProperties = {},
    overriddenProperties = [],
    revertOverride = () => {},
}: InspectorProps) => {
    const [selectedEntity, setSelectedEntity] = React.useState<UpdateableEditorEntity | null>(null);
    const { templateDefinition } = React.useContext(TemplateContext);
    const { isPropInspectorExpanded, togglePropInspectorExpanded } = React.useContext(DesignLayoutContext);
    const { isNewItem } = React.useContext(PropertyInspectorContext);

    const showHeader = !isNewItem;

    const handleEntitySelected = evt => {
        setSelectedEntity(evt.detail.entity);
    };

    const handleEntityLeave = () => {
        setSelectedEntity(null);
    };

    const isMasterEmbeddedComponent =
        isContainerType(component.type) &&
        isEmbeddedContainer(component as ComponentContainer) &&
        !isDescendantOfEmbeddedItem(component, templateDefinition.members);

    React.useEffect(() => {
        window.addEventListener("associated-text-entity-selected", handleEntitySelected);
        window.addEventListener("associated-text-entity-leave", handleEntityLeave);

        return () => {
            window.removeEventListener("associated-text-entity-selected", handleEntitySelected);
            window.removeEventListener("associated-text-entity-leave", handleEntityLeave);
        };
    });

    const componentProperties = ItemTypePropertiesMap[component.type];
    const hasProperties =
        (componentProperties && componentProperties.length) || (component && (component as any).update);

    const handleUpdate = (entityToUpdate: UpdateableEditorEntity) => (property: EntityUpdateProperty) => (value: any) =>
        entityToUpdate.update({ property, ...value });

    const handlePropertyUpdate = (propertyName: string) => (params: ParametersForPropertyUpdate) => {
        const propertyNames = Array.from(new Set([propertyName, params.propertyName]));
        onPropertyUpdate(
            { ...params, perspectiveKey },
            ItemTypePropertiesMap[component.type].filter(({ name }) => propertyNames.includes(name)),
        );
    };

    const handleRevertClick = (propertyName: string) => () =>
        revertOverride(
            propertyName,
            ItemTypePropertiesMap[component.type].filter(name => name === propertyName),
        );

    function renderProperty(property: PropsForProperty) {
        if (property.defaultValue === undefined && !component[property.name]) {
            return null;
        }
        const isOverridden = overriddenProperties.includes(property.name);
        const overrideDisabled = perspectiveKey && property.allowsOverrideEdit !== true;

        const validity: PropertyValidity = invalidProperties[property.name]
            ? { isValid: false, message: invalidProperties[property.name] as string }
            : { isValid: true };
        return (
            <div
                key={`${component.id}-property-${property.name}`}
                className={isOverridden ? "property-overridden" : "property-standard"}
            >
                <Property
                    {...property}
                    readOnly={overrideDisabled || !editEnabled || property.readOnly}
                    component={component}
                    onPropertyUpdate={handlePropertyUpdate(property.name)}
                    setFocus={property.name === focusOn}
                    validity={validity}
                />
                {isOverridden && (
                    <Button
                        className="property-override-revert"
                        iconName="undo"
                        onClick={handleRevertClick(property.name)}
                    />
                )}
            </div>
        );
    }

    function renderEntityProperties(entityUpdate: UpdateableEditorEntity) {
        const updateEntity = handleUpdate(entityUpdate);
        return Object.entries(entityUpdate.editableProperties).map(([key, value]) => (
            <ContentEntityProperty
                key={key}
                editEnabled={editEnabled}
                propertyForEdit={value}
                propertyValue={entityUpdate[value.key]}
                onUpdate={updateEntity(Number(key))}
            />
        ));
    }

    function renderProperties(): ReactNodeArray {
        const entityUpdate = (component as any).update as UpdateableEditorEntity;
        const isKeyValueProperty = (property: PropertyValueProps) =>
            !property.dataAttributes || property.dataAttributes.type !== ComponentDataType.CODE_LIST;
        const isListProperty = (property: PropertyValueProps) =>
            property.dataAttributes?.type === ComponentDataType.CODE_LIST;
        const selectedEntityProperty = selectedEntity ? renderEntityProperties(selectedEntity) : [];
        return entityUpdate && entityUpdate.entityId
            ? renderEntityProperties(entityUpdate)
            : componentProperties
                  .filter(isKeyValueProperty)
                  .filter(property => !property.hasEditor)
                  .map(renderProperty)
                  .concat(selectedEntityProperty)
                  .concat(componentProperties.filter(isListProperty).map(renderProperty));
    }
    const { t } = useTranslation();
    const getInfoBanner = () => {
        if (editEnabled) {
            return null;
        }

        return (
            <InfoBanner iconName="info-notification" iconTitle="info-notification">
                {isMasterEmbeddedComponent
                    ? t("propertyInspector.contentLibraryInfo")
                    : t("propertyInspector.cannotEditInfo")}
            </InfoBanner>
        );
    };
    return (
        <div className="sidebar-right">
            {showHeader && (
                <>
                    <div data-testid="sidebar-properties" className="sidebar-row sidebar-row-header">
                        <span style={{ flex: 1 }}>
                            {t("properties")}
                            <PropertyInspectorIndicators componentId={component.id} />
                        </span>
                        <Button
                            iconName={isPropInspectorExpanded ? "sidebar-right" : "sidebar-left"}
                            iconOnly
                            onClick={togglePropInspectorExpanded}
                        />
                    </div>
                    {getInfoBanner()}
                </>
            )}
            {hasProperties ? (
                renderProperties()
            ) : (
                <div className="sidebar-row sidebar-row-default">{t("propertyInspector.selectComponent")}</div>
            )}
            <div style={{ display: "none" }} data-testid="container-id">
                {component.id}
            </div>
        </div>
    );
};

export default PropertyInspector;
