import { ComponentContainer, PatientDataContext, RunningTemplateContext } from "@emisgroup/clint-templates-common";
import React from "react";
import ComponentRenderer from "./componentRenderer";
import { GO_TO_ITEM_EVENT } from "../constants";
import { initialiseDataItems } from "../utils/componentUtils";

import "./container.scss";

type AssessmentComponentProps = { container: ComponentContainer };
export default function AssessmentContainer({ container }: AssessmentComponentProps) {
    const [currentItemIndex, setCurrentItemIndex] = React.useState(0);
    const item = container.members[currentItemIndex];

    const { templateData, updateTemplateData } = React.useContext(RunningTemplateContext);
    const { queryResults } = React.useContext(PatientDataContext);
    const previousIndicesRef = React.useRef<number[]>([]);
    const handleBack = () => {
        if (previousIndicesRef.current.length > 0) {
            const newIndex = previousIndicesRef.current.pop();
            setCurrentItemIndex(newIndex || 0);
            updateTemplateData(initialiseDataItems([item], queryResults, templateData));
        }
    };

    React.useEffect(() => {
        const handleGoToItem = e => {
            const {
                detail: { id },
            } = e;

            const newIndex = container.members.findIndex(member => member.id === id);
            if (newIndex >= 0) {
                previousIndicesRef.current.push(currentItemIndex);
                setCurrentItemIndex(newIndex);
            }
        };

        window.addEventListener(GO_TO_ITEM_EVENT, handleGoToItem);
        return () => {
            window.removeEventListener(GO_TO_ITEM_EVENT, handleGoToItem);
        };
    }, [container, currentItemIndex]);

    const containerRef = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        if (containerRef.current?.scrollIntoView) {
            containerRef.current.scrollIntoView(true);
        }
    }, [item?.id]);

    if (!item) {
        return null;
    }

    return (
        <div ref={containerRef} className="nhsuk-width-container js-enabled">
            {previousIndicesRef.current.length > 0 && (
                <div className="nhsuk-back-link nhsuk-u-margin-top-4 nhsuk-u-margin-bottom-0">
                    <button
                        data-testid="back-button"
                        type="button"
                        className="nhsuk-back-link__link"
                        onClick={handleBack}
                    >
                        <svg
                            className="nhsuk-icon nhsuk-icon__chevron-left"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            aria-hidden="true"
                            height="24"
                            width="24"
                        >
                            <path d="M8.5 12c0-.3.1-.5.3-.7l5-5c.4-.4 1-.4 1.4 0s.4 1 0 1.4L10.9 12l4.3 4.3c.4.4.4 1 0 1.4s-1 .4-1.4 0l-5-5c-.2-.2-.3-.4-.3-.7z" />
                        </svg>
                        Back
                    </button>
                </div>
            )}

            <main className="nhsuk-main-wrapper--s" id="maincontent" role="main">
                <ComponentRenderer component={item} />
            </main>
        </div>
    );
}
