import * as React from "react";
import { IApplicationContext } from "@emisgroup/single-spa-state-management";
import { getAppStore } from "./store/AppStore";
import { StateAndRouterProvider } from "./StateAndRouterProvider";
import AppLocalWithAuth from "./AppLocalWithAuth";
import { IntlProvider } from "./IntlProvider";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authconfig";

/**
 * Responsible for rendering the component as per route path
 */
export const AppLocal: React.FC = () => {
    const appStore = getAppStore({} as IApplicationContext);
    const { store } = appStore;

    const msalInstance = new PublicClientApplication(msalConfig);

    return (
        <IntlProvider>
            <StateAndRouterProvider appStore={appStore}>
                <MsalProvider instance={msalInstance}>
                    <AppLocalWithAuth {...store} />
                </MsalProvider>
            </StateAndRouterProvider>
        </IntlProvider>
    );
};

export default AppLocal;
