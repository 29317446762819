import React from "react";
import {
    AppModeContext,
    TemplateContext,
    findCanvasItemParent,
    addRemoveUpdateItemInContainer,
    isDescendantOfEmbeddedItem,
    AppMode,
    CanvasItem,
    ComponentType,
    ComponentContainer,
    ItemClickProps,
    Panel,
    ClinicalContentComponent,
    ConfigContext,
    USE_TEMPLATE_PICKER,
} from "@emisgroup/clint-templates-common";
import { useTranslation } from "@emisgroup/application-intl";
import Sidebar from "./uiComponents/sidebar";
import Inspectors from "./uiComponents/inspectors";
import RibbonBar from "./uiComponents/ribbonBar";
import Canvas from "./canvas";
import { ContentLibraryStore, ParametersForPropertyUpdate, SelectionMode } from "./types";
import "./app.css";
import { getDisplayColumnIndexForItem } from "./utils/columnUtils";
import { isItemInGroup } from "./utils/ruleUtils";
import {
    getUpdatedContainerMembers,
    itemFinder,
    deleteCanvasItem,
    canDeleteCanvasItemFromContainer,
    getCanvasItemIdsWithNested,
} from "./utils/componentUtils";
import { ContentLibraryContext } from "./context/contentLibrary";
import { TemplatePickerContext } from "./context/templatePicker";
import AppContainer from "./appContainer";
import TemplatePicker from "./contentLibrary/templatePicker";
import { isItemEmbedded } from "./utils/embeddingUtils";
import DragLayer from "./dragLayer";
import NoTemplateCanvas from "./uiComponents/noTemplateCanvas";
import { navigateTo } from "./utils/domUtils";
import useFullTemplateDefinition from "./uiComponents/useFullTemplateDefinition";
import { DesignLayoutProvider } from "./context/designLayoutContext";
import { supportsConditionalPanel, addConditionalPanel } from "./utils/conditionalPanelUtils";

type AppProps = {
    useMockPatientSelect: boolean;
    redirectUri?: string;
};

function App({ useMockPatientSelect, redirectUri }: AppProps) {
    const {
        templateDefinition,
        setTemplateDefinition,
        groupedItems,
        setGroupedItems,
        selectedItem,
        setSelectedItem,
        containerOfSelectedItem,
        invalidComponentDefinitionIds,
        setInvalidComponentDefinitionIds,
    } = React.useContext(TemplateContext);
    const { t } = useTranslation();
    const { members: templateItems, columnCount: numberOfColumns } = templateDefinition;
    const { loadFromContentLibrary } = React.useContext(ContentLibraryContext);
    const { isTemplatePickerShowing, isTemplatePickerShowingInDialog, showTemplatePicker } =
        React.useContext(TemplatePickerContext);
    const { updateMode: changeMode, allowedModes } = React.useContext(AppModeContext);
    const { getFullTemplateDefinition, view: fullTemplateDefinitionView } = useFullTemplateDefinition();

    const selectionMode = React.useRef<SelectionMode>(SelectionMode.SINGLE);

    function setMultipleSelectionMode() {
        selectionMode.current = SelectionMode.MULTIPLE;
    }

    function setSingleSelectionMode() {
        selectionMode.current = SelectionMode.SINGLE;
    }

    const groupedItemsIndices = React.useMemo(() => {
        if (groupedItems.length === 0) {
            return [];
        }
        const groupedItemsContainer =
            containerOfSelectedItem || findCanvasItemParent(groupedItems[0].id, templateDefinition.members);
        const whereToLook = groupedItemsContainer
            ? groupedItemsContainer.members
            : templateItems.filter(
                  ({ columnIndex }) =>
                      typeof groupedItems[0].columnIndex === "undefined" || columnIndex === groupedItems[0].columnIndex,
              );
        return whereToLook.reduce(
            (indexList, templateItem, index) =>
                groupedItems.find(itemFinder(templateItem)) ? indexList.concat(index) : indexList,
            [] as number[],
        );
    }, [templateItems, groupedItems, numberOfColumns]);

    function addToGroupSelection(...items) {
        const groupedItemContainer =
            containerOfSelectedItem || findCanvasItemParent(items[0].id, templateDefinition.members);
        const whereToLook =
            groupedItemContainer && groupedItemContainer.id ? groupedItemContainer.members : templateItems;
        setGroupedItems(
            groupedItems.concat(items).sort((a, b) => {
                return (
                    whereToLook.findIndex(item => item.id === a.id) - whereToLook.findIndex(item => item.id === b.id)
                );
            }),
        );
    }

    function removeFromGroupSelection(item) {
        setGroupedItems(groupedItems.filter(groupedItem => groupedItem.id !== item.id));
    }

    function deselectCanvasItem() {
        setSelectedItem({} as CanvasItem);
    }

    function canAddToMultipleSelection(item) {
        const itemsToAddTo = selectedItem.id ? [selectedItem] : groupedItems;
        return (
            !isDescendantOfEmbeddedItem(item, templateItems) &&
            (itemsToAddTo.length === 0 ||
                itemsToAddTo
                    .map(groupedItem => getDisplayColumnIndexForItem(groupedItem, numberOfColumns))
                    .every(columnIndex => columnIndex === getDisplayColumnIndexForItem(item, numberOfColumns)))
        );
    }

    function multiSelectWhereToLook(
        panelWithClickedItem: ComponentContainer | undefined,
        doesGroupedSelectionExist: boolean,
    ): CanvasItem[] {
        return panelWithClickedItem
            ? panelWithClickedItem.members
            : templateItems.filter(
                  ({ columnIndex }) =>
                      !doesGroupedSelectionExist ||
                      typeof groupedItems[0].columnIndex === "undefined" ||
                      columnIndex === groupedItems[0].columnIndex,
              );
    }

    function multiSelectWithCanvasSelectionIsNewItemInRange(
        whereToLook: CanvasItem[],
        panelWithClickedItem: ComponentContainer | undefined,
        newItemPosition: number,
        item: CanvasItem,
    ): boolean {
        const selectedCanvasItemPosition = whereToLook.findIndex(itemFinder(selectedItem));
        if (containerOfSelectedItem) {
            return Boolean(
                panelWithClickedItem &&
                    containerOfSelectedItem.id === panelWithClickedItem.id &&
                    Math.abs(selectedCanvasItemPosition - newItemPosition) === 1,
            );
        }
        if (panelWithClickedItem) {
            return false;
        }
        if (item !== selectedItem) {
            return Math.abs(selectedCanvasItemPosition - newItemPosition) === 1;
        }
        return true;
    }

    function multiSelectWithGroupedSelectionIsNewItemInRange(
        isItemInGroupSelection: boolean,
        panelWithClickedItem: ComponentContainer | undefined,
        newItemPosition: number,
    ): boolean {
        const startPosition = Math.min(...groupedItemsIndices);
        const endPosition = Math.max(...groupedItemsIndices);
        if (isItemInGroupSelection) {
            return newItemPosition === startPosition || newItemPosition === endPosition;
        }
        const panelWithGroupedItems = findCanvasItemParent(groupedItems[0].id, templateDefinition.members);
        if (panelWithGroupedItems && panelWithClickedItem) {
            if (panelWithGroupedItems.id === panelWithClickedItem.id) {
                return startPosition - newItemPosition === 1 || newItemPosition - endPosition === 1;
            }
            return false;
        }
        if (!(panelWithClickedItem || panelWithGroupedItems)) {
            return startPosition - newItemPosition === 1 || newItemPosition - endPosition === 1;
        }
        return false;
    }

    function multiSelectCanAddToExistingGroup(
        doesGroupedSelectionExist: boolean,
        isItemInGroupSelection: boolean,
        isNewItemInRange: boolean,
    ): boolean {
        return doesGroupedSelectionExist && !isItemInGroupSelection && isNewItemInRange;
    }

    function multiSelectPerformSelectAction(
        doesGroupedSelectionExist: boolean,
        isItemInGroupSelection: boolean,
        isNewItemInRange: boolean,
        doesCanvasSelectionExist: boolean,
        item: CanvasItem,
    ) {
        if (multiSelectCanAddToExistingGroup(doesGroupedSelectionExist, isItemInGroupSelection, isNewItemInRange)) {
            addToGroupSelection(item);
        } else if (!doesGroupedSelectionExist) {
            let itemsToAdd: CanvasItem[] = [];
            if (doesCanvasSelectionExist) {
                if (isNewItemInRange) {
                    itemsToAdd = itemsToAdd.concat(selectedItem);
                }
                deselectCanvasItem();
            }
            itemsToAdd = isNewItemInRange ? itemsToAdd.concat(item) : itemsToAdd;
            if (itemsToAdd.length) {
                addToGroupSelection(...itemsToAdd);
            }
        } else if (isItemInGroupSelection) {
            if (isNewItemInRange) {
                removeFromGroupSelection(item);
            }
        } else {
            deselectCanvasItem();
        }
    }

    function handleMultipleSelectionMode(item: CanvasItem) {
        const doesGroupedSelectionExist = groupedItems.length !== 0;
        const panelWithClickedItem = findCanvasItemParent(item.id, templateDefinition.members);
        const whereToLook = multiSelectWhereToLook(panelWithClickedItem, doesGroupedSelectionExist);
        const doesCanvasSelectionExist = Boolean(selectedItem.id);
        const newItemPosition = whereToLook.findIndex(itemFinder(item));

        let isItemInGroupSelection = false;
        let isNewItemInRange = true;
        if (doesCanvasSelectionExist) {
            isNewItemInRange = multiSelectWithCanvasSelectionIsNewItemInRange(
                whereToLook,
                panelWithClickedItem,
                newItemPosition,
                item,
            );
        } else if (doesGroupedSelectionExist) {
            isItemInGroupSelection = isItemInGroup(item, groupedItems);
            isNewItemInRange = multiSelectWithGroupedSelectionIsNewItemInRange(
                isItemInGroupSelection,
                panelWithClickedItem,
                newItemPosition,
            );
        }

        multiSelectPerformSelectAction(
            doesGroupedSelectionExist,
            isItemInGroupSelection,
            isNewItemInRange,
            doesCanvasSelectionExist,
            item,
        );
    }

    function processItemClick({ evt, item }: ItemClickProps) {
        if (evt && (evt.shiftKey || evt.ctrlKey || evt.metaKey) && canAddToMultipleSelection(item)) {
            setMultipleSelectionMode();
        } else {
            setSingleSelectionMode();
        }
        if (!item || !item.id) {
            deselectCanvasItem();
            setGroupedItems([]);
            return;
        }
        if (selectionMode.current === SelectionMode.SINGLE) {
            if (item.id !== selectedItem.id) {
                setSelectedItem(item);
            }
            setGroupedItems([]);
        } else {
            handleMultipleSelectionMode(item);
        }
    }

    const deleteSelectedItem = () => {
        // Tech debt: Clinical content entity deletion acts on the clinical content component so requires
        //            the parent container members of the clinical content component. This should pass the container
        //            to have the deletion acted on it (either removing members or content in the case of clinical components)
        //            but this would need getUpdatedContainerMembers refactoring.

        const canvasItemIdsToDelete = getCanvasItemIdsWithNested(selectedItem);
        setInvalidComponentDefinitionIds(
            invalidComponentDefinitionIds.filter(id => !canvasItemIdsToDelete.includes(id)),
        );

        const containerToDeleteFrom =
            (selectedItem.type === ComponentType.CLINICAL_CONTENT_ENTITY
                ? findCanvasItemParent(
                      (containerOfSelectedItem as ClinicalContentComponent).id,
                      templateDefinition.members,
                  )
                : containerOfSelectedItem) || templateDefinition;
        const updatedItems = deleteCanvasItem(selectedItem, containerToDeleteFrom.members);

        setTemplateDefinition({
            ...templateDefinition,
            members: containerToDeleteFrom
                ? getUpdatedContainerMembers(templateDefinition, containerToDeleteFrom.id, updatedItems)
                : updatedItems,
        } as ComponentContainer);

        deselectCanvasItem();
    };

    const updateItems = (items: CanvasItem[]) => {
        let updatedDefinition = templateDefinition;
        // eslint-disable-next-line no-restricted-syntax
        for (const item of items) {
            updatedDefinition =
                item.id === templateDefinition.id
                    ? (item as ComponentContainer)
                    : addRemoveUpdateItemInContainer(updatedDefinition, item, "update");
        }
        setTemplateDefinition(updatedDefinition);
        const [itemToSelect] = items;
        setSelectedItem(itemToSelect);
    };

    const updateItemProperty = ({ item, propertyName, propertyValue }: ParametersForPropertyUpdate) =>
        updateItems([
            {
                ...item,
                [propertyName]: propertyValue,
            },
        ]);

    const isRootContainerSelected = selectedItem.type === ComponentType.TEMPLATE;

    const ungroupPanel = () => {
        const selectedPanel = selectedItem as Panel;
        const membersToUpdate = containerOfSelectedItem ? containerOfSelectedItem.members : templateItems;
        const itemIndex = membersToUpdate.findIndex(item => item.id === selectedPanel.id);
        const panelMembers = selectedPanel.members.map(item => {
            return {
                ...item,
                columnIndex: selectedPanel.columnIndex,
            } as CanvasItem;
        });
        const updatedMembers = [
            ...membersToUpdate.slice(0, itemIndex),
            ...panelMembers,
            ...membersToUpdate.slice(itemIndex + 1),
        ];
        setTemplateDefinition({
            ...templateDefinition,
            members: getUpdatedContainerMembers(
                templateDefinition,
                containerOfSelectedItem?.id || templateDefinition.id,
                updatedMembers,
            ),
        });
        deselectCanvasItem();
    };

    const handleAddConditionalPanel = () => {
        const [updatedTemplate, conditionalPanel] = addConditionalPanel(templateDefinition, selectedItem as Panel, t);
        setTemplateDefinition(updatedTemplate);
        setSelectedItem(conditionalPanel);
    };

    const templateHasId = Boolean(templateDefinition.id);

    const isSelectedItemEmbedded = React.useMemo(
        () => isItemEmbedded(selectedItem, templateItems),
        [selectedItem, templateItems],
    );

    const isSelectedItemDescendantOfEmbedded = React.useMemo(
        () => isDescendantOfEmbeddedItem(selectedItem, templateItems),
        [selectedItem, templateItems],
    );
    const onTemplateSelected = async (contentLibraryStore: ContentLibraryStore, definition: ComponentContainer) => {
        const fullDefinition = await getFullTemplateDefinition(definition);
        loadFromContentLibrary({ ...contentLibraryStore, template: fullDefinition });
    };
    const switchToTemplatePicker = () => showTemplatePicker(onTemplateSelected);
    const navigateToRedirect = () => {
        navigateTo((redirectUri?.length ?? 0) > 0 ? redirectUri : (process.env.APP_DEFAULT_REDIRECT as string));
    };
    const { features } = React.useContext(ConfigContext);
    const switchToLoadMode = features[USE_TEMPLATE_PICKER] ? switchToTemplatePicker : navigateToRedirect;

    if (isTemplatePickerShowing) {
        return (
            <AppContainer>
                <TemplatePicker />
            </AppContainer>
        );
    }

    const canDelete =
        Boolean(selectedItem && selectedItem.id) &&
        !isRootContainerSelected &&
        canDeleteCanvasItemFromContainer(containerOfSelectedItem || templateDefinition) &&
        !isSelectedItemDescendantOfEmbedded;

    const canAddConditionalPanel =
        Boolean(selectedItem && selectedItem.id) &&
        supportsConditionalPanel(selectedItem.type) &&
        !isSelectedItemDescendantOfEmbedded;

    return (
        <>
            <DragLayer />
            <AppContainer>
                <RibbonBar
                    useMockPatientSelect={useMockPatientSelect}
                    templateHasId={templateHasId}
                    onDelete={deleteSelectedItem}
                    allowedModes={allowedModes}
                    deleteEnabled={canDelete}
                    switchToLoadMode={switchToLoadMode}
                    switchToEditMode={() => changeMode(AppMode.EDIT)}
                    switchToRunMode={() => changeMode(AppMode.RUN)}
                    switchToReadMode={() => changeMode(AppMode.READ)}
                    conditionalPanelEnabled={canAddConditionalPanel}
                    addConditionalPanel={handleAddConditionalPanel}
                    groupToPanelEnabled={Boolean(groupedItems.length)}
                    ungroupPanelEnabled={
                        selectedItem.type === ComponentType.PANEL &&
                        !isSelectedItemEmbedded &&
                        (selectedItem as Panel).members.length > 0
                    }
                    ungroupPanel={ungroupPanel}
                    closeRedirectUri={redirectUri}
                />
                <div className="app-main">
                    {templateHasId && (
                        <DesignLayoutProvider>
                            <Sidebar />
                            <Canvas onSelect={processItemClick} onPropertyUpdate={updateItemProperty} />
                            <Inspectors
                                isPropertyInspectorEditEnabled={!isSelectedItemEmbedded}
                                isRuleEditorEditEnabled={!isSelectedItemDescendantOfEmbedded}
                                onItemsUpdate={updateItems}
                            />
                        </DesignLayoutProvider>
                    )}
                    {!templateHasId && <NoTemplateCanvas switchToLoadMode={switchToLoadMode} />}
                </div>
                {fullTemplateDefinitionView}
                {isTemplatePickerShowingInDialog && <TemplatePicker showInDialog />}
            </AppContainer>
        </>
    );
}

export default App;
