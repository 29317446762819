import { ComponentType } from "@emisgroup/clint-templates-common";
import { XYCoord } from "react-dnd";
import { isItemAncestorOf } from "./componentUtils";

const INDICATOR_HEIGHT = 4;
const FIRST_OR_LAST_INDICATOR_HEIGHT = INDICATOR_HEIGHT * 2;
const ACTIVE_INDICATOR_HEIGHT_DELTA = 24;
const CONTAINER_DROP_IN_INDICATOR_HEIGHT = 0;
const EMPTY_CONTAINER_DROP_IN_INDICATOR_HEIGHT = 36;
const TAB_INDICATOR_WIDTH = 2;
const ACTIVE_TAB_INDICATOR_WIDTH_DELTA = 20;

const inPixels = (pixels: number): string => {
    return `${pixels}px`;
};

export const DROP_BEFORE_INDEX = 0;
export const COMPONENT_INDEX = 1;
export const DROP_AFTER_INDEX = 2;

export enum HoverTarget {
    NONE,
    FIRST_HALF_COMPONENT,
    SECOND_HALF_COMPONENT,
}

export const getHoverTarget = (
    component: Element | undefined,
    hoverOffset: XYCoord | null,
    isVerticalDrag: boolean,
): HoverTarget => {
    if (component && hoverOffset) {
        const componentRect = component.getBoundingClientRect();

        const componentMiddle = isVerticalDrag
            ? (componentRect.bottom - componentRect.top) / 2
            : (componentRect.right - componentRect.left) / 2;

        const componentHoverOffset = isVerticalDrag
            ? hoverOffset.y - componentRect.top
            : hoverOffset.x - componentRect.left;

        return componentHoverOffset > componentMiddle
            ? HoverTarget.SECOND_HALF_COMPONENT
            : HoverTarget.FIRST_HALF_COMPONENT;
    }
    return HoverTarget.NONE;
};

export const getDropIndicatorHeight = (isActive: boolean, isFirstOrLast: boolean): string => {
    return inPixels(
        (isFirstOrLast ? FIRST_OR_LAST_INDICATOR_HEIGHT : INDICATOR_HEIGHT) +
            (isActive ? ACTIVE_INDICATOR_HEIGHT_DELTA : 0),
    );
};

export const getContainerDropInIndicatorHeight = (isActive: boolean, isEmpty: boolean): string => {
    return inPixels(
        (isEmpty ? EMPTY_CONTAINER_DROP_IN_INDICATOR_HEIGHT : CONTAINER_DROP_IN_INDICATOR_HEIGHT) +
            (isActive ? ACTIVE_INDICATOR_HEIGHT_DELTA : 0),
    );
};

export const getTabDropIndicatorWidth = (isActive: boolean): string => {
    return inPixels(TAB_INDICATOR_WIDTH + (isActive ? ACTIVE_TAB_INDICATOR_WIDTH_DELTA : 0));
};

export const canDropItemInto = (itemBeingDropped: any, itemBeingDroppedInto: any): boolean => {
    if (itemBeingDropped.id === itemBeingDroppedInto.id || isItemAncestorOf(itemBeingDropped, itemBeingDroppedInto))
        return false;

    return itemBeingDropped.type !== ComponentType.TAB_PAGE;
};

export const isDragWithinEditableContent = (dragOffset: XYCoord | null): boolean => {
    if (dragOffset && document.elementFromPoint) {
        const elementAtDragPosition = document.elementFromPoint(dragOffset.x, dragOffset.y);
        return Boolean((elementAtDragPosition as HTMLElement)?.isContentEditable);
    }
    return false;
};
