import * as React from "react";
import { ComponentContainer, ComponentType } from "@emisgroup/clint-templates-common";
// eslint-disable-next-line import/no-cycle
import CanvasColumn from "./canvasColumn";
import { getDisplayColumnIndexForItem } from "./utils/columnUtils";

type CanvasColumnsProps = {
    component: ComponentContainer;
    hasCustomActionButtons?: boolean;
};

const CanvasColumns = ({ component, hasCustomActionButtons = false }: CanvasColumnsProps) => {
    const { columnCount, members } = component;
    const columns = new Array(columnCount).fill(null).map((_, index) => index);
    const getItemsInColumn = React.useCallback(
        (columnIndex: number) =>
            members.filter(item => getDisplayColumnIndexForItem(item, columnCount) === columnIndex),
        [members, columnCount],
    );

    return (
        <>
            {columns.map(columnIndex => (
                <CanvasColumn
                    key={columnIndex}
                    columnIndex={columnIndex}
                    items={getItemsInColumn(columnIndex)}
                    isRootTemplate={component.type === ComponentType.TEMPLATE}
                    hasCustomActionButtons={hasCustomActionButtons}
                />
            ))}
        </>
    );
};

export default CanvasColumns;
