import * as React from "react";
import {
    PickingListComponent,
    PickingListOption,
    RunningTemplateContext,
    TemplateDataItem,
} from "@emisgroup/clint-templates-common";

type MultiSelectPickingListProps = {
    pickingList: PickingListComponent;
    dataItem: TemplateDataItem;
};

export default function MultiSelectPickingList({ pickingList, dataItem }: MultiSelectPickingListProps) {
    const { templateData, updateTemplateData } = React.useContext(RunningTemplateContext);
    const selectedItems = dataItem && dataItem.items ? dataItem.items : [];

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let updatedItems;
        if (e.currentTarget.checked) {
            const selectedOption = pickingList.options.find(({ id }) => id === e.currentTarget.value);
            const itemWithValue = selectedItems.find(
                ({ value }) => (value as PickingListOption)?.id === e.currentTarget.value,
            );
            updatedItems = [
                ...selectedItems.filter(({ value }) => value !== e.currentTarget.value),
                itemWithValue ? { ...itemWithValue, selected: true } : { value: selectedOption, selected: true },
            ];
        } else {
            updatedItems = selectedItems.filter(
                ({ value }) => (value as PickingListOption)?.id !== e.currentTarget.value,
            );
        }

        updateTemplateData({
            ...templateData,
            [pickingList.id]: {
                ...dataItem,
                items: updatedItems,
                interacted: true,
            },
        });
    };

    const handleBlur = () => {
        updateTemplateData({
            ...templateData,
            [pickingList.id]: { ...dataItem, interacted: true },
        });
    };

    return (
        <div className="nhsuk-checkboxes">
            {pickingList.options.map((option, index) => (
                <div key={option.id} className="nhsuk-checkboxes__item">
                    <input
                        className="nhsuk-checkboxes__input"
                        id={`${pickingList.id}-option-${index}`}
                        type="checkbox"
                        checked={selectedItems?.map(item => (item.value as PickingListOption)?.id).includes(option.id)}
                        value={option.id}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <label
                        className="nhsuk-label nhsuk-checkboxes__label"
                        htmlFor={`${pickingList.id}-option-${index}`}
                    >
                        {option.text}
                    </label>
                </div>
            ))}
        </div>
    );
}
