import * as React from "react";

const AppContainer = ({ children }) => {
    const containerDiv = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        const resize = () => {
            const appHeader = document.getElementsByClassName("app-header");
            if (appHeader.length > 0) {
                (containerDiv.current as HTMLDivElement).style.height = `${
                    document.documentElement.clientHeight - appHeader[0].getBoundingClientRect().height
                }px`;
                return;
            }

            // If there's no app header we're probably in a dev environment with just a logout button for a header
            const logoutButton = document.getElementById("logout-button");
            if (logoutButton) {
                (containerDiv.current as HTMLDivElement).style.height = `${
                    document.documentElement.clientHeight - logoutButton.getBoundingClientRect().height
                }px`;
            }
        };

        resize();
        window.addEventListener("resize", resize);
        return () => window.removeEventListener("resize", resize);
    }, []);

    return (
        <div ref={containerDiv} className="template-builder-app" data-testid="content-picker">
            {children}
        </div>
    );
};

export default AppContainer;
