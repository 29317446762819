import * as React from "react";
import DynamicTabBar from "./dynamicTabBar";

type DynamicTabContainerProps = {
    id?: string;
    className?: string;
    children?: React.ReactNode | React.ReactNodeArray;
    content?: React.ReactNode;
    onSelect?: (evt: MouseEvent) => void;
};

const DynamicTabContainer = ({ id = "", className = "", children, content, onSelect }: DynamicTabContainerProps) => {
    const handleClick = evt => {
        if (onSelect) {
            onSelect(evt);
        }
        evt.stopPropagation();
    };

    return (
        <div className={`tab-container ${className}`} data-testid={`tab-${id}`} role="none" onClick={handleClick}>
            <DynamicTabBar dynamicTabId={id}>{children}</DynamicTabBar>

            <div className="tab-content">{content}</div>
        </div>
    );
};

export default DynamicTabContainer;
