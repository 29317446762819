import * as React from "react";
import { FormElement } from "@emisgroup/ui-kit-react";
import { IPerspective } from "@emisgroup/clint-templates-perspectives";
import { AppMode, ComponentType, MouseEventHandler, DataFromTemplateRun, Code, DataPropertyValidity } from "../types";
import ComponentEffectiveDate from "./componentEffectiveDate";
import ComponentAssociatedText from "./componentAssociatedText";
import AppModeContext from "../context/appMode";
import AdditionalDataEntryComponent from "./additionalDataEntryComponent";
import Component from "./component";
import CodeHistory from "./codeHistory";
import { applyAdditionalDataPropertyUpdate } from "../utils";
import { RunningTemplateContext, TemplateHistoryContext } from "../context";
import InvalidPropertyIndicator from "./invalidPropertyIndicator";
import ComponentInfo from "./componentInfo";

type DataEntryComponentProps = {
    children: React.ReactNode | React.ReactNodeArray;
    id: string;
    type: ComponentType;
    label: string;
    isSelected: boolean;
    isMandatory?: boolean;
    arePropertiesValid: boolean;
    areValuesValid?: boolean;
    hasAssociatedText?: boolean;
    associatedText?: any;
    hasDatePrompt?: boolean;
    futureDateAllowed?: boolean;
    code?: Code;
    codes?: Code[];
    className?: string;
    excludeHistory?: boolean;
    tooltip?: string;
    perspectives?: IPerspective[];
    onSelect: MouseEventHandler;
    onChange: (data: DataFromTemplateRun) => void;
    onPropertyUpdate: (params: { propertyName: string; propertyValue: any }) => void;
};

const DataEntryComponent = (props: DataEntryComponentProps) => {
    const [showingHistoryList, setShowingHistoryList] = React.useState(false);
    const {
        children,
        id,
        type,
        label,
        isSelected,
        isMandatory,
        arePropertiesValid,
        areValuesValid,
        onSelect,
        code,
        codes,
        tooltip,
        className = "",
    } = props;
    const { mode } = React.useContext(AppModeContext);
    const { templateData = {} } = React.useContext(RunningTemplateContext);
    const { getHistoryKey } = React.useContext(TemplateHistoryContext);
    const [valuePropertiesValidity, setValuePropertValidity] = React.useState<DataPropertyValidity[]>([]);
    const invalidClass = arePropertiesValid ? "" : " invalid";

    if (mode !== AppMode.RUN) {
        return (
            <div
                className={`canvas-item${isSelected ? " selected" : ""} data-entry${invalidClass} ${className}`}
                role="none"
                onClick={onSelect}
                data-testid={`component-${id}`}
                key={`component-${id}`}
            >
                {!arePropertiesValid && <InvalidPropertyIndicator />}
                <FormElement className={`${isSelected ? "" : "not-selected "}design-mode`} mandatory={isMandatory}>
                    <Component {...props} excludeHistory>
                        {children}
                    </Component>
                </FormElement>
                <ComponentEffectiveDate {...props} />
                {isSelected && <ComponentAssociatedText {...props} />}
                <ComponentInfo {...props} />
            </div>
        );
    }

    const toggleShowingHistoryList = () => setShowingHistoryList(!showingHistoryList);
    const historyKey = showingHistoryList
        ? getHistoryKey(id, type, code?.emisCodeId || codes?.map(({ emisCodeId }) => emisCodeId))
        : null;

    const updatePropertyValidity = (propertyName: string, isValid: boolean) => {
        const updatedValuePropertiesValidities = valuePropertiesValidity
            .filter(v => v.propertyName !== propertyName)
            .concat({ propertyName, isValid });

        setValuePropertValidity(updatedValuePropertiesValidities);
    };

    const isAllDataValid: boolean =
        (areValuesValid ?? true) &&
        !valuePropertiesValidity.some(valuePropertyValidity => !valuePropertyValidity.isValid);

    return (
        <div title={tooltip} className="canvas-item data-entry" data-testid={`component-${id}`}>
            <FormElement mandatory={isMandatory}>
                <Component
                    {...props}
                    areValuesValid={isAllDataValid}
                    toggleShowingHistoryList={toggleShowingHistoryList}
                >
                    {children}
                    <AdditionalDataEntryComponent
                        {...props}
                        applyPropertyUpdate={applyAdditionalDataPropertyUpdate(templateData[id])}
                        updatePropertyValidity={updatePropertyValidity}
                    />
                    {showingHistoryList && historyKey && (
                        <div className="canvas-item-history-list">
                            <CodeHistory
                                title={code?.term || label}
                                historyKey={historyKey}
                                onClose={toggleShowingHistoryList}
                            />
                        </div>
                    )}
                </Component>
            </FormElement>
        </div>
    );
};

export default DataEntryComponent;
