import { AuthContext } from "@emisgroup/clint-templates-common";
import * as React from "react";

type AuthProviderProps = { children: React.ReactNode | React.ReactNodeArray; getBearerToken: () => Promise<string> };

const AuthProvider = ({ children, getBearerToken }: AuthProviderProps) => (
    <AuthContext.Provider value={{ getBearerToken }}>{children}</AuthContext.Provider>
);

export default AuthProvider;
