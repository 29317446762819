import * as React from "react";
import { Engine } from "json-rules-engine";
import {
    CanvasItem,
    ComponentContainer,
    RunningTemplateContext,
    PatientDataContext,
    ComponentConfigDataContext,
} from "@emisgroup/clint-templates-common";
import { evaluateRules, createEngine } from "./utils/ruleEngineUtils";
import { buildRunTimeTemplateItems, updateNewlyVisibleComponents } from "./utils/componentUtils";
import { RENDERED_ITEMS_UPDATE_EVENT } from "./constants";

type Params = { templateDefinition: ComponentContainer; queryApiUrl: string; getBearerToken: () => Promise<string> };
export default function useRuleEvaluation({ templateDefinition, queryApiUrl, getBearerToken }: Params) {
    const { isWaitingForPatientSelection, patientId } = React.useContext(PatientDataContext);
    const { templateData, updateTemplateData } = React.useContext(RunningTemplateContext);
    const { componentsConfig } = React.useContext(ComponentConfigDataContext);
    const { members: templateItems = [] } = templateDefinition;
    const [visibilityRulesError, setVisibilityRulesError] = React.useState(false);

    const engine = isWaitingForPatientSelection
        ? new Engine()
        : createEngine({ templateData, queryApiUrl, patientId, getBearerToken });

    const [itemsToRender, setItemsToRender] = React.useState<Array<CanvasItem>>([]);

    const updateItemsToRender = (updatedItems: CanvasItem[]) => {
        setItemsToRender(updatedItems);
        window.dispatchEvent(
            new CustomEvent(RENDERED_ITEMS_UPDATE_EVENT, {
                detail: { items: updatedItems },
            }),
        );
    };

    const visibleComponentIds = React.useRef<string[]>([]);
    const evaluate = React.useCallback(
        () =>
            evaluateRules({ engine, container: templateDefinition, templateData }).then(result => {
                if (result.success) {
                    const runTimeTemplateItems = buildRunTimeTemplateItems({
                        allowedIds: result.itemIds,
                        container: templateDefinition,
                    });
                    updateItemsToRender([...runTimeTemplateItems]);
                    const newlyVisibleIds = result.itemIds.filter(id => !visibleComponentIds.current.includes(id));
                    if (newlyVisibleIds.length) {
                        updateTemplateData(
                            updateNewlyVisibleComponents({
                                container: templateDefinition,
                                newlyVisibleIds,
                                templateData,
                            }),
                        );
                    }
                    visibleComponentIds.current = result.itemIds;
                    setVisibilityRulesError(false);
                } else {
                    visibleComponentIds.current = [];
                    setVisibilityRulesError(true);
                }
            }),
        [templateDefinition, templateItems, templateData, patientId, componentsConfig],
    );

    const clearVisibilityRulesErrors = () => setVisibilityRulesError(false);

    return { evaluate, itemsToRender, visibilityRulesError, clearVisibilityRulesErrors };
}
