import { TriangleDown, TriangleRight } from "@emisgroup/icons-react";
import React from "react";
import { TreeViewNode } from "./treeViewNode";
import { NodeIcons, TreeNodeData } from "./types";
import "./tree.scss";

const defaultShowRootNode = true;
const defaultIndentation = 32;

type TreeViewProps = {
    "data-testid"?: string;
    className?: string;
    id?: string;
    onNodeSelect?: (node: TreeNodeData) => void;
    onNodeDoubleClick?: (node: TreeNodeData) => void;
    indentation?: number;
    rootNode: TreeNodeData;
    showRootNode?: boolean;
    selectNodeId?: string;
    showNodeId?: string;
    emptyWarning?: string;
};

const TreeView: React.FC<TreeViewProps> = ({
    "data-testid": dataTestId,
    className,
    id,
    onNodeSelect,
    onNodeDoubleClick,
    indentation = defaultIndentation,
    rootNode,
    showRootNode = defaultShowRootNode,
    selectNodeId,
    showNodeId,
    emptyWarning,
}: TreeViewProps): JSX.Element => {
    const [selectedNodeId, setSelectedNodeId] = React.useState(selectNodeId || "");

    const getIcons = (): NodeIcons => {
        return {
            expandedNodeIconElement: <TriangleDown color="primary-dark" size="x-small" title="Expand" />,
            collapsedNodeIconElement: <TriangleRight color="primary-dark" size="x-small" title="Collapse" />,
            leafIconElement: <span className="eui-tree__leaf-icon">&bull;</span>,
        };
    };

    const handleNodeSelect = (node: TreeNodeData): void => {
        if (onNodeSelect) {
            onNodeSelect(node);
        }
        setSelectedNodeId(node.nodeId);
    };

    const handleNodeDoubleClick = (node: TreeNodeData): void => {
        if (onNodeDoubleClick) {
            onNodeDoubleClick(node);
        }
    };

    const createNode = (node: TreeNodeData): JSX.Element => {
        return (
            <TreeViewNode
                depth={0}
                icons={getIcons()}
                key={node.nodeId}
                node={node}
                onNodeSelect={handleNodeSelect}
                onNodeDoubleClick={handleNodeDoubleClick}
                indentation={indentation}
                selectedNodeId={selectedNodeId}
                showNodeId={showNodeId}
            />
        );
    };

    return (
        <>
            {emptyWarning && (rootNode.children?.length ?? 0) === 0 && (
                <div className="eui-tree__empty-warning">{emptyWarning}</div>
            )}

            <ul className={`eui-tree eui-tree__structure ${className}`} data-testid={dataTestId} id={id}>
                {showRootNode ? createNode(rootNode) : rootNode.children?.map(childNode => createNode(childNode))}
            </ul>
        </>
    );
};

export default TreeView;
