import { ActionData, ConfigContext, PERSPECTIVES, PerspectiveSetting } from "@emisgroup/clint-templates-common";
import { getDefaultDimensionValuesForSetting } from "@emisgroup/clint-templates-perspectives";
import { RunStyle } from "@emisgroup/clint-templates-runner";
import React, { ReactNode } from "react";
import { addToLocalStorage, getFromLocalStorage } from "../utils/localStorageUtils";

const RUN_STYLE_STORAGE_KEY = "RUN_STYLE";

export enum TestRunMode {
    Standard,
    Compact,
}

const defaultTestRunMode = TestRunMode.Standard;
const defaultRunStyle = RunStyle.Clinical;
const defaultActionData = { dateCompleted: "", items: [] };

type TestRunModeContextType = {
    actionData: ActionData;
    mode: TestRunMode;
    runStyle: RunStyle;
    runDimensionValues: string[];
    updateMode: (mode: TestRunMode) => void;
    updateRunStyle: (style: RunStyle) => void;
    updateRunDimensionValues: (perspectives: string[]) => void;
    updateActionData: (data: ActionData) => void;
};

export const TestRunModeContext = React.createContext<TestRunModeContextType>({
    actionData: defaultActionData,
    mode: defaultTestRunMode,
    runStyle: defaultRunStyle,
    runDimensionValues: [],
    updateMode: () => {},
    updateRunStyle: () => {},
    updateRunDimensionValues: () => {},
    updateActionData: () => {},
});

type TestRunModeProviderProps = {
    children: ReactNode;
    initialMode?: TestRunMode;
};

export const TestRunModeProvider = ({ children, initialMode = defaultTestRunMode }: TestRunModeProviderProps) => {
    const { features } = React.useContext(ConfigContext);
    const [mode, setMode] = React.useState<TestRunMode>(initialMode);
    const [actionData, updateActionData] = React.useState<ActionData>(defaultActionData);
    const [runStyle, setRunStyle] = React.useState(
        () => getFromLocalStorage(RUN_STYLE_STORAGE_KEY, RunStyle.Clinical) as RunStyle,
    );
    const updateMode = inputMode => setMode(inputMode);

    const updateRunStyle = (style: RunStyle) => {
        addToLocalStorage(RUN_STYLE_STORAGE_KEY, style);
        setRunStyle(style);
    };

    const [runDimensionValues, setRunDimensionValues] = React.useState<string[]>(
        getDefaultDimensionValuesForSetting(features[PERSPECTIVES], PerspectiveSetting.RUNNER),
    );

    const updateRunDimensionValues = (dimensionValues: string[]) => {
        setRunDimensionValues(dimensionValues);
    };

    return (
        <TestRunModeContext.Provider
            value={{
                actionData,
                mode,
                runStyle,
                runDimensionValues,
                updateMode,
                updateRunStyle,
                updateRunDimensionValues,
                updateActionData,
            }}
        >
            {children}
        </TestRunModeContext.Provider>
    );
};
