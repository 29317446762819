async function checkResponse(response: Response, failureMessage: string): Promise<void> {
    if (!response.ok) {
        const responseText = await response.text();
        throw new Error(
            `${failureMessage}: status:${response.status}, statusText:${response.statusText}, responseText:${responseText}`,
        );
    }
}

async function getToken(details: { [name: string]: string }) {
    const formBody: string[] = [];
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const property in details) {
        const encodedKey = encodeURIComponent(property);
        const encodedValue = encodeURIComponent(details[property]);
        formBody.push(`${encodedKey}=${encodedValue}`);
    }

    let response;
    try {
        response = await fetch(process.env.APP_PP_TOKEN_URL ?? "", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            },
            body: formBody.join("&"),
        });
    } catch (exception) {
        exception.message = `${exception.message} (get token)`;
        throw exception;
    }

    await checkResponse(response, "Failed to retrieve token");

    const result = await response.json();
    return result.access_token;
}

export default async function exchangeToken(token: string): Promise<string> {
    return getToken({
        client_id: process.env.APP_PP_AUTH_CLIENT_ID ?? "",
        grant_type: "exchange",
        scope: process.env.APP_PP_AUTH_SCOPE ?? "",
        token,
    });
}
