import * as React from "react";
import { Validity } from "@emisgroup/clinical-code-entry/lib/types";
import { DataEntryComponentProps, DataItem, TemplateData } from "../types";
import ComponentAssociatedText from "./componentAssociatedText";
import ComponentEffectiveDate from "./componentEffectiveDate";
import { RunningTemplateContext } from "../context";

type AdditionalDataEntryComponentProps = DataEntryComponentProps & {
    dataItem?: DataItem;
    applyPropertyUpdate: (obj) => any;
    getComponentHasValue?: (templateData: TemplateData) => boolean;
    updatePropertyValidity: (propertyName: string, isValid: boolean) => void;
};

const AdditionalDataEntryComponent = (props: AdditionalDataEntryComponentProps) => {
    const { templateData = {}, runDate } = React.useContext(RunningTemplateContext);
    const { id, onChange, dataItem = templateData[id], applyPropertyUpdate, updatePropertyValidity } = props;

    const setProperty = (propertyName: string, propertyValue: any, isValid: boolean) => {
        onChange({ [id]: applyPropertyUpdate({ [propertyName]: propertyValue }) });
        updatePropertyValidity(propertyName, isValid);
    };

    const setAssociatedText = (associatedText: string) => {
        setProperty("associatedText", associatedText, true);
    };

    const setEffectiveDate = (effectiveDate: string, validity: Validity) => {
        setProperty("effectiveDate", effectiveDate, validity.isValid);
    };

    return (
        <div className="additional-data-entry">
            <ComponentEffectiveDate
                {...props}
                date={dataItem?.effectiveDate || runDate?.toDateString()}
                onDateChange={setEffectiveDate}
            />
            <ComponentAssociatedText {...props} text={dataItem?.associatedText} onTextChange={setAssociatedText} />
        </div>
    );
};

export default AdditionalDataEntryComponent;
