import { AppMode, AppModeContext, TemplateContext } from "@emisgroup/clint-templates-common";
import { useTranslation } from "@emisgroup/application-intl";
import { Edit } from "@emisgroup/icons-react";
import * as React from "react";
import { ContentLibraryContext } from "./context/contentLibrary";
import { DEFAULT_TEMPLATE_LABEL, UNSAVED_CHANGES } from "./constants";
import { BETA_STATUS, DRAFT_STATUS, GENERAL_STATUS } from "./contentLibrary/loadDefinition";
import { ContentLibraryStore } from "./types";
import { isDateStale } from "./utils/dateTimeUtils";

const STALE_THRESHOLD_VALUE = Number(process.env.APP_CONTENT_STALE_THRESHOLD_VALUE) || 1;
const STALE_THRESHOLD_UNIT = process.env.APP_CONTENT_STALE_THRESHOLD_UNIT || "D";

const isStale = (lastLoaded: Date): boolean =>
    isDateStale(Date.now(), lastLoaded, STALE_THRESHOLD_VALUE, STALE_THRESHOLD_UNIT);

const getContentStatus = (unsaved: boolean, content: ContentLibraryStore) =>
    unsaved
        ? {
              unpublished: false,
              beta: false,
              published: false,
              stale: false,
          }
        : {
              unpublished: content.contentStatus === DRAFT_STATUS,
              beta: content.contentStatus === BETA_STATUS,
              published: content.contentStatus === GENERAL_STATUS,
              stale: (content.localStorageDate && isStale(content.localStorageDate)) === true,
          };

const getStaleMessage = (t, content: ContentLibraryStore) =>
    t("templates.templateStale", {
        date: content.localStorageDate ? new Date(content.localStorageDate).toString() : "",
    });

const addStatus = (isAvailable: boolean, text: string, statusClass: string, testId?: string, tooltip?: string) => (
    <>
        {isAvailable && (
            <div title={tooltip} className={`content-status ${statusClass}`} data-testid={testId}>
                {text}
            </div>
        )}
    </>
);

type TitleBarProps = {
    onSelect?: (eventData) => void;
};

const TitleBar = ({ onSelect }: TitleBarProps) => {
    const { t } = useTranslation();
    const { templateDefinition, hasDefinitionChanged } = React.useContext(TemplateContext);
    const { contentLibrary } = React.useContext(ContentLibraryContext);

    const templateTitleRef = React.useRef<HTMLDivElement>(null);
    React.useEffect(() => {
        const titleElement = templateTitleRef.current as HTMLDivElement;
        titleElement.title = titleElement.offsetWidth < titleElement.scrollWidth ? templateDefinition.label : "";
    }, [templateDefinition.label]);

    const templateHasId = Boolean(templateDefinition.id);

    const unsaved = !contentLibrary.name || hasDefinitionChanged;
    const templateName = React.useMemo(() => {
        if (templateHasId) {
            return contentLibrary.name ? contentLibrary.name : t(DEFAULT_TEMPLATE_LABEL);
        }
        return undefined;
    }, [contentLibrary.name, templateHasId, hasDefinitionChanged]);

    const selectItem = (evt, item) => {
        if (onSelect) {
            onSelect({ evt, item });
            evt.stopPropagation();
        }
    };

    const { mode } = React.useContext(AppModeContext);
    const readonly = mode === AppMode.READ;
    const contentStatus = getContentStatus(unsaved, contentLibrary);
    const staleMessage = contentStatus.stale ? getStaleMessage(t, contentLibrary) : "";
    return (
        <div className="title-bar" aria-label={templateName}>
            {templateName && (
                <>
                    <div className="label">{t("contentLibraryName")}:</div>
                    <div className="content-details">
                        <div className="content-name" data-testid="template-name">
                            {templateName}
                        </div>
                        {addStatus(unsaved, t(UNSAVED_CHANGES), "unsaved", "unsaved-changes")}
                        {addStatus(readonly, t("readOnly"), "read-only")}
                        {addStatus(contentStatus.unpublished, t("save.unpublishedChanges"), "unpublished")}
                        {addStatus(contentStatus.beta, t("save.betaChanges"), "beta")}
                        {addStatus(contentStatus.published, t("save.publishedChanges"), "published")}
                        {addStatus(contentStatus.stale, "!", "unpublished", "", staleMessage)}
                    </div>
                    <div className="label">{t("templates.name")}:</div>
                    <div role="none" onClick={evt => selectItem(evt, templateDefinition)}>
                        <div ref={templateTitleRef} className="template-title" data-testid="template-title">
                            {templateDefinition.label}
                        </div>
                        {onSelect && <Edit className="title-edit" size="small" title={t("templates.editName")} />}
                    </div>
                </>
            )}
        </div>
    );
};

export default TitleBar;
