import { PerspectiveSetting } from "@emisgroup/clint-templates-common";
import { IPerspective, keyToDescription } from "@emisgroup/clint-templates-perspectives/lib";

export type PerspectiveName = {
    perspectiveCustomName?: string;
};

export type NamedPerspective = PerspectiveName & IPerspective;

export type PerspectiveState = PerspectiveName & {
    selectedKeys: string[];
};

const keyToShortDescription = (t, key: string) =>
    keyToDescription(key)
        .replace(PerspectiveSetting.RUNNER, t("perspectives.user"))
        .replace(PerspectiveSetting.REFERRAL_REPORT, t("perspectives.report"));

export const getPerspectiveName = (t, perspective: NamedPerspective) =>
    perspective.perspectiveCustomName ?? keyToShortDescription(t, perspective.key);

export const settingToDescription = (t, setting: string) =>
    setting
        .replace(PerspectiveSetting.RUNNER, t("perspectives.runner"))
        .replace(PerspectiveSetting.REFERRAL_REPORT, t("perspectives.referralReport"));

export const getUnselectedStates = (selectedIndex: number, states: PerspectiveState[]) =>
    states.filter((_, i) => i !== selectedIndex);

export const getStateValidationErrors = (t, index: number, states: PerspectiveState[]) => {
    const errors: string[] = [];
    const state = states[index];
    if (state.selectedKeys.length === 0) errors.push(t("perspectives.atLeastOneSetting"));

    const unselectedStates = getUnselectedStates(index, states);
    const keyCompare = JSON.stringify(state.selectedKeys);
    if (unselectedStates.some(s => JSON.stringify(s.selectedKeys) === keyCompare))
        errors.push(t("perspectives.identicalSettings"));

    if (
        state.perspectiveCustomName &&
        unselectedStates.some(s => s.perspectiveCustomName === state.perspectiveCustomName)
    )
        errors.push(t("perspectives.identicalName"));
    return errors;
};
