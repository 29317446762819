import * as React from "react";
import { Dropdown, FormElement, IListItem, IRadioItem, RadioButtons } from "@emisgroup/ui-kit-react";
import { useTranslation } from "@emisgroup/application-intl";
import { AppMode, DataEntryComponentProps, TemplateDataItem } from "../types";
import MultiSelectDropdown from "./multiSelectDropdown/multiSelectDropdown";
import DataEntryComponent from "./dataEntryComponent";
import { AppModeContext } from "../context";
import { KEYS, RADIO_BUTTONS } from "../constants";

type ListProps = {
    componentId: string;
    dataSource: IListItem[];
    selectedValues: string[];
    onSelect: (selectedValues: string[]) => void;
    isValid: boolean;
};

const RadioButtonsList = ({ componentId, dataSource, selectedValues, onSelect, isValid }: ListProps) => {
    const handleChange = (selectedRadioItem: IRadioItem) => onSelect([selectedRadioItem.value as string]);

    const handleButtonKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === KEYS.DELETE) {
            onSelect([""]);
            e.preventDefault();
        }
    };

    const selected = selectedValues.length === 1 ? dataSource.find(d => d.value === selectedValues[0])?.text : "";
    return (
        <div
            role="none"
            onKeyDown={handleButtonKeyDown}
            onClick={() => onSelect([""])}
            onBlur={() => onSelect(selectedValues.length ? selectedValues : [""])}
        >
            <RadioButtons
                id={componentId}
                data-testid={`radio-buttons-${componentId}`}
                invalid={!isValid}
                name={`radioButtonsList-${componentId}`}
                selected={selected}
                onChange={handleChange}
                options={dataSource.slice(1)}
            />
        </div>
    );
};

const SingleSelectList = ({ componentId, dataSource, selectedValues, onSelect, isValid }: ListProps) => {
    const handleChange = (selectedValue: string) => onSelect([selectedValue]);
    const pickingListProps = {
        value: selectedValues.length === 1 ? selectedValues[0] : "",
        onChange: handleChange,
    };

    return (
        <div onBlur={() => onSelect(selectedValues.length ? selectedValues : [""])}>
            <Dropdown id={componentId} dataSource={dataSource} {...pickingListProps} invalid={!isValid} />
        </div>
    );
};

const MultiSelectList = ({ componentId, dataSource, selectedValues, onSelect, isValid }: ListProps) => (
    <MultiSelectDropdown
        id={componentId}
        placeholder={useTranslation().t("components.pickingList.selectFromList")}
        dataSource={dataSource}
        onChange={onSelect}
        values={selectedValues}
        isValid={isValid}
    />
);

type PickingListProps = DataEntryComponentProps & {
    dataSource: IListItem[];
    selectedValues: string[];
    onPickingListSelect: (selectedValues: string[]) => void;
    isMultiSelect?: boolean;
    displayStyle?: string;
    children?: React.ReactNode | React.ReactNodeArray;
    selectedValuesValid: boolean;
    templateData: TemplateDataItem;
};

const PickingList = (props: PickingListProps) => {
    const {
        id,
        dataSource,
        selectedValues,
        hasAssociatedText,
        onPickingListSelect,
        isMultiSelect,
        displayStyle,
        children,
        selectedValuesValid,
        templateData,
    } = props;
    const { t } = useTranslation();
    const { mode } = React.useContext(AppModeContext);
    const componentId = `component-${id}`;
    const isRadioButtons = (displayStyle ?? "") === RADIO_BUTTONS;

    if (mode !== AppMode.RUN) {
        const numberOfPlaceholderOptions = isMultiSelect ? 0 : 1;
        const numberOfValidOptions = dataSource.length - numberOfPlaceholderOptions;
        const placeholderText = t(
            numberOfValidOptions === 1
                ? "components.pickingList.availableOption"
                : "components.pickingList.availableOptions",
            { number: numberOfValidOptions },
        );
        return (
            <DataEntryComponent {...props} hasAssociatedText={hasAssociatedText} hasDatePrompt={false}>
                <div className="canvas-item-entry">
                    {!isRadioButtons && (
                        <Dropdown
                            id={componentId}
                            data-testid={`drop-down-${componentId}`}
                            dataSource={[
                                {
                                    text: placeholderText,
                                    value: "",
                                },
                            ]}
                            disabled
                        />
                    )}
                    {isRadioButtons && (
                        <RadioButtons
                            id={componentId}
                            data-testid={`radio-buttons-${componentId}`}
                            name={`radioButtonsList-${componentId}`}
                            options={dataSource.slice(1).map(d => ({ ...d, disabled: true }))}
                            selected=""
                        />
                    )}
                </div>
            </DataEntryComponent>
        );
    }

    let List;

    if (isMultiSelect) {
        List = MultiSelectList;
    } else {
        List = isRadioButtons ? RadioButtonsList : SingleSelectList;
    }

    const isValid = props.runtimeValidator ? props.runtimeValidator(templateData) : true;

    return (
        <>
            <DataEntryComponent
                {...props}
                areValuesValid={isValid && selectedValuesValid}
                hasAssociatedText={false}
                hasDatePrompt={false}
            >
                <div className="canvas-item-entry">
                    <FormElement errorText={isValid ? undefined : t("components.selectionMandatory")}>
                        <List
                            componentId={componentId}
                            selectedValues={selectedValues}
                            dataSource={dataSource}
                            onSelect={onPickingListSelect}
                            isValid={isValid}
                        />
                    </FormElement>
                    {children}
                </div>
            </DataEntryComponent>
        </>
    );
};

export default PickingList;
