import React from "react";
import { ActionType, ActionData } from "@emisgroup/clint-templates-common/src/types";
import { CanvasItem } from "@emisgroup/clint-templates-common";

type EventsContextType = {
    showDefaultActionButtons?: boolean;
    onRaiseAction?: (actionType: ActionType, validate: boolean, currentItem?: CanvasItem) => void;
    onHandleAction?: (actionType: ActionType, actionData: ActionData, validData: boolean | null) => void;
};

export const ActionsContext = React.createContext<EventsContextType>({
    showDefaultActionButtons: false,
    onRaiseAction: () => {},
    onHandleAction: () => {},
});
