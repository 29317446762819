import React from "react";
import { ICheckboxProps } from "@emisgroup/ui-kit-react/build/dist/src/components/Checkbox/ICheckboxProps";
import { FormElement } from "@emisgroup/ui-kit-react";
import { useTranslation } from "@emisgroup/application-intl";

type CheckboxProps = ICheckboxProps & {
    onFocus?: React.FocusEventHandler;
    onBlur?: React.FocusEventHandler;
    tabIndex?: number;
    isMandatory?: boolean;
};

const ControlledCheckbox = ({
    id,
    className = "",
    checked,
    labelText,
    invalid = false,
    isMandatory = false,
    onChange,
    onFocus,
    onBlur,
    disabled,
    title,
    "data-testid": dataTestId,
    tabIndex,
}: CheckboxProps) => {
    const { t } = useTranslation();
    return (
        <FormElement errorText={invalid ? t("components.selectionMandatory") : undefined} className="eui-checkbox">
            {isMandatory && <span className="checkbox-mandatory-indicator eui-form-element__label--mandatory" />}
            <input
                className={`eui-checkbox__input${invalid ? " eui-checkbox__input--invalid" : ""} ${className}`}
                id={id}
                type="checkbox"
                onChange={onChange}
                disabled={disabled}
                title={title}
                data-testid={dataTestId}
                checked={checked}
                onFocus={onFocus}
                onBlur={onBlur}
            />
            <label className="eui-checkbox__label" htmlFor={id} tabIndex={tabIndex}>
                {labelText || <>&nbsp;</>}
            </label>
        </FormElement>
    );
};

export default ControlledCheckbox;
