import * as React from "react";
import { GO_TO_ITEM_EVENT } from "./constants";

const DELAY_TO_FOCUS = 300;
export default function useGoToComponent(container: React.RefObject<HTMLElement>) {
    const handleGoToItem = React.useCallback(e => {
        const {
            detail: { id },
        } = e;

        const element = document.getElementById(`canvas-item-${id}`);
        if (!element || !container.current || !container.current.contains(element)) {
            return;
        }

        element.scrollIntoView();

        const [elementToFocus] = element.querySelectorAll("input, select, div[contenteditable='true']");
        if (elementToFocus) {
            setTimeout(() => {
                (elementToFocus as HTMLElement).focus();
            }, DELAY_TO_FOCUS);
        }
    }, []);

    React.useEffect(() => {
        window.addEventListener(GO_TO_ITEM_EVENT, handleGoToItem);
        return () => {
            window.removeEventListener(GO_TO_ITEM_EVENT, handleGoToItem);
        };
    }, [handleGoToItem]);
}
