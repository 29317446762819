import { Engine, NestedCondition, RuleProperties, TopLevelCondition } from "json-rules-engine";
import {
    isCanvasItemAContainer,
    extractCodedValue,
    CanvasItem,
    ComponentHasValueCondition,
    ComponentSelectedCondition,
    ComponentValueSelectedCondition,
    Condition,
    ConditionMember,
    ConditionOperator,
    ConditionsGroup,
    ConditionSource,
    ComponentContainer,
    ComponentValueConditionOperator,
    ComponentValueComparisionCondition,
    QueryCondition,
    Rule,
    TemplateData,
    ComponentType,
    CodedDataItem,
    QueryResults,
    PickingListOption,
} from "@emisgroup/clint-templates-common";
import { getAlwaysVisibleComponents } from "./ruleUtils";
import { getFlattenedList, getItemId } from "./componentUtils";
import {
    RULES_ENGINE_CONTAINS_OPERATOR,
    RULES_ENGINE_DOES_NOT_CONTAIN_OPERATOR,
    RULES_ENGINE_EQUALS_OPERATOR,
    RULES_ENGINE_GREATER_THAN_OPERATOR,
    RULES_ENGINE_GREATER_THAN_OR_EQUAL_OPERATOR,
    RULES_ENGINE_LESS_THAN_OPERATOR,
    RULES_ENGINE_LESS_THAN_OR_EQUAL_OPERATOR,
    RULES_ENGINE_NOT_EQUALS_OPERATOR,
} from "../constants";
import { executeQueryResources } from "../queries/executeQueries";

const convertToRulesEngineOperator = (componentValueConditionOperator: ComponentValueConditionOperator): string => {
    switch (componentValueConditionOperator) {
        case ComponentValueConditionOperator.NOT_EQUAL:
            return RULES_ENGINE_NOT_EQUALS_OPERATOR;

        case ComponentValueConditionOperator.GREATER_THAN:
            return RULES_ENGINE_GREATER_THAN_OPERATOR;

        case ComponentValueConditionOperator.GREATER_THAN_OR_EQUAL:
            return RULES_ENGINE_GREATER_THAN_OR_EQUAL_OPERATOR;

        case ComponentValueConditionOperator.LESS_THAN:
            return RULES_ENGINE_LESS_THAN_OPERATOR;

        case ComponentValueConditionOperator.LESS_THAN_OR_EQUAL:
            return RULES_ENGINE_LESS_THAN_OR_EQUAL_OPERATOR;

        default:
            return RULES_ENGINE_EQUALS_OPERATOR;
    }
};
const convertToRulesEngineNestedConditions = (conditionMembers: ConditionMember[]): NestedCondition[] => {
    return conditionMembers.map(conditionMember => {
        const conditionsGroup = conditionMember as ConditionsGroup;

        if (conditionsGroup.conditionMembers) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            return convertToRulesEngineConditions(conditionsGroup);
        }

        const condition = conditionMember as Condition;
        switch (condition.conditionSource) {
            case ConditionSource.COMPONENT_VALUE_COMPARISON: {
                const componentCondition = conditionMember as ComponentValueComparisionCondition;

                return {
                    fact: "componentValueComparison",
                    params: {
                        componentId: componentCondition.actorCanvasItemId,
                    },
                    operator: convertToRulesEngineOperator(componentCondition.operator),
                    value: Number(componentCondition.value),
                };
            }
            case ConditionSource.COMPONENT_VALUE_SELECTED: {
                const componentCondition = conditionMember as ComponentValueSelectedCondition;
                return {
                    fact: "componentValueSelected",
                    params: {
                        componentId: componentCondition.actorCanvasItemId,
                    },
                    operator:
                        componentCondition.negated === true
                            ? RULES_ENGINE_DOES_NOT_CONTAIN_OPERATOR
                            : RULES_ENGINE_CONTAINS_OPERATOR,
                    value: componentCondition.value,
                };
            }
            case ConditionSource.COMPONENT_HAS_VALUE: {
                const componentHasValueCondition = conditionMember as ComponentHasValueCondition;
                return {
                    fact: "componentHasValue",
                    params: {
                        componentId: componentHasValueCondition.actorCanvasItemId,
                    },
                    operator: RULES_ENGINE_EQUALS_OPERATOR,
                    value: componentHasValueCondition.hasValue,
                };
            }
            case ConditionSource.QUERY: {
                const queryCondition = conditionMember as QueryCondition;
                return {
                    fact: "queryResult",
                    params: {
                        queryId: queryCondition.queryId,
                    },
                    operator: RULES_ENGINE_EQUALS_OPERATOR,
                    value: queryCondition.resultValue,
                };
            }
            default: {
                const componentCondition = conditionMember as ComponentSelectedCondition;
                return {
                    fact: "componentSelected",
                    params: {
                        componentId: componentCondition.actorCanvasItemId,
                    },
                    operator: RULES_ENGINE_EQUALS_OPERATOR,
                    value: componentCondition.selected,
                };
            }
        }
    });
};

const convertToRulesEngineConditions = (conditionsGroup: ConditionsGroup): NestedCondition => {
    const nestedConditions: NestedCondition[] = convertToRulesEngineNestedConditions(conditionsGroup.conditionMembers);

    return conditionsGroup.conditionOperator === ConditionOperator.ALL
        ? { all: nestedConditions }
        : { any: nestedConditions };
};

const convertToRuleProperties = (rule: Rule, actedOnCanvasId: string): RuleProperties => ({
    conditions: convertToRulesEngineConditions(rule) as TopLevelCondition,
    event: {
        type: "showHideComponents",
        params: {
            componentId: actedOnCanvasId,
        },
    },
});

type ParamsForRunningRules = {
    engine: Engine;
    container: ComponentContainer;
    templateData: TemplateData;
};

function doEvaluation({ engine, container, templateData }: ParamsForRunningRules): Promise<Array<string>> {
    const { members: canvasItems } = container;
    const alwaysVisibleComponents = getAlwaysVisibleComponents(canvasItems, templateData);
    const alwaysVisibleComponentIds = alwaysVisibleComponents.map(({ id }) => id);
    const canvasItemsWithRule = canvasItems.filter(
        canvasItem => canvasItem.rule && !alwaysVisibleComponentIds.includes(canvasItem.id),
    );

    const alwaysVisibleContainers: ComponentContainer[] = alwaysVisibleComponents
        .filter(isCanvasItemAContainer)
        .map(item => item as ComponentContainer);

    if (canvasItemsWithRule.length === 0 && alwaysVisibleContainers.length === 0) {
        return Promise.resolve(alwaysVisibleComponents.map(getItemId));
    }

    if (alwaysVisibleContainers.length > 0 && canvasItemsWithRule.length === 0) {
        return Promise.all(
            getFlattenedList(
                alwaysVisibleContainers
                    .map(visibleContainer => doEvaluation({ engine, container: visibleContainer, templateData }))
                    .concat(Promise.resolve(alwaysVisibleComponents.map(getItemId))),
            ),
        ).then(getFlattenedList);
    }

    canvasItemsWithRule.forEach(canvasItem =>
        engine.addRule(convertToRuleProperties(canvasItem.rule as Rule, canvasItem.id)),
    );

    return engine.run().then(results => {
        const resultComponentIds: string[] = results.events.map(({ params }) => params?.componentId).filter(Boolean);
        const resultComponents: CanvasItem[] = canvasItems.filter(item => resultComponentIds.includes(item.id));
        const resultItems: CanvasItem[] = resultComponents.concat(alwaysVisibleComponents);
        return Promise.all(
            getFlattenedList(
                resultItems.map(async component =>
                    // eslint-disable-next-line no-nested-ternary
                    isCanvasItemAContainer(component)
                        ? [
                              component.id,
                              ...(await doEvaluation({
                                  engine,
                                  container: component as ComponentContainer,
                                  templateData,
                              })),
                          ]
                        : Promise.resolve(component.id),
                ),
            ),
        ).then(getFlattenedList);
    });
}

type RuleEvaluationSuccess = { success: true; itemIds: string[] };
type RuleEvaluationFailure = { success: false };
type RuleEvaluationResult = RuleEvaluationSuccess | RuleEvaluationFailure;

export async function evaluateRules({
    engine,
    container,
    templateData,
}: ParamsForRunningRules): Promise<RuleEvaluationResult> {
    try {
        const itemIds = await doEvaluation({ engine, container, templateData });
        return { success: true, itemIds };
    } catch (ex) {
        return { success: false };
    }
}

type CreateEngineParams = {
    templateData: TemplateData;
    queryApiUrl: string;
    patientId: string;
    getBearerToken: () => Promise<string>;
};
export function createEngine({ templateData, queryApiUrl, patientId, getBearerToken }: CreateEngineParams) {
    const engine = new Engine();

    engine.addFact("componentSelected", params =>
        templateData[params.componentId] ? Boolean(templateData[params.componentId].selected) : false,
    );

    engine.addFact("componentValueComparison", params => {
        const componentData = templateData[params.componentId];

        if (componentData.type === ComponentType.CODED) {
            const componentValue = extractCodedValue(componentData);
            return !Number.isNaN(componentValue) ? componentValue : undefined;
        }

        return !Number.isNaN(componentData.value) ? Number(componentData.value) : undefined;
    });

    engine.addFact("componentValueSelected", params => {
        const componentData = templateData[params.componentId];
        if (!componentData || !componentData.items) {
            return [];
        }

        const selectedItems = componentData.items.filter(item => item.selected);

        return componentData.type === ComponentType.CODED_PICKING_LIST
            ? selectedItems.map(selectedItem => (selectedItem as CodedDataItem).code.emisCodeId.toString())
            : selectedItems.map(selectedOption => (selectedOption.value as PickingListOption)?.id?.toString());
    });

    engine.addFact("componentHasValue", params => {
        const componentData = templateData[params.componentId];
        if (componentData.value !== undefined) {
            return componentData.value !== null && componentData.value !== "";
        }
        return componentData.initialValue !== undefined && componentData.initialValue !== null;
    });
    engine.addFact("queryResult", async params => {
        if (params.queryId) {
            const result: QueryResults = await executeQueryResources(
                queryApiUrl,
                await getBearerToken(),
                [params.queryId],
                patientId,
            );

            if (result.Error) {
                return Promise.reject(new Error(`Error running query ${params.queryId}`));
            }

            if (result.QueryResults[0].Data.length === 1 && typeof result.QueryResults[0].Data[0] === "boolean")
                return result.QueryResults[0].Data[0];

            return Boolean(result.QueryResults[0].Data.length);
        }
        return Promise.resolve(false);
    });

    return engine;
}
