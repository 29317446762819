import { v4 as uuid } from "uuid";
import {
    CanvasItem,
    ComponentType,
    ConditionOperator,
    ConditionSource,
    Panel,
    findRootLevelAncestor,
    ComponentContainer,
    PickingListOption,
} from "@emisgroup/clint-templates-common";
import { getRuleConditionSourcesForItem, itemFinder, insertItem } from "./componentUtils";
import { getDefaultPanelActionButtons } from "./panelActionUtils";

const typesSupportingConditionalPanel = [
    String(ComponentType.UNCODED),
    String(ComponentType.NUMERIC_VALUE),
    String(ComponentType.CODED),
    String(ComponentType.FREE_TEXT),
    String(ComponentType.DIARY_ENTRY),
    String(ComponentType.CALCULATOR),
];

export const supportsConditionalPanel = (type: string): boolean =>
    typesSupportingConditionalPanel.some(containerType => containerType === type);

type CreateConditionalPanelOptions = {
    t: any;
    actor: CanvasItem;
    columnIndex?: number;
    actorOption?: PickingListOption;
};

export const createConditionalPanel = ({
    t,
    actor,
    columnIndex,
    actorOption,
}: CreateConditionalPanelOptions): Panel => {
    const actorConditionSource = getRuleConditionSourcesForItem(actor)[0];
    const componentStatePropertyName =
        actorConditionSource === ConditionSource.COMPONENT_HAS_VALUE ? "hasValue" : "selected";
    const componentCondition = {
        conditionSource: actorConditionSource,
        actorCanvasItemId: actor.id,
        [componentStatePropertyName]: true,
    };
    const valueCondition = actorOption ? { value: actorOption.id } : undefined;

    return {
        id: uuid(),
        type: ComponentType.PANEL,
        label: `${t("newPanel")}: ${actorOption?.text || actor.label}`,
        columnIndex,
        rule: {
            conditionOperator: ConditionOperator.ALL,
            conditionMembers: [{ ...componentCondition, ...valueCondition }],
        },
        members: [],
        columnCount: 1,
        actionButtons: getDefaultPanelActionButtons(t),
    };
};

export function addConditionalPanel(
    templateDefinition: ComponentContainer,
    selectedItem: CanvasItem,
    t,
    pickingListOption?: PickingListOption,
) {
    const rootAncestor = findRootLevelAncestor(selectedItem.id, templateDefinition.members) ?? selectedItem;
    const destinationIndex = templateDefinition.members.findIndex(itemFinder(rootAncestor)) + 1;
    const conditionalPanel = createConditionalPanel({
        t,
        actor: selectedItem,
        columnIndex: rootAncestor.columnIndex,
        actorOption: pickingListOption,
    });
    const updatedTemplate = insertItem(conditionalPanel, templateDefinition, templateDefinition, destinationIndex);

    return [updatedTemplate, conditionalPanel];
}
