import { InfoBanner } from "@emisgroup/ui-kit-react";
import * as React from "react";
import "./warningBox.css";

type WarningBoxProps = { title: string; className?: string };
type PropsWithChildren = React.PropsWithChildren<WarningBoxProps>;

const WarningBox = ({ title, className = "", children }: PropsWithChildren) => {
    return (
        <InfoBanner
            className={`ctc-warning-box ${className}`}
            vertical
            variant="warning"
            iconName="info-warning"
            iconColor="danger"
            iconTitle={title}
        >
            <div className="ctc-warning-box__title">{title}</div>
            <div>{children}</div>
        </InfoBanner>
    );
};

export default WarningBox;
