export const NAME_FIELD_ID = "name";
export const DESCRIPTION_FIELD_ID = "description";
export const TAGS_FIELD_ID = "tags";

export type PropertyErrors = { [name: string]: string };
export type ValidationErrors = { [name: string]: string | undefined };

const getValidationError = (fieldValue: string, requiredError: string, propertyError?: string): string | undefined => {
    return fieldValue ? propertyError : requiredError;
};

const getPropertyError = (
    t: (v: string) => string,
    fieldId: string,
    fieldsIgnoringPropertyError: Set<string>,
    propertyErrors: PropertyErrors,
): string | undefined => {
    return fieldsIgnoringPropertyError.has(fieldId) || !propertyErrors[fieldId]
        ? undefined
        : t(propertyErrors[fieldId]);
};

export const getValidationErrors = (
    t: (v: string) => string,
    fieldsToValidate: Set<string>,
    fieldsIgnoringPropertyError: Set<string>,
    newName: string,
    newDescription: string,
    propertyErrors: PropertyErrors,
): ValidationErrors => {
    const validationErrors = {} as ValidationErrors;

    if (fieldsToValidate.has(NAME_FIELD_ID)) {
        validationErrors[NAME_FIELD_ID] = getValidationError(
            newName,
            t("save.errorNameRequired"),
            getPropertyError(t, NAME_FIELD_ID, fieldsIgnoringPropertyError, propertyErrors),
        );
    }

    if (fieldsToValidate.has(DESCRIPTION_FIELD_ID)) {
        validationErrors[DESCRIPTION_FIELD_ID] = getValidationError(
            newDescription,
            t("save.errorDescriptionRequired"),
            getPropertyError(t, DESCRIPTION_FIELD_ID, fieldsIgnoringPropertyError, propertyErrors),
        );
    }

    return validationErrors;
};

export const isValid = (validationErrors: ValidationErrors): boolean => {
    return !validationErrors[NAME_FIELD_ID] && !validationErrors[DESCRIPTION_FIELD_ID];
};
