import * as React from "react";
import { ListQualifiers, ValueQualifiers } from "@emisgroup/clinical-code-entry/lib/qualifiersEntry/types";
import { NumericQualifier } from "@emisgroup/clinical-code-entry/lib/numericEntry/types";
import { ProblemDefinition } from "@emisgroup/clinical-code-entry/lib/problemEntry/types";

export type Qualifiers = {
    numeric?: NumericQualifier;
    list?: ListQualifiers;
    value?: ValueQualifiers;
    compound?: CompoundQualifier[];
};

export type ComponentConfigData = {
    id: string;
    type: string;
    attributes?: {
        allowFutureDate: boolean;
        qualifiers?: Qualifiers;
        problem?: ProblemDefinition;
    };
};

export type CompoundQualifier = {
    equivalents?: number[];
    components?: ComponentDefinition[];
};

export type ComponentDefinition = {
    code: { codeId: number; term: string; qualifiers?: Qualifiers };
    equivalents?: number[];
};

type ComponentConfigDataContextType = {
    componentsConfig: Array<ComponentConfigData>;
    error?: string;
    setComponentsConfig: (data: Array<ComponentConfigData>, error?: string) => void;
};

export const ComponentConfigDataContext = React.createContext<ComponentConfigDataContextType>(
    {} as ComponentConfigDataContextType,
);
