import { Checkbox, IListItem } from "@emisgroup/ui-kit-react";
import * as React from "react";

type MultiSelectOptionProps = {
    isSelected: boolean;
    item: IListItem;
    onSelectionChange: (option: IListItem, selected: boolean, e: React.SyntheticEvent<any>) => void;
    "data-testid"?: string;
};

const MultiSelectOption = (props: MultiSelectOptionProps) => {
    const { isSelected, item, onSelectionChange, "data-testid": dataTestId } = props;

    const onChange = ev => {
        if (!item.disabled) {
            onSelectionChange(item, ev.target.checked, ev);
        }
    };

    return (
        <Checkbox
            data-testid={dataTestId}
            labelText={item.text}
            onChange={onChange}
            checked={isSelected}
            disabled={item.disabled}
        />
    );
};

export default MultiSelectOption;
