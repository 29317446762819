import * as React from "react";
import { ProblemEntry } from "@emisgroup/clinical-code-entry";
import { QualifierData } from "@emisgroup/clinical-code-entry/lib/qualifiersEntry/types";
import { ProblemDuration, ProblemDurationUnit } from "@emisgroup/clinical-code-entry/lib/problemEntry/types";
import { ErrorHandling, ErrorState } from "@emisgroup/clinical-code-entry/lib/types";
import { config } from "@emisgroup/config-sdk-ts";
import Logger from "../logging/Logger";
import { Code } from "../types";
import { AuthContext, ComponentConfigData, ConfigContext, PatientDataContext } from "../context";

import "./problem.css";

type ProblemProps = {
    code: Code;
    componentConfigData: ComponentConfigData | null;
    effectiveDate?: string;
    qualifiers?: QualifierData;
    onChange: (problem) => void;
    onHandleError?: (errorState: ErrorState) => ErrorHandling;
};

type ProblemEntryInitialisation = {
    bearerToken: string;
    orgProblemDuration?: ProblemDuration;
};

const APP_EMIS_WEB_API_URL = process.env.APP_EMIS_WEB_API_URL || "";
const getEmisWebOrgProblemDuration = async (bearerToken: string, problemCodeId: string) => {
    try {
        await config.useEmisWeb(APP_EMIS_WEB_API_URL, bearerToken);
    } catch (err) {
        Logger.error(`error-code:config-promise-rejected`);
        if (err) Logger.error(JSON.stringify(err));
        return undefined;
    }

    const orgProblemDuration = config.organisation?.codePickerOptions?.problemDefaultDurations?.find(
        c => `${c.problemCodeId}` === problemCodeId,
    );
    if (typeof orgProblemDuration?.defaultDurationInDays !== "undefined") {
        return {
            value: orgProblemDuration.defaultDurationInDays,
            unit:
                orgProblemDuration.defaultDurationInDays === 0
                    ? ProblemDurationUnit.Indefinite
                    : ProblemDurationUnit.Day,
        };
    }
    return undefined;
};

const Problem = ({ code, componentConfigData, effectiveDate, qualifiers, onChange, onHandleError }: ProblemProps) => {
    const { patientId } = React.useContext(PatientDataContext);
    const { platformUrl } = React.useContext(ConfigContext);
    const { getBearerToken } = React.useContext(AuthContext);
    const currentDateRef = React.useRef(new Date());
    const [initialisation, setInitialisation] = React.useState<ProblemEntryInitialisation>();

    const codeQualifiers = componentConfigData?.attributes?.qualifiers;
    const canAddAsProblem = !codeQualifiers || (!codeQualifiers.numeric && !codeQualifiers.compound);
    const codeId = code.emisCodeId.toString();

    React.useEffect(() => {
        const initialiseProblemEntry = async () => {
            const bearerToken = await getBearerToken();
            const orgProblemDuration = await getEmisWebOrgProblemDuration(bearerToken, codeId);
            setInitialisation({ bearerToken, orgProblemDuration });
        };

        if (canAddAsProblem) {
            initialiseProblemEntry();
        }
    }, [getBearerToken]);

    if (!canAddAsProblem) {
        return null;
    }
    const platformToken = initialisation?.bearerToken;
    const getConfigParams = {
        patientId,
        effectiveDate,
        qualifiers,
        platformToken,
        codeId,
        platformUri: platformUrl,
        codeTerm: code.term,
    };

    if (!platformToken) return null;

    return (
        <div className="coded-component-problem">
            <ProblemEntry
                getConfigParams={getConfigParams}
                currentDate={currentDateRef.current}
                codeQualifiers={codeQualifiers}
                problemDefinition={componentConfigData?.attributes?.problem}
                problemDuration={initialisation?.orgProblemDuration}
                onChange={onChange}
                onHandleError={onHandleError}
                alwaysSelected
            />
        </div>
    );
};

export default Problem;
