import * as React from "react";
import { useTranslation } from "@emisgroup/application-intl";
import {
    CalculatorComponent,
    CanvasItem,
    findCanvasItem,
    InvalidParameterReason,
    isValidCalculationParameter,
    TemplateContext,
} from "@emisgroup/clint-templates-common";
import ComponentSelectorDialog from "../uiComponents/componentSelectorDialog";
import { getClassName, toTreeNode } from "../utils/componentSelectorUtils";

type ComponentParameterSelectorProps = {
    component: CalculatorComponent;
    onCancel: () => void;
    onSelect: (component: CanvasItem) => void;
};

const INVALID_REASON_TEXT = {
    [InvalidParameterReason.CircularReference]: "componentSelection.errors.noSelectCircularReference",
    [InvalidParameterReason.Container]: "componentSelection.errors.noSelectLayoutComponent",
    [InvalidParameterReason.NonNumericCode]: "componentSelection.errors.noSelectNonNumericCode",
    [InvalidParameterReason.NotValueType]: "componentSelection.errors.noSelectNonValue",
    [InvalidParameterReason.PickingListWithNoValues]: "componentSelection.errors.noSelectNonValuePickingList",
    [InvalidParameterReason.SavedContent]: "componentSelection.errors.noSelectSavedContent",
    [InvalidParameterReason.TargetComponent]: "componentSelection.errors.noSelectTargetComponent",
};

const ComponentParameterSelector = ({ component, onCancel, onSelect }: ComponentParameterSelectorProps) => {
    const { templateDefinition } = React.useContext(TemplateContext);
    const { t } = useTranslation();

    const selectorRootNode = React.useMemo(() => {
        const nodeBuilder = {
            getClassName: (canvasItemId: string) => getClassName(canvasItemId, component.id),
            getSelectionError: (item: CanvasItem) => {
                const validParameter = isValidCalculationParameter(item, component, templateDefinition.members);
                return validParameter.valid
                    ? undefined
                    : INVALID_REASON_TEXT[validParameter.reason as InvalidParameterReason];
            },
            t,
            templateItems: templateDefinition.members,
        };
        return toTreeNode(templateDefinition, nodeBuilder);
    }, [templateDefinition]);

    const handleSelect = (componentId: string) =>
        onSelect(findCanvasItem(componentId, templateDefinition.members) as CanvasItem);

    return (
        <ComponentSelectorDialog
            componentsTreeRootNode={selectorRootNode}
            onCancel={onCancel}
            onSelect={handleSelect}
        />
    );
};

export default ComponentParameterSelector;
