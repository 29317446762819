import { useTranslation } from "@emisgroup/application-intl";
import { BrandingComponent, BrandingType, DeviceLabelComponent, MouseEventHandler } from "../types";
import "./branding.css";

type BrandingProps = {
    onSelect?: MouseEventHandler;
    className?: string;
    component: BrandingComponent;
};

const UkcaIcon = () => (
    <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M 11.301 18.419 L 6.1 18.419 C 2.736 18.419 0 15.717 0 12.396 L 0 1 L 3.955 1 L 3.955 12.34 C 3.955 13.508 4.94 14.458 6.152 14.458 L 11.248 14.458 C 12.46 14.458 13.445 13.508 13.445 12.34 L 13.445 1 L 17.4 1 L 17.4 12.394 C 17.4 15.714 14.664 18.419 11.301 18.419 Z M 38 1 L 32.885 1 L 24.517 7.591 L 24.517 1 L 20.563 1 L 20.563 18.416 L 24.517 18.416 L 24.517 11.781 L 32.885 18.416 L 37.977 18.416 L 26.97 9.688 L 38 1 Z M 17.4 39 L 6.017 39 C 2.7 39 0 36.21 0 32.843 L 0 27.742 C 0 24.376 2.7 21.584 6.017 21.584 L 17.4 21.584 L 17.4 25.543 L 6.07 25.543 C 4.904 25.543 3.955 26.529 3.955 27.742 L 3.955 32.842 C 3.955 34.056 4.904 35.042 6.07 35.042 L 17.4 35.042 L 17.4 39 Z M 31.868 21.584 L 26.665 21.584 C 23.302 21.584 20.566 24.286 20.566 27.607 L 20.566 39 L 24.521 39 L 24.521 35.042 L 34.009 35.042 L 34.009 39 L 37.963 39 L 37.963 27.607 C 37.963 24.286 35.228 21.584 31.868 21.584 Z M 24.518 31.083 L 24.518 27.658 C 24.518 26.491 25.504 25.541 26.715 25.541 L 31.811 25.541 C 33.023 25.541 34.009 26.491 34.009 27.658 L 34.009 31.083 L 24.519 31.083 L 24.518 31.083 Z"
            fill="#000"
        />
    </svg>
);

const MedicalDeviceIcon = () => (
    <svg width="40" height="40" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
        <rect x="16.617" y="46.44" width="166.67" height="106.78" fill="none" stroke="#000" strokeWidth="5" />
        <path
            d="m33.148 66.5v66.703h12.701v-49.729l12.982 49.729h0.69141 11.711 0.69141l12.982-49.729v49.729h12.701v-66.703h-8.2695-4.4316-7.9707l-11.559 44.273-11.559-44.273h-7.9707-4.4316-8.2695zm77.504 0.03125-0.08398 66.693 27.922-0.08399c21.156 0 28.607-15.888 28.607-33.264 0-17.569-7.4391-33.346-28.607-33.346h-27.838zm13.875 11.844h13.793c10.488 0 14.172 10.253 14.172 21.668 0 11.289-2.1459 21.611-14.172 21.611l-13.834 0.05468 0.04101-43.334z"
            fill="#000"
            fillRule="evenodd"
        />
    </svg>
);

const FactoryIcon = (props: { fill: string }) => (
    <svg width="40" height="40" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
        <path
            d="m135.36 118.66v-82.034h33.408v126.74h-137.53v-44.706l18.981-32.876 16.629 27.978 16.629-27.978 16.629 27.978 16.629-27.978 18.271 31.647"
            fill={props.fill}
            stroke="#000"
            strokeWidth="5"
        />
    </svg>
);

const LabellingRow = (props: any) => (
    <>
        <div className="ukcal-label">{props.label}</div>
        <div className="ukcal-value">{props.value}</div>
        <div className="ukcal-icon">{props.icon}</div>
        <div className="ukcal-separator" />
    </>
);

const BrandingContent = (props: { component: BrandingComponent }) => {
    const { t } = useTranslation();
    switch (props.component.brandingType) {
        case BrandingType.UKCALabelling: {
            const component = props.component as DeviceLabelComponent;
            return (
                <div className="ukcal-background">
                    <div className="ukcal-title">{t("components.branding.title")}</div>
                    <LabellingRow label={t("components.branding.ref")} value={component.brandingName} />
                    <LabellingRow label={t("components.branding.lot")} value={component.brandingVersion} />
                    <LabellingRow
                        label={t("components.branding.date")}
                        value={component.brandingReleaseDate}
                        icon={<FactoryIcon fill="none" />}
                    />
                    <LabellingRow
                        label={t("components.branding.address")}
                        value={component.brandingAddress}
                        icon={<FactoryIcon fill="#000" />}
                    />
                    <LabellingRow label={<UkcaIcon />} value={component.brandingNotes} icon={<MedicalDeviceIcon />} />
                </div>
            );
        }
        case BrandingType.UKCAIcon:
        default:
            return <UkcaIcon />;
    }
};

const Branding = (props: BrandingProps) => {
    return (
        <div
            role="none"
            onClick={props.onSelect}
            className={props.className ?? "component-branding"}
            data-testid="branding-component"
        >
            <BrandingContent component={props.component} />
        </div>
    );
};

export default Branding;
