import React, { MouseEvent } from "react";
import TabLock from "../uiComponents/tabLock";
import useSelectedTab from "../uiComponents/useSelectedTab";
import InvalidProperty from "./invalidPropertyIndicator";

export type TabPageSelectedProps = { evt: React.MouseEvent; selectedTabIndex: number };

type TabItemProps = {
    tabPageIndex: number;
    isTabPageSelected: boolean;
    text: string;
    isEmbedded: boolean;
    showInvalidWarning?: boolean;
    onTabSelect: (tabPageSelectedProps: TabPageSelectedProps) => void;
};
const TabItem = ({
    tabPageIndex,
    isTabPageSelected,
    text,
    isEmbedded,
    showInvalidWarning = false,
    onTabSelect,
}: TabItemProps) => {
    const selectedPageClassName = isTabPageSelected ? "eui-dynamic-tabs__list-item--active" : "";
    const tabRef = React.useRef<HTMLDivElement>(null);
    useSelectedTab(tabRef, isTabPageSelected);

    const invalidClass = showInvalidWarning ? " invalid" : " ";

    return (
        <div ref={tabRef} className={`eui-dynamic-tabs__list-item ${selectedPageClassName} ${invalidClass}`} role="tab">
            <button
                aria-controls={text}
                aria-selected={isTabPageSelected}
                className="eui-dynamic-tabs__button"
                data-testid={`tabpage-${tabPageIndex}`}
                role="tab"
                key={tabPageIndex}
                type="button"
                onClick={evt => {
                    onTabSelect({ evt: evt as MouseEvent, selectedTabIndex: tabPageIndex });
                }}
            >
                {text}
                <TabLock isEmbedded={isEmbedded} />
                {showInvalidWarning && <InvalidProperty />}
            </button>
        </div>
    );
};

export default TabItem;
