import React, { MouseEvent } from "react";
import { useDrag, useDrop } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { ComponentType, TabItemType, TabItem } from "@emisgroup/clint-templates-common";
import {
    DROP_BEFORE_INDEX,
    COMPONENT_INDEX,
    DROP_AFTER_INDEX,
    HoverTarget,
    getHoverTarget,
    getTabDropIndicatorWidth,
} from "../utils/dragDropUtils";

export type DraggedTabPage = { type: string; dynamicTabId: string; tabPageIndex: number; text: string };
export type TabPageSelectedProps = { evt: MouseEvent; selectedTabIndex: number };

type DynamicTabPageProps = {
    tabItem: TabItemType;
    tabPageIndex: number;
    onTabSelect: (tabPageSelectedProps: TabPageSelectedProps) => void;
    shouldSetActiveTabFocus: boolean;
    isTabPageSelected: boolean;
    dynamicTabId: string;
    showInvalidWarning?: boolean;
    dragDropEnabled: boolean;
    onItemDropped: (draggedItem: DraggedTabPage, targetIndex: number) => void;
};

const DynamicTabPage = ({
    tabItem,
    tabPageIndex,
    onTabSelect,
    isTabPageSelected,
    shouldSetActiveTabFocus,
    dynamicTabId,
    showInvalidWarning = false,
    dragDropEnabled,
    onItemDropped,
}: DynamicTabPageProps) => {
    const tabPageRef = React.useRef<HTMLDivElement>(null);
    const [hoverTarget, setHoverTarget] = React.useState(HoverTarget.NONE);

    const tabPage: DraggedTabPage = { type: ComponentType.TAB_PAGE, dynamicTabId, tabPageIndex, text: tabItem.text };

    const [{ canDrop }, drop] = useDrop({
        accept: ComponentType.TAB_PAGE,
        canDrop: (item, monitor) => {
            return (
                item.type === ComponentType.TAB_PAGE &&
                monitor.isOver({ shallow: true }) &&
                (item as DraggedTabPage).dynamicTabId === dynamicTabId
            );
        },
        hover: (_, monitor) => {
            setHoverTarget(
                getHoverTarget(tabPageRef.current?.children[COMPONENT_INDEX], monitor.getClientOffset(), false),
            );
        },
        collect: monitor => ({
            canDrop: monitor.canDrop(),
        }),
        drop: item => {
            onItemDropped(
                item as DraggedTabPage,
                hoverTarget === HoverTarget.SECOND_HALF_COMPONENT ? tabPageIndex + 1 : tabPageIndex,
            );
        },
    });

    const [{ isDragging }, drag, preview] = useDrag({
        item: tabPage,
        canDrag: () => {
            return dragDropEnabled;
        },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
        begin: () => tabPage,
    });

    const setTabDropIndicatorWidth = (indicatorIndex: number, isHovering: boolean) => {
        const indicator = tabPageRef.current?.children[indicatorIndex] as HTMLElement;
        indicator.style.width = getTabDropIndicatorWidth(canDrop && isHovering);
    };

    React.useEffect(() => {
        if (dragDropEnabled && tabPageRef.current) {
            setTabDropIndicatorWidth(DROP_BEFORE_INDEX, hoverTarget === HoverTarget.FIRST_HALF_COMPONENT);
            setTabDropIndicatorWidth(DROP_AFTER_INDEX, hoverTarget === HoverTarget.SECOND_HALF_COMPONENT);
        }
    }, [canDrop, hoverTarget]);

    React.useEffect(() => {
        if (isTabPageSelected && shouldSetActiveTabFocus) {
            const tabButton = tabPageRef.current?.querySelector("button.eui-dynamic-tabs__button") as HTMLButtonElement;
            if (tabButton) {
                tabButton.focus();
            }
        }
    }, [shouldSetActiveTabFocus]);

    React.useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, []);

    if (dragDropEnabled) {
        drag(drop(tabPageRef));
    }

    return (
        <div
            key={tabPageIndex}
            ref={tabPageRef}
            className="dynamic-tab-drag-container"
            data-testid={`drag-container-${dynamicTabId}-${tabPageIndex}`}
            style={{
                opacity: isDragging ? 0.5 : 1,
            }}
        >
            {dragDropEnabled && (
                <div
                    className={`dynamic-tab-drop-indicator${tabPageIndex === 0 ? " first-tab" : ""}`}
                    data-testid={`${dynamicTabId}-${tabPageIndex}-drop-before-indicator`}
                />
            )}
            <TabItem
                {...tabItem}
                tabPageIndex={tabPageIndex}
                isTabPageSelected={isTabPageSelected}
                showInvalidWarning={showInvalidWarning}
                onTabSelect={onTabSelect}
            />
            {dragDropEnabled && (
                <div
                    className="dynamic-tab-drop-indicator"
                    data-testid={`${dynamicTabId}-${tabPageIndex}-drop-after-indicator`}
                />
            )}
        </div>
    );
};

export default DynamicTabPage;
