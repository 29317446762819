import { IApplicationContext } from "@emisgroup/single-spa-state-management";

export const getBaseName = (applicationContext: IApplicationContext): string | undefined => {
    if (applicationContext.selectedApplication) {
        return applicationContext.selectedApplication.relativeUrl
            ? `/${applicationContext.selectedApplication.relativeUrl}`
            : undefined;
    }
    return undefined;
};
