import { ComponentContainerHistoryItem } from "@emisgroup/clint-templates-common";
import { compare, applyPatch, Operation } from "fast-json-patch";

export type TemplateChange = Operation[];

export function getTemplateChange(originalDefinition, updatedDefinition): TemplateChange {
    return originalDefinition && updatedDefinition ? compare(originalDefinition, updatedDefinition) : [];
}

function applyChange(template, change: TemplateChange) {
    const applied = applyPatch(template, change, undefined, false);
    return applied[applied.length - 1]?.newDocument || template;
}
export function rebuildHistory(
    template: ComponentContainerHistoryItem,
    changes: TemplateChange[],
): ComponentContainerHistoryItem[] {
    const history = [template];
    let latestDefinition = template;
    // eslint-disable-next-line no-restricted-syntax
    for (const change of changes.filter(c => c.length)) {
        latestDefinition = applyChange(latestDefinition, change);
        history.push(latestDefinition);
    }

    return history;
}
