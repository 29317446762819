import {
    FeaturesConfig,
    NUMERIC_VALUE_COMPONENT_ENABLED,
    SAVED_CONTENT_ENABLED,
    ComponentType,
    COMPONENT_TYPE_LABELS,
    CODED_COMPONENTS_ENABLED,
} from "@emisgroup/clint-templates-common";

type SectionListItem = {
    name: string;
    componentTypes: ComponentType[];
};

type ComponentData = {
    name: string;
    type: ComponentType;
};

export type ComponentSectionData = {
    name: string;
    components: ComponentData[];
};

const codedComponentTypes = [ComponentType.CODED, ComponentType.CODED_PICKING_LIST, ComponentType.DIARY_ENTRY];

const sectionList: SectionListItem[] = [
    {
        name: "templates.availableComponents.groups.coded",
        componentTypes: codedComponentTypes,
    },
    {
        name: "templates.availableComponents.groups.uncoded",
        componentTypes: [
            ComponentType.UNCODED,
            ComponentType.PICKING_LIST,
            ComponentType.NUMERIC_VALUE,
            ComponentType.FREE_TEXT,
        ],
    },
    {
        name: "templates.availableComponents.groups.calculations",
        componentTypes: [ComponentType.CALCULATOR],
    },
    {
        name: "templates.availableComponents.groups.layout",
        componentTypes: [ComponentType.PANEL, ComponentType.TAB_CONTAINER],
    },
    {
        name: "templates.availableComponents.groups.guidance",
        componentTypes: [ComponentType.BRANDING, ComponentType.CLINICAL_CONTENT],
    },
    {
        name: "templates.availableComponents.groups.savedContent",
        componentTypes: [ComponentType.SAVED_CONTENT],
    },
];

const isComponentAvailable = (features: FeaturesConfig, type: ComponentType) => {
    if (type === ComponentType.NUMERIC_VALUE) {
        return features[NUMERIC_VALUE_COMPONENT_ENABLED];
    }

    if (
        type === ComponentType.CALCULATOR ||
        codedComponentTypes.some(codedComponentType => codedComponentType === type)
    ) {
        return features[CODED_COMPONENTS_ENABLED];
    }

    if (type === ComponentType.SAVED_CONTENT) {
        return features[SAVED_CONTENT_ENABLED];
    }
    return true;
};

const getComponentSectionData = (
    features: FeaturesConfig,
    sectionListItem: SectionListItem,
): ComponentSectionData | undefined => {
    const availableComponentTypes = sectionListItem.componentTypes.filter(type => isComponentAvailable(features, type));

    return availableComponentTypes.length === 0
        ? undefined
        : {
              name: sectionListItem.name,
              components: availableComponentTypes.map(type => ({ name: COMPONENT_TYPE_LABELS[type], type })),
          };
};

const componentSectionList = (features: FeaturesConfig): ComponentSectionData[] => {
    return sectionList.reduce((componentSections, sectionListItem) => {
        const componentSectionData = getComponentSectionData(features, sectionListItem);
        if (componentSectionData) componentSections.push(componentSectionData);
        return componentSections;
    }, [] as ComponentSectionData[]);
};

export default componentSectionList;
