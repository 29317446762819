import { CanvasItem, PropertyAttributes, TemplateContext } from "@emisgroup/clint-templates-common";
import { useTranslation } from "@emisgroup/application-intl";
import { updatePerspectiveProperty } from "@emisgroup/clint-templates-perspectives/lib";
import * as React from "react";
import {
    InvalidPerspective,
    InvalidProperties,
    ParametersForPropertyUpdate,
    PerspectiveValidities,
    PropertyAndPerspectiveValidities,
    PropertyValidity,
} from "../types";
import { areAllComponentPropertiesValid, getComponentPropertyValidity } from "../utils/componentUtils";
import { getUpdateSideEffects } from "../utils/updateUtils";

// Create an InvalidPerspective from some PerspectiveValidities
const createInvalidPerspectiveToDisplay = (
    showAllInvalidProperties: boolean,
    perspectiveValidities: PerspectiveValidities,
    interactedPropertyNames: string[],
): InvalidPerspective => {
    return {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        ...getInvalidPropertiesToDisplay(showAllInvalidProperties, perspectiveValidities, interactedPropertyNames),
        key: perspectiveValidities.key,
    };
};

// Add just the invalid InvalidPerspectives from some PerspectiveValidities
const addInvalidPerspectivesToDisplay = (
    name: string,
    invalidProps: InvalidProperties,
    showAllInvalidProperties: boolean,
    interactedPropertyNames: string[],
    perspectivesValidities: PerspectiveValidities[],
): InvalidProperties => {
    const perspectivesValiditiesToDisplay = perspectivesValidities.map(perspectiveValidities =>
        createInvalidPerspectiveToDisplay(showAllInvalidProperties, perspectiveValidities, interactedPropertyNames),
    );
    return {
        ...invalidProps,
        [name]: perspectivesValiditiesToDisplay,
    };
};

// Get just the InvalidProperties from some PropertyAndPerspectiveValidities
function getInvalidPropertiesToDisplay(
    showAllInvalidProperties: boolean,
    propertyAndPerspectiveValidities: PropertyAndPerspectiveValidities,
    interactedPropertyNames: string[],
): InvalidProperties {
    return Object.entries(propertyAndPerspectiveValidities)
        .filter(value => Array.isArray(value) || (value as PropertyValidity).isValid === false)
        .filter(([propertyName]) => showAllInvalidProperties || interactedPropertyNames.includes(propertyName))
        .reduce(
            (invalidProps, [name, value]) =>
                Array.isArray(value)
                    ? addInvalidPerspectivesToDisplay(
                          name,
                          invalidProps,
                          showAllInvalidProperties,
                          interactedPropertyNames,
                          value as PerspectiveValidities[],
                      )
                    : { ...invalidProps, [name]: (value as PropertyValidity).message || "" },
            {},
        );
}

function useProperties<T>(item: CanvasItem, setItems: (items: T[]) => void, validateAll?: boolean) {
    const { t } = useTranslation();
    const { templateDefinition } = React.useContext(TemplateContext);
    const [showAllInvalidProperties, setShowAllInvalidProperties] = React.useState(Boolean(validateAll));
    const interactedPropertyNames = React.useRef<string[]>([]);

    const invalidProperties = React.useMemo(() => {
        return getInvalidPropertiesToDisplay(
            showAllInvalidProperties,
            getComponentPropertyValidity(t, item),
            interactedPropertyNames.current,
        );
    }, [showAllInvalidProperties, item, interactedPropertyNames.current]);

    const updateItemProperty = (
        { item: component, propertyName, propertyValue, perspectiveKey }: ParametersForPropertyUpdate,
        properties: PropertyAttributes[],
    ) => {
        if (!component) {
            return;
        }
        if (!interactedPropertyNames.current.includes(propertyName)) {
            interactedPropertyNames.current = [...interactedPropertyNames.current, propertyName];
        }

        const propertyAttributes = properties.find(prop => prop.name === propertyName);
        const { updatedItem } =
            propertyAttributes && propertyAttributes.dataAttributes && propertyAttributes.dataAttributes.onUpdate
                ? propertyAttributes.dataAttributes.onUpdate(propertyValue, component)
                : { updatedItem: component };

        const perspectives = perspectiveKey
            ? updatePerspectiveProperty(updatedItem, propertyName, propertyValue, perspectiveKey)
            : undefined;
        const update = perspectiveKey
            ? { ...updatedItem, perspectives }
            : ({ ...updatedItem, [propertyName]: propertyValue } as any);
        const itemsToUpdate = [update];
        itemsToUpdate.push(...getUpdateSideEffects(item, update, templateDefinition));
        setItems(itemsToUpdate);
    };

    const showAllValidation = () => {
        setShowAllInvalidProperties(true);
    };

    const areAllPropertiesValid = () => areAllComponentPropertiesValid(t, item);

    return { areAllPropertiesValid, invalidProperties, updateItemProperty, showAllValidation };
}

export default useProperties;
