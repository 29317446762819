import * as React from "react";
import { Clear } from "@emisgroup/icons-react";
import { useTranslation } from "@emisgroup/application-intl";
import { PickingListOption } from "@emisgroup/clint-templates-common";
import VisibilityRuleIndicator from "./visibilityRuleIndicator";
import ListBuilderConditionalPanelButton from "./listBuilderConditionalPanelButton";
import { PropertyInspectorContext } from "./propertyInspectorContext";

type ListBuilderOptionButtonsProps = {
    isReadOnly: boolean;
    optionIndex: number;
    showVisibilityIndicator: boolean;
    componentId: string;
    option: PickingListOption;
    onRemove: () => void;
};
export default function ListBuilderOptionButtons({
    isReadOnly,
    optionIndex,
    showVisibilityIndicator,
    componentId,
    option,
    onRemove,
}: ListBuilderOptionButtonsProps) {
    const { t } = useTranslation();
    const { isNewItem } = React.useContext(PropertyInspectorContext);

    return (
        <div className="list-builder-option-buttons">
            {showVisibilityIndicator && (
                <VisibilityRuleIndicator
                    componentId={componentId}
                    optionValue={option.id}
                    dataTestId={`list-item-rule-indicator-${optionIndex}`}
                />
            )}

            {!isReadOnly && (
                <>
                    {!isNewItem && (
                        <ListBuilderConditionalPanelButton
                            dataTestId={`list-item-condition-panel-button-${optionIndex}`}
                            option={option}
                        />
                    )}
                    <button
                        type="button"
                        data-testid={`list-item-remove-button-${optionIndex}`}
                        className="btn btn-default option-button"
                        onClick={onRemove}
                    >
                        <Clear title={t("listEntry.removeOption")} />
                    </button>
                </>
            )}
        </div>
    );
}
