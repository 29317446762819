import * as React from "react";
import {
    PickingListComponent,
    PickingListOption,
    RunningTemplateContext,
    TemplateDataItem,
} from "@emisgroup/clint-templates-common";

type RadioButtonPickingListProps = {
    pickingList: PickingListComponent;
    dataItem: TemplateDataItem;
};

export default function RadioButtonPickingList({ pickingList, dataItem }: RadioButtonPickingListProps) {
    const { templateData, updateTemplateData } = React.useContext(RunningTemplateContext);
    const listName = `radios-${pickingList.id}`;
    const selectedItem = dataItem && dataItem.items ? dataItem.items[0] : null;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedOption = pickingList.options.find(({ id }) => id === e.currentTarget.value);

        updateTemplateData({
            ...templateData,
            [pickingList.id]: {
                ...dataItem,
                items: selectedOption ? [{ ...selectedItem, value: selectedOption, selected: true }] : [],
                interacted: true,
            },
        });
    };

    const handleBlur = () => {
        updateTemplateData({
            ...templateData,
            [pickingList.id]: { ...dataItem, interacted: true },
        });
    };

    return (
        <>
            <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--s">
                <h1 className="nhsuk-fieldset__heading">{pickingList.label}</h1>
            </legend>

            <div className="nhsuk-radios">
                {pickingList.options.map((option, index) => (
                    <div key={option.id} className="nhsuk-radios__item">
                        <input
                            className="nhsuk-radios__input"
                            id={`${pickingList.id}-option-${index}`}
                            name={listName}
                            type="radio"
                            value={option.id}
                            checked={Boolean(
                                selectedItem && option.id === (selectedItem?.value as PickingListOption)?.id,
                            )}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                        <label
                            className="nhsuk-label nhsuk-radios__label"
                            htmlFor={`${pickingList.id}-option-${index}`}
                        >
                            {option.text}
                        </label>
                    </div>
                ))}
            </div>
        </>
    );
}
