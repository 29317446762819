import * as React from "react";

type DesignLayoutContextType = {
    isPropInspectorExpanded: boolean;
    togglePropInspectorExpanded: () => void;
};

export const DesignLayoutContext = React.createContext<DesignLayoutContextType>({
    isPropInspectorExpanded: false,
    togglePropInspectorExpanded: () => {},
});

type DesignLayoutProviderProps = { children: React.ReactNode | React.ReactNodeArray };

export const DesignLayoutProvider = ({ children }: DesignLayoutProviderProps) => {
    const [isPropInspectorExpanded, setIsPropInspectorExpanded] = React.useState(false);

    return (
        <DesignLayoutContext.Provider
            value={{
                isPropInspectorExpanded,
                togglePropInspectorExpanded: () => setIsPropInspectorExpanded(!isPropInspectorExpanded),
            }}
        >
            {children}
        </DesignLayoutContext.Provider>
    );
};
