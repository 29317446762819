import * as React from "react";
import { TemplateDataItem } from "../types";

type AssociatedTextProps = {
    id?: string;
    text?: string;
    placeholder?: string;
    ariaLabel?: string;
    disabled?: boolean;
    templateData?: TemplateDataItem;
    onBlur?: (text: string) => void;
    onChange?: (text: string) => void;
    runtimeValidator?: (data: TemplateDataItem) => boolean;
};

const MultilineTextEntry = ({
    id,
    text,
    placeholder,
    ariaLabel,
    disabled,
    templateData,
    onBlur,
    onChange,
    runtimeValidator,
}: AssociatedTextProps) => {
    const editorDiv = React.useRef<HTMLDivElement>(null);
    const handlePaste = (evt: React.ClipboardEvent) => {
        evt.preventDefault();
        document.execCommand("inserttext", false, evt.clipboardData.getData("text/plain"));
    };

    const handleBlur = (evt: React.FocusEvent) => {
        if (onBlur) {
            onBlur(evt.target.textContent || "");
        }
    };
    React.useEffect(() => {
        const handleEditorInput = ev => {
            (onChange as (text: string) => void)(ev.target.textContent);
        };

        if (onChange) {
            editorDiv.current?.addEventListener("input", handleEditorInput);
        }

        return () => editorDiv.current?.removeEventListener("input", handleEditorInput);
    }, [editorDiv.current, onChange]);

    React.useEffect(() => {
        if (editorDiv.current && editorDiv.current.textContent !== text) {
            editorDiv.current.textContent = text || "";
        }
    }, [text]);

    const validationClassName =
        runtimeValidator && templateData && !runtimeValidator(templateData) ? " eui-text-input--invalid" : "";

    return (
        <div
            id={id}
            ref={editorDiv}
            className={`multiline-text ${disabled ? "disabled" : ""} eui-text-input${validationClassName}`}
            placeholder={placeholder}
            onBlur={handleBlur}
            onPaste={handlePaste}
            contentEditable={!disabled}
            suppressContentEditableWarning
            aria-label={ariaLabel}
            role="textbox"
            tabIndex={disabled ? undefined : 0}
        />
    );
};

export default MultilineTextEntry;
