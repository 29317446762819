import React from "react";
import { useTranslation } from "@emisgroup/application-intl";
import { ComponentSectionData } from "./componentSectionList";
import TemplateComponent from "./templateComponent";

type TemplateComponentSectionProps = { componentSectionData: ComponentSectionData };

const TemplateComponentSection = ({ componentSectionData }: TemplateComponentSectionProps) => {
    const { t } = useTranslation();
    return (
        <div className="library-section">
            <div className="library-section-title">{t(componentSectionData.name)}</div>
            {componentSectionData.components.map(component => (
                <TemplateComponent
                    label={component.name}
                    type={component.type}
                    key={`${component.name}-${component.type}`}
                />
            ))}
        </div>
    );
};

export default TemplateComponentSection;
