/* eslint-disable import/prefer-default-export */
import * as React from "react";
import { Code } from "@emisgroup/clint-templates-common";
import { v4 as uuid } from "uuid";
import { LIST_ITEM_DRAG_TYPE } from "../constants";
import addDragDropSort from "./addDragDropSort";
import InlineCodeItem from "./inlineCodeItem";

const SortableInlineCodeItem = addDragDropSort(InlineCodeItem, LIST_ITEM_DRAG_TYPE);

type CodeBasketProps = {
    codes: Code[];
    updateCodes: (codes: Code[]) => void;
    includeDetails: boolean;
    isReadOnly?: boolean;
};

const swapCodes = (codes: Code[], fromIndex: number, toIndex: number) => {
    const updatedCodes = codes.slice();
    const to = updatedCodes[toIndex];
    updatedCodes[toIndex] = updatedCodes[fromIndex];
    updatedCodes[fromIndex] = to;

    return updatedCodes;
};

let uniqueIds: { code: Code; id: string }[] = [];

const getUniqueCodeId = (code: Code) => {
    const existingId = uniqueIds.find(c => c.code === code);
    if (existingId) {
        return existingId.id;
    }

    const id = uuid();
    uniqueIds.push({ code, id });
    return id;
};
export const InlineCodeBasket = ({ codes, updateCodes, includeDetails, isReadOnly }: CodeBasketProps) => {
    if (codes.length === 0) {
        return null;
    }

    React.useEffect(() => {
        uniqueIds = [];
    }, []);

    const handleRemove = (indexInCodeList: number) => () => {
        const updatedCodes = [...codes];
        updatedCodes.splice(indexInCodeList, 1);
        updateCodes(updatedCodes);
    };

    const handleSwapItems = (fromIndex: number, toIndex: number) => {
        if (typeof fromIndex === "undefined" || typeof toIndex === "undefined") {
            return;
        }

        updateCodes(swapCodes(codes, fromIndex, toIndex));
    };

    return (
        <div className="inline-code-basket">
            {codes.map((code, index) => {
                const id = getUniqueCodeId(code);
                return (
                    <SortableInlineCodeItem
                        key={id}
                        code={code}
                        index={index}
                        includeDetails={includeDetails}
                        onRemove={handleRemove(index)}
                        isReadOnly={isReadOnly}
                        swapItems={handleSwapItems}
                        id={id}
                    />
                );
            })}
        </div>
    );
};
