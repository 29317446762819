import React from "react";
import { ButtonDropdown, IRadioItem, RadioButtons } from "@emisgroup/ui-kit-react";
import { ConfidentialLock } from "@emisgroup/icons-react";
import {
    CanvasItem,
    Rule,
    ConditionOperator,
    Condition,
    ComponentCondition,
    findCanvasItem,
    TemplateContext,
    QueryCondition,
    ConditionSource,
    AuthContext,
    ConfigContext,
    ConditionSourceType,
} from "@emisgroup/clint-templates-common";
import { useTranslation } from "@emisgroup/application-intl";
import "./visibilityRules.css";
import { QUERY_RESOURCE_TYPE } from "@emisgroup/clint-content/lib/constants";
import { ContentLibraryItem } from "@emisgroup/content-picker/lib/types";
import { ContentPicker } from "@emisgroup/content-picker";
import VisibilityRulesList from "./visibilityRulesList";
import { getAvailableRuleComponentStates, getRuleConditionSourcesForItem } from "../utils/componentUtils";
import { canvasItemCanHaveVisibilityRules, getConditionSourceType, getConditionWithNewState } from "../utils/ruleUtils";
import ComponentSelectorDialog from "./componentSelectorDialog";
import { createVisibilityRulesComponentTree } from "../utils/componentSelectorUtils";

enum DialogBoxType {
    SELECTING_QUERY,
    SELECTING_COMPONENT,
}

type VisibilityDialogState = {
    dialogBoxType?: DialogBoxType;
    conditionIndex?: number;
};

type PropsForVisibilityRules = {
    show: boolean;
    editEnabled: boolean;
    selectedCanvasItem: CanvasItem;
    disallowedItems?: CanvasItem[];
    invalidRuleConditions?: any;
    onRuleAddOrUpdate?: (rule: Rule | null) => void;
    onUpdateRuleConditionValidity?: (conditionIndex: number) => void;
};

const VisibilityRules = ({
    show,
    editEnabled,
    selectedCanvasItem,
    disallowedItems,
    invalidRuleConditions,
    onRuleAddOrUpdate = () => ({}),
    onUpdateRuleConditionValidity = () => ({}),
}: PropsForVisibilityRules) => {
    const { t } = useTranslation();
    const { getBearerToken } = React.useContext(AuthContext);
    const { contentLibraryUrl } = React.useContext(ConfigContext);
    const { templateDefinition } = React.useContext(TemplateContext);
    const [visibilityDialog, setVisibilityDialog] = React.useState<VisibilityDialogState>({});
    const [addedConditionIndex, setAddedConditionIndex] = React.useState<number>();
    const visibilityRuleDivRef = React.useRef<HTMLDivElement>(null);
    const rule = selectedCanvasItem.rule ?? {
        conditionOperator: ConditionOperator.ALL,
        conditionMembers: [],
    };

    const canEditRules = editEnabled && (canvasItemCanHaveVisibilityRules(selectedCanvasItem) ?? false);

    const conditionOperatorOptions: IRadioItem[] = [
        {
            text: t("templates.rules.allRules"),
            value: ConditionOperator.ALL,
            disabled: !canEditRules,
        },
        {
            text: t("templates.rules.anyRules"),
            value: ConditionOperator.ANY,
            disabled: !canEditRules,
        },
    ];

    const selectedConditionOperatorText = conditionOperatorOptions.filter(c => c.value === rule.conditionOperator)[0]
        .text;

    const updateCondition = (conditionIndex: number, updatedCondition: Condition) => {
        const newRuleAfterUpdate: Rule = {
            ...rule,
            conditionMembers: rule.conditionMembers.map((conditionMember, index) =>
                conditionIndex === index ? updatedCondition : conditionMember,
            ),
        };

        onRuleAddOrUpdate(newRuleAfterUpdate);
        onUpdateRuleConditionValidity(conditionIndex);
    };

    const deleteCondition = (conditionIndex: number) => {
        const newConditionMembers = rule.conditionMembers.filter((_, index) => index !== conditionIndex);
        const newRuleAfterDeletion =
            newConditionMembers.length > 0
                ? {
                      ...rule,
                      conditionOperator:
                          newConditionMembers.length > 1 ? rule.conditionOperator : ConditionOperator.ALL,
                      conditionMembers: newConditionMembers,
                  }
                : null;

        onRuleAddOrUpdate(newRuleAfterDeletion);
    };

    const selectNewSource = (conditionIndex: number) => {
        const condition: Condition = rule.conditionMembers[conditionIndex] as Condition;
        const dialogBoxType =
            getConditionSourceType(condition) === ConditionSourceType.QUERY
                ? DialogBoxType.SELECTING_QUERY
                : DialogBoxType.SELECTING_COMPONENT;

        setVisibilityDialog({ dialogBoxType, conditionIndex });
    };

    const updateConditionOperator = (radioItem: IRadioItem) => {
        const conditionOperator = radioItem.value as ConditionOperator;
        const newRuleAfterUpdate: Rule = {
            ...rule,
            conditionOperator,
        };

        onRuleAddOrUpdate(newRuleAfterUpdate);
    };

    const addComponentCondition = (actorCanvasItemId: string) => {
        const actorCanvasItem = findCanvasItem(actorCanvasItemId, templateDefinition.members);

        const defaultConditionSourceForItem = getRuleConditionSourcesForItem(actorCanvasItem)[0];

        const condition: ComponentCondition = {
            conditionSource: defaultConditionSourceForItem,
            actorCanvasItemId,
        };

        const defaultComponentState = getAvailableRuleComponentStates(t, actorCanvasItem)[0].value;

        const newComponentCondition: Condition = getConditionWithNewState(condition, defaultComponentState);

        if (typeof visibilityDialog.conditionIndex !== "undefined") {
            updateCondition(visibilityDialog.conditionIndex, newComponentCondition);
        } else {
            const ruleWithNewCondition: Rule = {
                ...rule,
                conditionMembers: rule.conditionMembers.concat(newComponentCondition),
            };
            setAddedConditionIndex(ruleWithNewCondition.conditionMembers.length - 1);
            onRuleAddOrUpdate(ruleWithNewCondition);
        }

        setVisibilityDialog({});
    };

    const addQueryCondition = (queryItem: ContentLibraryItem) => {
        const queryCondition: QueryCondition = {
            conditionSource: ConditionSource.QUERY,
            queryId: queryItem.ern.replace(/emis:resource:/, ""),
            queryName: queryItem.name,
        };

        if (typeof visibilityDialog.conditionIndex !== "undefined") {
            updateCondition(visibilityDialog.conditionIndex, queryCondition);
            return;
        }

        const ruleWithNewCondition: Rule = {
            ...rule,
            conditionMembers: rule.conditionMembers.concat(queryCondition),
        };

        setAddedConditionIndex(ruleWithNewCondition.conditionMembers.length - 1);
        onRuleAddOrUpdate(ruleWithNewCondition);
    };

    const addRuleButton = (
        <ButtonDropdown
            className="visibility-rules-list_add"
            buttonVariant="primary"
            data={[
                [
                    {
                        action: () => setVisibilityDialog({ dialogBoxType: DialogBoxType.SELECTING_COMPONENT }),
                        text: t("templates.rules.componentOnThisPage"),
                    },
                    {
                        action: () => setVisibilityDialog({ dialogBoxType: DialogBoxType.SELECTING_QUERY }),
                        text: t("templates.rules.queryFromContentLibrary"),
                    },
                ],
            ]}
            buttonText={t("templates.rules.addVisibilityRule")}
            aria-label={t("templates.rules.addVisibilityRule")}
            data-testid="add-visibility-rule"
        />
    );

    const handleBlur = (e: React.FocusEvent) => {
        if (e.currentTarget.contains(e.relatedTarget as Node)) {
            e.stopPropagation();
        } else {
            onRuleAddOrUpdate(rule);
            setAddedConditionIndex(undefined);
        }
    };

    React.useEffect(() => {
        if (typeof addedConditionIndex !== "undefined" && visibilityRuleDivRef.current?.scrollIntoView) {
            visibilityRuleDivRef.current.scrollIntoView();
        }
    }, [addedConditionIndex]);

    const selectedConditionActorId =
        visibilityDialog?.dialogBoxType === DialogBoxType.SELECTING_COMPONENT &&
        typeof visibilityDialog.conditionIndex !== "undefined"
            ? (rule.conditionMembers[visibilityDialog.conditionIndex] as ComponentCondition).actorCanvasItemId
            : undefined;

    return (
        <div
            className={`sidebar-right visibility-rules ${show ? "" : "hidden"}`}
            data-testid="visibility-rules-panel"
            onBlur={handleBlur}
            ref={visibilityRuleDivRef}
        >
            <div className="sidebar-row sidebar-row-header">
                {t("templates.rules.visibilityRules")}
                {!editEnabled && (
                    <ConfidentialLock title={t("templates.rules.visibilityRulesLocked")} className="panel-lock" />
                )}
            </div>

            {selectedCanvasItem.id && (
                <div className="visibility-rules-display">
                    <VisibilityRulesList
                        canvasItemLabel={selectedCanvasItem.label}
                        rule={rule}
                        invalidRuleConditions={invalidRuleConditions}
                        canEditRules={canEditRules}
                        onDelete={deleteCondition}
                        onUpdate={updateCondition}
                        onSelectNewSource={selectNewSource}
                    />

                    {rule.conditionMembers.length > 1 && (
                        <>
                            <div
                                data-testid="conditionJoinSelect"
                                className="sidebar-row visibility-rules-list__join-select"
                            >
                                <div className="visibility-rules-list__join-select__with">
                                    {t("templates.rules.with")}
                                </div>
                                <RadioButtons
                                    selected={selectedConditionOperatorText}
                                    name="with"
                                    options={conditionOperatorOptions}
                                    onChange={updateConditionOperator}
                                />
                            </div>
                        </>
                    )}

                    {canEditRules && addRuleButton}
                </div>
            )}
            {visibilityDialog.dialogBoxType === DialogBoxType.SELECTING_QUERY && (
                <div data-testid="query-picker-dialog">
                    <ContentPicker
                        resourceTypes={[QUERY_RESOURCE_TYPE]}
                        contentLibraryUrl={contentLibraryUrl}
                        getBearerToken={getBearerToken}
                        onClose={() => {
                            setVisibilityDialog({});
                        }}
                        onError={() => setVisibilityDialog({})}
                        onLoaded={(queryItem: ContentLibraryItem) => {
                            addQueryCondition(queryItem);
                            setVisibilityDialog({});
                        }}
                        onlyLoadIdentifier={true}
                        showInDialog={true}
                    />
                </div>
            )}
            {visibilityDialog.dialogBoxType === DialogBoxType.SELECTING_COMPONENT && (
                <ComponentSelectorDialog
                    componentsTreeRootNode={createVisibilityRulesComponentTree(
                        t,
                        templateDefinition,
                        selectedCanvasItem.id,
                        disallowedItems?.map(item => item.id),
                    )}
                    showComponentId={selectedConditionActorId || selectedCanvasItem.id}
                    selectedComponentId={selectedConditionActorId}
                    onCancel={() => setVisibilityDialog({})}
                    onSelect={id => {
                        addComponentCondition(id);
                    }}
                />
            )}
        </div>
    );
};

export default VisibilityRules;
