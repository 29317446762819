import { ComponentContainer, ComponentConfigData, ComponentType, Code } from "@emisgroup/clint-templates-common";

export type ConfigForComponents = {
    data: Array<ComponentConfigData>;
    error?: string;
};

export type TemplateFilingItem = {
    componentId: string;
    category: string;
    type: ComponentType;
    code?: Code;
    value?: any;
};

export enum RunStyle {
    Clinical = "clinical",
    Assessment = "assessment",
}

export type RunningContainerProps = {
    className?: string;
    container: ComponentContainer;
    columnCount?: number;
    hasCustomActionButtons?: boolean;
};
