/* eslint-disable import/prefer-default-export */
const YEAR_INDEX = 0;
const MONTH_INDEX = 1;
const DAY_INDEX = 2;

const monthText = {
    1: "months.jan",
    2: "months.feb",
    3: "months.mar",
    4: "months.apr",
    5: "months.may",
    6: "months.jun",
    7: "months.jul",
    8: "months.aug",
    9: "months.sep",
    10: "months.oct",
    11: "months.nov",
    12: "months.dec",
};
const getDatePart = (part: string, index: number) => {
    switch (index) {
        case YEAR_INDEX:
            return part;
        case MONTH_INDEX:
            return monthText[Number(part)];
        case DAY_INDEX:
            return part.split("T")[0];
        default:
            return "";
    }
};

export const getDateDescriptionFromYMD = (t, dateYMD: string) => {
    const parts = dateYMD.split("-");
    if (parts.length < 2) return dateYMD;
    return parts
        .slice(1)
        .map((d, i) => t(getDatePart(d, i)))
        .reverse()
        .join("-");
};
