import * as React from "react";

const findSection = (element: HTMLElement | null): HTMLElement | null => {
    if (!element) {
        return null;
    }

    if (element.classList.contains("eui-dynamic-tabs")) {
        return null;
    }

    return element.classList.contains("eui-dynamic-tabs__section") ? element : findSection(element.parentElement);
};

const useSelectedTab = (tabRef: React.RefObject<HTMLElement>, isSelected: boolean) => {
    React.useEffect(() => {
        if (!isSelected || !tabRef.current) {
            return;
        }

        const section = findSection(tabRef.current.parentElement);
        if (!section) {
            return;
        }

        const { left: tabLeft, right: tabRight } = tabRef.current.getBoundingClientRect();
        const { left: sectionLeft, right: sectionRight } = section.getBoundingClientRect();
        if (tabLeft < sectionLeft || tabRight > sectionRight) {
            tabRef.current.scrollIntoView();
        }
    }, [isSelected]);
};

export default useSelectedTab;
