import * as React from "react";
import {
    AppMode,
    AppModeContext,
    AuthContext,
    ConfigContext,
    TemplateContext,
    ActionType,
    ActionData,
    PerspectiveSetting,
    PERSPECTIVES,
} from "@emisgroup/clint-templates-common";
import { ErrorHandling, ErrorState } from "@emisgroup/clinical-code-entry/lib/types";
import { useTranslation } from "@emisgroup/application-intl";
import { Runner } from "@emisgroup/clint-templates-runner";
import { getDefaultPerspectivesKeyForSetting } from "@emisgroup/clint-templates-perspectives";
import AppCanvas from "./uiComponents/appCanvas";
import { ParametersForPropertyUpdate } from "./types";
import { SelectedPatientContext } from "./context/selectedPatientContext";
import { TestRunMode, TestRunModeContext } from "./context/testRunMode";

type CanvasProps = { onSelect: (eventData) => void; onPropertyUpdate: (params: ParametersForPropertyUpdate) => void };

const Canvas = (props: CanvasProps) => {
    const { t } = useTranslation();
    const { mode } = React.useContext(AppModeContext);
    const { templateDefinition } = React.useContext(TemplateContext);
    const { getBearerToken } = React.useContext(AuthContext);
    const { patientId } = React.useContext(SelectedPatientContext);
    const { freeTextMaxChars, platformUrl, features } = React.useContext(ConfigContext);
    const { updateActionData, mode: testRunMode, runStyle, runDimensionValues } = React.useContext(TestRunModeContext);
    const { updateMode: updateAppMode } = React.useContext(AppModeContext);
    const cancelTemplate = () => updateAppMode(AppMode.EDIT);
    const defaultPerspectivesKey = React.useMemo(
        () => getDefaultPerspectivesKeyForSetting(features[PERSPECTIVES], PerspectiveSetting.RUNNER),
        [],
    );

    const onHandleError = (errorState: ErrorState): ErrorHandling => {
        console.error(errorState.error);
        return {
            displayMessage: t("templates.errorProblemDetails"),
            retryButton: true,
        };
    };

    const onHandleAction = (_: ActionType, data: ActionData) => {
        updateActionData(data);
    };

    if (mode === AppMode.RUN) {
        return (
            <div className="canvas-builder-run-container col-12">
                <div className={`canvas-builder-run${testRunMode === TestRunMode.Compact ? " compact" : ""}`}>
                    <Runner
                        templateDefinition={templateDefinition}
                        getBearerToken={getBearerToken}
                        cancelTemplate={cancelTemplate}
                        queryApiUrl={process.env.APP_QUERY_API_URL as string}
                        ckbUrl={process.env.APP_CLINKB_API_URL || ""}
                        patientId={patientId}
                        componentOptions={{ freeTextMaxChars }}
                        platformUrl={platformUrl}
                        onHandleError={onHandleError}
                        showDefaultActionButtons={true}
                        onHandleAction={onHandleAction}
                        runStyle={runStyle}
                        dimensionValues={runDimensionValues}
                        defaultPerspectivesKey={defaultPerspectivesKey}
                    />
                </div>
            </div>
        );
    }

    return <AppCanvas {...props} />;
};

export default Canvas;
