import { useTranslation } from "@emisgroup/application-intl";
import { CanvasItem, ComponentType, Panel } from "@emisgroup/clint-templates-common";
import * as React from "react";
import { validateActionButtons } from "../utils/panelActionUtils";

const getInvalidInteractedPanelActions = (panelActionErrors: any, interactedPanelActions: number[]) =>
    Object.entries(panelActionErrors)
        .filter(([panelActionIndex]) => interactedPanelActions.includes(Number(panelActionIndex)))
        .reduce((result, [panelActionIndex, errors]) => ({ ...result, [panelActionIndex]: errors }), {});

function usePanelActions(canvasItem: CanvasItem) {
    const { t } = useTranslation();

    const panelActionButtons = (canvasItem as Panel).actionButtons ?? [];

    const interactedPanelActionIndexes = React.useRef<number[]>([]);

    React.useEffect(() => {
        interactedPanelActionIndexes.current = Array.from(panelActionButtons.keys());
    }, [canvasItem.id]);

    const invalidPanelActions = React.useMemo(
        () =>
            canvasItem.type === ComponentType.PANEL
                ? getInvalidInteractedPanelActions(
                      validateActionButtons(t, panelActionButtons),
                      interactedPanelActionIndexes.current,
                  )
                : [],

        [canvasItem, interactedPanelActionIndexes.current],
    );

    const onPanelActionInteracted = (panelActionIndex: number, clearInteracted = false) => {
        if (clearInteracted) {
            interactedPanelActionIndexes.current = interactedPanelActionIndexes.current.reduce<number[]>(
                (result, index) => {
                    if (index < panelActionIndex) {
                        result.push(index);
                    } else if (index > panelActionIndex) {
                        result.push(index - 1);
                    }

                    return result;
                },
                [],
            );
        } else if (!interactedPanelActionIndexes.current.includes(panelActionIndex)) {
            interactedPanelActionIndexes.current = [...interactedPanelActionIndexes.current, panelActionIndex];
        }
    };

    const areAllPanelActionsValid = () => Object.entries(invalidPanelActions).length === 0;

    return { areAllPanelActionsValid, invalidPanelActions, onPanelActionInteracted };
}

export default usePanelActions;
