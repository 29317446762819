import { useTranslation } from "@emisgroup/application-intl";
import { getAlertDialog, AlertType } from "@emisgroup/clint-templates-common";

type Params = {
    retry: () => void;
    cancel: () => void;
    errorCode: string;
};

const getConfigComponentWarning = ({ retry, cancel, errorCode }: Params) => {
    const { t } = useTranslation();
    const dialogButtons = [
        {
            onClick: retry,
            label: t("retry"),
            isPrimary: true,
            ariaLabel: t("retry"),
            disabled: false,
        },
        {
            onClick: cancel,
            label: t("cancel"),
            isPrimary: false,
            ariaLabel: t("cancel"),
        },
    ];

    if (errorCode) console.error(errorCode);

    const content = (
        <>
            <p>{t("canvas.componentConfigUnavailable.detail")}</p>
        </>
    );

    return getAlertDialog(
        t("canvas.componentConfigUnavailable.title"),
        content,
        dialogButtons,
        cancel,
        AlertType.ERROR,
    );
};

export default getConfigComponentWarning;
