import React, { useEffect } from "react";
import { setupInteropForApplication, detachInteropForApplication } from "@emisgroup/single-spa-state-management";
import { ApplicationContextProvider } from "@emisgroup/single-spa-state-react";
import { IInteropProps } from "@emisgroup/single-spa-library";
import { Store } from "redux";
import { Layout } from "./layout/Index";
import defaultApplicationContext from "./localDev/defaults/AppContext";
import { getAppStore } from "./store/AppStore";

export interface IApplicationProps extends Omit<IInteropProps, "sessionContext"> {
    isRunningInApplicationFrame: boolean;
    store: Store;
}
/**
 * Responsible for rendering the Application component
 */
const Application = (props: IApplicationProps): JSX.Element => {
    /**
     * This section should be used to setup the application when the application
     * is navigated to in the frame.
     */
    const onApplicationMount = (): void => {
        setupInteropForApplication(props.applicationContext, props.store);
    };

    /**
     * This section should is used to clean up when the application
     * is navigated away from in the frame.
     */
    const onApplicationUnMount = (): void => {
        detachInteropForApplication(props.applicationContext);
    };

    useEffect(() => {
        onApplicationMount();

        return (): void => {
            onApplicationUnMount();
        };
    });

    return (
        <ApplicationContextProvider value={props.applicationContext}>
            <Layout isRunningInApplicationFrame={props.isRunningInApplicationFrame} />
        </ApplicationContextProvider>
    );
};

Application.defaultProps = {
    applicationContext: defaultApplicationContext,
    store: getAppStore(defaultApplicationContext),
};

export default Application;
