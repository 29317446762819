import { MAX_STORAGE_SIZE_BYTES } from "../constants";
import { TemplateChange } from "./templateChange";

const byteSize = str => new Blob([str]).size;

export const getSafeHistorySize = (
    definition: any,
    defaultMaxHistoryEntries: number,
    maxSizeBytes: number = MAX_STORAGE_SIZE_BYTES,
) => {
    const size = byteSize(JSON.stringify(definition));
    const safeMaxHistoryEntries = Math.floor(maxSizeBytes / size);
    return Math.min(safeMaxHistoryEntries, defaultMaxHistoryEntries);
};

export const isValidToPersist = (toStore: any, maxSizeBytes: number = MAX_STORAGE_SIZE_BYTES) => {
    const size = byteSize(JSON.stringify(toStore));
    const isValid = size < maxSizeBytes;
    if (!isValid) console.warn(`Definition size ${size} too large to store locally`);
    return isValid;
};

const appendToHistory = (
    templateDefinitionHistory: any[],
    updatedDefinition: any,
    storedHistory: TemplateChange[],
    change: TemplateChange,
    templateHistoryIndex: number,
) => {
    if (templateHistoryIndex !== templateDefinitionHistory.length - 1) {
        // if the history index isn't the last one, then the user has already undone one or more changes
        const updatedHistory = [...templateDefinitionHistory.slice(0, templateHistoryIndex + 1), updatedDefinition];
        const updatedStoredHistory = [...storedHistory.slice(0, templateHistoryIndex + 1), change];
        return {
            history: updatedHistory,
            storedHistory: updatedStoredHistory,
            // in which case, ignore the undo history after the current index, and append the latest change
            index: updatedHistory.length - 1,
        };
    }
    return {
        history: templateDefinitionHistory.concat(updatedDefinition),
        storedHistory: [...storedHistory, change],
        index: templateHistoryIndex + 1,
    };
};

const discardAndAppendToHistory = (
    templateDefinitionHistory: any[],
    updatedDefinition: any,
    storedHistory: TemplateChange[],
    change: TemplateChange,
    templateHistoryIndex: number,
) => {
    if (templateHistoryIndex === templateDefinitionHistory.length - 1) {
        return {
            // we have a full buffer and are at the end, so discard earliest entry
            history: [...templateDefinitionHistory.slice(1), updatedDefinition],
            storedHistory: [...storedHistory.slice(1), change],
            index: templateHistoryIndex,
        };
    }

    // we have a full buffer and are not at the end, so discard entries after the current index
    const updatedHistory = [...templateDefinitionHistory.slice(0, templateHistoryIndex + 1), updatedDefinition];
    const updatedStoredHistory = [...storedHistory.slice(0, templateHistoryIndex + 1), change];
    return {
        history: updatedHistory,
        storedHistory: updatedStoredHistory,
        index: updatedHistory.length - 1,
    };
};

export const addToDefinitionHistory = (
    templateDefinitionHistory: any[],
    updatedDefinition: any,
    storedHistory: TemplateChange[],
    change: TemplateChange,
    templateHistoryIndex: number,
    defaultMaxHistoryEntries: number,
) => {
    const maxHistoryEntries = getSafeHistorySize(updatedDefinition, defaultMaxHistoryEntries);

    return templateDefinitionHistory.length + 1 < maxHistoryEntries
        ? appendToHistory(templateDefinitionHistory, updatedDefinition, storedHistory, change, templateHistoryIndex)
        : discardAndAppendToHistory(
              templateDefinitionHistory,
              updatedDefinition,
              storedHistory,
              change,
              templateHistoryIndex,
          );
};
