import * as React from "react";
import { Checkbox, Dropdown, FormElement, IListItem } from "@emisgroup/ui-kit-react";
import { Clear } from "@emisgroup/icons-react";
import { ConnectDropTarget, ConnectDragSource } from "react-dnd";
import { useTranslation } from "@emisgroup/application-intl";
import { ActionButton, ActionType, ActionButtonStyle } from "@emisgroup/clint-templates-common";
import { DragDropSortProps, DragDropSortInstance } from "./addDragDropSort";
import { ActionButtonProperty } from "../types";
import { anyInvalidActionButtonProperties, getActionButtonPropertyValidity } from "../utils/panelActionUtils";

type PanelActionItemProps = DragDropSortProps & {
    initialActionButton: ActionButton;
    invalidProperties: any;
    isReadOnly: boolean;
    setFocus: boolean;
    onRemove: () => void;
    onUpdate: (actionButton: ActionButton) => void;
    isDragging: boolean;
    connectDragSource: ConnectDragSource;
    connectDropTarget: ConnectDropTarget;
};

const PanelActionItem = React.forwardRef<HTMLDivElement, PanelActionItemProps>(
    (
        {
            index,
            initialActionButton,
            invalidProperties,
            setFocus,
            isReadOnly,
            onUpdate,
            onRemove,
            isDragging,
            connectDragSource,
            connectDropTarget,
        }: PanelActionItemProps,
        ref,
    ) => {
        const { t } = useTranslation();

        const [actionButton, setActionButton] = React.useState<ActionButton>(initialActionButton);

        const actionTypes = React.useMemo<IListItem[]>(
            () =>
                Object.entries(ActionType).map(actionType => ({
                    text: t(`templates.panelActionButtons.${actionType[1]}`),
                    value: actionType[1],
                })),
            [],
        );

        const actionButtonStyles = React.useMemo<IListItem[]>(
            () =>
                Object.entries(ActionButtonStyle).map(actionButtonStyle => ({
                    text: t(`templates.panelActionButtons.${actionButtonStyle[1]}`),
                    value: actionButtonStyle[1],
                })),
            [],
        );

        const updateProperty = (propertyName: string, propertyValue: any) => {
            const { [propertyName]: extractedProp, ...existingValue } = actionButton as any;

            setActionButton({ ...existingValue, [propertyName]: propertyValue });
        };

        const handleTextChange = (propertyName: string) => (e: React.ChangeEvent<HTMLInputElement>) =>
            updateProperty(propertyName, e.currentTarget.value);
        const handleTextBlur = (propertyName: string) => (e: React.FocusEvent<HTMLInputElement>) =>
            updateProperty(propertyName, e.currentTarget.value.trim());
        const handleCheckboxChange = (propertyName: string) => (e: React.ChangeEvent<HTMLInputElement>) =>
            updateProperty(propertyName, e.currentTarget.checked);

        const handlePanelActionBlur = (e: React.FocusEvent) => {
            if (e.currentTarget.contains(e.relatedTarget as Node)) {
                e.stopPropagation();
            } else {
                onUpdate(actionButton);
            }
        };

        const elementRef = React.useRef<HTMLDivElement>(null);
        const panelActionRef = React.useRef<HTMLDivElement>(null);

        if (!isReadOnly) {
            connectDragSource(elementRef);
            connectDropTarget(elementRef);
        }

        const opacity = isDragging ? 0 : 1;

        React.useImperativeHandle<any, DragDropSortInstance>(ref, () => ({
            getNode: () => elementRef.current,
        }));

        React.useEffect(() => {
            if (setFocus && panelActionRef.current) {
                (panelActionRef.current.childNodes[0].childNodes[0] as HTMLSelectElement).focus();
            }
        }, [setFocus]);

        const { isValid: isLabelValid, message: labelErrorMessage } = getActionButtonPropertyValidity(
            ActionButtonProperty.LABEL,
            invalidProperties,
        );

        const panelActionItemClass = `panel-action-item ${
            anyInvalidActionButtonProperties(invalidProperties) ? "panel-action-item--invalid" : ""
        }`;

        return (
            <div
                data-testid={`panel-action-item-${index}`}
                ref={elementRef}
                className={panelActionItemClass}
                style={{ opacity }}
            >
                <div
                    data-testid={`panel-action-item-grid-${index}`}
                    className="panel-actions__grid"
                    onBlur={handlePanelActionBlur}
                >
                    <span ref={panelActionRef} className="panel-actions__grid__action">
                        <Dropdown
                            data-testid={`panel-action-type-${index}`}
                            ariaLabel={t("templates.panelActionButtons.selectAction")}
                            dataSource={actionTypes}
                            onChange={value => updateProperty("action", value)}
                            value={actionButton.action}
                            disabled={isReadOnly}
                        />
                    </span>

                    <FormElement
                        className="panel-actions__grid__label"
                        errorText={isLabelValid ? undefined : labelErrorMessage ?? " "}
                    >
                        <input
                            className={`eui-text-input${!isLabelValid ? " eui-text-input--invalid" : ""}`}
                            aria-label={t("templates.panelActionButtons.enterLabel")}
                            onChange={handleTextChange("label")}
                            onBlur={handleTextBlur("label")}
                            value={actionButton.label}
                            readOnly={isReadOnly}
                            data-testid={`panel-action-label-${index}`}
                        />
                    </FormElement>

                    <span className="panel-actions__grid__style">
                        <Dropdown
                            data-testid={`panel-action-style-${index}`}
                            ariaLabel={t("templates.panelActionButtons.selectStyle")}
                            dataSource={actionButtonStyles}
                            onChange={value => updateProperty("style", value)}
                            value={actionButton.style}
                            disabled={isReadOnly}
                        />
                    </span>

                    <span className="panel-actions__grid__validate">
                        <Checkbox
                            data-testid={`panel-action-validate-${index}`}
                            className="panel-actions__grid__validate"
                            onChange={handleCheckboxChange("validate")}
                            checked={actionButton.validate}
                            disabled={isReadOnly}
                        />
                    </span>

                    <span className="panel-actions__grid__remove">
                        {!isReadOnly && (
                            <button
                                type="button"
                                aria-label={t("templates.panelActionButtons.removePanelActionButton")}
                                data-testid={`panel-action-remove-button-${index}`}
                                className="btn btn-default remove-option-button"
                                onClick={onRemove}
                            >
                                <Clear title={t("templates.panelActionButtons.removePanelActionButton")} />
                            </button>
                        )}
                    </span>
                </div>
            </div>
        );
    },
);

export default PanelActionItem;
