import * as React from "react";
import { ActionButton, ActionButtonStyle, Panel, RunningTemplateContext } from "@emisgroup/clint-templates-common";
import { ActionsContext } from "../context/actions";
import { validateItems } from "../utils/validationUtils";

type ButtonProps = { actionButton: ActionButton; panel: Panel };
function Button({ actionButton, panel }: ButtonProps) {
    const { onRaiseAction } = React.useContext(ActionsContext);
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.currentTarget.blur();

        if (onRaiseAction) {
            onRaiseAction(actionButton.action, actionButton.validate, panel);
        }
    };

    const className = `nhsuk-button ${
        actionButton.style === ActionButtonStyle.SECONDARY ? "nhsuk-button--secondary" : ""
    } nhsuk-u-margin-right-9`;
    return (
        <button
            id={panel.id}
            data-testid={`action-button-${actionButton.action}`}
            className={className}
            type="button"
            onClick={handleClick}
        >
            {actionButton.label}
        </button>
    );
}

type ActionButtonsProps = { panel: Panel };
function ActionButtons({ panel }: ActionButtonsProps) {
    if (!panel.actionButtons) {
        return null;
    }

    return (
        <div className="nhsuk-grid-row nhsuk-u-margin-top-9">
            <div className="nhsuk-grid-column-full">
                {React.Children.toArray(
                    panel.actionButtons.map(button => <Button actionButton={button} panel={panel} />),
                )}
            </div>
        </div>
    );
}

type AssessmentPanelProps = { panel: Panel; children?: React.ReactNode | React.ReactNodeArray };
export default function AssessmentPanel({ panel, children }: AssessmentPanelProps) {
    const { templateData } = React.useContext(RunningTemplateContext);
    const isValid = validateItems(panel.members, templateData);

    return (
        <div
            className={`nhsuk-form-group${isValid ? "" : " nhsuk-form-group--error"}`}
            data-testid={`panel-${panel.id}`}
        >
            <fieldset className="nhsuk-fieldset">
                <legend className="nhsuk-fieldset__legend nhsuk-fieldset__legend--m">
                    <h1 className="nhsuk-fieldset__heading">{panel.label}</h1>
                </legend>
                {children}
            </fieldset>
            <ActionButtons panel={panel} />
        </div>
    );
}
