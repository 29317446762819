import * as React from "react";
import {
    CanvasItem,
    ComponentDataType,
    ConfigContext,
    DropdownPropertyDataType,
    MultiSelectPropertyDataType,
} from "@emisgroup/clint-templates-common";
import BooleanPropertyEntry from "../properties/booleanPropertyEntry";
import StringPropertyEntry from "../properties/stringPropertyEntry";
import CodePropertyEntry from "../properties/codePropertyEntry";
import DropdownPropertyEntry from "../properties/dropdownPropertyEntry";
import MultiLineStringPropertyEntry from "../properties/multiLineStringPropertyEntry";
import ParameterPropertyEntry from "../properties/parameterPropertyEntry";
import { PropertyValueProps } from "../properties/types";
import CalculatorExpressionPropertyEntry from "../properties/calculatorExpressionPropertyEntry";
import MultiSelectPropertyEntry from "../properties/multiSelectPropertyEntry";

const ArrayPropertyEntry = ({ component, name }: { component: CanvasItem; name: string }) => {
    return component[name].map(entry => entry.label || "").join(",");
};

const PropertyValue = ({
    id,
    dataAttributes,
    component,
    description,
    name,
    isReadOnly,
    isPropertySelected,
    onPropertyUpdate,
}: PropertyValueProps) => {
    const { features } = React.useContext(ConfigContext);
    switch (dataAttributes.type) {
        case ComponentDataType.CODE:
            return (
                <CodePropertyEntry
                    id={`input-${id}`}
                    description={description}
                    component={component}
                    dataAttributes={dataAttributes}
                    name={name}
                    isReadOnly={isReadOnly}
                    isPropertySelected={isPropertySelected}
                    onPropertyUpdate={onPropertyUpdate}
                />
            );
        case ComponentDataType.BOOLEAN:
            return (
                <BooleanPropertyEntry
                    id={`input-${id}`}
                    component={component}
                    name={name}
                    isReadOnly={isReadOnly}
                    onPropertyUpdate={onPropertyUpdate}
                />
            );
        case ComponentDataType.ARRAY:
            return <ArrayPropertyEntry component={component} name={name} />;
        case ComponentDataType.DROPDOWN:
            return (
                <DropdownPropertyEntry
                    component={component}
                    name={name}
                    isReadOnly={isReadOnly}
                    options={(dataAttributes as DropdownPropertyDataType).options}
                    onPropertyUpdate={onPropertyUpdate}
                />
            );
        case ComponentDataType.MULTI_SELECT_DROPDOWN: {
            const multiSelectPropertyDataType = dataAttributes as MultiSelectPropertyDataType;
            const options = multiSelectPropertyDataType.getOptions(features);

            return (
                <MultiSelectPropertyEntry
                    component={component}
                    name={name}
                    placeHolder={multiSelectPropertyDataType.placeHolder}
                    isReadOnly={isReadOnly}
                    options={options}
                    onPropertyUpdate={onPropertyUpdate}
                />
            );
        }

        case ComponentDataType.MULTI_LINE_STRING:
            return (
                <MultiLineStringPropertyEntry
                    id={`input-${id}`}
                    component={component}
                    name={name}
                    isReadOnly={isReadOnly}
                    onPropertyUpdate={onPropertyUpdate}
                />
            );

        case ComponentDataType.PARAMETER_LIST:
            return (
                <ParameterPropertyEntry
                    id={`input-${id}`}
                    description={description}
                    isPropertySelected={isPropertySelected}
                    dataAttributes={dataAttributes}
                    component={component}
                    name={name}
                    isReadOnly={isReadOnly}
                    onPropertyUpdate={onPropertyUpdate}
                />
            );
        case ComponentDataType.CALCULATION_EXPRESSION:
            return (
                <CalculatorExpressionPropertyEntry
                    id={`input-${id}`}
                    component={component}
                    name={name}
                    description={description}
                    isReadOnly={isReadOnly}
                    onPropertyUpdate={onPropertyUpdate}
                    isPropertySelected={isPropertySelected}
                    dataAttributes={dataAttributes}
                />
            );
        default:
            return (
                <StringPropertyEntry
                    id={`input-${id}`}
                    component={component}
                    name={name}
                    description={description}
                    isReadOnly={isReadOnly}
                    onPropertyUpdate={onPropertyUpdate}
                    isPropertySelected={isPropertySelected}
                    dataAttributes={dataAttributes}
                    isNumber={dataAttributes.type === ComponentDataType.NUMERIC}
                />
            );
    }
};

export default PropertyValue;
