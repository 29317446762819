import { MultiSelectPropertyOption } from "@emisgroup/clint-templates-common";

export const getOptionText = (selectedValue: string, options: MultiSelectPropertyOption[]) =>
    options.find(option => option.value === selectedValue)?.text;

export const getSelectionsAsText = (t, selectedValues: string[], options: MultiSelectPropertyOption[]) => {
    if (selectedValues.length === 0) return undefined;
    if (selectedValues.length === 1)
        return `${getOptionText(selectedValues[0], options)} ${t("components.property.only")}`;

    return `${selectedValues
        .slice(0, -1)
        .map(selectedValue => getOptionText(selectedValue, options))
        .join(", ")} ${t("components.property.and")} ${getOptionText(
        selectedValues[selectedValues.length - 1],
        options,
    )}`;
};
