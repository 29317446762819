import * as React from "react";
import {
    ASSOCIATED_TEXT_IS_CLINICAL_CONTENT,
    CLINICAL_CONTENT_QUERY_DISPLAY_TYPES,
    CODE_EXTRA_INFORMATION_PROPERTIES,
    ConfigContext,
    CODED_COMPONENTS_ENABLED,
    NUMERIC_VALUE_COMPONENT_ENABLED,
    SAVED_CONTENT_ENABLED,
    SUPPORT_MULTI_COLUMN,
    VISIBILITY_RULES_SELECT_QUERY_ENABLED,
    USE_TEMPLATE_PICKER,
    PERSPECTIVES,
} from "@emisgroup/clint-templates-common";

type ConfigProviderProps = {
    children: React.ReactNode | React.ReactNodeArray;
    platformUrl: string;
    freeTextMaxChars: number;
};

const features = {
    [CLINICAL_CONTENT_QUERY_DISPLAY_TYPES]: [],
    [SAVED_CONTENT_ENABLED]: false,
    [VISIBILITY_RULES_SELECT_QUERY_ENABLED]: false,
    [CODED_COMPONENTS_ENABLED]: false,
    [NUMERIC_VALUE_COMPONENT_ENABLED]: false,
    [CODE_EXTRA_INFORMATION_PROPERTIES]: [],
    [SUPPORT_MULTI_COLUMN]: true,
    [ASSOCIATED_TEXT_IS_CLINICAL_CONTENT]: true,
    [USE_TEMPLATE_PICKER]: true,
    [PERSPECTIVES]: [],
};
const ConfigProvider = ({ children, platformUrl, freeTextMaxChars }: ConfigProviderProps) => (
    <ConfigContext.Provider value={{ features, contentLibraryUrl: "", platformUrl, freeTextMaxChars }}>
        {children}
    </ConfigContext.Provider>
);

export default ConfigProvider;
