import { DisplayType } from "@emisgroup/clint-content/lib/types";
import {
    CLINICAL_CONTENT_QUERY_DISPLAY_TYPES,
    SAVED_CONTENT_ENABLED,
    VISIBILITY_RULES_SELECT_QUERY_ENABLED,
    CODED_COMPONENTS_ENABLED,
    NUMERIC_VALUE_COMPONENT_ENABLED,
    CODE_EXTRA_INFORMATION_PROPERTIES,
    SUPPORT_MULTI_COLUMN,
    ASSOCIATED_TEXT_IS_CLINICAL_CONTENT,
    USE_TEMPLATE_PICKER,
    PERSPECTIVES,
    FeaturesConfig,
    PerspectiveSetting,
} from "@emisgroup/clint-templates-common";

const featureConfig = process.env.APP_FEATURE_CONFIG;
const features = (): FeaturesConfig => {
    switch (featureConfig) {
        case "ASSESSMENTS":
            return {
                [USE_TEMPLATE_PICKER]: true,
                [CLINICAL_CONTENT_QUERY_DISPLAY_TYPES]: [],
                [SAVED_CONTENT_ENABLED]: false,
                [VISIBILITY_RULES_SELECT_QUERY_ENABLED]: false,
                [CODED_COMPONENTS_ENABLED]: false,
                [NUMERIC_VALUE_COMPONENT_ENABLED]: false,
                [CODE_EXTRA_INFORMATION_PROPERTIES]: [],
                [SUPPORT_MULTI_COLUMN]: false,
                [ASSOCIATED_TEXT_IS_CLINICAL_CONTENT]: false,
                [PERSPECTIVES]: [
                    {
                        setting: PerspectiveSetting.RUNNER,
                        dimensions: [{ name: "user", icon: "user", values: ["patient", "healthcare"] }],
                        predefined: [
                            { key: "patient", display: "perspectives.runnerPatient", isDefault: true },
                            { key: "healthcare", display: "perspectives.runnerHealthCare", isDefault: true },
                        ],
                    },
                    {
                        setting: PerspectiveSetting.REFERRAL_REPORT,
                        dimensions: [
                            { name: "audience", icon: "user", values: ["patient", "healthcare"] },
                            { name: "section", icon: "user", values: ["title", "summary", "body"] },
                        ],
                        predefined: [
                            { key: "title", display: "perspectives.reportTitle", isDefault: false },
                            { key: "summary", display: "perspectives.reportSummary", isDefault: false },
                            { key: "body", display: "perspectives.reportBody", isDefault: true },
                        ],
                    },
                ],
            };

        default:
            return {
                [USE_TEMPLATE_PICKER]: true,
                [CLINICAL_CONTENT_QUERY_DISPLAY_TYPES]: [
                    DisplayType.List,
                    DisplayType.Table,
                    DisplayType.Text,
                    DisplayType.Value,
                ],
                [SAVED_CONTENT_ENABLED]: true,
                [VISIBILITY_RULES_SELECT_QUERY_ENABLED]: true,
                [CODED_COMPONENTS_ENABLED]: true,
                [NUMERIC_VALUE_COMPONENT_ENABLED]: true,
                [CODE_EXTRA_INFORMATION_PROPERTIES]: [
                    { propertyName: "snomedConceptId", header: "Concept ID" },
                    { propertyName: "snomedDescriptionId", header: "Description ID" },
                ],
                [SUPPORT_MULTI_COLUMN]: true,
                [ASSOCIATED_TEXT_IS_CLINICAL_CONTENT]: true,
                [PERSPECTIVES]: [],
            };
    }
};

export default features;
