import React from "react";
import {
    CanvasItem,
    ComponentType,
    DataFromTemplateRun,
    TemplateData,
    RunningTemplateContext,
    getComponentTypeClass,
} from "@emisgroup/clint-templates-common";
import { getAdditionalDataProperty } from "./utils/componentUtils";
// eslint-disable-next-line import/no-cycle
import CanvasComponent from "./canvasComponent";

type RendererProps = {
    component: CanvasItem;
    className?: string;
};

function ComponentRenderer({ component, className = "" }: RendererProps) {
    const { templateData, updateTemplateData, onHandleError } = React.useContext(RunningTemplateContext);

    const augmentWithItemDetails = (data: DataFromTemplateRun, canvasItem: CanvasItem): TemplateData => {
        const additionalDataProperty = getAdditionalDataProperty(canvasItem);

        return {
            [canvasItem.id]: {
                ...templateData[canvasItem.id],
                type: canvasItem.type as ComponentType,
                narrativeData:
                    additionalDataProperty &&
                    (canvasItem[additionalDataProperty.name] || additionalDataProperty.defaultValue),
                ...data[canvasItem.id],
            },
        };
    };

    function updateDataForTemplates(data: DataFromTemplateRun) {
        const augmentedItemData = augmentWithItemDetails(data, component);

        updateTemplateData({
            ...templateData,
            ...augmentedItemData,
        });
    }

    return (
        <>
            <div
                id={`canvas-item-${component.id}`}
                data-testid={`drag-container-${component.id}`}
                className={`drag-container canvas-item-entry-container ${getComponentTypeClass(
                    component.type,
                )} ${className}`}
            >
                <CanvasComponent
                    component={component}
                    onTemplateDataChange={updateDataForTemplates}
                    onHandleError={onHandleError}
                />
            </div>
        </>
    );
}

export default ComponentRenderer;
