import { v4 as uuid } from "uuid";
import { EntityUpdateProperty } from "@emisgroup/clint-content/lib/types";
import {
    findCanvasItem,
    isCanvasItemAContainer,
    CanvasItem,
    ClinicalContentEntity,
    ComponentCondition,
    ComponentHasValueCondition,
    ComponentSelectedCondition,
    ComponentType,
    ComponentValueSelectedCondition,
    Condition,
    ConditionMember,
    ConditionsGroup,
    ConditionSource,
    ComponentContainer,
    QueryCondition,
    Rule,
    ConditionSourceType,
    CanvasItemWithContainer,
    getAllNestedCanvasItems,
    getCanvasItemsWithNestedMembers,
    ComponentValueComparisionCondition,
} from "@emisgroup/clint-templates-common";
import { ConditionFieldError, ConditionForDisplay, VisibilityRuleFields, VisibilityFieldValidity } from "../types";

// eslint-disable-next-line import/no-cycle
import { getCanvasItemParentContainers, getCanvasItemWithContainer, getItemId } from "./componentUtils";
import { actorsForComponentType } from "../constants";

const nestedConditionsReducer = (allConditions: Condition[], conditionMember: ConditionMember) => {
    const conditionsGroup = conditionMember as ConditionsGroup;

    if (conditionsGroup.conditionMembers) {
        return conditionsGroup.conditionMembers.reduce(nestedConditionsReducer, allConditions);
    }

    allConditions.push(conditionMember as Condition);
    return allConditions;
};

export const getAllConditions = (rule: Rule): Condition[] => {
    return rule.conditionMembers.reduce(nestedConditionsReducer, []);
};

export const getConditionSourceType = (condition: Condition) =>
    condition.conditionSource === ConditionSource.QUERY ? ConditionSourceType.QUERY : ConditionSourceType.COMPONENT;

function getComponentConditions(rule: Rule): ComponentCondition[] {
    return getAllConditions(rule)
        .filter(condition => getConditionSourceType(condition) === ConditionSourceType.COMPONENT)
        .map(condition => condition as ComponentCondition);
}

export function isRuleDependentOnComponent(rule: Rule, canvasItemId: string): boolean {
    return getComponentConditions(rule).some(
        componentCondition => canvasItemId === componentCondition.actorCanvasItemId,
    );
}

export const isItemInGroup = (item: CanvasItem, groupedItems: CanvasItem[]): boolean =>
    groupedItems.map(getItemId).includes(item.id);

export const getRuleFromComponent = ({ rule }: CanvasItem) => (typeof rule !== "undefined" ? rule : null);

const getConditionText = (t, condition: Condition): string => {
    switch (condition.conditionSource) {
        case ConditionSource.COMPONENT_SELECTED: {
            const componentCondition = condition as ComponentSelectedCondition;

            if (typeof componentCondition.selected !== "undefined")
                return t(componentCondition.selected ? "selected" : "notSelected");
            break;
        }

        case ConditionSource.COMPONENT_VALUE_SELECTED: {
            const componentValueSelectedCondition = condition as ComponentValueSelectedCondition;
            return componentValueSelectedCondition.value || "";
        }

        case ConditionSource.COMPONENT_VALUE_COMPARISON: {
            const componentValueComparisonCondition = condition as ComponentValueComparisionCondition;
            const operatorText = t(`templates.rules.operators.${componentValueComparisonCondition.operator}`);
            return `${t("templates.rules.is")} ${operatorText} ${componentValueComparisonCondition.value}}`;
        }

        case ConditionSource.COMPONENT_HAS_VALUE: {
            const componentValueCondition = condition as ComponentHasValueCondition;
            return t(
                componentValueCondition.hasValue === true ? "templates.rules.hasValue" : "templates.rules.hasNoValue",
            );
        }
        case ConditionSource.QUERY: {
            const queryCondition = condition as QueryCondition;

            if (typeof queryCondition.resultValue !== "undefined") {
                return t(queryCondition.resultValue ? "true" : "false");
            }
            break;
        }

        default:
            return "";
    }

    return "";
};

export function getConditionForDisplay(t, condition: Condition, canvasItems: CanvasItem[]): ConditionForDisplay {
    let sourceText = "";
    const conditionSourceType = getConditionSourceType(condition);
    let conditionComponentType: ComponentType | undefined;

    if (conditionSourceType === ConditionSourceType.COMPONENT) {
        const componentCondition = condition as ComponentCondition;
        const templateItem = findCanvasItem(componentCondition.actorCanvasItemId, canvasItems) as CanvasItem;
        sourceText = templateItem.label;
        conditionComponentType = templateItem.type as ComponentType;
    } else if (conditionSourceType === ConditionSourceType.QUERY) {
        const whichCondition = condition as QueryCondition;
        sourceText = `${whichCondition.queryName}`;
    }

    return {
        key: uuid(),
        condition,
        sourceText,
        conditionSourceType,
        conditionComponentType,
        conditionText: getConditionText(t, condition),
    };
}

export function getConditionsForDisplay(t, rule: Rule, canvasItems: CanvasItem[]): ConditionForDisplay[] {
    return rule.conditionMembers
        .map(conditionMember => {
            const condition = conditionMember as Condition;
            return condition.conditionSource ? getConditionForDisplay(t, condition, canvasItems) : null;
        })
        .filter(Boolean) as ConditionForDisplay[];
}

const isCanvasItemAValidRuleActor = (canvasItem: CanvasItem): boolean =>
    Object.values(actorsForComponentType).some(componentTypeList =>
        componentTypeList.toString().includes(canvasItem.type),
    );

type ComponentsInRule = { actor: CanvasItemWithContainer; actedOn: CanvasItemWithContainer };

const findCanvasItemsActedOnFromActors = (
    potentialActorCanvasItems: CanvasItemWithContainer[],
    templateContainer: ComponentContainer,
): ComponentsInRule[] => {
    const componentsInRules: ComponentsInRule[] = [];

    getAllNestedCanvasItems(templateContainer)
        .filter(item => item.canvasItem.rule)
        .forEach(canvasItemWithContainerActedOn => {
            getComponentConditions(canvasItemWithContainerActedOn.canvasItem.rule as Rule).forEach(
                componentCondition => {
                    const actorInParentRule = potentialActorCanvasItems.find(
                        c => c.canvasItem.id === componentCondition.actorCanvasItemId,
                    );

                    if (actorInParentRule) {
                        componentsInRules.push({
                            actedOn: canvasItemWithContainerActedOn,
                            actor: actorInParentRule,
                        });
                    }
                },
            );
        });

    return componentsInRules;
};

const getPotentialActorCanvasItemsWithContainer = (
    canvasItem: CanvasItem,
    templateContainer: ComponentContainer,
): CanvasItemWithContainer[] => {
    if (isCanvasItemAContainer(canvasItem)) {
        return getAllNestedCanvasItems(canvasItem as ComponentContainer).filter(canvasItemWithContainer =>
            isCanvasItemAValidRuleActor(canvasItemWithContainer.canvasItem),
        );
    }
    if (isCanvasItemAValidRuleActor(canvasItem)) {
        return [getCanvasItemWithContainer(canvasItem.id, templateContainer)];
    }
    return [];
};

const getAllComponentRulesMembers = (templateContainer: ComponentContainer): Map<string, string[]> => {
    const allActedOnForActors = new Map<string, string[]>();

    getAllNestedCanvasItems(templateContainer)
        .filter(item => item.canvasItem.rule)
        .flatMap(canvasItemWithRule =>
            getComponentConditions(canvasItemWithRule.canvasItem.rule as Rule).forEach(componentCondition => {
                const actedOnIdsForActor = allActedOnForActors.get(componentCondition.actorCanvasItemId);

                if (actedOnIdsForActor) {
                    actedOnIdsForActor.push(canvasItemWithRule.canvasItem.id);
                } else {
                    allActedOnForActors.set(componentCondition.actorCanvasItemId, [canvasItemWithRule.canvasItem.id]);
                }
            }),
        );

    return allActedOnForActors;
};

const getAllActedOnFromActor = (actorId: string, actorToActedOnMap: Map<string, string[]>, result: string[]) => {
    const actorReferences = actorToActedOnMap.get(actorId);
    actorReferences?.forEach(actedOnId => {
        if (!result.includes(actedOnId)) {
            result.push(actedOnId);
            getAllActedOnFromActor(actedOnId, actorToActedOnMap, result);
        }
    });
};

export const getCanvasItemIdsActedOnBy = (
    actedOnCanvasItemId: string,
    templateContainer: ComponentContainer,
): string[] => {
    const result = [];
    getAllActedOnFromActor(actedOnCanvasItemId, getAllComponentRulesMembers(templateContainer), result);
    return result;
};

const getCanvasItemsActedOnBy = (canvasItem: CanvasItem, templateContainer: ComponentContainer): ComponentsInRule[] => {
    const canvasItemsWithContainer = getPotentialActorCanvasItemsWithContainer(canvasItem, templateContainer);

    return findCanvasItemsActedOnFromActors(canvasItemsWithContainer, templateContainer);
};

export const getActorCanvasIdsFromRule = (rule?: Rule | null): string[] =>
    rule && typeof rule !== "undefined" ? getComponentConditions(rule).map(c => c.actorCanvasItemId) : [];

export const isCanvasItemAnActorInRule = (canvasItemId: string, rule?: Rule | null): boolean =>
    getActorCanvasIdsFromRule(rule).includes(canvasItemId);

const getCanvasItemText = (canvasItem: CanvasItem): string =>
    (canvasItem.type !== ComponentType.CLINICAL_CONTENT && isCanvasItemAContainer(canvasItem)
        ? `${canvasItem.type} `
        : "") + canvasItem.label;

const getContainerText = (container: ComponentContainer): string =>
    container.type !== ComponentType.TEMPLATE &&
    container.type !== ComponentType.CLINICAL_CONTENT &&
    container.type !== ComponentType.TAB_CONTAINER
        ? ` in ${container.type} ${container.label}`
        : "";

const getVisibilityRuleDescription = (t, actor: CanvasItemWithContainer, actedOn: CanvasItemWithContainer): string => {
    const actorItemContainer = actor.container;
    const actedOnItemContainer = actedOn.container;

    const actedOnItemText =
        actedOn.canvasItem.type === ComponentType.CLINICAL_CONTENT_ENTITY
            ? actedOnItemContainer.label
            : getCanvasItemText(actedOn.canvasItem);
    return t("templates.conflicts.affectsVisibility", {
        actorName: `${actor.canvasItem.label}${getContainerText(actorItemContainer)}`,
        actedOnName: `${actedOnItemText}${getContainerText(actedOnItemContainer)}`,
    });
};

const getCanvasItemsActingOnOrInto = (
    canvasItem: CanvasItem,
    templateContainer: ComponentContainer,
): ComponentsInRule[] => {
    const getCanvasItemsActingOnOrIntoReducer = (
        componentsInRuleResult: ComponentsInRule[],
        canvasItemWithContainer: CanvasItemWithContainer,
    ) => {
        if (isCanvasItemAContainer(canvasItemWithContainer.canvasItem)) {
            const container = canvasItemWithContainer.canvasItem as ComponentContainer;
            container.members
                .map(memberCanvasItem => ({ canvasItem: memberCanvasItem, container }))
                .reduce(getCanvasItemsActingOnOrIntoReducer, [])
                .forEach(componentsInRule => componentsInRuleResult.push(componentsInRule));
        }

        getActorCanvasIdsFromRule(canvasItemWithContainer.canvasItem.rule).forEach(actorCanvasItemId =>
            componentsInRuleResult.push({
                actedOn: canvasItemWithContainer,
                actor: getCanvasItemWithContainer(actorCanvasItemId, templateContainer),
            } as ComponentsInRule),
        );

        return componentsInRuleResult;
    };

    return [getCanvasItemWithContainer(canvasItem.id, templateContainer)].reduce(
        getCanvasItemsActingOnOrIntoReducer,
        [],
    );
};

const isActedOnOwnParentAfteMove = (actedOnCanvasItem: CanvasItem, parentContainers: ComponentContainer[]): boolean =>
    isCanvasItemAContainer(actedOnCanvasItem) &&
    parentContainers.findIndex(container => container.id === actedOnCanvasItem.id) > -1;

export const getVisibilityConflictsForMove = (
    t,
    canvasItemIdToMove: string,
    containerToMoveTo: ComponentContainer,
    templateContainer: ComponentContainer,
): string[] => {
    const itemToMove = findCanvasItem(canvasItemIdToMove, templateContainer.members) as CanvasItem;
    const canvasItemRulesAsActor = getCanvasItemsActedOnBy(itemToMove, templateContainer);
    const invalidContainersToActOn = [
        containerToMoveTo,
        ...getCanvasItemParentContainers(containerToMoveTo.id, templateContainer),
    ];

    return canvasItemRulesAsActor
        .filter(c => isActedOnOwnParentAfteMove(c.actedOn.canvasItem, invalidContainersToActOn))
        .map(itemInRule => getVisibilityRuleDescription(t, itemInRule.actor, itemInRule.actedOn));
};

export const getVisibilityConflictsForDelete = (
    t,
    canvasItemToDelete: CanvasItem,
    templateContainer: ComponentContainer,
): string[] => {
    const nestedItemsToBeDeleted = isCanvasItemAContainer(canvasItemToDelete)
        ? getCanvasItemsWithNestedMembers((canvasItemToDelete as ComponentContainer).members, templateContainer)
        : [];

    return getCanvasItemsActedOnBy(canvasItemToDelete, templateContainer)
        .filter(rule => !nestedItemsToBeDeleted.includes(rule.actedOn.canvasItem))
        .map(itemInRule => getVisibilityRuleDescription(t, itemInRule.actor, itemInRule.actedOn));
};

export const getVisibilityConflictsForSaveContainer = (
    t,
    container: ComponentContainer,
    templateContainer: ComponentContainer,
): string[] => {
    const nestedItemsToBeSaved = getCanvasItemsWithNestedMembers(container.members, templateContainer);

    const itemsActingOutsideSavedContainer = getCanvasItemsActedOnBy(container as CanvasItem, templateContainer).filter(
        rule => !nestedItemsToBeSaved.includes(rule.actedOn.canvasItem),
    );

    const itemsActedOnFromOutsideContainer = getCanvasItemsActingOnOrInto(container, templateContainer).filter(
        rule => !nestedItemsToBeSaved.includes(rule.actor.canvasItem) && rule.actedOn.canvasItem !== container,
    );

    return [...itemsActingOutsideSavedContainer, ...itemsActedOnFromOutsideContainer].map(itemInRule =>
        getVisibilityRuleDescription(t, itemInRule.actor, itemInRule.actedOn),
    );
};

const validateVisibilityRuleCondition = (t, condition: Condition): ConditionFieldError | null => {
    const conditionErrors: ConditionFieldError = {};

    if (condition.conditionSource === ConditionSource.COMPONENT_VALUE_COMPARISON) {
        const componentValueComparisonCondition = condition as ComponentValueComparisionCondition;

        if (typeof componentValueComparisonCondition.value === "undefined") {
            conditionErrors[VisibilityRuleFields.COMPONENT_COMPARISON_VALUE] = t("templates.rules.enterValue");
        }
    }

    return Object.keys(conditionErrors).length ? conditionErrors : null;
};

export const validateVisibilityRule = (t, rule?: Rule | null) => {
    const result = {};

    if (!rule) return result;

    rule.conditionMembers.forEach((conditionMember, index) => {
        const ruleConditionErrors = validateVisibilityRuleCondition(t, conditionMember as Condition);
        if (ruleConditionErrors !== null) {
            result[index] = ruleConditionErrors;
        }
    });

    return result;
};

export const isVisibilityRuleValid = (t, canvasItem: CanvasItem): boolean =>
    canvasItem.rule ? Object.entries(validateVisibilityRule(t, canvasItem.rule)).length === 0 : true;

export const getVisiblityFieldValidity = (
    visibilityRuleField: VisibilityRuleFields,
    invalidConditionFields?: any,
): VisibilityFieldValidity =>
    typeof invalidConditionFields !== "undefined" && typeof invalidConditionFields[visibilityRuleField] !== "undefined"
        ? { isValid: false, message: invalidConditionFields[visibilityRuleField] }
        : { isValid: true };

export const canvasItemCanHaveVisibilityRules = (canvasItem: CanvasItem) => {
    if (typeof canvasItem.type === "undefined" || canvasItem.type === ComponentType.TEMPLATE) {
        return false;
    }
    if (canvasItem.type !== ComponentType.CLINICAL_CONTENT_ENTITY) {
        return true;
    }

    const clinicalContentEntity = canvasItem as ClinicalContentEntity;
    return (
        clinicalContentEntity &&
        clinicalContentEntity.update &&
        Object.keys(clinicalContentEntity.update.editableProperties).includes(
            EntityUpdateProperty.QueryResource.toString(),
        )
    );
};

export const getNumberOfRuleConditions = (canvasItem: CanvasItem): number =>
    canvasItem.rule !== null && typeof canvasItem.rule !== "undefined" ? canvasItem.rule.conditionMembers.length : 0;

export const getConditionState = (condition: Condition) => {
    let whichCondition;
    switch (condition.conditionSource) {
        case ConditionSource.COMPONENT_SELECTED:
            whichCondition = condition as ComponentSelectedCondition;
            if (whichCondition.selected === true) {
                return "selected";
            }
            return whichCondition.selected === false ? "unselected" : "";
        case ConditionSource.COMPONENT_VALUE_SELECTED:
            whichCondition = condition as ComponentValueSelectedCondition;
            return whichCondition.value;
        case ConditionSource.COMPONENT_HAS_VALUE:
            whichCondition = condition as ComponentHasValueCondition;
            if (whichCondition.hasValue === true) {
                return "hasValue";
            }
            return whichCondition.hasValue === false ? "hasNoValue" : "";
        case ConditionSource.COMPONENT_VALUE_COMPARISON:
            whichCondition = condition as ComponentValueComparisionCondition;
            return whichCondition.operator;
        case ConditionSource.QUERY:
            whichCondition = condition as QueryCondition;
            if (whichCondition.resultValue === true) {
                return "true";
            }
            return whichCondition.resultValue === false ? "false" : "";
        default:
            return "";
    }
};

const queryResultValues = { true: true, false: false };

export const getConditionWithNewState = (conditionToUpdate: Condition, newState: string): Condition => {
    let newCondition;
    switch (conditionToUpdate.conditionSource) {
        case ConditionSource.COMPONENT_SELECTED:
            newCondition = { ...conditionToUpdate } as ComponentSelectedCondition;
            if (newState) {
                newCondition.selected = newState === "selected";
            } else {
                delete newCondition.selected;
            }
            break;
        case ConditionSource.COMPONENT_VALUE_SELECTED:
            newCondition = { ...conditionToUpdate } as ComponentValueSelectedCondition;
            if (newState) {
                newCondition.value = newState;
            } else {
                delete newCondition.value;
            }
            break;
        case ConditionSource.COMPONENT_HAS_VALUE:
            newCondition = { ...conditionToUpdate } as ComponentHasValueCondition;
            newCondition.hasValue = newState === "hasValue";
            break;

        case ConditionSource.COMPONENT_VALUE_COMPARISON:
            newCondition = { ...conditionToUpdate, operator: newState } as ComponentValueComparisionCondition;

            break;

        case ConditionSource.QUERY:
            newCondition = { ...conditionToUpdate } as QueryCondition;
            if (newState) {
                newCondition.resultValue = queryResultValues[newState];
            } else {
                delete newCondition.resultValue;
            }
            break;
        default:
            newCondition = conditionToUpdate;
    }

    return newCondition;
};
