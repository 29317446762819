import useCanvasSizeClass from "./useCanvasSizeClass";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { version } = require("../package.json");

export * from "./templateComponents";
export * from "./uiComponents";
export * from "./utils";
export * from "./constants";
export * from "./context";
export * from "./types";

export { useCanvasSizeClass, version };
