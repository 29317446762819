export const getAsMinutes = (minutes: number, unit: string): number => {
    switch (unit) {
        case "M":
            return minutes;
        case "H":
            return minutes * 60;
        case "D":
            return minutes * (60 * 24);
        default:
            return 0;
    }
};

export const isDateStale = (
    dateNow: number,
    dateThen: Date,
    thresholdValue: number,
    thresholdUnit: string,
): boolean => {
    const minutesOld = Math.floor((dateNow - new Date(dateThen).getTime()) / (1000 * 60));
    return minutesOld > getAsMinutes(thresholdValue, thresholdUnit);
};
