import React from "react";
import { Add } from "@emisgroup/icons-react";
import { v4 as uuid } from "uuid";
import {
    AppModeContext,
    TemplateContext,
    addRemoveUpdateItemInContainer,
    AppMode,
    Tab,
    ComponentContainer,
    CanvasItemClickHandler,
    ItemClickProps,
    ComponentType,
    ComponentInfo,
    TabItemType,
    isEmbeddedItem,
    DynamicTabContainer,
    InvalidPropertyIndicator,
    findTabPageContainingCanvasItem,
} from "@emisgroup/clint-templates-common";
import { useTranslation } from "@emisgroup/application-intl";
import { ParametersForPropertyUpdate } from "../types";
import { moveFromSourceToTarget } from "../utils/componentUtils";
import DynamicTabPage, { DraggedTabPage, TabPageSelectedProps } from "../uiComponents/dynamicTabPage";
// eslint-disable-next-line import/no-cycle
import SelectedTabPageContent from "./selectedTabPageContent";
import "@emisgroup/clint-templates-common/lib/tabContainer.css";

type TabContainerProps = {
    tabContainer: Tab;
    isSelected: boolean;
    dragDropEnabled: boolean;
    onSelect: CanvasItemClickHandler;
    arePropertiesValid: boolean;
    onPropertyUpdate: (params: ParametersForPropertyUpdate) => void;
};

const getInvalidComponentIds = (isInEditMode: boolean, invalidComponentDefinitionIds: string[], tabContainer: Tab) => {
    if (!isInEditMode || !invalidComponentDefinitionIds) {
        return [];
    }

    return invalidComponentDefinitionIds.reduce((invalidTabPages: string[], invalidComponentId: string) => {
        const tabPage = findTabPageContainingCanvasItem(invalidComponentId, tabContainer);
        return tabPage && !invalidTabPages.includes(tabPage.id) ? [...invalidTabPages, tabPage.id] : invalidTabPages;
    }, []);
};

const TabContainer = (props: TabContainerProps) => {
    const { t } = useTranslation();
    const { tabContainer, isSelected, onSelect, arePropertiesValid } = props;
    const { mode } = React.useContext(AppModeContext);

    const { templateDefinition, setTemplateDefinition, invalidComponentDefinitionIds } =
        React.useContext(TemplateContext);
    const [activeTabFocus, setActiveTabFocus] = React.useState(false);
    const [selectedTabPageIndex, setSelectedTabPageIndex] = React.useState(0);

    const isInEditMode = mode === AppMode.EDIT;

    const tabPagesWithInvalidComponents = React.useMemo(
        () => getInvalidComponentIds(isInEditMode, invalidComponentDefinitionIds, tabContainer),
        [isInEditMode, invalidComponentDefinitionIds],
    );

    const onTabSelected = (index: number) =>
        window.dispatchEvent(
            new CustomEvent("tab-container-page-selected", { detail: { id: tabContainer.members[index].id } }),
        );

    const tabPageSelected = ({ evt, selectedTabIndex }: TabPageSelectedProps) => {
        const selectedTabPage = tabContainer.members[selectedTabIndex];
        const itemClickProps: ItemClickProps = { evt: evt as any, item: selectedTabPage };
        evt.stopPropagation();
        onSelect(itemClickProps);
        setSelectedTabPageIndex(selectedTabIndex);
        onTabSelected(selectedTabIndex);
        setActiveTabFocus(false);
    };

    const addNewTabPage = (evt: React.MouseEvent) => {
        const pageNumber = (tabContainer.members.length + 1).toString();
        const newPage: ComponentContainer = {
            type: ComponentType.TAB_PAGE,
            id: uuid(),
            label: t("tabContainer.tabPageNumber", { pageNumber }),
            members: [],
            columnCount: 1,
            columnIndex: 0,
            rule: null,
        };
        const tabContainerWithNewPageAdded = { ...tabContainer, members: [...tabContainer.members, newPage] };
        const updatedDefinition = addRemoveUpdateItemInContainer(
            templateDefinition,
            tabContainerWithNewPageAdded,
            "update",
        );
        evt.stopPropagation();
        setTemplateDefinition(updatedDefinition);
        setSelectedTabPageIndex(tabContainerWithNewPageAdded.members.length - 1);
        onSelect({ evt: {} as MouseEvent, item: newPage });
    };

    const moveTabPage = (tabPageIndex: number, targetTabPagePositionIndex: number) => {
        setActiveTabFocus(false);
        const movedTabPage = tabContainer.members[tabPageIndex];

        const updatedDefinition = moveFromSourceToTarget(
            movedTabPage,
            tabContainer as ComponentContainer,
            tabContainer as ComponentContainer,
            targetTabPagePositionIndex,
            templateDefinition,
        );

        const insertedPositionIndex =
            targetTabPagePositionIndex > tabPageIndex ? targetTabPagePositionIndex - 1 : targetTabPagePositionIndex;

        setTemplateDefinition(updatedDefinition);
        onSelect({ evt: {} as MouseEvent, item: movedTabPage });
        setSelectedTabPageIndex(insertedPositionIndex);
        setActiveTabFocus(true);
    };

    const tabsList: TabItemType[] = tabContainer.members.map(member => ({
        text: member.label,
        isEmbedded: isEmbeddedItem(member),
    }));

    const itemDropHandler = (draggedItem: DraggedTabPage, targetIndex: number) => {
        moveTabPage(draggedItem.tabPageIndex, targetIndex);
    };

    const renderTabPage = (tabItem: TabItemType, tabPageIndex: number, showInvalidWarning: boolean) => (
        <DynamicTabPage
            key={tabPageIndex}
            tabItem={tabItem}
            tabPageIndex={tabPageIndex}
            isTabPageSelected={selectedTabPageIndex !== null && selectedTabPageIndex === tabPageIndex}
            shouldSetActiveTabFocus={activeTabFocus}
            onTabSelect={tabPageSelected}
            dynamicTabId={tabContainer.id}
            showInvalidWarning={showInvalidWarning}
            dragDropEnabled
            onItemDropped={itemDropHandler}
        />
    );

    const createAddTabPage = () => (
        <div className="eui-dynamic-tabs__list-item eui-dynamic-tabs__list-item--add" role="tab" id="add-item">
            <button className="eui-dynamic-tabs__button" type="button" onClick={addNewTabPage}>
                {t("tabContainer.addAnother")}
                <Add title={t("tabContainer.addAnotherTab")} />
            </button>
        </div>
    );

    if (!isInEditMode && tabsList.length === 0) {
        return null;
    }

    const invalidClass = arePropertiesValid ? "" : " invalid";
    const showTabPageInvalid = tabPageIndex =>
        tabPagesWithInvalidComponents.some(tabPageId => tabPageId === tabContainer.members[tabPageIndex].id);
    const selectedTabPage = tabContainer.members[Math.min(selectedTabPageIndex, tabContainer.members.length - 1)];
    return (
        <DynamicTabContainer
            id={tabContainer.id}
            className={`${isSelected ? "selected" : ""} ${invalidClass}`}
            onSelect={evt => onSelect({ evt, item: tabContainer })}
            content={
                <>
                    <SelectedTabPageContent {...props} tabPage={selectedTabPage} />
                    <ComponentInfo
                        id={tabContainer.id}
                        type={ComponentType.TAB_CONTAINER}
                        rule={tabContainer.rule || selectedTabPage.rule}
                        perspectives={tabContainer.perspectives}
                    />
                </>
            }
        >
            {!arePropertiesValid && <InvalidPropertyIndicator />}
            {tabsList.map((tabItem, tabPageIndex) =>
                renderTabPage(tabItem, tabPageIndex, showTabPageInvalid(tabPageIndex)),
            )}
            {createAddTabPage()}
        </DynamicTabContainer>
    );
};

export default TabContainer;
