import * as React from "react";
import { ClinicalContentComponent, PatientDataContext } from "@emisgroup/clint-templates-common";
import { ContentViewer } from "@emisgroup/clint-content";

type AssessmentClinicalContentProps = { clinicalContent: ClinicalContentComponent };

export default function AssessmentClinicalContent({ clinicalContent }: AssessmentClinicalContentProps) {
    const { queryResults } = React.useContext(PatientDataContext);

    return (
        <div data-testid={`clinical-content-${clinicalContent.id}`}>
            <ContentViewer
                content={clinicalContent.content}
                queryResults={queryResults}
                visibleEntityIds={clinicalContent.members.map(member => member.id)}
                placeholder=""
            />
        </div>
    );
}
