import { routerMiddleware } from "connected-react-router";
import { History, createBrowserHistory } from "history";

import { applyMiddleware, compose, createStore, Middleware, Store } from "redux";
import { IApplicationContext } from "@emisgroup/single-spa-state-management";
import createRootReducer, { IRootState } from "../reducer/CombineReducer";
import { getBaseName } from "../utilities/router/pathUtil";

export interface IAppStore {
    /**
     * History
     */
    history: History;
    /**
     * Store
     */
    store: Store<IRootState>;
}

export const getAppStore = (applicationContext: IApplicationContext): IAppStore => {
    const browserHistory = createBrowserHistory({ basename: getBaseName(applicationContext) });
    const middleWare: Middleware = routerMiddleware(browserHistory);

    return {
        history: browserHistory,
        store: createStore(createRootReducer(browserHistory), compose(applyMiddleware(middleWare))),
    };
};
