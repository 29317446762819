/* eslint-disable import/prefer-default-export */
import React, { ReactNode } from "react";
import {
    TemplateContext,
    CanvasItem,
    ComponentType,
    ComponentContainer,
    ComponentContainerHistoryItem,
    ClinicalContentEntity,
    findCanvasItem,
    findCanvasItemParent,
    getAllNestedCanvasItems,
    Panel,
} from "@emisgroup/clint-templates-common";
import { useTranslation } from "@emisgroup/application-intl";
import { addToLocalStorage, getFromLocalStorage } from "../utils/localStorageUtils";
import {
    TEMPLATE_CHANGE_HISTORY_KEY,
    TEMPLATE_CHANGE_BASE_DEFINITION_KEY,
    MAX_TEMPLATE_HISTORY_ENTRIES,
    TEMPLATE_HISTORY_INDEX_KEY,
    TEMPLATE_ORIGINAL_DEFINITION_KEY,
    TEMPLATE_ID,
} from "../constants";
import { areAllComponentPropertiesValid } from "../utils/componentUtils";
import { isVisibilityRuleValid } from "../utils/ruleUtils";
import { areAllActionButtonsValid } from "../utils/panelActionUtils";
import { addToDefinitionHistory, isValidToPersist } from "../utils/changeHistoryUtils";
import { getTemplateChange, TemplateChange } from "../utils/templateChange";

type TemplateProviderProps = {
    children: ReactNode;
    initialDefinition: ComponentContainer;
    originalDefinition?: string;
    initialSelectedItem?: CanvasItem;
    initialHistoryIndex?: number;
    initialHistory?: Array<ComponentContainerHistoryItem>;
    defaultMaxHistoryEntries?: number;
};

const commonTemplateProperties = {
    id: TEMPLATE_ID,
    type: ComponentType.TEMPLATE,
};

const getGroupedItemId = (item: CanvasItem) =>
    item.type === ComponentType.CLINICAL_CONTENT_ENTITY ? (item as ClinicalContentEntity).componentId : item.id;

export const TemplateProvider = ({
    children,
    initialDefinition,
    originalDefinition = "{}",
    initialSelectedItem = {} as CanvasItem,
    initialHistoryIndex = 0,
    initialHistory = [],
    defaultMaxHistoryEntries = MAX_TEMPLATE_HISTORY_ENTRIES,
}: TemplateProviderProps) => {
    const [selectedItem, setSelectedItem] = React.useState<CanvasItem>(initialSelectedItem);
    const [originalTemplateDefinition, setOriginalTemplateDefinition] = React.useState<string>(originalDefinition);

    const [history, setHistory] = React.useState({
        templateHistoryIndex: initialHistoryIndex,
        templateDefinitionHistory: initialHistory,
    });
    const { templateHistoryIndex, templateDefinitionHistory } = history;
    const { t } = useTranslation();

    function setHistoryIndexAndPersist(index: number) {
        addToLocalStorage(TEMPLATE_HISTORY_INDEX_KEY, index);
        setHistory({ ...history, templateHistoryIndex: index });
    }

    const storedHistory = React.useRef<TemplateChange[]>([]);
    React.useEffect(() => {
        storedHistory.current = getFromLocalStorage(TEMPLATE_CHANGE_HISTORY_KEY, []);
    }, []);

    const templateDefinition = React.useMemo<ComponentContainer>(() => {
        if (templateDefinitionHistory.length === 0) {
            return initialDefinition;
        }
        const { change, ...definitionFromHistory } =
            templateDefinitionHistory[
                templateHistoryIndex > -1 && templateHistoryIndex < templateDefinitionHistory.length
                    ? templateHistoryIndex
                    : templateDefinitionHistory.length - 1
            ];

        return definitionFromHistory;
    }, [templateDefinitionHistory, templateHistoryIndex]);

    const areAllPanelActionsValid = (canvasItem: CanvasItem) =>
        canvasItem.type === ComponentType.PANEL
            ? areAllActionButtonsValid(t, (canvasItem as Panel)?.actionButtons ?? [])
            : true;

    const isComponentValid = (canvasItem: CanvasItem) =>
        areAllComponentPropertiesValid(t, canvasItem) &&
        isVisibilityRuleValid(t, canvasItem) &&
        areAllPanelActionsValid(canvasItem);

    const getInvalidComponentIds = (): string[] =>
        typeof templateDefinition.members !== "undefined"
            ? getAllNestedCanvasItems(templateDefinition).reduce((invalidComponents: [], canvasItemWithContainer) => {
                  return isComponentValid(canvasItemWithContainer.canvasItem)
                      ? invalidComponents
                      : [...invalidComponents, canvasItemWithContainer.canvasItem.id];
              }, [])
            : [];

    const [invalidComponentDefinitionIds, setInvalidComponentDefinitionIds] = React.useState<string[]>(
        getInvalidComponentIds(),
    );

    function clearTemplateDefinition() {
        const clearedHistory = [{} as ComponentContainerHistoryItem];
        addToLocalStorage(TEMPLATE_HISTORY_INDEX_KEY, 0);
        addToLocalStorage(TEMPLATE_ORIGINAL_DEFINITION_KEY, "");
        addToLocalStorage(TEMPLATE_CHANGE_HISTORY_KEY, []);
        setSelectedItem({} as CanvasItem);
        setOriginalTemplateDefinition("{}");
        setHistory({ templateHistoryIndex: 0, templateDefinitionHistory: clearedHistory });
        setInvalidComponentDefinitionIds([]);
    }

    function setDefinitionAndPersist(
        { members, columnCount, label }: ComponentContainer,
        isOriginalDefinition?: boolean,
        resetHistory?: boolean,
        addToHistory = true,
    ) {
        const currentDefinition = templateDefinitionHistory[templateHistoryIndex];
        const updatedDefinition: ComponentContainerHistoryItem = {
            ...commonTemplateProperties,
            ...currentDefinition,
            label,
            columnCount,
            members,
            change: { itemId: selectedItem?.id || "" },
        };
        if (isOriginalDefinition) {
            setOriginalTemplateDefinition(JSON.stringify(updatedDefinition));
            if (isValidToPersist(updatedDefinition))
                addToLocalStorage(TEMPLATE_ORIGINAL_DEFINITION_KEY, JSON.stringify(updatedDefinition));
        }
        let updatedHistory: ComponentContainerHistoryItem[] = [];
        let updatedIndex = templateHistoryIndex;
        if (resetHistory) {
            updatedHistory = [updatedDefinition];
            updatedIndex = 0;
            storedHistory.current = [];
            setSelectedItem({} as CanvasItem);
        } else if (addToHistory) {
            const result = addToDefinitionHistory(
                templateDefinitionHistory,
                updatedDefinition,
                storedHistory.current,
                getTemplateChange(templateDefinition, updatedDefinition),
                templateHistoryIndex,
                defaultMaxHistoryEntries,
            );
            updatedHistory = result.history;
            updatedIndex = result.index;
            storedHistory.current = result.storedHistory;
        }
        if (updatedHistory.length > 0) {
            const baseDefinition = updatedHistory[0];
            addToLocalStorage(TEMPLATE_HISTORY_INDEX_KEY, updatedIndex);
            addToLocalStorage(TEMPLATE_CHANGE_HISTORY_KEY, storedHistory.current);
            addToLocalStorage(TEMPLATE_CHANGE_BASE_DEFINITION_KEY, baseDefinition);
            setHistory({ templateHistoryIndex: updatedIndex, templateDefinitionHistory: updatedHistory });
        }
    }

    function removeChangeFromDefinition(definition: ComponentContainerHistoryItem) {
        const { change, ...definitionWithoutChange } = definition;
        return definitionWithoutChange;
    }

    const hasDefinitionChanged = React.useMemo(() => {
        const current = templateDefinitionHistory[templateHistoryIndex];
        if (!current) {
            return false;
        }
        const definitionToCompare = removeChangeFromDefinition(current);
        const originalDefinitionToCompare = removeChangeFromDefinition(JSON.parse(originalTemplateDefinition));
        return JSON.stringify(definitionToCompare) !== JSON.stringify(originalDefinitionToCompare);
    }, [templateHistoryIndex, templateDefinitionHistory, originalTemplateDefinition]);

    const containerOfSelectedItem: ComponentContainer | undefined = React.useMemo(() => {
        if (selectedItem.id) {
            return findCanvasItemParent(selectedItem.id, templateDefinition.members);
        }
        return undefined;
    }, [selectedItem, templateDefinition]);

    const [groupedItemIds, setGroupedItemIds] = React.useState<string[]>([]);
    function setGroupedItems(items: CanvasItem[]) {
        setGroupedItemIds(Array.from(new Set(items.map(getGroupedItemId))));
    }

    const groupedItems = React.useMemo(
        () => groupedItemIds.map(id => findCanvasItem(id, templateDefinition.members)).filter(Boolean) as CanvasItem[],
        [groupedItemIds, templateDefinition],
    );

    function setSelectionFromHistory(historyIndex: number) {
        if (groupedItems.length) {
            setGroupedItems([]);
        }

        const { change, members } = history.templateDefinitionHistory[historyIndex];
        if (change.itemId) {
            const selectedItemInHistory = findCanvasItem(change.itemId, members);
            setSelectedItem(selectedItemInHistory || ({} as CanvasItem));
        } else {
            setSelectedItem({} as CanvasItem);
        }
    }

    return (
        <TemplateContext.Provider
            value={{
                templateDefinition,
                setTemplateDefinition: setDefinitionAndPersist,
                clearTemplateDefinition,
                groupedItems,
                setGroupedItems,
                selectedItem,
                setSelectedItem,
                containerOfSelectedItem,
                history: templateDefinitionHistory,
                currentIndex: templateHistoryIndex,
                goBack: () => {
                    setHistoryIndexAndPersist(templateHistoryIndex - 1);
                    setSelectionFromHistory(templateHistoryIndex - 1);
                },
                goForward: () => {
                    setHistoryIndexAndPersist(templateHistoryIndex + 1);
                    setSelectionFromHistory(templateHistoryIndex + 1);
                },
                hasDefinitionChanged,
                invalidComponentDefinitionIds,
                setInvalidComponentDefinitionIds,
            }}
        >
            {children}
        </TemplateContext.Provider>
    );
};
