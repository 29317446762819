import {
    CanvasItem,
    ComponentDataType,
    ComponentType,
    ComponentContainer,
    TemplateData,
    isCanvasItemAContainer,
    ItemTypePropertiesMap,
    TypeOfProperty,
    findCanvasItem,
    CodedComponent,
    UncodedComponent,
    NumericValueComponent,
    ComponentWithAssociatedText,
    isContainerType,
} from "@emisgroup/clint-templates-common";
import { contentToText, QueryResultData } from "@emisgroup/clint-content";

export const getItemId = ({ id }: CanvasItem): string => id;

export function getFlattenedList(list: any[]): any[] {
    return list.reduce((finalList, listItem) => {
        if (typeof listItem === "object" && listItem.slice) {
            return [...finalList, ...getFlattenedList(listItem)];
        }
        return [...finalList, listItem];
    }, []);
}
const isComponentDirty = (component: CanvasItem, templateData: TemplateData) => {
    const itemData = templateData[component.id];
    if (!itemData) {
        return false;
    }
    switch (component.type) {
        case ComponentType.UNCODED:
            return itemData.selected;
        case ComponentType.DIARY_ENTRY:
        case ComponentType.CODED:
            return itemData.selected;
        case ComponentType.CODED_PICKING_LIST:
        case ComponentType.PICKING_LIST:
            if (!itemData.items) {
                return false;
            }
            return itemData.items.some(item => item.selected);
        case ComponentType.FREE_TEXT:
        case ComponentType.NUMERIC_VALUE:
            return (itemData.value ?? "") !== "";
        default:
            return false;
    }
};

export function isCanvasItemDirty(item: CanvasItem, templateData: TemplateData) {
    if (isCanvasItemAContainer(item)) {
        return (item as ComponentContainer).members.some(member => isCanvasItemDirty(member, templateData));
    }

    return isComponentDirty(item, templateData);
}

type ParamsForMatchingIdFinder = {
    allowedIds: string[]; // a list of item ids
    container: ComponentContainer;
};

export function buildRunTimeTemplateItems({ allowedIds, container }: ParamsForMatchingIdFinder): CanvasItem[] {
    const { members = [] } = container;
    return members.reduce((final, member) => {
        if (isCanvasItemAContainer(member)) {
            const nestedContainer = member as ComponentContainer;
            const filteredMembers = buildRunTimeTemplateItems({
                allowedIds,
                container: nestedContainer,
            });
            return allowedIds.includes(nestedContainer.id)
                ? [
                      ...final,
                      {
                          ...nestedContainer,
                          members: filteredMembers,
                      } as ComponentContainer,
                  ]
                : final;
        }
        return allowedIds.includes(member.id) ? [...final, member] : final;
    }, [] as CanvasItem[]);
}

export const getRequestBodyForComponents = (codes: string[]) => ({
    data: codes.map(codeId => ({
        type: "ern:emis:ckb:code",
        id: codeId,
    })),
});

export function getAdditionalDataProperty(component: CanvasItem) {
    const componentProperties = ItemTypePropertiesMap[component.type];
    return componentProperties.find(
        property =>
            property.propertyType === TypeOfProperty.ADDITIONAL_DATA &&
            property.dataAttributes.type !== ComponentDataType.BOOLEAN,
    );
}

type ParamsForUpdateNewVisibleComponents = {
    container: ComponentContainer;
    newlyVisibleIds: string[];
    templateData: TemplateData;
};
type CanvasItemThatCanBeSelectedByDefault = CodedComponent | UncodedComponent;
function isSelectedByDefault(item: CanvasItemThatCanBeSelectedByDefault) {
    return Boolean(item.selectedByDefault);
}
export function updateNewlyVisibleComponents({
    container,
    newlyVisibleIds,
    templateData,
}: ParamsForUpdateNewVisibleComponents) {
    const newlyVisibleItemsSelectedByDefault = newlyVisibleIds
        .map(id => findCanvasItem(id, container.members))
        .filter(isSelectedByDefault) as CanvasItem[];

    return newlyVisibleItemsSelectedByDefault.reduce(
        (updatedTemplateData, item) => ({
            ...updatedTemplateData,
            [item.id]: {
                ...updatedTemplateData[item.id],
                type: item.type as ComponentType,
                selected: true,
                initialValue: true,
            },
        }),
        templateData,
    );
}

function getItemInitialValue(canvasItem: CanvasItem) {
    if (canvasItem.type === ComponentType.NUMERIC_VALUE) {
        const componentValue = (canvasItem as NumericValueComponent).value;
        const initialValue =
            typeof componentValue === "number" || (typeof componentValue === "string" && componentValue !== "")
                ? Number(componentValue)
                : null;
        return initialValue === null || Number.isNaN(initialValue)
            ? { initialValue: null }
            : { initialValue, value: initialValue };
    }
    return {};
}

function getInitialAssociatedText(item: CanvasItem, queryResults: QueryResultData) {
    const { hasAssociatedText, associatedText } = item as ComponentWithAssociatedText;
    if (!hasAssociatedText) {
        return {};
    }

    if (typeof associatedText === "string") {
        return { associatedText };
    }

    return { associatedText: (associatedText ? contentToText(associatedText, queryResults) : "") || "" };
}

export function getInitialisedItem(canvasItem: CanvasItem, queryResults: QueryResultData) {
    return { ...getItemInitialValue(canvasItem), ...getInitialAssociatedText(canvasItem, queryResults) };
}

export function initialiseDataItems(items: CanvasItem[], queryResults: QueryResultData, templateData: TemplateData) {
    let initialised = { ...templateData };
    // eslint-disable-next-line no-restricted-syntax
    for (const item of items) {
        if (isContainerType(item.type)) {
            initialised = initialiseDataItems((item as ComponentContainer).members, queryResults, initialised);
        } else {
            initialised[item.id] = { ...getInitialisedItem(item, queryResults), type: item.type as ComponentType };
        }
    }

    return initialised;
}
