export const enGBMessages = {
    canvas: {
        componentConfigUnavailable: {
            title: "Unable to load template",
            detail: "If this error persists, contact EMIS support.",
        },
        visibilityRulesError: {
            title: "Unable to load template",
            detail: "If this error persists, contact EMIS support.",
        },
        initialising: "Initialising...",
        selectPatientToRun: "Select a patient to run the template",
        confidentialityWarning:
            "This patient's clinical record contains confidential data. <bold>If the template features this data</bold>, some fields and previous entries may not be shown. Use caution when adding a new diary entry as this will replace an existing one which may be hidden.",
    },
    months: {
        jan: "Jan",
        feb: "Feb",
        mar: "Mar",
        apr: "Apr",
        may: "May",
        jun: "Jun",
        jul: "Jul",
        aug: "Aug",
        sep: "Sep",
        oct: "Oct",
        nov: "Nov",
        dec: "Dec",
    },
    actionButtons: {
        create: "Create",
        discard: "Discard",
    },
    assessment: {
        componentNotSupported: "Component not currently supported in assessment style",
    },
};
