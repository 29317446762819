import { CanvasItem, ComponentContainer, TemplateContext } from "@emisgroup/clint-templates-common";
import * as React from "react";
import { useHistory } from "react-router";
import { ContentLibraryContext } from "./context/contentLibrary";
import { ContentLibraryStore } from "./types";
import NewTemplateDialog from "./uiComponents/newTemplateDialog";
import useChangesWarning from "./uiComponents/useChangesWarning";

type CreateNewTemplateProps = { children?: React.ReactNode | React.ReactNodeArray | null; redirectUri?: string };
const CreateNewTemplate = ({ children, redirectUri }: CreateNewTemplateProps) => {
    const { setTemplateDefinition, selectedItem, setSelectedItem } = React.useContext(TemplateContext);
    const { setContentLibrary } = React.useContext(ContentLibraryContext);

    const history = useHistory();
    const handleCancel = () => {
        if (redirectUri) {
            window.location.href = redirectUri;
        } else {
            history.push("/");
        }
    };

    const { dialog: warningDialog } = useChangesWarning({ onDialogCancel: handleCancel, initialCheck: true });

    const creatingNewTemplate = history?.location.pathname.toLowerCase() === "/new";

    if (!creatingNewTemplate) {
        return <>{children}</>;
    }

    const createNewTemplate = (newTemplateDefinition: ComponentContainer) => {
        if (selectedItem.id) {
            setSelectedItem({} as CanvasItem);
        }
        setTemplateDefinition(newTemplateDefinition, true, true);
        setContentLibrary({} as ContentLibraryStore);
        history.push("/");
    };

    return (
        <>
            {children}
            {warningDialog}
            {!warningDialog && <NewTemplateDialog createNewTemplate={createNewTemplate} cancel={handleCancel} />}
        </>
    );
};

export default CreateNewTemplate;
