import { ResponseType, Api, ApiResponse, GetResourceContentResponse } from "@emisgroup/content-library-sdk-ts";
import { ContentLibraryActivity, ContentLibraryStore } from "../types";
import { getContentLibraryApi, getReadableFailMessage, getStatusCode } from "./utils";

type LoadDefinitionOptions = {
    contentLibraryUrl: string;
    bearerToken: string;
    ern: string;
};

export enum LoadDefinitionResultType {
    Success,
    Fail,
}

type LoadDefinitionSuccess = {
    type: LoadDefinitionResultType.Success;
    definition: any;
    contentStatus: string;
};

type LoadContentLibraryTemplateSuccess = {
    type: LoadDefinitionResultType.Success;
    contentLibraryTemplate: ContentLibraryStore & { template: any };
};

type LoadDefinitionError = {
    type: LoadDefinitionResultType.Fail;
    statusCode: string;
    error: string;
};

export type LoadDefinitionResult = LoadDefinitionSuccess | LoadDefinitionError;
type LoadContentLibraryTemplateResult = LoadContentLibraryTemplateSuccess | LoadDefinitionError;

const createFailResult = ({ errors }): LoadDefinitionError => {
    return {
        type: LoadDefinitionResultType.Fail,
        statusCode: getStatusCode(errors),
        error: getReadableFailMessage(errors, ContentLibraryActivity.LOAD).message,
    };
};

const downloadContent = async (
    contentLibraryApi: Api,
    downloadUrl: string,
    contentStatus: string,
): Promise<LoadDefinitionResult> => {
    const downloadResult = await contentLibraryApi.downloadContent(downloadUrl);

    if (downloadResult.type === ResponseType.Error) {
        return createFailResult(downloadResult);
    }

    return { type: LoadDefinitionResultType.Success, definition: downloadResult.payload, contentStatus };
};

export const GENERAL_STATUS = "general";
export const BETA_STATUS = "beta";
export const DRAFT_STATUS = "draft";
const STATUS_RANKING = [DRAFT_STATUS, BETA_STATUS, GENERAL_STATUS];

export const getCurrentStatus = (statusFlags: string[]) =>
    STATUS_RANKING.find(ranking => statusFlags?.includes(ranking)) ?? DRAFT_STATUS;

const getContent = async (contentLibraryApi: Api, statusFlags: string[], ern: string, version?: string) => {
    let loadContentResult: ApiResponse<GetResourceContentResponse>;
    const currentStatus = getCurrentStatus(statusFlags);
    if (version) {
        loadContentResult = await contentLibraryApi.getResourceContent(ern, version);
    } else if (currentStatus === GENERAL_STATUS) {
        loadContentResult = await contentLibraryApi.getLatestResourceContent(ern);
    } else if (currentStatus === BETA_STATUS) {
        loadContentResult = await contentLibraryApi.getLatestBetaContent(ern);
    } else {
        loadContentResult = await contentLibraryApi.getLatestDraftContent(ern);
    }

    if (loadContentResult.type === ResponseType.Error) {
        return createFailResult(loadContentResult);
    }
    return downloadContent(contentLibraryApi, loadContentResult.payload.downloadUrl, currentStatus);
};

export const loadDraftDefinition = async ({
    contentLibraryUrl,
    bearerToken,
    ern,
}: LoadDefinitionOptions): Promise<LoadDefinitionResult> =>
    getContent(await getContentLibraryApi(contentLibraryUrl, bearerToken), [], ern);

export const loadTemplateFromContentLibrary = async ({
    contentLibraryUrl,
    bearerToken,
    ern,
    version,
}: {
    contentLibraryUrl: string;
    bearerToken: string;
    ern: string;
    version?: string;
}): Promise<LoadContentLibraryTemplateResult> => {
    const contentLibraryApi = await getContentLibraryApi(contentLibraryUrl, bearerToken);

    const getResourceDetailsResult = await contentLibraryApi.getResourceDetails(ern);
    if (getResourceDetailsResult.type === ResponseType.Error) {
        return createFailResult(getResourceDetailsResult);
    }

    const loadContentResult = await getContent(
        contentLibraryApi,
        getResourceDetailsResult.payload.statusFlags,
        ern,
        version,
    );
    if (loadContentResult.type === LoadDefinitionResultType.Fail) {
        return loadContentResult;
    }
    const [latestActivity] = getResourceDetailsResult.payload.activity
        .filter(act => !version || act.version === version)
        .slice()
        .sort((act1, act2) => new Date(act2.timestamp).getTime() - new Date(act1.timestamp).getTime());
    const ref = latestActivity ? latestActivity.ref : "";
    return {
        type: LoadDefinitionResultType.Success,
        contentLibraryTemplate: {
            ...getResourceDetailsResult.payload,
            ref,
            contentStatus: loadContentResult.contentStatus,
            template:
                typeof loadContentResult.definition === "string"
                    ? JSON.parse(loadContentResult.definition)
                    : loadContentResult.definition,
        },
    };
};
