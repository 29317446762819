import { GlobalEventDistributor, IApplicationContext } from "@emisgroup/single-spa-state-management";
import { IApplicationConfig } from "@emisgroup/module-discovery";
import { createBrowserHistory } from "history";

const ApplicationErn = "TestApp:Ern:1";

const application = (): IApplicationConfig => {
    return {
        absoluteUrl: "",
        displayName: "",
        ern: ApplicationErn,
        icon: null,
        isStandalone: true,
        mappingPath: "",
        name: "",
        parentErn: null,
        rank: 0,
        relativeUrl: "",
        supportsOffline: false,
        supportsPatientChange: false,
        version: "",
    } as IApplicationConfig;
};

const applications: IApplicationConfig[] = [];
applications.push(application());

const selectedApplication = (selectedApplicationErn: string): IApplicationConfig => {
    return applications.find((app: IApplicationConfig) => ({
        ...app,
        ern: selectedApplicationErn,
    })) as IApplicationConfig;
};

const AppContext: IApplicationContext = {
    applicationConfiguration: applications,
    globalEventDistributor: new GlobalEventDistributor(),
    history: createBrowserHistory(),
    selectedApplication: selectedApplication(ApplicationErn),
    selectedApplicationErn: ApplicationErn,
    appEdmOpenApiHubUrl: "",
};

export default AppContext;
