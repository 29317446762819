import * as React from "react";
import { Notification } from "@emisgroup/ui-kit-react";

import "./timedNotification.css";

type TimedNotificationProps = {
    type: "confirmation" | "info";
    text: string;
    milliseconds: number;
    onTimeout: () => void;
};

const TimedNotification = ({ type, text, milliseconds, onTimeout }: TimedNotificationProps) => {
    React.useEffect(() => {
        let notificationTimer: NodeJS.Timeout | null = null;
        notificationTimer = setTimeout(onTimeout, milliseconds) as unknown as NodeJS.Timeout;

        return () => {
            if (notificationTimer) {
                clearTimeout(notificationTimer);
            }
        };
    }, [milliseconds, onTimeout]);

    return (
        <div className="timed-notification">
            <Notification notificationType={type} content={{ text, title: "" }} />
        </div>
    );
};

export default TimedNotification;
