import * as React from "react";
import { IntlProvider as AppIntlProvider } from "@emisgroup/application-intl";
import { enGBMessages } from "@emisgroup/clinical-code-entry/lib/locales/en-GB/translation";
import { enGBMessages as enCommon } from "@emisgroup/clint-templates-common/lib/locales/en-GB/translation";
import { enGBMessages as enRunner } from "@emisgroup/clint-templates-runner/lib/locales/en-GB/translation";

import templatesMessages from "./locales/en-GB/translation.json";

const namespace = "translation";
const resources = {
    "en-GB": {
        [namespace]: {
            ...enGBMessages,
            ...enCommon,
            ...enRunner,
            ...templatesMessages,
        },
    },
};

type Props = { children: React.ReactNode };

/**
 * Responsible for rendering the IntlProvider component
 */
const IntlProvider: React.FC<Props> = (props: Props) => {
    return (
        <AppIntlProvider resources={resources} defaultNS={namespace}>
            {props.children}
        </AppIntlProvider>
    );
};

export { IntlProvider };
