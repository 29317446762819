import * as React from "react";
import { InfoBanner } from "@emisgroup/ui-kit-react";
import { useTranslation } from "@emisgroup/application-intl";

import "./propertyWarning.css";

type PropertyWarningProps = { name: string; warnings: string[] };

const PropertyWarning = ({ name, warnings }: PropertyWarningProps) => (
    <InfoBanner
        data-testid={`${name}-warnings`}
        className="property-warning"
        variant="warning"
        iconName="notification-info"
        iconTitle={useTranslation().t("information")}
        vertical
    >
        {warnings.map(warning => (
            <div key={warning} className="property-warning-text">
                {warning}
            </div>
        ))}
    </InfoBanner>
);

export default PropertyWarning;
