import { InfoWarningMono } from "@emisgroup/icons-react";
import { useTranslation } from "@emisgroup/application-intl";

const InvalidPropertyIndicator = () => {
    const { t } = useTranslation();

    return (
        <>
            <div data-testid="invalid-property-indicator" className="invalid-property-indicator">
                <InfoWarningMono color="danger" size="small" title={t("components.property.invalidProperty")} />
            </div>
        </>
    );
};

export default InvalidPropertyIndicator;
