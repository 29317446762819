import * as React from "react";

type AuthContextType = {
    getBearerToken: () => Promise<string>;
};

const AuthContext = React.createContext<AuthContextType>({
    getBearerToken: async () => "",
});

export default AuthContext;
