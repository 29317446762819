/* eslint-disable react/style-prop-object */
import * as React from "react";
import { Button } from "@emisgroup/ui-kit-react";
import { useTranslation } from "@emisgroup/application-intl";
import { useHistory } from "react-router";

type NoTemplateCanvasProps = {
    switchToLoadMode: () => void;
};

const NoTemplateCanvas = ({ switchToLoadMode }: NoTemplateCanvasProps) => {
    const history = useHistory();
    const { t } = useTranslation();
    return (
        <>
            <div className="no-selection-message">
                <h3 style={{ textAlign: "center" }}>{t("templates.noTemplateSelected")}</h3>
                <Button
                    ariaLabel={t("templates.createNewTemplate")}
                    variant="primary"
                    onClick={() => history.push("/new")}
                >
                    {t("new")}
                </Button>
                <span style={{ margin: "1rem" }}>{t("or")}</span>
                <Button ariaLabel={t("templates.loadTemplate")} variant="primary" onClick={switchToLoadMode}>
                    {t("load")}
                </Button>
            </div>
        </>
    );
};

export default NoTemplateCanvas;
