import {
    TemplateContext,
    CanvasItem,
    ComponentContainer,
    findCanvasItemParent,
} from "@emisgroup/clint-templates-common";
import { useTranslation } from "@emisgroup/application-intl";
import * as React from "react";
import { getVisibilityConflictsForMove } from "../utils/ruleUtils";
import { moveFromSourceToTarget } from "../utils/componentUtils";
import { getDialogForItemMove } from "../utils/dialogUtils";

const useMoveItem = (component: ComponentContainer) => {
    const { t } = useTranslation();
    const { templateDefinition: rootContainer, setTemplateDefinition } = React.useContext(TemplateContext);
    const [showingMoveConflictDialog, setShowingMoveConflictDialog] = React.useState(false);
    const [visibilityConflicts, setVisibilityConflicts] = React.useState<Array<string>>([]);

    const moveItemWithinContainer = (item: CanvasItem, position: number) => {
        const updatedTemplate = moveFromSourceToTarget(item, component, component, position, rootContainer);
        setTemplateDefinition(updatedTemplate);
    };

    const moveItemFromContainer = (
        item: CanvasItem,
        source: ComponentContainer,
        position: number,
        columnIndex: number,
    ) => {
        const updatedTemplate = moveFromSourceToTarget(
            { ...item, columnIndex },
            source,
            component,
            position,
            rootContainer,
        );
        setTemplateDefinition(updatedTemplate);
    };

    const moveItemFromRoot = (item: CanvasItem, position: number, columnIndex: number) => {
        const updatedTemplate = moveFromSourceToTarget(
            {
                ...item,
                columnIndex,
            },
            rootContainer,
            component,
            position,
            rootContainer,
        );
        setTemplateDefinition(updatedTemplate);
    };

    const moveIfNoConflict = (itemId: string, move: () => void) => {
        const visibilityRuleConflicts = getVisibilityConflictsForMove(t, itemId, component, rootContainer);
        setVisibilityConflicts(visibilityRuleConflicts);

        if (visibilityRuleConflicts.length === 0) {
            move();
        } else {
            setShowingMoveConflictDialog(true);
        }
    };

    const moveItem = (item: CanvasItem, targetIndex: number, itemColumnIndex: number) => {
        const parentOfItem = findCanvasItemParent(item.id, rootContainer.members);

        if (parentOfItem) {
            if (parentOfItem.id !== component.id) {
                moveIfNoConflict(item.id, () => {
                    moveItemFromContainer(item, parentOfItem, targetIndex, itemColumnIndex);
                });
            } else {
                moveItemWithinContainer(item, targetIndex);
            }
        } else {
            moveIfNoConflict(item.id, () => moveItemFromRoot(item, targetIndex, itemColumnIndex));
        }
    };

    const closeMoveConflictDialog = () => {
        setShowingMoveConflictDialog(false);
        setVisibilityConflicts([]);
    };

    return {
        moveItem,
        moveItemDialog: showingMoveConflictDialog
            ? getDialogForItemMove({ t, visibilityConflicts, onClose: closeMoveConflictDialog })
            : null,
    };
};

export default useMoveItem;
