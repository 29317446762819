import * as React from "react";
import { Condition, ConditionOperator, Rule, TemplateContext } from "@emisgroup/clint-templates-common";
import { useTranslation } from "@emisgroup/application-intl";
import { HidePassword, ShowPassword } from "@emisgroup/icons-react";
import { getConditionsForDisplay } from "../utils/ruleUtils";
import { capitalise } from "../utils/componentUtils";
import VisibilityCondition from "./visibilityCondition";

type VisibilityRulesListProps = {
    canvasItemLabel: string;
    rule: Rule;
    invalidRuleConditions: any;
    canEditRules: boolean;
    onDelete: (conditionIndex: number) => void;
    onUpdate: (conditionIndex: number, updatedCondition: Condition) => void;
    onSelectNewSource: (conditionIndex: number) => void;
};

const VisibilityRulesList = ({
    canvasItemLabel,
    rule,
    invalidRuleConditions = [],
    canEditRules,
    onDelete,
    onUpdate,
    onSelectNewSource,
}: VisibilityRulesListProps) => {
    const { t } = useTranslation();
    const { templateDefinition } = React.useContext(TemplateContext);

    const componentVisibilityHeader = (rulesDefined: boolean) => {
        const visibilityTitle = t(
            rulesDefined ? "templates.rules.componentVisibleWhen" : "templates.rules.componentIsAlwaysVisible",
        );

        return (
            <>
                <div className="visibility-rules-list__header">
                    <div>
                        {rulesDefined ? (
                            <HidePassword title={visibilityTitle} />
                        ) : (
                            <ShowPassword title={visibilityTitle} />
                        )}
                    </div>
                    <div className="visibility-rules-list__title">{visibilityTitle}</div>
                </div>
            </>
        );
    };

    const conditionsDefined = rule ? rule.conditionMembers.length > 0 : false;

    const conditionOperatorTranslationKeys = { [ConditionOperator.ALL]: "and", [ConditionOperator.ANY]: "or" };

    const conditionOperatorForDisplay =
        rule && rule.conditionOperator ? capitalise(t(conditionOperatorTranslationKeys[rule.conditionOperator])) : "";

    const conditionsForDisplay = rule ? getConditionsForDisplay(t, rule, templateDefinition.members) : [];

    return (
        <div className="visibility-rules-list">
            {componentVisibilityHeader(conditionsDefined)}
            {conditionsForDisplay.map((conditionForDisplay, index) => (
                <div key={conditionForDisplay.key}>
                    <VisibilityCondition
                        canvasItemLabel={canvasItemLabel}
                        conditionIndex={index}
                        conditionForDisplay={conditionForDisplay}
                        canEditRules={canEditRules}
                        invalidConditionFields={invalidRuleConditions[index.toString()]}
                        onDelete={() => onDelete(index)}
                        onUpdate={updatedCondition => onUpdate(index, updatedCondition)}
                        onSelectNewSource={() => onSelectNewSource(index)}
                    />

                    {index + 1 < conditionsForDisplay.length && (
                        <div
                            data-testid="conditionOperator"
                            className="sidebar-row-default visibility-rules-list__rule-box__join"
                        >
                            {conditionOperatorForDisplay}
                        </div>
                    )}
                    <div />
                </div>
            ))}
        </div>
    );
};

export default VisibilityRulesList;
