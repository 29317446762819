import React from "react";
import {
    AppMode,
    ComponentContainerHistoryItem,
    version as TemplatesCommonVersion,
} from "@emisgroup/clint-templates-common";
import { version as TemplatesRunnerVersion } from "@emisgroup/clint-templates-runner";
import { version as TemplatesPerspectivesVersion } from "@emisgroup/clint-templates-perspectives";
import App from "./app";
import ErrorBoundary from "./errorBoundary";
import InitialTemplateLoad from "./initialTemplateLoad";
import DragAndDrop from "./dragAndDrop";
import { addToLocalStorage, getFromLocalStorage, deleteFromLocalStorage } from "./utils/localStorageUtils";
import { AppModeProvider } from "./context/appMode";
import { TestRunModeProvider } from "./context/testRunMode";
import { ContentLibraryProvider } from "./context/contentLibrary";
import { TemplatePickerProvider } from "./context/templatePicker";
import { TemplateProvider } from "./context/template";
import ConfigProvider from "./context/configProvider";
import { SelectedPatientProvider } from "./context/selectedPatientContext";
import {
    TEMPLATE_CONTENT_LIBRARY_KEY,
    TEMPLATE_HISTORY_INDEX_KEY,
    TEMPLATE_HISTORY_KEY_DEPRECATED,
    TEMPLATE_CHANGE_HISTORY_KEY,
    TEMPLATE_ORIGINAL_DEFINITION_KEY,
    TEMPLATE_BUILDER_APP_MODE_KEY,
    TEMPLATE_CHANGE_BASE_DEFINITION_KEY,
} from "./constants";
import { AuthProvider } from "./context/auth";
import { moveLinksBelowStyles } from "./utils/domUtils";
import CreateNewTemplate from "./createNewTemplate";
import PackageJson from "../../../package.json";
import { rebuildHistory, TemplateChange } from "./utils/templateChange";

import "./home.css";

const getInitialDataFromLocalStorage = () => {
    let templateHistoryIndex: number = getFromLocalStorage(TEMPLATE_HISTORY_INDEX_KEY, 0);
    const originalDefinitionFromLocalStorage = getFromLocalStorage(TEMPLATE_ORIGINAL_DEFINITION_KEY, undefined);
    const initialAppMode = getFromLocalStorage(TEMPLATE_BUILDER_APP_MODE_KEY, AppMode.EDIT) as AppMode;

    const baseTemplateDefinition: ComponentContainerHistoryItem = getFromLocalStorage(
        TEMPLATE_CHANGE_BASE_DEFINITION_KEY,
        {} as ComponentContainerHistoryItem,
    );
    let templateHistory: ComponentContainerHistoryItem[] | undefined;
    let templateDefinition = baseTemplateDefinition;
    const templateChangeHistory: TemplateChange[] | null = getFromLocalStorage(TEMPLATE_CHANGE_HISTORY_KEY, null);
    const deprecatedTemplateHistory = getFromLocalStorage(TEMPLATE_HISTORY_KEY_DEPRECATED, null);
    if (templateChangeHistory) {
        templateHistory = rebuildHistory(baseTemplateDefinition, templateChangeHistory);
        templateDefinition = templateHistory[templateHistoryIndex];
    } else if (deprecatedTemplateHistory) {
        templateDefinition = deprecatedTemplateHistory[templateHistoryIndex];
        addToLocalStorage(TEMPLATE_CHANGE_BASE_DEFINITION_KEY, templateDefinition);
        addToLocalStorage(TEMPLATE_CHANGE_HISTORY_KEY, [[]]);
        deleteFromLocalStorage(TEMPLATE_HISTORY_KEY_DEPRECATED);
        templateHistory = [templateDefinition];
        templateHistoryIndex = 0;
        addToLocalStorage(TEMPLATE_HISTORY_INDEX_KEY, templateHistoryIndex);
    }

    let originalDefinition: string = originalDefinitionFromLocalStorage;
    if (!originalDefinition) {
        originalDefinition = JSON.stringify(templateDefinition);
        addToLocalStorage(TEMPLATE_ORIGINAL_DEFINITION_KEY, originalDefinition);
    }

    const contentLibraryFromLocalStorage = getFromLocalStorage(TEMPLATE_CONTENT_LIBRARY_KEY, {});

    return {
        templateDefinition,
        templateHistoryIndex,
        templateHistory,
        originalDefinition,
        contentLibraryFromLocalStorage,
        initialAppMode,
    };
};

const useMockPatientSelect = process.env.APP_USE_MOCK_PATIENTS === "true" || false;

moveLinksBelowStyles();

const Home = () => {
    const initialData = React.useRef(getInitialDataFromLocalStorage());
    const {
        templateDefinition,
        templateHistoryIndex,
        templateHistory,
        originalDefinition,
        contentLibraryFromLocalStorage,
        initialAppMode,
    } = initialData.current;
    const queryStringParams = new URLSearchParams(window.location.search);
    const redirectUri = queryStringParams.get("redirectUrl") || "";
    return (
        <ErrorBoundary>
            <ConfigProvider>
                <AuthProvider>
                    <AppModeProvider initialMode={initialAppMode}>
                        <TestRunModeProvider>
                            <TemplateProvider
                                initialDefinition={templateDefinition}
                                initialHistoryIndex={templateHistoryIndex}
                                initialHistory={templateHistory}
                                originalDefinition={originalDefinition}
                            >
                                <ContentLibraryProvider initialContentLibrary={contentLibraryFromLocalStorage}>
                                    <TemplatePickerProvider>
                                        <SelectedPatientProvider>
                                            <DragAndDrop>
                                                <InitialTemplateLoad
                                                    redirectUri={redirectUri}
                                                    contentLibraryTemplateErn={queryStringParams.get("template")}
                                                    editorStatus={queryStringParams.get("status")}
                                                >
                                                    <CreateNewTemplate redirectUri={redirectUri}>
                                                        <App
                                                            useMockPatientSelect={useMockPatientSelect}
                                                            redirectUri={redirectUri}
                                                        />
                                                    </CreateNewTemplate>
                                                </InitialTemplateLoad>
                                            </DragAndDrop>
                                        </SelectedPatientProvider>
                                    </TemplatePickerProvider>
                                    <div className="template-version">
                                        {`@emisgroup/template-builder-version: ${PackageJson.version}`}
                                        {`@emisgroup/template-builder-applicationversion: ${PackageJson.ApplicationVersion}`}
                                        {`@emisgroup/clint-templates-common: ${TemplatesCommonVersion}`}
                                        {`@emisgroup/clint-templates-runner: ${TemplatesRunnerVersion}`}
                                        {`@emisgroup/clint-templates-perspectives: ${TemplatesPerspectivesVersion}`}
                                    </div>
                                </ContentLibraryProvider>
                            </TemplateProvider>
                        </TestRunModeProvider>
                    </AppModeProvider>
                </AuthProvider>
            </ConfigProvider>
        </ErrorBoundary>
    );
};

export default Home;
