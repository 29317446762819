import {
    CalculatorComponent,
    CanvasItem,
    ComponentContainer,
    ComponentType,
    DataItem,
    findCanvasItem,
    TemplateData,
} from "@emisgroup/clint-templates-common";
import { TemplateFilingItem } from "./types";

type TemplateDataItem = {
    type: ComponentType;
    selected?: boolean;
    items?: DataItem[];
    value?: any;
};

function addCode(dataItem: TemplateFilingItem, item): TemplateFilingItem {
    return item.code ? { ...dataItem, code: item.code } : dataItem;
}

function addValue(dataItem: TemplateFilingItem, item): TemplateFilingItem {
    switch (dataItem.type) {
        case ComponentType.UNCODED:
            return { ...dataItem, value: item.data || item.label };
        case ComponentType.PICKING_LIST:
            return { ...dataItem, value: dataItem.value?.data || dataItem.value?.text };
        default:
            return dataItem;
    }
}

function augmentDataItem(dataItem: TemplateFilingItem, item): TemplateFilingItem {
    const withCode = addCode(dataItem, item);
    return addValue(withCode, item);
}

const SELECTABLE_COMPONENT_TYPES = [ComponentType.UNCODED, ComponentType.CODED, ComponentType.DIARY_ENTRY];
function canItemBeFiled(dataItem: TemplateDataItem, templateItem: CanvasItem) {
    const { type, selected, value } = dataItem;
    switch (true) {
        case SELECTABLE_COMPONENT_TYPES.includes(type):
            return Boolean(selected);
        case type === ComponentType.CALCULATOR:
            return (templateItem as CalculatorComponent).fileResult;
        default:
            return Boolean(value);
    }
}

function getDataItems(dataItem: TemplateDataItem, templateItem: CanvasItem): TemplateDataItem[] {
    const { items } = dataItem;
    if (!items) {
        return canItemBeFiled(dataItem, templateItem) ? [dataItem] : [];
    }

    return items.filter(({ selected }) => selected).map(item => ({ ...item, type: dataItem.type }));
}

export default function dataToFile(templateData: TemplateData, template: ComponentContainer): TemplateFilingItem[] {
    return Object.entries(templateData).reduce((toFile, [id, templateDataItem]) => {
        const templateItem = findCanvasItem(id, template.members);
        if (!templateItem) {
            return toFile;
        }
        return [
            ...toFile,
            ...getDataItems(templateDataItem, templateItem).map(dataItem =>
                augmentDataItem(
                    {
                        ...dataItem,
                        componentId: id,
                        category: (templateItem as TemplateFilingItem).category,
                    },
                    templateItem,
                ),
            ),
        ];
    }, []);
}
