import {
    Branding,
    CanvasItem,
    ClinicalContentComponent,
    ComponentType,
    FreeTextComponent,
    Panel,
    PickingListComponent,
    UncodedComponent,
    BrandingComponent,
} from "@emisgroup/clint-templates-common";
import { useTranslation } from "@emisgroup/application-intl";
import AssessmentPanel from "./assessmentPanel";
import AssessmentUncoded from "./assessmentUncoded";
import { validateComponent } from "../utils/validationUtils";
import AssessmentPickingList from "./assessmentPickingList";
import AssessmentFreeText from "./assessmentFreeText";
import AssessmentClinicalContent from "./assessmentClinicalContent";

type ComponentRendererProps = {
    component: CanvasItem;
};

export default function ComponentRenderer({ component }: ComponentRendererProps) {
    const { t } = useTranslation();
    const runtimeValidator = data => validateComponent(component, data);

    const getComponent = () => {
        switch (component.type) {
            case ComponentType.BRANDING:
                return <Branding component={component as BrandingComponent} />;
            case ComponentType.PANEL:
                return (
                    <AssessmentPanel panel={component as Panel}>
                        {(component as Panel).members.map(member => (
                            <ComponentRenderer key={member.id} component={member} />
                        ))}
                    </AssessmentPanel>
                );

            case ComponentType.UNCODED:
                return (
                    <AssessmentUncoded uncoded={component as UncodedComponent} runtimeValidator={runtimeValidator} />
                );

            case ComponentType.PICKING_LIST:
                return (
                    <AssessmentPickingList
                        pickingList={component as PickingListComponent}
                        runtimeValidator={runtimeValidator}
                    />
                );

            case ComponentType.FREE_TEXT:
                return (
                    <AssessmentFreeText freeText={component as FreeTextComponent} runtimeValidator={runtimeValidator} />
                );

            case ComponentType.CLINICAL_CONTENT:
                return <AssessmentClinicalContent clinicalContent={component as ClinicalContentComponent} />;

            default:
                return <h3>{t("assessment.componentNotSupported")}</h3>;
        }
    };

    return (
        <div className="assessment-item" data-testid={`item-${component.id}`}>
            {getComponent()}
        </div>
    );
}
