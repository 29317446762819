import React from "react";
import { ContentViewer, ContentDesigner, getContentIds } from "@emisgroup/clint-content";
import { UpdateableEditorEntity, LinkEditorEntity, QueryEditorEntity } from "@emisgroup/clint-content/lib/types";
import { AppMode, ComponentType, ClinicalContentComponent as ContentComponent, ClinicalContentEntity } from "../types";
import AppModeContext from "../context/appMode";
import PatientDataContext from "../context/patientData";
import { TemplateContext } from "../context/template";
import AuthContext from "../context/auth";
import ConfigContext, { CLINICAL_CONTENT_QUERY_DISPLAY_TYPES } from "../context/config";
import { addRemoveUpdateItemInContainer } from "../utils/componentUtils";
import Component from "./component";

import "./clinicalContent.css";
import ComponentInfo from "./componentInfo";

type ClinicalContentProps = {
    clinicalContentComponent: ContentComponent;
    isSelected: boolean;
    onSelect: (eventData: React.MouseEvent) => void;
};

const getRuleForMember = (memberId: string, members: ClinicalContentEntity[]) =>
    members.filter(member => member.id === memberId).map(({ rule }) => rule)[0] || null;

const ClinicalContent = ({ clinicalContentComponent, isSelected, onSelect }: ClinicalContentProps) => {
    const { id, label, content, members } = clinicalContentComponent;
    const { mode } = React.useContext(AppModeContext);
    const { queryResults } = React.useContext(PatientDataContext);
    const { templateDefinition, setTemplateDefinition, setSelectedItem } = React.useContext(TemplateContext);
    const { getBearerToken } = React.useContext(AuthContext);
    const { features, contentLibraryUrl } = React.useContext(ConfigContext);

    const contentLibraryConfig = {
        url: contentLibraryUrl,
        getBearerToken,
    };

    const isInRunMode = mode === AppMode.RUN;

    const handleBlur = value => {
        if (JSON.stringify(value) === JSON.stringify(content)) {
            return;
        }

        const updatedItem = {
            ...clinicalContentComponent,
            content: value,
            members: getContentIds(value).map(contentId => ({
                id: contentId,
                type: ComponentType.CLINICAL_CONTENT_ENTITY,
                columnIndex: 0,
                label: "entity",
                componentId: id,
                rule: getRuleForMember(contentId, members),
            })),
        } as ContentComponent;

        const updatedDefinition = addRemoveUpdateItemInContainer(templateDefinition, updatedItem, "update");
        setTemplateDefinition(updatedDefinition);
        setSelectedItem(updatedItem);
    };

    const renderInRunMode = () => (
        <div className="clinical-content-viewer" key={`component-${id}`} data-testid={`component-${id}`}>
            <ContentViewer
                content={content}
                queryResults={queryResults}
                visibleEntityIds={members.map(member => member.id)}
                placeholder=""
            />
        </div>
    );

    const handleClick = ev => {
        const isMultiSelectMode = ev.shiftKey || ev.ctrlKey || ev.metaKey;
        if (isMultiSelectMode || (mode !== AppMode.RUN && !isSelected)) {
            onSelect(ev);
        } else {
            ev.stopPropagation();
        }
    };

    const handleSelectEntity = (entity: UpdateableEditorEntity) =>
        setSelectedItem({
            id: entity.entityId,
            label: (entity as QueryEditorEntity).name || (entity as LinkEditorEntity).url,
            type: ComponentType.CLINICAL_CONTENT_ENTITY,
            columnIndex: 0,
            rule: getRuleForMember(entity.entityId, members),
            componentId: id,
            update: entity,
        });

    const handleLeaveEntity = () => setSelectedItem(clinicalContentComponent);

    const renderInEditMode = () => (
        <Component id={id} label="" excludeHistory>
            <div
                className={`canvas-item clinical-content-viewer${isSelected ? " selected" : ""}`}
                role="none"
                onClick={handleClick}
                data-testid={`component-${id}`}
                title={label}
                aria-label={label}
            >
                <div className="canvas-item data-entry">
                    {isSelected && mode === AppMode.EDIT ? (
                        <ContentDesigner
                            className="inline-editor"
                            id={`component-${id}`}
                            initialContent={content}
                            onBlur={handleBlur}
                            onSelectEntity={handleSelectEntity}
                            onLeaveEntity={handleLeaveEntity}
                            contentLibraryConfig={contentLibraryConfig}
                            queryDisplayTypes={features[CLINICAL_CONTENT_QUERY_DISPLAY_TYPES]}
                            placeholder=""
                        />
                    ) : (
                        <ContentViewer id={`component-${id}`} content={content} placeholder="&nbsp;" />
                    )}
                    <ComponentInfo
                        id={id}
                        type={ComponentType.CLINICAL_CONTENT}
                        rule={clinicalContentComponent.rule}
                        perspectives={clinicalContentComponent.perspectives}
                    />
                </div>
            </div>
        </Component>
    );

    return isInRunMode ? renderInRunMode() : renderInEditMode();
};

export default ClinicalContent;
