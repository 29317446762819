import {
    CodedPickingListComponent,
    ComponentConfigData,
    ComponentType,
    HistoryItems,
    HistoryRequest,
} from "@emisgroup/clint-templates-common";
import { getDateDescriptionFromYMD } from "./dateUtils";

const onlyUnique = (value, index, self) => self.indexOf(value) === index;

const isConfigMatch = (emisCodeId: number, componentConfig: ComponentConfigData) =>
    componentConfig.id === `${emisCodeId}`;

const getConfig = (emisCodeId: number, componentConfigs: ComponentConfigData[]) =>
    componentConfigs.find(c => isConfigMatch(emisCodeId, c));

export const getEquivalents = (emisCodeId: number, componentConfigs: ComponentConfigData[]) => {
    const compound = getConfig(emisCodeId, componentConfigs)?.attributes?.qualifiers?.compound;
    let equivalents: number[] = [emisCodeId];
    if (compound) {
        equivalents = equivalents.concat(compound[0].equivalents ?? []);
        compound[0].components?.forEach(c => {
            equivalents = equivalents.concat(c.equivalents ?? []);
        });
    }
    return equivalents.filter(onlyUnique);
};

export const getHistoryItems = (t, results: { key: string; Data?: any[] }[]): HistoryItems => {
    return results.reduce((historyResults, result) => {
        const extractedItems = result.Data
            ? result.Data.map(historyItem => ({
                  date: historyItem.Date && getDateDescriptionFromYMD(t, historyItem.Date),
                  term: historyItem.Term,
                  value: historyItem.ValueAndUnits,
                  associatedText: historyItem.Narrative,
                  qualifiers: historyItem.Qualifiers?.map(q => ({ key: q.Key, value: q.Value })),
              }))
            : [];
        const deferredItemCount = extractedItems.filter(item => !item).length;
        return deferredItemCount ? historyResults : { ...historyResults, [result.key]: extractedItems };
    }, {});
};

export const getHistoryKey = (_: string, type: string, codeIds?: number | number[]): string | undefined => {
    switch (type) {
        case ComponentType.DIARY_ENTRY:
            return `history-${codeIds}-diary`;
        case ComponentType.CODED:
            return `history-${codeIds}-clinical`;
        case ComponentType.CODED_PICKING_LIST:
            return `history-${(codeIds as number[]).join("-")}-clinical`;
        case ComponentType.CALCULATOR:
            return codeIds ? `history-${codeIds}-calculator` : undefined;
        default:
            return undefined;
    }
};

const getMissingConfigs = (requiredCodes, availableConfigs) =>
    requiredCodes
        .filter(code => !availableConfigs.some(config => isConfigMatch(code.emisCodeId, config)))
        .map(c => c.emisCodeId)
        .filter(onlyUnique);

export const getHistoryRequests = (item, componentConfigs: ComponentConfigData[]): HistoryRequest[] => {
    let historyKey: string | undefined;
    let emisCodeIds: number[] = [];
    let requiredConfigs: ComponentConfigData[];
    let requiredConfig: ComponentConfigData | undefined;
    const pickingList = item as CodedPickingListComponent;

    switch (item.type) {
        case ComponentType.CODED_PICKING_LIST:
            requiredConfigs = componentConfigs
                .filter(config => pickingList.codes.some(code => isConfigMatch(code.emisCodeId, config)))
                .filter(onlyUnique);
            emisCodeIds = pickingList.codes.map(c => c.emisCodeId).filter(onlyUnique);
            if (requiredConfigs.length !== emisCodeIds.length) {
                console.warn(
                    "Could not get history without corresponding component configs",
                    getMissingConfigs(pickingList.codes, componentConfigs),
                );
                return [];
            }
            historyKey = getHistoryKey(item.id, item.type, emisCodeIds);
            emisCodeIds = emisCodeIds.flatMap(emisCodeId => getEquivalents(emisCodeId, requiredConfigs));
            break;
        case ComponentType.DIARY_ENTRY:
            historyKey = getHistoryKey(item.id, item.type, item.code.emisCodeId);
            emisCodeIds = [item.code.emisCodeId];
            break;
        case ComponentType.CODED:
        case ComponentType.CALCULATOR:
            if (!item.code) return [];
            requiredConfig = getConfig(item.code.emisCodeId, componentConfigs);
            if (typeof requiredConfig === "undefined") {
                console.warn(`Could not get history without component config for ${item.code.emisCodeId}`);
                return [];
            }
            historyKey = getHistoryKey(item.id, item.type, item.code.emisCodeId);
            emisCodeIds = getEquivalents(item.code.emisCodeId, [requiredConfig]);
            break;
        default:
            return [];
    }
    return [
        {
            key: historyKey ?? "",
            componentId: item.id,
            componentType: item.type,
            emisCodeIds,
        },
    ];
};
