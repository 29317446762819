import * as React from "react";
import { ComponentContainer, PatientDataContext, RunningTemplateContext } from "@emisgroup/clint-templates-common";
import { getQueriesForComponentType } from "../constants";
import { executeQueryResources, QueryResults } from "../queries/executeQueries";

type PatientDataProviderProps = {
    children: React.ReactNode;
    queryApiUrl: string;
    getBearerToken: () => Promise<string>;
    patientId?: string;
    templateDefinition: ComponentContainer;
};

const PatientDataProvider = ({
    children,
    queryApiUrl,
    getBearerToken,
    patientId = "",
    templateDefinition,
}: PatientDataProviderProps) => {
    const { initialiseTemplateData } = React.useContext(RunningTemplateContext);
    const { members: templateItems } = templateDefinition;
    const [isInitialising, setIsInitialising] = React.useState(true);
    const [queryResults, setQueryResults] = React.useState<QueryResults>({});

    const initialiseFromQueries = async (templatePatientId: string) => {
        const requiredQueryResourceIds = templateItems.reduce((queries, templateItem) => {
            const getQueries = getQueriesForComponentType[templateItem.type] || (() => []);
            return queries.concat(getQueries(templateItem));
        }, []);
        setIsInitialising(true);
        setQueryResults({});
        try {
            const results =
                requiredQueryResourceIds.length > 0
                    ? await executeQueryResources(
                          queryApiUrl,
                          await getBearerToken(),
                          requiredQueryResourceIds,
                          templatePatientId,
                      )
                    : {};
            setQueryResults(results);
            initialiseTemplateData(results);
        } finally {
            setIsInitialising(false);
        }
    };

    const initialiseTemplateRun = async () => {
        if (patientId) {
            await initialiseFromQueries(patientId);
        }
    };

    React.useEffect(() => {
        initialiseTemplateRun();
    }, [patientId]);

    return (
        <PatientDataContext.Provider
            value={{
                patientId,
                isWaitingForPatientSelection: patientId === "",
                isInitialising,
                queryResults,
            }}
        >
            {children}
        </PatientDataContext.Provider>
    );
};

export default PatientDataProvider;
