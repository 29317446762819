import * as React from "react";
import { TemplateContext } from "@emisgroup/clint-templates-common";
import { getCanvasItemIdsActedOnBy } from "../utils/ruleUtils";
import VisibilityRuleIndicator from "./visibilityRuleIndicator";

import "./propertyInspectorIndicators.css";

type PropertyInspectorIndicatorsProps = { componentId: string };
export default function PropertyInspectorIndicators({ componentId }: PropertyInspectorIndicatorsProps) {
    const { templateDefinition } = React.useContext(TemplateContext);

    const showVisibilityIndicator = React.useMemo(
        () => Boolean(templateDefinition.members && getCanvasItemIdsActedOnBy(componentId, templateDefinition).length),
        [componentId],
    );

    return (
        <span className="indicator-container">
            {showVisibilityIndicator && <VisibilityRuleIndicator componentId={componentId} />}
        </span>
    );
}
