import React from "react";
import { ComponentType, TemplateContext } from "@emisgroup/clint-templates-common";
import { ContentLibraryStore } from "../types";
import { addToLocalStorage } from "../utils/localStorageUtils";
import { TEMPLATE_CONTENT_LIBRARY_KEY, TEMPLATE_ID } from "../constants";
import { migrateToNewRulesAndContainers, migrateToLatestFormat } from "../../../migration/migrateTemplateDefinition";

type ContentLibraryLoad = ContentLibraryStore & { template: any };

type ContentLibraryContextType = {
    contentLibrary: ContentLibraryStore;
    setContentLibrary: (contentLibrary: ContentLibraryStore) => void;
    loadFromContentLibrary: (contentLibraryLoad: ContentLibraryLoad) => void;
};

export const ContentLibraryContext = React.createContext<ContentLibraryContextType>({
    contentLibrary: {} as ContentLibraryStore,
    setContentLibrary: () => {},
    loadFromContentLibrary: () => {},
});

type ContentLibraryProviderProps = {
    children: React.ReactNode;
    initialContentLibrary?: ContentLibraryStore;
};

export const ContentLibraryProvider = ({
    children,
    initialContentLibrary = {} as ContentLibraryStore,
}: ContentLibraryProviderProps) => {
    const { setTemplateDefinition } = React.useContext(TemplateContext);

    const [contentLibrary, setContentLibrary] = React.useState(initialContentLibrary);
    function setContentLibraryAndPersist(updatedContentLibrary: ContentLibraryStore) {
        addToLocalStorage(TEMPLATE_CONTENT_LIBRARY_KEY, {
            ...updatedContentLibrary,
            localStorageDate: new Date(Date.now()),
        });
        setContentLibrary(updatedContentLibrary);
    }

    function loadFromContentLibrary(contentLibraryLoad: ContentLibraryLoad) {
        setContentLibraryAndPersist(contentLibraryLoad);
        const { template: libraryDefinition, name } = contentLibraryLoad;
        const label = libraryDefinition.label || name;
        let newDefinition;
        if (libraryDefinition.items && libraryDefinition.rules) {
            newDefinition = migrateToNewRulesAndContainers({ ...libraryDefinition, label });
        } else if (libraryDefinition.items) {
            newDefinition = {
                id: TEMPLATE_ID,
                type: ComponentType.TEMPLATE,
                label,
                members: migrateToLatestFormat(libraryDefinition.items),
                columnCount: libraryDefinition.columnCount || libraryDefinition.numberOfColumns,
            };
        } else {
            newDefinition = {
                ...libraryDefinition,
                label,
                members: migrateToLatestFormat(libraryDefinition.members),
            };
        }
        setTemplateDefinition(newDefinition, true, true);
    }

    return (
        <ContentLibraryContext.Provider
            value={{
                contentLibrary,
                setContentLibrary: setContentLibraryAndPersist,
                loadFromContentLibrary,
            }}
        >
            {children}
        </ContentLibraryContext.Provider>
    );
};
