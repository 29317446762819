import { getRequiredQueries } from "@emisgroup/clint-content";
import { ComponentType } from "@emisgroup/clint-templates-common";

export const KEYCODES = {
    ENTER: 13,
    ESC: 27,
    TAB: 9,
    ARROW_UP: 38,
    ARROW_DOWN: 40,
};

type AllowedActorListForComponentType = {
    [componentType in ComponentType]: Array<ComponentType>;
};

const dataEntryComponents = [
    ComponentType.CODED,
    ComponentType.UNCODED,
    ComponentType.NUMERIC_VALUE,
    ComponentType.FREE_TEXT,
    ComponentType.PICKING_LIST,
    ComponentType.DIARY_ENTRY,
    ComponentType.CODED_PICKING_LIST,
];
export const actorsForComponentType: AllowedActorListForComponentType = {
    [ComponentType.PANEL]: dataEntryComponents,
    [ComponentType.UNCODED]: dataEntryComponents,
    [ComponentType.BRANDING]: dataEntryComponents,
    [ComponentType.CLINICAL_CONTENT]: dataEntryComponents,
    [ComponentType.CODED]: dataEntryComponents,
    [ComponentType.CLINICAL_CONTENT_ENTITY]: dataEntryComponents,
    [ComponentType.CONTAINER]: [...dataEntryComponents, ComponentType.PANEL, ComponentType.CLINICAL_CONTENT],
    [ComponentType.TEMPLATE]: [
        ...dataEntryComponents,
        ComponentType.CONTAINER,
        ComponentType.CLINICAL_CONTENT,
        ComponentType.TAB_CONTAINER,
    ],
    [ComponentType.TAB_CONTAINER]: [...dataEntryComponents, ComponentType.CONTAINER, ComponentType.CLINICAL_CONTENT],
    [ComponentType.TAB_PAGE]: [...dataEntryComponents, ComponentType.CONTAINER, ComponentType.CLINICAL_CONTENT],
    [ComponentType.SAVED_CONTENT]: [],
    [ComponentType.NUMERIC_VALUE]: dataEntryComponents,
    [ComponentType.FREE_TEXT]: dataEntryComponents,
    [ComponentType.PICKING_LIST]: dataEntryComponents,
    [ComponentType.DIARY_ENTRY]: dataEntryComponents,
    [ComponentType.CODED_PICKING_LIST]: dataEntryComponents,
    [ComponentType.CALCULATOR]: dataEntryComponents,
};

export const TEMPLATE_ITEMS_KEY = "TEMPLATE_ITEMS";

export const TEMPLATE_DEFINITION_KEY = "TEMPLATE_DEFINITION";

export const TEMPLATE_COLUMNS_KEY = "TEMPLATE_COLUMNS";

export const TEMPLATE_CONTENT_LIBRARY_KEY = "TEMPLATE_CONTENT_LIBRARY";

export const TEMPLATE_HISTORY_KEY_DEPRECATED = "TEMPLATE_DEFINITION_HISTORY";

export const TEMPLATE_CHANGE_HISTORY_KEY = "TEMPLATE_CHANGE_HISTORY";

export const TEMPLATE_CHANGE_BASE_DEFINITION_KEY = "TEMPLATE_CHANGE_BASE_DEFINITION";

export const TEMPLATE_HISTORY_INDEX_KEY = "TEMPLATE_DEFINITION_HISTORY_INDEX";

export const MAX_TEMPLATE_HISTORY_ENTRIES = 20;

export const MAX_STORAGE_SIZE_BYTES = 4000000;

export const TEMPLATE_ORIGINAL_DEFINITION_KEY = "TEMPLATE_ORIGINAL_DEFINITION";

export const TEMPLATE_BUILDER_APP_MODE_KEY = "TEMPLATE_BUILDER_APP_MODE";

export const TEMPLATE_ID = "template";

const getQueriesForAssociatedText = associatedText => (associatedText ? getRequiredQueries(associatedText) : []);

export const getQueriesForComponentType = {
    [ComponentType.CLINICAL_CONTENT]: ({ content }) => getRequiredQueries(content),
    [ComponentType.PANEL]: panel =>
        [].concat(
            ...panel.members
                .map(member => (getQueriesForComponentType[member.type] || (() => []))(member))
                .filter(Boolean),
        ),
    [ComponentType.UNCODED]: component => getQueriesForAssociatedText(component.associatedText),
};

export const NOTIFICATION_TIMEOUT_MS = 5000;

export const DEFAULT_TEMPLATE_LABEL = "templates.unsavedTemplate";

export const UNSAVED_CHANGES = "save.unsavedChanges";

export const NEW_TEMPLATE_DEFINITION = {
    id: TEMPLATE_ID,
    type: ComponentType.TEMPLATE,
    label: DEFAULT_TEMPLATE_LABEL,
    members: [],
    columnCount: 1,
};

export const RULES_ENGINE_EQUALS_OPERATOR = "equal";

export const LIST_ITEM_DRAG_TYPE = "LIST_ITEM";
export const PANEL_ACTION_ITEM_DRAG_TYPE = "PANEL_ACTION";
