import React from "react";
import { useDrop } from "react-dnd";
import { $enum } from "ts-enum-util";
import {
    TabPageContent,
    CanvasItemClickHandler,
    TabPage,
    ComponentType,
    CanvasItem,
} from "@emisgroup/clint-templates-common";
// eslint-disable-next-line import/no-cycle
import DesignerCanvasColumns from "../uiComponents/designerCanvasColumns";
import useCreateItem from "../uiComponents/useCreateItem";
import { ParametersForPropertyUpdate } from "../types";
import { canDropItemInto, getContainerDropInIndicatorHeight } from "../utils/dragDropUtils";
import useMoveItem from "../uiComponents/useMoveItem";

type SelectedTabPageContentProps = {
    tabPage: TabPage;
    dragDropEnabled: boolean;
    onSelect: CanvasItemClickHandler;
    onPropertyUpdate: (params: ParametersForPropertyUpdate) => void;
};

const SelectedTabPageContent = ({
    tabPage,
    dragDropEnabled,
    onSelect,
    onPropertyUpdate,
}: SelectedTabPageContentProps) => {
    const tabPageContentRef = React.useRef<HTMLDivElement>(null);
    const dropIndicatorRef = React.useRef<HTMLDivElement>(null);
    const { newItemProperties, handleItemAddition } = useCreateItem(tabPage);
    const { moveItem, moveItemDialog } = useMoveItem(tabPage);

    const [{ canDrop }, drop] = useDrop({
        accept: $enum(ComponentType).getValues(),
        canDrop(item: CanvasItem, monitor) {
            return monitor.isOver({ shallow: true }) && canDropItemInto(item, tabPage);
        },
        collect: monitor => ({
            canDrop: monitor.canDrop(),
        }),
        drop(item: CanvasItem) {
            if (item.id === "") {
                handleItemAddition(item, 0, 0);
            } else {
                moveItem(item, 0, 0);
            }
        },
    });

    React.useEffect(() => {
        if (dragDropEnabled && dropIndicatorRef.current) {
            const dropIndicator = dropIndicatorRef.current as HTMLElement;
            dropIndicator.style.height = getContainerDropInIndicatorHeight(canDrop, tabPage.members.length === 0);
        }
    }, [canDrop, tabPage.members.length]);

    if (dragDropEnabled) {
        drop(tabPageContentRef);
    }

    return (
        <>
            <div ref={tabPageContentRef} data-testid={`${tabPage.id}-content`}>
                {dragDropEnabled && (
                    <div
                        ref={dropIndicatorRef}
                        className="drop-indicator"
                        data-testid={`${tabPage.id}-drop-into-indicator`}
                    />
                )}
                <TabPageContent tabPage={tabPage} onSelect={onSelect}>
                    <DesignerCanvasColumns
                        component={tabPage}
                        dragDropEnabled={dragDropEnabled}
                        onItemPropertyUpdate={onPropertyUpdate}
                        onItemSelect={onSelect}
                        handleItemAddition={handleItemAddition}
                    />
                </TabPageContent>
            </div>
            {newItemProperties && newItemProperties()}
            {moveItemDialog}
        </>
    );
};

export default SelectedTabPageContent;
