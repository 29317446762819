import { AllConditions, ConditionProperties, Rule as JsonEngineRule } from "json-rules-engine";
import {
    CanvasItem,
    ComponentCondition,
    ComponentType,
    ConditionOperator,
    ConditionsGroup,
    ConditionSource,
    ComponentContainer,
    Rule,
    isContainerType,
    PickingListComponent,
    PickingListOption,
    findCanvasItem,
} from "@emisgroup/clint-templates-common";
import { v4 as uuid } from "uuid";
import { TEMPLATE_ID } from "../components/Home/constants";

function migrateRule(jsonEngineRule: JsonEngineRule): Rule | null {
    if (jsonEngineRule == null || typeof jsonEngineRule === "undefined") {
        return null;
    }

    const conditionMembers: ComponentCondition[] = (jsonEngineRule.conditions as AllConditions).all
        .map(condition => {
            const conditionProperties = condition as ConditionProperties;
            if (conditionProperties.params == null) return null;

            return {
                conditionSource: ConditionSource.COMPONENT_SELECTED,
                actorCanvasItemId: conditionProperties.params.componentId,
                selected: conditionProperties.value,
            } as ComponentCondition;
        })
        .filter(Boolean) as ComponentCondition[];

    return conditionMembers.length > 0
        ? ({
              conditionOperator: ConditionOperator.ALL,
              conditionMembers,
          } as ConditionsGroup)
        : null;
}

function getNestedRuleAffectingComponent(componentId, templateItems, rules) {
    let ruleAffectingComponent = rules.find(rule => rule.event.params.componentId === componentId);
    if (ruleAffectingComponent) {
        return ruleAffectingComponent;
    }
    for (let index = 0, len = templateItems.length; index < len && !ruleAffectingComponent; index += 1) {
        const component = templateItems[index];
        if (component.type === "panel") {
            const panelRules = component.rules;
            ruleAffectingComponent = panelRules.find(rule => rule.event.params.componentId === componentId);
            if (ruleAffectingComponent) {
                break;
            }
            ruleAffectingComponent = getNestedRuleAffectingComponent(componentId, component.members, panelRules);
        }
    }
    return ruleAffectingComponent;
}

function getRuleAffectingComponent(componentId, templateItems, rules): Rule | null {
    const legacyRule = getNestedRuleAffectingComponent(componentId, templateItems, rules);

    return legacyRule !== null ? migrateRule(legacyRule) : null;
}

export function migrateItemsWithRules(items, topLevelRules, topLevelItems): CanvasItem[] {
    return items.map(item => {
        if (item.type === "panel") {
            const { members, rules: panelRules } = item;
            const updatedMembers = migrateItemsWithRules(members, panelRules, topLevelItems);
            // delete item.rules;
            const newItem = {
                ...item,
                members: updatedMembers,
                rule: getRuleAffectingComponent(item.id, topLevelItems, topLevelRules) || null,
            };
            delete newItem.rules;
            return newItem;
        }
        return {
            ...item,
            rule: getRuleAffectingComponent(item.id, topLevelItems, topLevelRules) || null,
        };
    });
}

export function migrateToNewRulesAndContainers({ items, rules, numberOfColumns, label }) {
    return {
        id: TEMPLATE_ID,
        type: ComponentType.TEMPLATE,
        label,
        members: migrateItemsWithRules(items, rules, items),
        columnCount: numberOfColumns,
    } as ComponentContainer;
}

function migrateToNewConditions(canvasItems: any[]): CanvasItem[] {
    return canvasItems.map(canvasItem => {
        if (canvasItem.type === ComponentType.PANEL) {
            return {
                ...canvasItem,
                members: migrateToNewConditions(canvasItem.members),
                rule: canvasItem.rule && canvasItem.rule.conditions ? migrateRule(canvasItem.rule) : canvasItem.rule,
            };
        }

        return {
            ...canvasItem,
            rule: canvasItem.rule && canvasItem.rule.conditions ? migrateRule(canvasItem.rule) : canvasItem.rule,
        };
    });
}

function migratePickingListActors(canvasItems: any[]): CanvasItem[] {
    return canvasItems.map(canvasItem => {
        if (canvasItem.rule === null) {
            return canvasItem;
        }

        const updatedRule = {
            ...canvasItem.rule,
            conditionMembers: canvasItem.rule.conditionMembers.map(member => {
                const actor = findCanvasItem(member.actorCanvasItemId, canvasItems);
                if (!actor || actor.type !== ComponentType.PICKING_LIST) {
                    return member;
                }

                const pickingList = actor as PickingListComponent;
                const memberOption = pickingList.options.find(option => option.text === member.value);
                return { ...member, value: memberOption?.id || member.value };
            }),
        };

        return { ...canvasItem, rule: updatedRule };
    });
}

function migratePickingList(pickingList: any): PickingListComponent {
    return {
        ...pickingList,
        options: (pickingList.options || []).map(option => {
            if (typeof option === "string") {
                return { id: uuid(), text: option };
            }

            if (!(option as PickingListOption).id) {
                return { ...option, id: uuid() };
            }

            return option;
        }),
    };
}

function migrateComponent(canvasItem: any): CanvasItem {
    if (canvasItem.type === ComponentType.PICKING_LIST) {
        return migratePickingList(canvasItem);
    }

    return canvasItem;
}

function migrateToNewComponentFormat(canvasItems: any[]): CanvasItem[] {
    return canvasItems.map(canvasItem => {
        if (isContainerType(canvasItem.type)) {
            return {
                ...canvasItem,
                members: migrateToNewComponentFormat(canvasItem.members),
            };
        }

        return migrateComponent(canvasItem);
    });
}

export function migrateToLatestFormat(canvasItems: any[]): CanvasItem[] {
    return migratePickingListActors(migrateToNewComponentFormat(migrateToNewConditions(canvasItems)));
}
