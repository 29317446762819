import { CanvasItem, ComponentContainer } from "../types";
import { findCanvasItemParent } from "./componentUtils";

export const isDescendantOfEmbeddedItem = (item: CanvasItem, templateItems: CanvasItem[]): boolean => {
    let parent = findCanvasItemParent(item.id, templateItems);

    while (parent) {
        if (parent.ern) {
            return true;
        }
        parent = findCanvasItemParent(parent.id, templateItems);
    }

    return false;
};

export const isEmbeddedItem = (item: CanvasItem): boolean => Boolean((item as ComponentContainer).ern);
