import * as React from "react";
import { useTranslation } from "@emisgroup/application-intl";
import AppModeContext from "../context/appMode";
import { AppMode, DataFromTemplateRun, DataEntryComponentProps } from "../types";
import MultilineTextEntry from "./multilineTextEntry";
import { RunningTemplateContext } from "../context";
import DataEntryComponent from "./dataEntryComponent";
import ConfigContext from "../context/config";

type FreeTextProps = DataEntryComponentProps;
const FreeText = (props: FreeTextProps) => {
    const { t } = useTranslation();
    const { label, id, onChange, isMandatory = false } = props;
    const { mode } = React.useContext(AppModeContext);
    const { templateData = {} } = React.useContext(RunningTemplateContext);
    const { freeTextMaxChars } = React.useContext(ConfigContext);
    const isInRunMode = mode === AppMode.RUN;
    const componentId = `component-${id}`;
    const hasInteracted = templateData[id]?.interacted;

    const handleBlur = (text: string) => {
        onChange({ [id]: { value: text, interacted: true } } as DataFromTemplateRun);
    };

    const value = templateData[id] ? (templateData[id].value as string) : "";
    const [maxCharsExceeded, setMaxCharsExceeded] = React.useState(
        isInRunMode && value && value.length > freeTextMaxChars,
    );
    const isMandatoryValueRequired = isInRunMode && hasInteracted && isMandatory && !value;
    const areValuesValid = !maxCharsExceeded && !isMandatoryValueRequired;

    const handleChange = (text: string) => {
        if (text.length) {
            setMaxCharsExceeded(text.length > freeTextMaxChars);
        }
        onChange({ [id]: { value: text, interacted: true } } as DataFromTemplateRun);
    };

    const component = (
        <div className="canvas-item-entry">
            <MultilineTextEntry
                id={componentId}
                text={isInRunMode ? value : " "}
                ariaLabel={label}
                onBlur={handleBlur}
                onChange={handleChange}
                disabled={!isInRunMode}
                templateData={templateData[id]}
                runtimeValidator={props.runtimeValidator}
            />
            {maxCharsExceeded && (
                <div className="eui-form-element__error">
                    {t("components.maximumCharactersWarning", { maxLength: freeTextMaxChars })}
                </div>
            )}
            {isMandatoryValueRequired && (
                <div className="eui-form-element__error">{t("components.freeText.enterSomeText")}</div>
            )}
        </div>
    );

    return (
        <DataEntryComponent {...props} areValuesValid={areValuesValid}>
            {component}
        </DataEntryComponent>
    );
};

export default FreeText;
