import React, { ReactNode } from "react";
import { QueryResultData } from "@emisgroup/clint-content/lib/types";
import {
    CanvasItem,
    ComponentType,
    ComponentContainer,
    TemplateData,
    isCanvasItemAContainer,
    RunningTemplateContext,
} from "@emisgroup/clint-templates-common";
import { ErrorHandling, ErrorState } from "@emisgroup/clinical-code-entry/lib/types";
import { addToLocalStorage } from "../utils/localStorageUtils";
import { getInitialisedItem } from "../utils/componentUtils";
import { TEMPLATE_RUN_DATE_KEY } from "../constants";
import { TemplateFilingItem } from "../types";
import dataToFile from "../dataToFile";

type RunningTemplateProviderProps = {
    children: ReactNode | React.ReactNodeArray;
    templateDefinition: ComponentContainer;
    initialData?: TemplateData;
    initialRunDate?: Date;
    onChange?: (filingItems: TemplateFilingItem[]) => void;
    onHandleError?: (errorState: ErrorState) => ErrorHandling;
};

const RunningTemplateProvider = ({
    children,
    templateDefinition,
    initialData = {},
    initialRunDate = new Date(),
    onChange,
    onHandleError,
}: RunningTemplateProviderProps) => {
    const [templateData, setTemplateData] = React.useState<TemplateData>(initialData);
    const setTemplateDataAndPersist = data => {
        setTemplateData(data);
        if (onChange) {
            onChange(dataToFile(data, templateDefinition));
        }
    };

    const [runDate, setRunDate] = React.useState(initialRunDate);
    function setRunDateAndPersist(date: Date) {
        addToLocalStorage(TEMPLATE_RUN_DATE_KEY, runDate);
        setRunDate(date);
    }

    function initialiseTemplateData(queryResults: QueryResultData) {
        const reducer = (initialisedData: TemplateData, member: CanvasItem) => {
            if (member.type === ComponentType.CLINICAL_CONTENT) {
                return initialisedData;
            }

            if (isCanvasItemAContainer(member)) {
                return (member as ComponentContainer).members.reduce(reducer, initialisedData);
            }

            return {
                ...initialisedData,
                [member.id]: {
                    ...getInitialisedItem(member, queryResults),
                },
            };
        };

        setTemplateDataAndPersist(
            Object.keys(initialData).length ? initialData : templateDefinition.members.reduce(reducer, {}),
        );
        setRunDateAndPersist(new Date());
    }

    return (
        <RunningTemplateContext.Provider
            value={{
                templateData,
                updateTemplateData: setTemplateDataAndPersist,
                initialiseTemplateData,
                onHandleError,
                runDate,
            }}
        >
            {children}
        </RunningTemplateContext.Provider>
    );
};

export default RunningTemplateProvider;
