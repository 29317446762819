import * as React from "react";
import { ContentPicker } from "@emisgroup/content-picker";
import { ContentLibraryItem } from "@emisgroup/content-picker/lib/types";
import { AuthContext, ConfigContext } from "@emisgroup/clint-templates-common";
import { TemplatePickerContext } from "../context/templatePicker";
import { definitionAsObject, TEMPLATE_RESOURCE_TYPE } from "./utils";
import TimedNotification from "../uiComponents/timedNotification";
import { NOTIFICATION_TIMEOUT_MS } from "../constants";
import { getCurrentStatus } from "./loadDefinition";

type TemplatePickerProps = { showInDialog?: boolean };
const TemplatePicker = ({ showInDialog }: TemplatePickerProps) => {
    const { getBearerToken } = React.useContext(AuthContext);
    const { onTemplateSelected, hideTemplatePicker } = React.useContext(TemplatePickerContext);
    const { contentLibraryUrl } = React.useContext(ConfigContext);

    const [openTemplateError, setOpenTemplateError] = React.useState("");

    const handleError = message => setOpenTemplateError(message);

    const handleLoaded = async (contentLibraryItem: ContentLibraryItem) => {
        const { libraryItem, ...contentLibraryStore } = contentLibraryItem;
        const templateDefinition = definitionAsObject(libraryItem);
        if (templateDefinition) {
            onTemplateSelected(
                { ...contentLibraryStore, contentStatus: getCurrentStatus(contentLibraryStore.statusFlags) },
                { ...templateDefinition, libraryName: contentLibraryStore.name },
            );
        }
    };

    return (
        <>
            <ContentPicker
                resourceTypes={[TEMPLATE_RESOURCE_TYPE]}
                contentLibraryUrl={contentLibraryUrl}
                getBearerToken={getBearerToken}
                onClose={hideTemplatePicker}
                onError={handleError}
                onLoaded={handleLoaded}
                showInDialog={showInDialog}
            />
            {openTemplateError && (
                <TimedNotification
                    type="info"
                    text={`There was an error loading the template: ${openTemplateError}`}
                    milliseconds={NOTIFICATION_TIMEOUT_MS}
                    onTimeout={() => setOpenTemplateError("")}
                />
            )}
        </>
    );
};

export default TemplatePicker;
