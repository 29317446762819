import { NodeIcons, TreeNodeData } from "./types";

export const getDivClassName = (node: TreeNodeData, selectedId: string): string => {
    let divClassNames = "eui-tree__content";
    if (node.className) {
        divClassNames = divClassNames.concat(" ", node.className);
    }
    if (selectedId === node.nodeId) {
        divClassNames = divClassNames.concat(" eui-tree__content--selected");
    }
    if (node.disabled) {
        divClassNames = divClassNames.concat(" eui-tree__content--disabled");
    }
    return divClassNames;
};

export const getUlClassName = (showChildren: boolean): string => {
    return `eui-tree ${showChildren ? "eui-tree__node--show" : "eui-tree__node--hide"}`;
};

export const getExpandableNodeIcon = (icons: NodeIcons, expanded: boolean): JSX.Element => {
    return expanded ? icons.expandedNodeIconElement : icons.collapsedNodeIconElement;
};

export const isAncestorOf = (ancestorNode: TreeNodeData, descendantNodeId: string): boolean => {
    return ancestorNode.nodeId === descendantNodeId
        ? true
        : Boolean(ancestorNode.children?.some(childNode => isAncestorOf(childNode, descendantNodeId)));
};

export const shouldScroll = (node: TreeNodeData, showNodeId: string | undefined, scrollIntoView?: () => void) => {
    return Boolean(node.nodeId === showNodeId && scrollIntoView);
};
