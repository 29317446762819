import * as React from "react";
import { CanvasItem } from "../types";

export type HistoryQualifier = {
    key: string;
    value: string;
};

export type HistoryItem = {
    date?: string;
    term?: string;
    value?: string;
    qualifiers?: HistoryQualifier[];
    associatedText?: string;
};

export type HistoryRequest = {
    key: string;
    emisCodeIds?: number[];
    componentType: string;
    componentId: string;
};

export type HistoryItems = { [key: string]: HistoryItem[] };

type TemplateHistoryContextType = {
    history: HistoryItems;
    historyWithErrors: HistoryRequest[];
    areConfidentialItemsRemoved: boolean;
    updateHistory: (items: CanvasItem[]) => void;
    retryHistory: () => void;
    isHistoryRetry: boolean;
    getHistoryKey: (id: string, type: string, codeId?: number | number[]) => string | undefined;
};

export const TemplateHistoryContext = React.createContext<TemplateHistoryContextType>({
    history: {},
    historyWithErrors: [],
    areConfidentialItemsRemoved: false,
    updateHistory: () => ({}),
    retryHistory: () => ({}),
    isHistoryRetry: false,
    getHistoryKey: () => "",
});
