import React, { ReactNode } from "react";
import { ComponentContainer } from "@emisgroup/clint-templates-common";
import { ContentLibraryStore } from "../types";

type OnSelectedHandler = (contentLibraryStore: ContentLibraryStore, definition: ComponentContainer) => void;

type TemplatePickerContextType = {
    isTemplatePickerShowing: boolean;
    isTemplatePickerShowingInDialog: boolean;
    onTemplateSelected: OnSelectedHandler;
    showTemplatePicker: (onTemplateSelected: OnSelectedHandler) => void;
    showTemplatePickerInDialog: (onTemplateSelected: OnSelectedHandler) => void;
    hideTemplatePicker: () => void;
};

export const TemplatePickerContext = React.createContext<TemplatePickerContextType>({
    isTemplatePickerShowing: false,
    isTemplatePickerShowingInDialog: false,
    onTemplateSelected: () => {},
    showTemplatePicker: () => {},
    showTemplatePickerInDialog: () => {},
    hideTemplatePicker: () => {},
});

type TemplatePickerProviderProps = {
    children: ReactNode;
    initialIsShowing?: boolean;
    initialOnSelected?: OnSelectedHandler;
};

export const TemplatePickerProvider = ({
    children,
    initialIsShowing = false,
    initialOnSelected = () => {},
}: TemplatePickerProviderProps) => {
    const [isTemplatePickerShowing, setIsTemplatePickerShowing] = React.useState(initialIsShowing);
    const [isTemplatePickerShowingInDialog, setIsTemplatePickerShowingInDialog] = React.useState(false);
    const onTemplateSelected = React.useRef<OnSelectedHandler>(initialOnSelected);

    const showTemplatePicker = (selectedAction: OnSelectedHandler) => {
        onTemplateSelected.current = selectedAction;
        setIsTemplatePickerShowing(true);
        setIsTemplatePickerShowingInDialog(false);
    };

    const showTemplatePickerInDialog = (selectedAction: OnSelectedHandler) => {
        onTemplateSelected.current = selectedAction;
        setIsTemplatePickerShowing(false);
        setIsTemplatePickerShowingInDialog(true);
    };

    const hideTemplatePicker = () => {
        setIsTemplatePickerShowing(false);
        setIsTemplatePickerShowingInDialog(false);
        onTemplateSelected.current = () => {};
    };

    return (
        <TemplatePickerContext.Provider
            value={{
                isTemplatePickerShowing,
                isTemplatePickerShowingInDialog,
                onTemplateSelected: onTemplateSelected.current,
                showTemplatePicker,
                showTemplatePickerInDialog,
                hideTemplatePicker,
            }}
        >
            {children}
        </TemplatePickerContext.Provider>
    );
};
