import { Container } from "@emisgroup/clint-templates-common";
import CanvasColumns from "../canvasColumns";
import { RunningContainerProps } from "../types";

export default function ClinicalContainer({
    className,
    container,
    columnCount,
    hasCustomActionButtons,
}: RunningContainerProps) {
    return (
        <div className={`canvas-body canvas-body-run-mode template-columns-${columnCount} ${className || ""}`}>
            <Container component={container} className="canvas-container">
                <CanvasColumns component={container} hasCustomActionButtons={hasCustomActionButtons} />
            </Container>
        </div>
    );
}
