import React from "react";
import { DndProvider, createDndContext } from "react-dnd";
import multiBackend from "react-dnd-multi-backend";
import HTML5toTouch from "react-dnd-multi-backend/dist/cjs/HTML5toTouch";

const dndContext = createDndContext(multiBackend, undefined, HTML5toTouch);

const useDndProvider = props => {
    const dndContextRef = React.useRef(dndContext);

    if (!props.children) return null;

    return <DndProvider manager={dndContextRef.current.dragDropManager}>{props.children}</DndProvider>;
};

const DragAndDrop = props => {
    const DndProviderElement = useDndProvider(props);
    return <>{DndProviderElement}</>;
};

export default DragAndDrop;
