import { getRequestBodyForComponents } from "../utils/componentUtils";
import { ConfigForComponents } from "../types";

const buildResponse = (codes: string[], responseData: any) => {
    if (!responseData?.data) return { data: [], error: responseData?.message ?? "error-code:no-code-data" };

    const responseCodes = responseData.data.map(item => item.id);
    const matchedCodes = codes.filter(code => responseCodes.includes(code));

    return codes.length === matchedCodes.length ? responseData : { data: [], error: "error-code:missing-code-data" };
};

export const getConfigDataForCodedComponents = async (
    ckbUrl: string,
    bearerToken: string,
    codes: string[],
    codeSystem = "snomedct",
    region = "england",
): Promise<ConfigForComponents> => {
    const clinicalKBUrl = ckbUrl.replace("<region>", region).replace("<codesystem>", codeSystem);
    const codeDataForRequest = getRequestBodyForComponents(codes);
    try {
        const response = await fetch(clinicalKBUrl, {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/vnd.api+json",
                Authorization: `Bearer ${bearerToken}`,
            }),
            body: JSON.stringify(codeDataForRequest),
        });
        const responseData = await response.json();
        return buildResponse(codes, responseData) as ConfigForComponents;
    } catch {
        return { data: [], error: "error-code:code-data-fetch-failed" };
    }
};
