import * as React from "react";
import { QueryResults } from "../types";

type PatientDataContextType = {
    patientId: string;
    isWaitingForPatientSelection: boolean;
    isInitialising: boolean;
    queryResults: QueryResults;
};

const PatientDataContext = React.createContext<PatientDataContextType>({
    patientId: "",
    isWaitingForPatientSelection: false,
    isInitialising: false,
    queryResults: {},
});

export default PatientDataContext;
