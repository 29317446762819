import * as React from "react";

export const PropertyInspectorContext = React.createContext({ isNewItem: false });

type PropertyInspectorProviderProps = {
    children: React.ReactNode | React.ReactNodeArray;
    isNewItem: boolean;
};

export function PropertyInspectorProvider({ children, isNewItem }: PropertyInspectorProviderProps) {
    return <PropertyInspectorContext.Provider value={{ isNewItem }}>{children}</PropertyInspectorContext.Provider>;
}
