import {
    CanvasItem,
    RunningTemplateContext,
    ActionType,
    ActionData,
    PatientDataContext,
    ComponentContainer,
    findRootLevelAncestor,
} from "@emisgroup/clint-templates-common";
import React, { ReactNode } from "react";
import actionData from "../actionData";
import { setAllInteractedUpToItem, validateItems } from "../utils/validationUtils";
import { ActionsContext } from "./actions";
import { GO_TO_ITEM_EVENT, RENDERED_ITEMS_UPDATE_EVENT } from "../constants";
import { createEngine, evaluateRules } from "../utils/ruleEngineUtils";
import { buildRunTimeTemplateItems } from "../utils/componentUtils";

type ActionsProviderProps = {
    children: ReactNode | React.ReactNodeArray;
    templateDefinition: ComponentContainer;
    queryApiUrl: string;
    getBearerToken: () => Promise<string>;
    showDefaultActionButtons?: boolean;
    onHandleAction?: (actionType: ActionType, actionData: ActionData, dataValid: boolean | null) => void;
};

const ActionsProvider = ({
    children,
    templateDefinition,
    queryApiUrl,
    getBearerToken,
    showDefaultActionButtons,
    onHandleAction,
}: ActionsProviderProps) => {
    const { templateData, updateTemplateData } = React.useContext(RunningTemplateContext);
    const { queryResults } = React.useContext(PatientDataContext);
    const renderedItems = React.useRef<CanvasItem[]>([]);
    const handleItemsUpdate = e => {
        renderedItems.current = e.detail.items;
    };
    const { patientId } = React.useContext(PatientDataContext);

    const engine = createEngine({ templateData, queryApiUrl, patientId, getBearerToken });

    React.useEffect(() => {
        window.addEventListener(RENDERED_ITEMS_UPDATE_EVENT, handleItemsUpdate);
        return () => {
            window.removeEventListener(RENDERED_ITEMS_UPDATE_EVENT, handleItemsUpdate);
        };
    }, []);

    const getIndexOfCurrentItem = (canvasItems: CanvasItem[], currentCanvasItem?: CanvasItem) => {
        if (!currentCanvasItem) {
            return canvasItems.length - 1;
        }

        let indexOfCurrentItem = canvasItems.findIndex(c => c.id === currentCanvasItem.id);

        if (indexOfCurrentItem === -1) {
            const rootLevelAncestor = findRootLevelAncestor(currentCanvasItem.id, canvasItems);
            if (rootLevelAncestor) {
                indexOfCurrentItem = canvasItems.findIndex(c => c.id === rootLevelAncestor.id);
            }
        }

        return indexOfCurrentItem;
    };

    const goToNextComponent = (currentCanvasItem: CanvasItem) => {
        const indexOfCurrentItem = getIndexOfCurrentItem(renderedItems.current, currentCanvasItem);

        const nextItem = renderedItems.current[indexOfCurrentItem + 1];
        if (nextItem) {
            window.dispatchEvent(new CustomEvent(GO_TO_ITEM_EVENT, { detail: { id: nextItem.id } }));
        }
    };

    const onAction = (actionType: ActionType, isValid: boolean | null, currentCanvasItem?: CanvasItem) => {
        if (actionType === ActionType.NEXTSTEP && currentCanvasItem && (isValid || isValid === null)) {
            goToNextComponent(currentCanvasItem);
        }
    };

    const onRaiseActionHandler = (actionType: ActionType, validate: boolean, currentCanvasItem?: CanvasItem) => {
        if (onHandleAction) {
            const updatedTemplateData = setAllInteractedUpToItem(
                templateData,
                renderedItems.current,
                currentCanvasItem || renderedItems.current[renderedItems.current.length - 1],
            );
            updateTemplateData(updatedTemplateData);

            const valid = validate ? validateItems(renderedItems.current, updatedTemplateData) : null;
            onAction(actionType, valid, currentCanvasItem);

            evaluateRules({ engine, container: templateDefinition, templateData }).then(result => {
                if (result.success) {
                    const indexOfCurrentItem = getIndexOfCurrentItem(templateDefinition.members, currentCanvasItem);

                    const templateWithVisitedPanels = {
                        ...templateDefinition,
                        members: templateDefinition.members.slice(0, indexOfCurrentItem + 1),
                    };

                    const templateItems = buildRunTimeTemplateItems({
                        allowedIds: result.itemIds,
                        container: templateWithVisitedPanels,
                    });

                    onHandleAction(
                        actionType,
                        actionData(new Date(), templateItems, updatedTemplateData, queryResults),
                        valid,
                    );
                }
            });
        }
    };

    return (
        <ActionsContext.Provider
            value={{
                showDefaultActionButtons,
                onRaiseAction: onRaiseActionHandler,
                onHandleAction,
            }}
        >
            {children}
        </ActionsContext.Provider>
    );
};

export default ActionsProvider;
