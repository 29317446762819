/* eslint-disable react/style-prop-object */
import * as React from "react";
import { Button } from "@emisgroup/ui-kit-react";
import { useTranslation } from "@emisgroup/application-intl";
import { CalculatorComponent, CanvasItem, ComponentParameter } from "@emisgroup/clint-templates-common";
import { PropertyValueProps } from "./types";
import ComponentParameterSelector from "./componentParameterSelector";
import Parameters from "./parameters";

import "./parameterPropertyEntry.css";

const ParameterPropertyEntry = ({ component, name, isReadOnly, onPropertyUpdate }: PropertyValueProps) => {
    const { t } = useTranslation();
    const [parameters, setParameters] = React.useState<ComponentParameter[]>(component[name] || []);
    const [selectingComponent, setSelectingComponent] = React.useState(false);

    const handleAdd = () => setSelectingComponent(true);

    const handleComponentSelect = (selectedComponent: CanvasItem) => {
        setSelectingComponent(false);
        if (selectedComponent) {
            const defaultLabel = `P${parameters.length + 1}`;
            setParameters([
                ...parameters,
                {
                    componentId: selectedComponent.id,
                    label: parameters.map(({ label }) => label.toUpperCase()).includes(defaultLabel)
                        ? ""
                        : defaultLabel,
                },
            ]);
        }
    };

    const handleBlur = (e: React.FocusEvent) => {
        if (e.currentTarget.contains(e.relatedTarget as Node)) {
            e.stopPropagation();
        } else {
            onPropertyUpdate({ item: component, propertyName: name, propertyValue: parameters });
        }
    };

    const onUpdateAndCommit = (updatedParameters: ComponentParameter[]) => {
        setParameters(updatedParameters);
        onPropertyUpdate({ item: component, propertyName: name, propertyValue: updatedParameters });
    };

    return (
        <div data-testid={`input-${name}`} onBlur={handleBlur}>
            <Parameters
                parameters={parameters}
                isReadOnly={isReadOnly}
                onUpdate={setParameters}
                onUpdateAndCommit={onUpdateAndCommit}
            />
            {!isReadOnly && (
                <Button
                    iconName="add-secondary"
                    iconPosition="left"
                    data-testid="list-item-add-button"
                    onClick={handleAdd}
                    variant="primary"
                >
                    {t("parameterEntry.addParameter")}
                </Button>
            )}
            {selectingComponent && (
                <ComponentParameterSelector
                    component={component as CalculatorComponent}
                    onCancel={() => setSelectingComponent(false)}
                    onSelect={handleComponentSelect}
                />
            )}
        </div>
    );
};

export default ParameterPropertyEntry;
