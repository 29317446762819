/* eslint-disable react/style-prop-object */
import * as React from "react";
import { Button, ButtonGroup, Dialog, DialogContent, DialogFooter } from "@emisgroup/ui-kit-react";
import { InfoError } from "@emisgroup/icons-react";
import { useTranslation } from "@emisgroup/application-intl";
import TreeView from "./treeView/treeView";
import { TreeNodeData } from "./treeView/types";
import { findTreeNode, getTreeNodeSelectionError } from "../utils/componentSelectorUtils";

import "./componentSelectorDialog.scss";

type ComponentSelectorDialogProps = {
    componentsTreeRootNode: TreeNodeData;
    selectedComponentId?: string;
    showComponentId?: string;
    onSelect: (componentId: string) => void;
    onCancel: () => void;
};

const ComponentSelectorDialog = ({
    componentsTreeRootNode,
    selectedComponentId,
    showComponentId,
    onSelect,
    onCancel,
}: ComponentSelectorDialogProps) => {
    const showRootNode = false;
    const initialNodeSelection = selectedComponentId
        ? findTreeNode(selectedComponentId, componentsTreeRootNode, showRootNode)
        : undefined;

    const { t } = useTranslation();
    const [selectionError, setSelectionError] = React.useState<string | undefined>(
        getTreeNodeSelectionError(initialNodeSelection),
    );
    const selectedNode = React.useRef<TreeNodeData | undefined>(initialNodeSelection);

    const handleNodeSelect = (node: TreeNodeData) => {
        selectedNode.current = node;
        setSelectionError(getTreeNodeSelectionError(node));
    };

    const handleNodeDoubleClick = (node: TreeNodeData) => {
        handleNodeSelect(node);
        if (!getTreeNodeSelectionError(node)) {
            onSelect(node.nodeId);
        }
    };

    const handleAccept = () => {
        if (!selectedNode.current) {
            setSelectionError("componentSelection.errors.selectAComponent");
        } else if (!getTreeNodeSelectionError(selectedNode.current)) {
            onSelect(selectedNode.current.nodeId);
        }
    };

    return (
        <Dialog open={true} onClose={onCancel} title={t("componentSelection.componentSelector")} disableDismiss>
            <DialogContent className="dialog-content">
                <div className="tree-container">
                    <TreeView
                        showRootNode={showRootNode}
                        rootNode={componentsTreeRootNode}
                        selectNodeId={selectedComponentId}
                        showNodeId={showComponentId}
                        onNodeSelect={handleNodeSelect}
                        onNodeDoubleClick={handleNodeDoubleClick}
                        emptyWarning={t("componentSelection.templateDoesNotContainComponents")}
                    />
                </div>
            </DialogContent>
            <DialogFooter>
                {selectionError && (
                    <div className="selection-error">
                        <InfoError className="selection-error-icon" color="danger" title="" />
                        <div className="selection-error-message" data-testid="selection-error-message">
                            {t(selectionError)}
                        </div>
                    </div>
                )}
                <ButtonGroup>
                    <Button
                        data-testid="select-component"
                        type="button"
                        variant="primary"
                        onClick={handleAccept}
                        ariaLabel={t("componentSelection.selectComponent")}
                    >
                        {t("componentSelection.selectComponent")}
                    </Button>
                    <Button
                        data-testid="cancel-select-component"
                        type="button"
                        variant="secondary"
                        onClick={onCancel}
                        ariaLabel={t("cancel")}
                    >
                        {t("cancel")}
                    </Button>
                </ButtonGroup>
            </DialogFooter>
        </Dialog>
    );
};

export default ComponentSelectorDialog;
