import { useTranslation } from "@emisgroup/application-intl";
import * as React from "react";
import { getStringPropertyDisplayValue } from "../utils/componentUtils";
import { PropertyValueProps } from "./types";
import usePropertyUpdates from "./usePropertyUpdates";

const useStringInput = (isPropertySelected: boolean, hasDefinedValue: boolean, valueToDisplay: string) => {
    const inputRef = React.useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        if (inputRef.current) {
            inputRef.current.value = valueToDisplay;
        }
    }, [inputRef.current, hasDefinedValue, valueToDisplay]);

    React.useEffect(() => {
        if (inputRef.current && isPropertySelected) {
            inputRef.current.focus();
        }
    }, [isPropertySelected]);

    return inputRef;
};

type StringValueProps = PropertyValueProps & { isNumber?: boolean };

const StringPropertyEntry = ({
    id,
    component,
    name,
    dataAttributes,
    isReadOnly,
    onPropertyUpdate,
    isPropertySelected,
    isNumber,
}: StringValueProps) => {
    const { t } = useTranslation();
    const hasDefinedValue = typeof component[name] !== "undefined" && Boolean(String(component[name]).trim());
    const valueToDisplay = React.useMemo(
        () => (hasDefinedValue ? getStringPropertyDisplayValue(t, name, component[name].toString()) : ""),
        [hasDefinedValue, component[name]],
    );
    const inputRef = useStringInput(isPropertySelected, hasDefinedValue, valueToDisplay);
    const { handleChange } = usePropertyUpdates(name, valueToDisplay, inputRef.current, dataAttributes);

    const handleBlur = (ev: React.ChangeEvent<HTMLInputElement>) =>
        onPropertyUpdate({ item: component, propertyName: name, propertyValue: ev.target.value });

    const handleKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
        if (ev.key === "Enter") {
            onPropertyUpdate({ item: component, propertyName: name, propertyValue: ev.currentTarget.value });
        }
    };

    const inputType = isNumber === true ? "number" : "text";

    return !isReadOnly ? (
        <input
            ref={inputRef}
            type={inputType}
            className="eui-text-input"
            data-testid={`input-${name}`}
            id={id}
            aria-label={t("stringEntry.enterValueForName", { name })}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
        />
    ) : (
        <>{valueToDisplay}</>
    );
};

export default StringPropertyEntry;
