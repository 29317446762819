import React from "react";
import { FormElement, TextInput } from "@emisgroup/ui-kit-react/build/dist/src";
import { useTranslation } from "@emisgroup/application-intl";
import AppModeContext from "../context/appMode";
import { AppMode, DataFromTemplateRun, DataEntryComponentProps } from "../types";
import DataEntryComponent from "./dataEntryComponent";
import { RunningTemplateContext } from "../context";
import NumericEntry from "./numericEntry";

type NumericValueProps = { value?: number } & DataEntryComponentProps;

const NumericValue = (props: NumericValueProps) => {
    const { label, id, onChange } = props;
    const { t } = useTranslation();
    const { mode } = React.useContext(AppModeContext);
    const { templateData = {} } = React.useContext(RunningTemplateContext);
    const isInRunMode = mode === AppMode.RUN;
    const componentId = `component-${id}`;

    const handleUpdate = (value: number) => {
        onChange({ [id]: { interacted: true, value: Number.isNaN(value) ? undefined : value } } as DataFromTemplateRun);
    };

    const renderInRunMode = () => {
        const value = templateData[id] ? (templateData[id].value as number) : undefined;
        const isValid = props.runtimeValidator ? props.runtimeValidator(templateData[id]) : true;
        return (
            <div className="canvas-item-entry">
                <DataEntryComponent {...props} areValuesValid={isValid}>
                    <FormElement errorText={isValid ? undefined : t("components.numericEntry.enterANumber")}>
                        <NumericEntry
                            className={`eui-text-input ${!isValid ? "eui-text-input--invalid" : ""}`}
                            id={componentId}
                            aria-label={label}
                            onChange={handleUpdate}
                            onBlur={handleUpdate}
                            defaultValue={value}
                        />
                    </FormElement>
                </DataEntryComponent>
            </div>
        );
    };

    const renderInEditMode = () => (
        <DataEntryComponent {...props}>
            <div className="canvas-item-entry">
                <TextInput
                    id={componentId}
                    ariaLabel={label}
                    inputType="number"
                    value={String(props.value)}
                    disabled={true}
                />
            </div>
        </DataEntryComponent>
    );

    return isInRunMode ? renderInRunMode() : renderInEditMode();
};

export default NumericValue;
