import React from "react";
import { Code } from "@emisgroup/clint-templates-common";
import CodePickerPortal from "./codePickerPortal";

type CodePickerDialogProps = {
    bearerToken: string;
    initialCodes?: Code[];
    suppressPreferredTermAlert?: boolean;
    multiSelection?: boolean;
    onClose: () => void;
    onCodeSelect?: (code: Code) => void;
    onCodeListSelect?: (codes: Code[]) => void;
};

const CodePickerDialog = ({
    bearerToken,
    initialCodes, // TODO multi-code picker should accept this
    suppressPreferredTermAlert = false,
    multiSelection,
    onClose,
    onCodeSelect,
    onCodeListSelect,
}: CodePickerDialogProps) => {
    const toTemplateCode = pickerCode => ({
        emisCodeId: Number(pickerCode.id),
        term: pickerCode.attributes.term,
        isNumeric:
            Boolean(pickerCode.attributes.qualifiers?.numeric) || Boolean(pickerCode.attributes.qualifiers?.compound),
        snomedConceptId: pickerCode.attributes.terminology["snomed-ct"].concept.id,
        snomedDescriptionId: pickerCode.attributes.terminology["snomed-ct"].description.id,
    });

    const handleCodeSelect = pickerCodeOrCodes => {
        const codes = (Array.isArray(pickerCodeOrCodes) ? pickerCodeOrCodes : [pickerCodeOrCodes]).map(toTemplateCode);
        if (onCodeSelect) codes.forEach(c => onCodeSelect(c));
        if (onCodeListSelect) onCodeListSelect((initialCodes || []).concat(codes));
    };

    return (
        <CodePickerPortal
            bearerToken={bearerToken}
            suppressPreferredTermAlert={suppressPreferredTermAlert}
            multiSelection={multiSelection}
            onCodeSelect={handleCodeSelect}
            onClose={onClose}
        />
    );
};

export default CodePickerDialog;
