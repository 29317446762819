import * as React from "react";
import { DateEntry } from "@emisgroup/clinical-code-entry";
import { useTranslation } from "@emisgroup/application-intl";
import { Validity } from "@emisgroup/clinical-code-entry/lib/types";
import AppModeContext from "../context/appMode";
import { AppMode, TemplateData } from "../types";
import { componentHasValue } from "../utils";
import { RunningTemplateContext } from "../context";
import useDateEntry from "../useDateEntry";

export type EffectiveDateProps = {
    id: string;
    type: string;
    hasDatePrompt?: boolean;
    futureDateAllowed?: boolean;
    date?: string;
    getComponentHasValue?: (templateData: TemplateData) => boolean;
    onDateChange?: (date: string, validity: Validity) => void;
};

const ComponentEffectiveDate = ({
    id,
    hasDatePrompt,
    type,
    futureDateAllowed,
    date,
    getComponentHasValue,
    onDateChange,
}: EffectiveDateProps) => {
    const { t } = useTranslation();
    const { mode } = React.useContext(AppModeContext);
    const isInRunMode = mode === AppMode.RUN;
    const { templateData = {}, runDate } = React.useContext(RunningTemplateContext);

    const hasValue =
        typeof getComponentHasValue !== "undefined"
            ? getComponentHasValue(templateData)
            : componentHasValue(templateData, id, type);
    const isAvailable = isInRunMode && hasDatePrompt && hasValue;
    const dateEntryRef = React.useRef<HTMLDivElement>(null);
    useDateEntry(dateEntryRef.current);

    if (!isAvailable) {
        return null;
    }

    const datePicker = (
        <div ref={dateEntryRef} className="indented-data-entry">
            <DateEntry
                data-testid="effective-date-picker"
                dateValue={date}
                onChange={onDateChange || (() => {})}
                todaysDate={runDate}
                futureDateAllowed={futureDateAllowed ?? false}
                isMandatory={false}
                label={t("components.date")}
            />
        </div>
    );

    return <div className="canvas-item-entry">{datePicker}</div>;
};

export default ComponentEffectiveDate;
