/* eslint-disable no-console */
import React from "react";
import { Button } from "@emisgroup/ui-kit-react";
import { Store } from "redux";
import { useTranslation } from "@emisgroup/application-intl";
import Application, { IApplicationProps } from "./App";
import applicationContext from "./localDev/defaults/AppContext";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "./authconfig";

/**
 * Responsible for rendering the component as per route path
 */

const AppLocalWithAuth = (store: Store): JSX.Element => {
    const { t } = useTranslation();
    const { instance } = useMsal();

    const login = async (): Promise<void> => {
        instance.loginRedirect(loginRequest);
    };

    const logout = async (): Promise<void> => {
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    };

    const applicationProps: IApplicationProps = {
        applicationContext,
        isRunningInApplicationFrame: false,
        store,
    };

    return (
        <>
            <UnauthenticatedTemplate>
                <div>{t("app.youNeedToLogin")}</div>
                <Button id="login-button" onClick={login}>
                    {t("app.login")}
                </Button>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <Button id="logout-button" onClick={logout}>
                    {t("app.logout")}
                </Button>
                <Application {...applicationProps} />
            </AuthenticatedTemplate>
        </>
    );
};

export default AppLocalWithAuth;
