import {
    CanvasItem,
    ComponentContainer,
    ComponentType,
    FreeTextComponent,
    isContainerType,
    NumericValueComponent,
    PickingListComponent,
    PickingListOption,
    TemplateData,
    TemplateDataItem,
    UncodedComponent,
    UncodedActionItem,
    NumericActionItem,
    TextActionItem,
    PickingListActionItem,
    ActionItem,
    PanelActionItem,
    ActionData,
    ClinicalContentComponent,
    QueryResults,
    ClinicalContentActionItem,
    CodedComponent,
    CodedActionItem,
    CodedNumericActionItem,
    CodeValueType,
    CodedPickingListComponent,
    CodedPickingListActionItem,
    CodedDataItem,
    DiaryEntryComponent,
    DiaryEntryActionItem,
} from "@emisgroup/clint-templates-common";
import dateformat from "dateformat";
import { contentToHtml } from "@emisgroup/clint-content";
import { BrandingActionItem, BrandingComponent } from "@emisgroup/clint-templates-common/src/types";

type InteractedActionItem =
    | UncodedActionItem
    | NumericActionItem
    | TextActionItem
    | PickingListActionItem
    | ClinicalContentActionItem
    | CodedActionItem
    | CodedNumericActionItem
    | CodedPickingListActionItem
    | DiaryEntryActionItem;
const withInteraction =
    (func: (item: CanvasItem, templateDataItem: TemplateDataItem) => InteractedActionItem) =>
    (item: CanvasItem, templateDataItem: TemplateDataItem): InteractedActionItem => {
        const actionItem = func(item, templateDataItem);
        return {
            ...actionItem,
            ...(templateDataItem.interacted && { interacted: templateDataItem.interacted }),
            ...(item.perspectives && { perspectives: item.perspectives }),
        };
    };

const createUncodedActionItem = withInteraction(
    (item: UncodedComponent, dataItem: TemplateDataItem): UncodedActionItem => ({
        type: ComponentType.UNCODED,
        label: item.label,
        data: item.data,
        selected: Boolean(dataItem.selected),
    }),
);

const createCodedActionItem = withInteraction(
    (item: CodedComponent, dataItem: TemplateDataItem): CodedActionItem => ({
        type: ComponentType.CODED,
        label: item.label,
        codeId: item.code.emisCodeId,
        selected: Boolean(dataItem.selected),
    }),
);

const createNumericActionItem = withInteraction(
    (item: NumericValueComponent, dataItem: TemplateDataItem): NumericActionItem => ({
        type: ComponentType.NUMERIC_VALUE,
        label: item.label,
        value: dataItem.value?.toString() || "",
    }),
);

const createCodedNumericActionItem = withInteraction(
    (item: CodedComponent, dataItem: TemplateDataItem): CodedNumericActionItem => ({
        type: ComponentType.CODED,
        label: item.label,
        codeId: item.code.emisCodeId,
        value: dataItem.value ? (dataItem.value as CodeValueType).values.join("/") : "",
    }),
);

const createFreeTextActionItem = withInteraction(
    (item: FreeTextComponent, dataItem: TemplateDataItem): TextActionItem => ({
        type: ComponentType.FREE_TEXT,
        label: item.label,
        value: dataItem.value?.toString() || "",
    }),
);

const createPickingListActionItem = withInteraction(
    (item: PickingListComponent, dataItem: TemplateDataItem): PickingListActionItem => ({
        type: ComponentType.PICKING_LIST,
        label: item.label,
        options: item.options.map(({ text, data }) => ({ text, data })),
        selected:
            dataItem.items
                ?.filter(({ selected }) => selected)
                .map(({ value }) => value)
                .map((value: PickingListOption) => ({ text: value.text, data: value.data })) || [],
    }),
);

const createCodedPickingListActionItem = withInteraction(
    (item: CodedPickingListComponent, dataItem: TemplateDataItem): CodedPickingListActionItem => ({
        type: ComponentType.CODED_PICKING_LIST,
        label: item.label,
        options: item.codes.map(({ term }) => ({ text: term })),
        selected:
            dataItem.items
                ?.filter(({ selected }) => selected)
                .map((codedDataItem: CodedDataItem) => ({ text: codedDataItem.code.term })) || [],
    }),
);

function createClinicalContentActionItem(
    item: ClinicalContentComponent,
    queryResults: QueryResults,
): ClinicalContentActionItem {
    return {
        type: ComponentType.CLINICAL_CONTENT,
        label: item.label,
        content: contentToHtml({ content: item.content, queryResults }),
        ...(item.perspectives && { perspectives: item.perspectives }),
    };
}

const createDiaryEntryActionItem = withInteraction(
    (item: DiaryEntryComponent, dataItem: TemplateDataItem): DiaryEntryActionItem => ({
        type: ComponentType.DIARY_ENTRY,
        label: item.label,
        codeId: item.code.emisCodeId,
        date: dataItem.selected && dataItem.value ? (dataItem.value as Date).toISOString() : "",
    }),
);

function createPanelActionItem(item: ComponentContainer, items: ActionItem[]): PanelActionItem {
    return {
        type: ComponentType.PANEL,
        label: item.label,
        items,
        ...(item.perspectives && { perspectives: item.perspectives }),
    };
}

function createBrandingActionItem(item: BrandingComponent): BrandingActionItem {
    return {
        type: ComponentType.BRANDING,
        ...(item.perspectives && { perspectives: item.perspectives }),
    };
}

function createActionItems(item: CanvasItem, templateData: TemplateData, queryResults: QueryResults): ActionItem[] {
    const templateDataItem = templateData[item.id];
    switch (item.type) {
        case ComponentType.UNCODED:
            return [createUncodedActionItem(item as UncodedComponent, templateDataItem)];
        case ComponentType.NUMERIC_VALUE:
            return [createNumericActionItem(item as NumericValueComponent, templateDataItem)];
        case ComponentType.FREE_TEXT:
            return [createFreeTextActionItem(item as FreeTextComponent, templateDataItem)];
        case ComponentType.PANEL:
            return [
                createPanelActionItem(
                    item as ComponentContainer,
                    (item as ComponentContainer).members.flatMap(member =>
                        createActionItems(member, templateData, queryResults),
                    ),
                ),
            ];
        case ComponentType.PICKING_LIST:
            return [createPickingListActionItem(item as PickingListComponent, templateDataItem)];
        case ComponentType.CLINICAL_CONTENT:
            return [createClinicalContentActionItem(item as ClinicalContentComponent, queryResults)];
        case ComponentType.CODED: {
            const codedItem = item as CodedComponent;
            const createActionItem = codedItem.code.isNumeric ? createCodedNumericActionItem : createCodedActionItem;
            return [createActionItem(codedItem, templateDataItem)];
        }
        case ComponentType.CODED_PICKING_LIST:
            return [createCodedPickingListActionItem(item as CodedPickingListComponent, templateDataItem)];
        case ComponentType.DIARY_ENTRY:
            return [createDiaryEntryActionItem(item as DiaryEntryComponent, templateDataItem)];
        case ComponentType.BRANDING:
            return [createBrandingActionItem(item as BrandingComponent)];
        default:
            if (isContainerType(item.type)) {
                return (item as ComponentContainer).members.flatMap(member =>
                    createActionItems(member, templateData, queryResults),
                );
            }

            return [];
    }
}

export default function actionData(
    dateTimeCompleted: Date,
    items: CanvasItem[],
    templateData: TemplateData,
    queryResults: QueryResults,
): ActionData {
    return {
        dateCompleted: dateformat(dateTimeCompleted, "yyyy-mm-dd HH:MM"),
        items: items.flatMap(item => createActionItems(item, templateData, queryResults)),
    };
}
