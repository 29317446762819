import { ConfidentialLock } from "@emisgroup/icons-react";
import { useTranslation } from "@emisgroup/application-intl";

type LockedTabProps = {
    isEmbedded: boolean;
};

const TabLock = ({ isEmbedded }: LockedTabProps) =>
    isEmbedded ? (
        <ConfidentialLock className="tab-lock" title={useTranslation().t("components.tabBar.locked")} />
    ) : null;

export default TabLock;
