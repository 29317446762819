import { ComponentType, getComponentTypeClass, isContainerType } from "@emisgroup/clint-templates-common";
import * as React from "react";

const CANVAS_ITEM_CONTAINER_CLASS = "canvas-item-entry-container";

const validScrollElementClasses = Object.values(ComponentType)
    .filter(type => !isContainerType(type))
    .map(getComponentTypeClass);

const getScrollElementRect = (element: Element | null) => {
    if (!element) {
        return null;
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const className of element.classList) {
        if (validScrollElementClasses.includes(className)) {
            return element.getBoundingClientRect();
        }
    }

    return null;
};

const getScrollY = ({
    topRect,
    bottomRect,
    activeRect,
    containerRect,
}: {
    topRect: DOMRect;
    bottomRect: DOMRect;
    activeRect: DOMRect;
    containerRect: DOMRect;
}) => {
    const { top: canvasItemTop } = topRect;
    const { bottom: canvasItemBottom } = bottomRect;
    const { top: containerTop, bottom: containerBottom, height: containerHeight } = containerRect;
    const { height: activeHeight } = activeRect;

    if (activeHeight >= containerHeight) {
        return 0;
    }

    const overflowAbove = canvasItemTop < containerTop;
    const overflowBelow = canvasItemBottom > containerBottom;
    if (!overflowAbove && !overflowBelow) {
        return 0;
    }

    const overflowAboveAndBelow = overflowAbove && overflowBelow;
    if (overflowAboveAndBelow && activeRect.bottom !== bottomRect.bottom) {
        return getScrollY({ topRect, bottomRect: activeRect, activeRect, containerRect });
    }
    if (overflowAboveAndBelow && activeRect.top !== bottomRect.top) {
        return getScrollY({ topRect: activeRect, bottomRect: activeRect, activeRect, containerRect });
    }

    return overflowBelow ? canvasItemBottom - containerBottom : canvasItemTop - containerTop;
};

const useAutoScroll = (container: React.RefObject<HTMLElement>) => {
    const activeCanvasItemEntryContainer = React.useRef<Element | null>(null);

    const handleResize = () => {
        if (!activeCanvasItemEntryContainer.current || !container.current) {
            return;
        }

        const activeClientRect = activeCanvasItemEntryContainer.current.getBoundingClientRect();
        const topClientRect =
            getScrollElementRect(activeCanvasItemEntryContainer.current.previousElementSibling) || activeClientRect;
        const bottomClientRect =
            getScrollElementRect(activeCanvasItemEntryContainer.current.nextElementSibling) || activeClientRect;
        const scrollY = getScrollY({
            topRect: topClientRect,
            bottomRect: bottomClientRect,
            activeRect: activeClientRect,
            containerRect: container.current.getBoundingClientRect(),
        });
        if (scrollY) {
            container.current.scrollBy(0, scrollY);
        }
    };

    const resizeObserver = React.useRef(new ResizeObserver(handleResize));

    const removeResizeObserverFromCanvasItem = () => {
        if (activeCanvasItemEntryContainer.current) {
            resizeObserver.current.unobserve(activeCanvasItemEntryContainer.current);
        }
    };

    const addResizeObserverToCanvasItem = () => {
        if (activeCanvasItemEntryContainer.current) {
            resizeObserver.current.observe(activeCanvasItemEntryContainer.current);
        }
    };

    const findCanvasItemEntryContainer = (element: Element) => {
        if (element.classList.contains(CANVAS_ITEM_CONTAINER_CLASS)) {
            return element;
        }

        if (!element.parentElement || !container.current?.contains(element.parentElement)) {
            return null;
        }

        return findCanvasItemEntryContainer(element.parentElement);
    };

    React.useEffect(() => {
        const handleFocusIn = () => {
            if (!document.activeElement || !container.current?.contains(document.activeElement)) {
                removeResizeObserverFromCanvasItem();
                return;
            }

            const canvasItem = findCanvasItemEntryContainer(document.activeElement);
            if (canvasItem === activeCanvasItemEntryContainer.current) {
                return;
            }

            removeResizeObserverFromCanvasItem();
            activeCanvasItemEntryContainer.current = canvasItem;
            addResizeObserverToCanvasItem();
        };
        document.addEventListener("focusin", handleFocusIn);
        return () => document.removeEventListener("focusin", handleFocusIn);
    }, []);
};

export default useAutoScroll;
