import * as React from "react";
import { useTranslation } from "@emisgroup/application-intl";
import { Dropdown, IListItem } from "@emisgroup/ui-kit-react";
import { Condition, ConditionSource, ComponentValueSelectedCondition } from "@emisgroup/clint-templates-common";

const is = "is";
const isNot = "isNot";

type VisibilityConditionOperatorProps = {
    condition: Condition;
    conditionIndex: number;
    canEdit: boolean;
    onChanged: (negated?: boolean) => void;
};

const VisibilityConditionOperator = ({
    condition,
    conditionIndex,
    canEdit,
    onChanged,
}: VisibilityConditionOperatorProps) => {
    const { t } = useTranslation();

    const options: IListItem[] = [
        {
            text: t("templates.rules.is"),
            value: is,
        },
        {
            text: t("templates.rules.isNot"),
            value: isNot,
        },
    ];

    if (condition.conditionSource === ConditionSource.COMPONENT_VALUE_SELECTED) {
        const componentValueSelectedCondition = condition as ComponentValueSelectedCondition;
        const currentValue = componentValueSelectedCondition.negated === true ? isNot : is;

        return (
            <div className="visibility-condition__condition__operator">
                <Dropdown
                    data-testid={`condition-operator-${conditionIndex}`}
                    dataSource={options}
                    onChange={value => onChanged(value === isNot ? true : undefined)}
                    value={currentValue}
                    disabled={!canEdit}
                />
            </div>
        );
    }

    return (
        <div className="visibility-condition__condition__is">
            {condition.conditionSource === ConditionSource.QUERY ? t("templates.rules.has") : t("templates.rules.is")}
        </div>
    );
};

export default VisibilityConditionOperator;
