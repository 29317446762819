import {
    CanvasItem,
    ComponentType,
    ComponentContainer,
    PickingListComponent,
    ComponentValueCondition,
    findCanvasItem,
    CodedPickingListComponent,
} from "@emisgroup/clint-templates-common";
import { getCanvasItemIdsActedOnBy } from "./ruleUtils";

function isPickingListWithDeletedOptions(item: CanvasItem, updated: CanvasItem) {
    const isTextPickingListWithDeletedOptions =
        item.type === ComponentType.PICKING_LIST &&
        (item as PickingListComponent).options.length > (updated as PickingListComponent).options.length;
    const isCodedPickingListWithDeletedOptions =
        item.type === ComponentType.CODED_PICKING_LIST &&
        (item as CodedPickingListComponent).codes.length > (updated as CodedPickingListComponent).codes.length;

    return isTextPickingListWithDeletedOptions || isCodedPickingListWithDeletedOptions;
}

function getOptionIds(item: CanvasItem) {
    return item.type === ComponentType.CODED_PICKING_LIST
        ? (item as CodedPickingListComponent).codes.map(({ emisCodeId }) => emisCodeId.toString())
        : (item as PickingListComponent).options.map(({ id }) => id);
}
export function getUpdateSideEffects(item: CanvasItem, updated: CanvasItem, template: ComponentContainer) {
    if (!isPickingListWithDeletedOptions(item, updated)) {
        return [];
    }

    const itemsActedOn = getCanvasItemIdsActedOnBy(item.id, template);
    if (!itemsActedOn.length) {
        return [];
    }

    const updatedOptionIds = getOptionIds(updated);
    const itemOptionIds = getOptionIds(item);
    const [deletedOptionId] = itemOptionIds.filter(id => !updatedOptionIds.includes(id));

    const sideEffects: CanvasItem[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const itemId of itemsActedOn) {
        const actedOnItem = findCanvasItem(itemId, template.members);
        if (!actedOnItem?.rule) {
            break;
        }

        const updatedActedOnItem = {
            ...actedOnItem,
            rule: {
                ...actedOnItem.rule,
                conditionMembers: actedOnItem.rule.conditionMembers.filter(
                    member => (member as ComponentValueCondition).value !== deletedOptionId,
                ),
            },
        };

        sideEffects.push(updatedActedOnItem);
    }

    return sideEffects;
}
