import React from "react";
import { AppMode, DataFromTemplateRun, DataEntryComponentProps } from "../types";
import { AppModeContext, RunningTemplateContext } from "../context";
import Checkbox from "./controlledCheckbox";
import DataEntryComponent from "./dataEntryComponent";

type UncodedProps = DataEntryComponentProps;

const Uncoded = (props: UncodedProps) => {
    const { id, label, onChange, isMandatory } = props;
    const { mode } = React.useContext(AppModeContext);
    const { templateData = {} } = React.useContext(RunningTemplateContext);

    const handleBlur = () => {
        onChange({ [id]: { interacted: true } } as DataFromTemplateRun);
    };

    const isInRunMode = mode === AppMode.RUN;
    const updateData = ev => {
        const isChecked = ev.target.checked;
        const data = {
            [id]: {
                selected: isChecked,
                interacted: true,
            },
        } as DataFromTemplateRun;
        onChange(data);
    };

    const checked = React.useMemo(() => {
        if (!isInRunMode) {
            return false;
        }

        return templateData[id] ? Boolean(templateData[id].selected) : false;
    }, [templateData, isInRunMode]);

    const renderInRunMode = () => {
        const isValid = props.runtimeValidator ? props.runtimeValidator(templateData[id]) : true;
        return (
            <DataEntryComponent {...props} areValuesValid={isValid} className="checkbox-component">
                <div className="canvas-item-selection">
                    <Checkbox
                        id={`component-${id}`}
                        onChange={updateData}
                        onBlur={handleBlur}
                        checked={checked}
                        labelText={label}
                        invalid={!isValid}
                        isMandatory={isMandatory}
                        tabIndex={-1}
                    />
                </div>
            </DataEntryComponent>
        );
    };

    const renderInEditMode = () => (
        <DataEntryComponent {...props} className="checkbox-component">
            <div className="canvas-item-selection">
                <Checkbox
                    id={`component-${id}`}
                    disabled={true}
                    checked={false}
                    labelText={label}
                    isMandatory={isMandatory}
                />
            </div>
        </DataEntryComponent>
    );

    return isInRunMode ? renderInRunMode() : renderInEditMode();
};

export default Uncoded;
