import { contentToText } from "@emisgroup/clint-content";
import { CanvasItem, MultilineTextEntry } from "@emisgroup/clint-templates-common";
import * as React from "react";
import { ParametersForPropertyUpdate } from "../types";

type MultiLineStringPropertyEntryProps = {
    id: string;
    isReadOnly: boolean;
    component: CanvasItem;
    name: string;
    onPropertyUpdate: (params: ParametersForPropertyUpdate) => void;
};

const DEBOUNCE_TIMEOUT_MS = 500;
const MultiLineStringPropertyEntry = ({
    id,
    component,
    name,
    isReadOnly,
    onPropertyUpdate,
}: MultiLineStringPropertyEntryProps) => {
    const debounceTimerRef = React.useRef<number | NodeJS.Timeout | null>(null);
    React.useEffect(() => {
        return () => {
            if (debounceTimerRef.current) {
                clearTimeout(debounceTimerRef.current as number);
            }
        };
    }, []);

    const hasDefinedValue = typeof component[name] !== "undefined" && Boolean(String(component[name]).trim());
    const valueToDisplay = hasDefinedValue ? component[name].toString() : "";
    const updateProperty = (propertyValue: string) =>
        onPropertyUpdate({ item: component, propertyName: name, propertyValue });

    const handleChange = (changedText: string) => {
        if (debounceTimerRef.current) {
            clearTimeout(debounceTimerRef.current as number);
        }

        debounceTimerRef.current = setTimeout(() => updateProperty(changedText), DEBOUNCE_TIMEOUT_MS);
    };

    const handleBlur = (componentText: string) => {
        clearTimeout(debounceTimerRef.current as number);
        debounceTimerRef.current = null;
        updateProperty(componentText);
    };
    const text = (component[name] as any)?.blocks ? contentToText(component[name]) : component[name];
    return !isReadOnly ? (
        <MultilineTextEntry id={id} text={text} onChange={handleChange} onBlur={handleBlur} />
    ) : (
        valueToDisplay
    );
};

export default MultiLineStringPropertyEntry;
