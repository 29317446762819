import * as React from "react";

type CanvasColumnProps = { children: React.ReactNode | React.ReactNodeArray; columnIndex: number };

const CanvasColumn = React.forwardRef<HTMLDivElement, CanvasColumnProps>(
    ({ children, columnIndex }: CanvasColumnProps, ref) => {
        const columnIdentifier = `canvas-column-${columnIndex}`;
        return (
            <div data-testid={columnIdentifier} className={`canvas-column ${columnIdentifier}`} ref={ref}>
                {children}
            </div>
        );
    },
);

export default CanvasColumn;
