import * as React from "react";
import { ConfigContext, FeaturesConfig } from "@emisgroup/clint-templates-common";
import features from "../features";

type ConfigProviderProps = {
    children: React.ReactNode | React.ReactNodeArray;
    contentLibraryUrl?: string;
    platformUrl?: string;
    freeTextMaxChars?: number;
    featuresConfig?: FeaturesConfig;
};

const ConfigProvider = ({
    children,
    contentLibraryUrl = process.env.APP_CONTENT_LIBRARY_URL as string,
    platformUrl = process.env.APP_PROBLEMS_API_URL as string,
    freeTextMaxChars = Number(process.env.APP_MAX_FREE_TEXT_CHARS as string),
    featuresConfig = features(),
}: ConfigProviderProps) => (
    <ConfigContext.Provider
        value={{
            features: featuresConfig,
            contentLibraryUrl,
            platformUrl,
            freeTextMaxChars,
        }}
    >
        {children}
    </ConfigContext.Provider>
);

export default ConfigProvider;
