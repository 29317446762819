import * as React from "react";
import { NewWindow } from "@emisgroup/icons-react";
import { useTranslation } from "@emisgroup/application-intl";
import { PickingListOption, TemplateContext } from "@emisgroup/clint-templates-common";
import { addConditionalPanel } from "../utils/conditionalPanelUtils";

type ListBuilderConditionalPanelButtonProps = {
    dataTestId: string;
    option: PickingListOption;
};

export default function ListBuilderConditionalPanelButton({
    dataTestId,
    option,
}: ListBuilderConditionalPanelButtonProps) {
    const { t } = useTranslation();
    const { templateDefinition, selectedItem, setTemplateDefinition, setSelectedItem } =
        React.useContext(TemplateContext);

    const handleClick = () => {
        const [updatedTemplate, conditionalPanel] = addConditionalPanel(templateDefinition, selectedItem, t, option);

        setTemplateDefinition(updatedTemplate);
        setSelectedItem(conditionalPanel);
    };

    return (
        <button type="button" data-testid={dataTestId} className="btn btn-default option-button" onClick={handleClick}>
            <NewWindow title={t("addConditionalPanel")} />
        </button>
    );
}
