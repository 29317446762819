import {
    ComponentContainer,
    ActionType,
    ActionData,
    CanvasItem,
    PerspectiveSetting,
} from "@emisgroup/clint-templates-common";
import { ErrorHandling, ErrorState } from "@emisgroup/clinical-code-entry/lib/types";
import { applyPerspectives } from "@emisgroup/clint-templates-perspectives";
import Canvas from "./canvas";
import PatientDataProvider from "./context/patientDataProvider";
import RunningTemplateProvider from "./context/runningTemplateProvider";
import TemplateHistoryProvider from "./context/templateHistoryProvider";
import ComponentConfigDataProvider from "./context/componentConfigDataProvider";
import AppModeProvider from "./context/appModeProvider";
import ConfigProvider from "./context/configProvider";
import { RunStyle, TemplateFilingItem } from "./types";
import AuthProvider from "./context/authProvider";
import ActionsProvider from "./context/actionsProvider";
import ClinicalContainer from "./clinical/container";
import AssessmentContainer from "./assessment/container";

const DEFAULT_FREE_TEXT_MAX_CHARS = 500;

type ComponentOptions = {
    freeTextMaxChars?: number;
};

type RunnerProps = {
    templateDefinition: ComponentContainer;
    queryApiUrl: string;
    ckbUrl: string;
    platformUrl: string;
    getBearerToken: () => Promise<string>;
    cancelTemplate: () => void;
    patientId?: string;
    className?: string;
    componentOptions?: ComponentOptions;
    onChange?: (filingItems: TemplateFilingItem[]) => void;
    onHandleError?: (errorState: ErrorState) => ErrorHandling;
    showDefaultActionButtons?: boolean;
    onHandleAction?: (actionType: ActionType, actionData: ActionData, validData: boolean | null) => void;
    runStyle?: RunStyle;
    dimensionValues?: string[];
    defaultPerspectivesKey?: string;
};

const Runner = ({
    templateDefinition,
    queryApiUrl,
    ckbUrl,
    platformUrl,
    getBearerToken,
    cancelTemplate,
    patientId = "",
    className = "",
    componentOptions = { freeTextMaxChars: DEFAULT_FREE_TEXT_MAX_CHARS },
    onChange,
    onHandleError,
    showDefaultActionButtons = false,
    onHandleAction,
    runStyle = RunStyle.Clinical,
    dimensionValues = [],
    defaultPerspectivesKey = "",
}: RunnerProps) => {
    const runtimeTemplateDefinition: ComponentContainer = {
        ...templateDefinition,
        members: applyPerspectives(
            defaultPerspectivesKey,
            [PerspectiveSetting.RUNNER, ...dimensionValues],
            templateDefinition.members,
            "members",
        ) as CanvasItem[],
    };

    return (
        <AppModeProvider>
            <AuthProvider getBearerToken={getBearerToken}>
                <ConfigProvider
                    freeTextMaxChars={componentOptions.freeTextMaxChars || DEFAULT_FREE_TEXT_MAX_CHARS}
                    platformUrl={platformUrl}
                >
                    <RunningTemplateProvider
                        templateDefinition={templateDefinition}
                        onChange={onChange}
                        onHandleError={onHandleError}
                    >
                        <PatientDataProvider
                            templateDefinition={runtimeTemplateDefinition}
                            patientId={patientId}
                            queryApiUrl={queryApiUrl}
                            getBearerToken={getBearerToken}
                        >
                            <ActionsProvider
                                templateDefinition={templateDefinition}
                                queryApiUrl={queryApiUrl}
                                getBearerToken={getBearerToken}
                                showDefaultActionButtons={showDefaultActionButtons}
                                onHandleAction={onHandleAction}
                            >
                                <ComponentConfigDataProvider initialComponentConfig={[]}>
                                    <TemplateHistoryProvider
                                        templateDefinition={runtimeTemplateDefinition}
                                        patientId={patientId}
                                        queryApiUrl={queryApiUrl}
                                        getBearerToken={getBearerToken}
                                    >
                                        <Canvas
                                            key={`${patientId}-${templateDefinition.id}`}
                                            templateDefinition={runtimeTemplateDefinition}
                                            queryApiUrl={queryApiUrl}
                                            ckbUrl={ckbUrl}
                                            getBearerToken={getBearerToken}
                                            cancelTemplate={cancelTemplate}
                                            className={`${className} ${runStyle}`}
                                            runningContainer={
                                                runStyle === RunStyle.Assessment
                                                    ? AssessmentContainer
                                                    : ClinicalContainer
                                            }
                                        />
                                    </TemplateHistoryProvider>
                                </ComponentConfigDataProvider>
                            </ActionsProvider>
                        </PatientDataProvider>
                    </RunningTemplateProvider>
                </ConfigProvider>
            </AuthProvider>
        </AppModeProvider>
    );
};

export default Runner;
