import * as React from "react";
import { useDrop } from "react-dnd";
import { $enum } from "ts-enum-util";
import {
    CanvasColumn,
    TemplateContext,
    CanvasItem,
    CanvasItemClickHandler,
    ComponentType,
    ComponentContainer,
} from "@emisgroup/clint-templates-common";
import { ComponentDragDropLayout, ParametersForPropertyUpdate } from "../types";
// eslint-disable-next-line import/no-cycle
import ComponentRenderer from "./componentRenderer";
import { findIndicesOfItems } from "../utils/componentUtils";
import { canDropItemInto } from "../utils/dragDropUtils";

type DesignerCanvasColumnProps = {
    columnIndex: number;
    items: CanvasItem[];
    parent: ComponentContainer;
    dragDropEnabled: boolean;
    onSelect: CanvasItemClickHandler;
    onPropertyUpdate: (params: ParametersForPropertyUpdate) => void;
    onItemDropped: (item: any, index: number, columnIndex: number) => void;
};

const DesignerCanvasColumn = ({
    columnIndex,
    items,
    parent,
    dragDropEnabled,
    onSelect,
    onPropertyUpdate,
    onItemDropped,
}: DesignerCanvasColumnProps) => {
    const { selectedItem, groupedItems, invalidComponentDefinitionIds } = React.useContext(TemplateContext);
    const { members: parentItems } = parent;
    const firstItemIndex = React.useMemo(() => parentItems.indexOf(items[0]), [items, parentItems]);

    const isSelected = (item: CanvasItem): boolean => selectedItem.id === item.id;

    const itemDropHandlerWithAdjustedPosition = (item: CanvasItem, index: number) => {
        const adjustedPosition = firstItemIndex !== -1 ? index + firstItemIndex : index;
        onItemDropped(item, adjustedPosition, columnIndex);
    };

    const canDropItem = (item: any): boolean => canDropItemInto(item, parent);

    const [, drop] = useDrop({
        accept: $enum(ComponentType).getValues(),
        canDrop(item: CanvasItem, monitor) {
            return monitor.isOver({ shallow: true }) && canDropItem(item);
        },
        drop(item: CanvasItem) {
            const adjustedPosition = firstItemIndex !== -1 ? parentItems.length + firstItemIndex : parentItems.length;
            onItemDropped(item, adjustedPosition, columnIndex);
        },
    });

    const renderComponent = (indexOffset: number) => (item: CanvasItem, index: number) => {
        const componentIndex = index + indexOffset;
        const dragDropLayout: ComponentDragDropLayout = {
            dragDropEnabled,
            isFirstChild: item.id === items[0].id,
            isLastChild: item.id === items[items.length - 1].id,
        };

        return (
            <ComponentRenderer
                key={`${item.id}-${componentIndex}`}
                component={item}
                isSelected={isSelected(item)}
                arePropertiesValid={!invalidComponentDefinitionIds.includes(item.id)}
                onSelect={onSelect}
                onPropertyUpdate={onPropertyUpdate}
                index={componentIndex}
                dragDropLayout={dragDropLayout}
                onItemDropped={itemDropHandlerWithAdjustedPosition}
                canDropItem={canDropItem}
            />
        );
    };

    const renderComponents = (componentList: CanvasItem[]) => {
        const groupedItemsIndices: number[] = findIndicesOfItems(groupedItems, componentList);
        if (groupedItemsIndices.length) {
            const groupStartPosition = Math.min(...groupedItemsIndices);
            const groupEndPosition = Math.max(...groupedItemsIndices);

            return (
                <>
                    {componentList.slice(0, groupStartPosition).map(renderComponent(0))}
                    <div className="group" role="group" data-testid="item-group">
                        {componentList
                            .slice(groupStartPosition, groupEndPosition + 1)
                            .map(renderComponent(groupStartPosition))}
                    </div>
                    {componentList.slice(groupEndPosition + 1).map(renderComponent(groupEndPosition + 1))}
                </>
            );
        }

        return componentList.map(renderComponent(0));
    };

    return (
        <CanvasColumn columnIndex={columnIndex} ref={drop}>
            {renderComponents(items)}
        </CanvasColumn>
    );
};

export default DesignerCanvasColumn;
