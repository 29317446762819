import { useTranslation } from "@emisgroup/application-intl";
import { TemplateContext } from "@emisgroup/clint-templates-common";
import * as React from "react";
import { getBlockingErrorAlertDialog, getAlertDialogForUnsavedChanges } from "../utils/dialogUtils";
import useSave from "./useSave";

type Params = {
    onDialogCancel?: () => void;
    initialCheck?: boolean;
};

const useChangesWarning = ({ onDialogCancel, initialCheck = false }: Params) => {
    const { t } = useTranslation();
    const { hasDefinitionChanged, templateDefinition, invalidComponentDefinitionIds } =
        React.useContext(TemplateContext);
    const [showingChangesWarning, setShowingChangesWarning] = React.useState(initialCheck && hasDefinitionChanged);
    const [showingInvalidWarning, setShowingInvalidWarning] = React.useState(false);

    const [saving, setSaving] = React.useState(false);
    const executeOnChangesContinue = React.useRef<(() => any) | null>(null);

    const closeDialog = () => {
        setShowingChangesWarning(false);
        setShowingInvalidWarning(false);

        if (onDialogCancel) {
            onDialogCancel();
        }
    };

    const { save, view: saveView } = useSave({
        onCancelSave: () => {
            setSaving(false);
            closeDialog();
        },
    });

    const continueOperation = () => {
        setShowingChangesWarning(false);

        if (executeOnChangesContinue.current) {
            executeOnChangesContinue.current();
        }
        executeOnChangesContinue.current = null;
    };

    const saveAndContinueOperation = () => {
        if (invalidComponentDefinitionIds.length > 0) {
            setShowingInvalidWarning(true);
            return;
        }
        setSaving(true);
        save((isSaveSuccess: boolean) => {
            setSaving(false);
            if (isSaveSuccess) {
                continueOperation();
            }
        });
    };

    const withChangesWarning = (func: () => void) => () => {
        if (hasDefinitionChanged) {
            executeOnChangesContinue.current = func;
            setShowingChangesWarning(true);
        } else {
            executeOnChangesContinue.current = null;
            func();
        }
    };

    const getDialog = () => {
        if (saving) {
            return saveView;
        }

        if (showingInvalidWarning) {
            return getBlockingErrorAlertDialog({
                t,
                title: t("save.unableToSave"),
                errorMessage: t("save.errorMandatoryInfoMissing"),
                onClose: () => closeDialog(),
            });
        }

        return showingChangesWarning
            ? getAlertDialogForUnsavedChanges({
                  t,
                  templateName: templateDefinition.libraryName || templateDefinition.label,
                  saveAndContinueOperation,
                  continueOperation,
                  closeDialog,
              })
            : null;
    };

    return {
        withChangesWarning,
        dialog: getDialog(),
    };
};

export default useChangesWarning;
