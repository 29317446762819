/* eslint-disable react/style-prop-object */
import React from "react";
import { Button } from "@emisgroup/ui-kit-react";
import { ConfidentialLock, ShowPassword } from "@emisgroup/icons-react";
import { Panel, ActionButton, ActionType, ActionButtonStyle } from "@emisgroup/clint-templates-common";
import { useTranslation } from "@emisgroup/application-intl";
import "./panelActions.css";
import { v4 as uuid } from "uuid";
import { PANEL_ACTION_ITEM_DRAG_TYPE } from "../constants";
import PanelActionItem from "./panelActionItem";
import addDragDropSort from "./addDragDropSort";

export type ActionButtonWithKey = {
    actionButton: ActionButton;
    key: string;
};

const SortablePanelActionItem = addDragDropSort(PanelActionItem, PANEL_ACTION_ITEM_DRAG_TYPE);

const swapItems = (items: ActionButton[], fromIndex: number, toIndex: number) => {
    const updatedItems = items.slice();
    const to = updatedItems[toIndex];

    updatedItems[toIndex] = updatedItems[fromIndex];
    updatedItems[fromIndex] = to;

    return updatedItems;
};

const noPanelActionsHeader = t => {
    const title = t("templates.panelActionButtons.panelHasNoActionButtons");

    return (
        <>
            <div data-testid="no-actions-for-panel" className="panel-actions__header">
                <div>
                    <ShowPassword title={title} />
                </div>
                <div className="panel-actions__title">{title}</div>
            </div>
        </>
    );
};

const panelActionsHeader = t => {
    return (
        <div className="panel-actions__grid">
            <span className="panel-actions__grid__action">{t("templates.panelActionButtons.action")}</span>
            <span className="panel-actions__grid__label">{t("templates.panelActionButtons.label")}</span>
            <span className="panel-actions__grid__style">{t("templates.panelActionButtons.style")}</span>
            <span className="panel-actions__grid__validate">{t("templates.panelActionButtons.validate")}</span>
        </div>
    );
};

type PropsForPanelActions = {
    panel: Panel;
    editEnabled: boolean;
    invalidPanelActions: any;
    onPanelActionsUpdate: (actionButtons: ActionButton[] | null) => void;
    onPanelActionInteracted: (panelActionIndex: number, clearInteraction?: boolean) => void;
};

const PanelActions = ({
    panel,
    editEnabled,
    invalidPanelActions,
    onPanelActionsUpdate,
    onPanelActionInteracted,
}: PropsForPanelActions) => {
    const { t } = useTranslation();

    const actionButtons = panel.actionButtons ?? [];
    const [addedPanelActionIndex, setaddedPanelActionIndex] = React.useState<number | undefined>();
    const panelActionsDivRef = React.useRef<HTMLDivElement>(null);

    const handleAdd = () => {
        setaddedPanelActionIndex(actionButtons.length);
        const newActionButton: ActionButton = {
            action: ActionType.NEXTSTEP,
            label: "",
            style: ActionButtonStyle.PRIMARY,
            validate: true,
        };
        const updatedActionButtons = [...actionButtons, newActionButton];

        onPanelActionsUpdate(updatedActionButtons);
    };

    const handleRemove = (actionButtonIndex: number) => () => {
        setaddedPanelActionIndex(undefined);
        const updatedActionButtons = [...actionButtons];
        updatedActionButtons.splice(actionButtonIndex, 1);

        onPanelActionsUpdate(updatedActionButtons.length > 0 ? updatedActionButtons : null);
        onPanelActionInteracted(actionButtonIndex, true);
    };

    const handleUpdate = (index: number) => (updatedActionButton: ActionButton) => {
        setaddedPanelActionIndex(undefined);
        const updatedActionButtons = [...actionButtons];
        updatedActionButtons[index] = updatedActionButton;

        onPanelActionsUpdate(updatedActionButtons);
        onPanelActionInteracted(index);
    };

    const addPanelAction = (
        <Button
            variant="primary"
            className="panel-actions__add"
            iconName="add-secondary"
            aria-label={t("templates.panelActionButtons.addAnActionButton")}
            onClick={handleAdd}
        >
            {t("templates.panelActionButtons.addAnActionButton")}
        </Button>
    );

    const handleSwapItems = (fromIndex: number, toIndex: number) => {
        if (typeof fromIndex === "undefined" || typeof toIndex === "undefined") {
            return;
        }
        onPanelActionsUpdate(swapItems(actionButtons, fromIndex, toIndex));
    };

    React.useEffect(() => {
        if (typeof addedPanelActionIndex !== "undefined" && panelActionsDivRef.current?.scrollIntoView) {
            panelActionsDivRef.current.scrollIntoView();
        }
    }, [addedPanelActionIndex]);

    return (
        <div className="sidebar-right panel-actions" ref={panelActionsDivRef}>
            <div className="sidebar-row sidebar-row-header">
                {t("templates.panelActionButtons.panelActionButtons")}
                {!editEnabled && (
                    <ConfidentialLock
                        title={t("templates.panelActionButtons.panelActionButtonsLocked")}
                        className="panel-lock"
                    />
                )}
            </div>
            {actionButtons.length === 0 && noPanelActionsHeader(t)}
            <div className="panel-actions__display">
                {actionButtons.length > 0 && (
                    <>
                        {panelActionsHeader(t)}
                        {actionButtons.map((actionButton, index) => (
                            <SortablePanelActionItem
                                initialActionButton={actionButton}
                                index={index}
                                key={uuid()}
                                invalidProperties={invalidPanelActions[index.toString()]}
                                isReadOnly={!editEnabled}
                                setFocus={index === addedPanelActionIndex}
                                onRemove={handleRemove(index)}
                                onUpdate={handleUpdate(index)}
                                swapItems={handleSwapItems}
                            />
                        ))}
                    </>
                )}

                {editEnabled && addPanelAction}
            </div>
        </div>
    );
};

export default PanelActions;
