import * as React from "react";
import { RunningTemplateContext, TemplateDataItem, UncodedComponent } from "@emisgroup/clint-templates-common";
import { useTranslation } from "@emisgroup/application-intl";

type AssessmentUncodedProps = {
    uncoded: UncodedComponent;
    runtimeValidator: (templateData: TemplateDataItem) => boolean;
};

export default function AssessmentUncoded({ uncoded, runtimeValidator }: AssessmentUncodedProps) {
    const { t } = useTranslation();
    const { templateData, updateTemplateData } = React.useContext(RunningTemplateContext);
    const data = templateData[uncoded.id];
    const isValid = runtimeValidator(data);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateTemplateData({
            ...templateData,
            [uncoded.id]: { ...data, interacted: true, selected: e.currentTarget.checked },
        });
    };

    const handleBlur = () => {
        updateTemplateData({ ...templateData, [uncoded.id]: { ...data, interacted: true } });
    };
    return (
        <div className="nhsuk-checkboxes" data-testid={`uncoded-${uncoded.id}`}>
            <div className="nhsuk-checkboxes__item">
                <input
                    className="nhsuk-checkboxes__input"
                    id={`checkbox-${uncoded.id}`}
                    type="checkbox"
                    checked={data?.selected}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
                <label className="nhsuk-label nhsuk-checkboxes__label" htmlFor={`checkbox-${uncoded.id}`}>
                    {uncoded.label}
                </label>
            </div>
            {!isValid && (
                <span className="nhsuk-error-message">
                    <span className="nhsuk-u-visually-hidden">Error:</span> {t("components.selectionMandatory")}
                </span>
            )}
        </div>
    );
}
